import { Button, Col, Dropdown, Row, Spin, Table, TableProps } from "antd";
import { StyleSheet } from "../../../StyleSheet";
import { useCallback, useEffect, useState } from "react";
import { CatalogBankResponse, DeleteGroupsRequest, GroupResponse, Status, TableParams } from "../../../types/types";
import { EditOutlined, CloudDownloadOutlined, DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { deleteAccountGroupList, getAccountGroups, getBanks } from "../../../methods/axiosMethods";
import { useSelector } from "react-redux";
import { clearGroups, setGroups } from "../../../store/slices/groups";
import dayjs from "dayjs";
import { dispatch } from "../../../store";
import ModalConfirmation from "../../../components/ModalConfirmation";
import _ from "lodash";
import { MenuInfo } from "rc-menu/lib/interface";
import * as XLSX from 'xlsx';
import { setInstitution } from "../../../store/slices/institutions";
import { subMenusOfAccounts } from "../../../commons/subMenus";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import CardDashboard from "../../../components/CardDashboard";
import { image } from "../../../commons/ImageRoutes";
import Buttons from "../../../components/Buttons";
import ModalNotification, { AlertTypeNew } from "../../../components/ModalNotification";

const AccountsGroupMain = () => {
    const [accountGroupsList, setAccountGroupsList] = useState<Array<GroupResponse>>([]);
    const [accountGroupsListTmp, setAccountGroupsListTmp] = useState<Array<GroupResponse>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const hasSelected = selectedRowKeys.length > 0;
    const groupsList = useSelector((state: any) => state.group.groups);
    const banksList = useSelector((state: any) => state.institutions.institutionsInfo);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationIcon, setNotificationIcon] = useState<AlertTypeNew>("SUCCESS");
    const [bankList, setBankList] = useState<CatalogBankResponse[]>([]);

    const navigate = useNavigate();
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            // Se define que inicia en la pag 1 y el tamaño de la pagina será de 10
            current: 1,
            pageSize: 10,
            showSizeChanger: false,
        },
    });

    const exportOpt = [
        { key: 'immediately', label: 'Inmediata' },
        { key: 'scheduled', label: 'Programada' },
    ];

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns: ColumnsType<GroupResponse> = [
        {
            title: "Acciones",
            dataIndex: "operation",
            key: "operation",
            className: 'custom-header',
            render: (event, record: GroupResponse) => (
                <Row gutter={[16, 12]} justify={"center"}>
                    <Col>
                        <Dropdown
                            menu={{
                                onClick: (event) => exportToXLSX(event, record),
                                items: exportOpt,
                            }}
                            placement="bottom"
                            arrow={{ pointAtCenter: true }}
                        >
                            <Col>
                                <Button
                                    type="primary"
                                    shape='round'
                                    style={styles.textButtonExport}
                                >
                                    <span>
                                        Exportar
                                    </span>
                                    {<CloudDownloadOutlined />}
                                </Button>
                            </Col>
                        </Dropdown>
                    </Col>
                    <Col>
                        <Buttons
                            type="default"
                            title="Editar"
                            color="#ffb13d"
                            action={() => navigate(`/admin/accountsGroups/${record.id}`)}
                            icon={<EditOutlined />}
                        />
                    </Col>
                </Row>
            ),
        },
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
            className: 'custom-header',
        },
        {
            title: "Descripción",
            dataIndex: "description",
            key: "description",
            className: 'custom-header',
        },
        {
            title: 'Creación',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => dayjs(text).format("DD/MM/YYYY"),
            className: 'custom-header',
        },
        {
            title: "# de Cuentas",
            dataIndex: "thirdAccounts",
            key: "thirdAccounts",
            className: 'custom-header',
            align: "center",
            render: (accounts) => accounts.length,
        },
    ];

    useEffect(() => {
        if (banksList && _.isEmpty(banksList)) {
            setIsLoading(true);
            getBanks()
                .then((response) => {
                    if (response.data.status === Status.OK && response.data.data && !_.isEmpty(response.data.data)) {
                        dispatch(setInstitution({ institutionsInfo: response.data.data }))
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    setShowNotification(true);
                    setNotificationIcon("ERROR");
                    setNotificationMessage("Hubo un error al cargar la lista de Instituciones, intente más tarde.");
                });
        } else {
            setBankList(banksList);
        }
    }, [banksList]);

    const handleTableChange: TableProps<GroupResponse>["onChange"] = (
        pagination,
    ) => {
        setTableParams({
            pagination,
        });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setAccountGroupsListTmp([]);
        }
    };

    const getAccountsGroupList = useCallback(() => {
        setIsLoading(true);
        getAccountGroups()
            .then((response) => {
                if (response.data && response.data.data &&
                    response.data.status === Status.OK &&
                    !_.isEmpty(response.data.data)) {
                    setAccountGroupsList(response.data.data);
                    setAccountGroupsListTmp(groupsList);
                    dispatch(setGroups({
                        groups: response.data.data
                    }));
                } else if (response.data && response.data.data &&
                    response.data.status === Status.OK) {
                    setAccountGroupsList(response.data.data);
                    setAccountGroupsListTmp(groupsList);
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setShowNotification(true);
                setNotificationIcon("ERROR");
                setNotificationMessage("Hubo un error al cargar la lista grupos de cuentas, intente más tarde.");
            })
    }, [groupsList]);

    useEffect(() => {
        if (_.isEmpty(groupsList)) {
            getAccountsGroupList()
        } else {
            setAccountGroupsList(groupsList);
            setAccountGroupsListTmp(groupsList);
        }
    }, [getAccountsGroupList, groupsList]);

    const handleDeleteGroup = () => {
        setConfirmDelete(true);
    }

    const deleteGroup = () => {
        const data: DeleteGroupsRequest = { accountsGroup: [] };
        selectedRowKeys.forEach((element) => {
            if (element.valueOf()) {
                data.accountsGroup.push(element.toString());
            }
        })

        setConfirmDelete(false);
        setSelectedRowKeys([]);
        setIsLoading(true);
        deleteAccountGroupList(data)
            .then((response) => {
                if (response.data && response.data.status) {
                    setShowNotification(true);
                    setNotificationMessage(response.data.message);
                    if (response.data && response.data.status &&
                        response.data.status === Status.DELETED) {
                        dispatch(clearGroups());
                        setNotificationIcon("SUCCESS");
                    } else {
                        setNotificationIcon("ERROR");
                    }
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setShowNotification(true);
                setNotificationMessage("Hubo un error borrando los grupos de cuentas");
            })
    }

    // Funcion para crear la exportación a XLSX
    const exportToXLSX = (templateType: MenuInfo, groupAccounts: GroupResponse) => {
        const data: DeleteGroupsRequest = { accountsGroup: [] };
        selectedRowKeys.forEach((element) => {
            if (element.valueOf()) {
                data.accountsGroup.push(element.toString());
            }
        })
        const arrayToXLSX: any = [];
        const Heading = [
            [
                'Cuenta origen',
                'Cuenta destino CLABE/Tarjeta/Teléfono',
                'Tipo',
                'Nombre',
                'RFC',
                'Entidad',
                // estos datos los va a poner el usuario
                'Concepto',
                'Referencia númerica',
                'Importe Sin IVA',
                'IVA',
                _.isEqual(templateType.key, "scheduled") ? "Fecha (DD/MM/AAAA)" : null,
                _.isEqual(templateType.key, "scheduled") ? "Hora (HH:MM)" : null,
            ]
        ];

        groupAccounts.thirdAccounts.forEach(async element => {
            let accountTypeFormated = _.isEqual(element.accountType.toString(), "DEBIT") ? "Tarjeta de Debito"
                : _.isEqual(element.accountType.toString(), "CEL") ? "Teléfono celular"
                    : "CLABE"

            const objToDefine = {
                foreignAccountNumber: '',
                typeAccount: '',
                name: '',
                foreignBankId: '',
                rfc: ''
            };
            objToDefine.foreignAccountNumber = element.bankAccountNumber;
            objToDefine.typeAccount = accountTypeFormated;
            objToDefine.name = element.name;
            objToDefine.rfc = element.rfc;
            let dataResp = _.find(bankList, { bankCode: element.bankName });
            objToDefine.foreignBankId = dataResp ? dataResp.bankName : "";

            const objToOrder = Object.assign(objToDefine, element);
            arrayToXLSX.push({
                accountNumber: "'".toString(),
                foreignAccountNumber: objToOrder.foreignAccountNumber,
                typeAccount: objToOrder.typeAccount,
                name: objToOrder.name,
                rfc: objToOrder.rfc,
                foreignBankId: objToOrder.foreignBankId,
            });

        });
        const ws = XLSX.utils.book_new();
        const wb = XLSX.utils.book_new();
        const exportFileName = _.isEqual(templateType.key, "scheduled") ? 'Plantilla_programadas.xlsx' : "Plantilla_inmediatas.xlsx";

        XLSX.utils.sheet_add_aoa(ws, Heading);
        XLSX.utils.sheet_add_json(ws, arrayToXLSX, { origin: 'A2', skipHeader: true });
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
        const sheet: XLSX.Sheet = wb.Sheets['Hoja1'];
        sheet['!cols'] = [{ width: 25 }, { width: 35 }, {}, { width: 20 }, {}, {}, {}, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }]; // Ajusta los valores de las columnas

        XLSX.writeFile(wb, exportFileName);
    };

    const headerSearcher = (text: string) => {
        const filteredResults = accountGroupsList.filter(
            (data: GroupResponse) =>
                data.name.toLowerCase().includes(text.toLowerCase()) ||
                data.description.toLowerCase().includes(text.toLowerCase())
        );
        setAccountGroupsListTmp(filteredResults);
        setSelectedRowKeys([])
    };

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfAccounts} title="Grupos de Cuentas">
            <Row gutter={[15, 25]}>
                <Col span={24}>
                    <Row>
                        <Col span={24} md={6} lg={4}>
                            <CardDashboard
                                title={"Crear Grupo de Cuentas"}
                                icon={image.accountGroupsIcon}
                                action={"/admin/accountsGroups/add-group"}
                                height={135} />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <div style={styles.primaryContainer}>
                        <Row gutter={[16, 12]} style={styles.rowContainer}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={5}>
                                <Search
                                    onSearch={(value: string) => headerSearcher(value)}
                                    onChange={(value) =>
                                        _.isEmpty(value.target.value) ?
                                            setAccountGroupsListTmp(accountGroupsList)
                                            : null
                                    }
                                    placeholder="Buscar por nombre o descripción"
                                />
                            </Col>
                            <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={14} />
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}
                                xl={8}
                                xxl={5}
                                style={{ display: 'flex', justifyContent: 'space-evenly' }}
                            >
                                <Buttons
                                    type="primary"
                                    title="Borrar"
                                    color="#765CE9"
                                    isDisabled={!hasSelected}
                                    action={handleDeleteGroup}
                                    icon={<DeleteOutlined />}
                                    iconLeft
                                />
                            </Col>
                        </Row>
                        <Col xs={24} xl={24}>
                            <Table
                                rowSelection={rowSelection}
                                rowKey={(record) => record.id}
                                dataSource={accountGroupsListTmp}
                                columns={columns}
                                onChange={handleTableChange}
                                scroll={{ y: 400, x: 1300 }}
                                locale={{ emptyText: "No hay datos disponibles" }}
                                pagination={tableParams.pagination}
                                loading={{
                                    spinning: isLoading,
                                    indicator: <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />,
                                }}
                            />
                        </Col>
                    </div>
                </Col>
            </Row>
            <ModalConfirmation
                open={confirmDelete}
                handleConfirm={deleteGroup}
                handleClose={() => setConfirmDelete(false)}
                message={"¿Estas seguro que deseas eliminar los grupos de cuentas seleccionados?"}
            />
            <ModalNotification
                title={"Grupos de Cuentas"}
                message={notificationMessage}
                alertType={notificationIcon}
                show={showNotification}
                onClose={() => setShowNotification(false)}
            />
        </SubDefaultLayout>
    );
};

const styles = StyleSheet.create({
    primaryContainer: {
        width: "100%",
        margin: "20px auto",
        padding: "20px",
        borderRadius: 10,
        backgroundColor: 'white',
        boxShadow: "rgba(177, 179, 185, 0.51) 5px 8px 24px 5px",
    },
    textButton: {
        color: '#ffffff',
        backgroundColor: '#1890ff',
        fontSize: 15
    },
    rowContainer: {
        marginBottom: '20px',
    },
    textButtonExport: {
        color: 'white',
        borderColor: '#3d3dff',
        backgroundColor: "#3d3dff"
    },
});

export default AccountsGroupMain;
