import { useCallback, useEffect, useState } from "react";
import { AccountThirdData, AccountType, CatalogBankResponse, Institution, MunicipalityData, Status, UpdateAccountThirdRequest } from "../../../types/types";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getAccountThirdById, getBanks, updateAccountThird } from "../../../methods/axiosMethods";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row, Skeleton, Spin, Typography } from "antd";
import { StyleSheet } from "../../../StyleSheet";
import estadoCiudadData from "../../../components/EstadoCiudad";
import { clearAccountThird } from "../../../store/slices/accountsThird";
import { setInstitution } from "../../../store/slices/institutions";
import { isValidCel, isValidClabe, isValidDebit, validateRfc } from "../../../functions/Functions";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import { subMenusOfAccounts } from "../../../commons/subMenus";
import CardDashboard from "../../../components/CardDashboard";
import { image } from "../../../commons/ImageRoutes";
import Input from "../../../components/Input";
import states from '../../../components/states.json';
import SelectInput from "../../../components/SelectInput";
import ModalNotification, { AlertTypeNew } from "../../../components/ModalNotification";
import { LoadingOutlined } from "@ant-design/icons";
const { Text } = Typography;

const bankTypes = [
    {
        value: 'CLABE',
        label: 'CLABE'
    },
    {
        value: 'DEBIT',
        label: 'Tarjeta de Debito'
    },
    {
        value: 'CEL',
        label: 'Teléfono celular'
    }
];

const AccountsThirdEdit = () => {
    const session = useSelector((state: any) => state.user);
    const [userRol, setUserRol] = useState("");
    const [loading, setLoading] = useState(false);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
    const [accountThirdId, setAccountThirdId] = useState("");
    const [nameForm, setNameForm] = useState('');
    const [aliasForm, setAliasForm] = useState('');
    const [accountNumberForm, setAccountNumberForm] = useState('');
    const [bankForm, setBankForm] = useState<Institution>();
    const [typeForm, setTypeForm] = useState<AccountType>();
    const [rfcForm, setRfcForm] = useState('');
    const [streetForm, setStreetForm] = useState('');
    const [streetNumberForm, setStreetNumberForm] = useState('');
    const [zipcodeForm, setZipcodeForm] = useState('');
    const [stateForm, setStateForm] = useState('');
    const [cityForm, setCityForm] = useState('');
    const [enabled, setEnabled] = useState<boolean>(false);
    const [municipalitiesList, setMunicipalitiesList] = useState<MunicipalityData[]>([]);
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [shouldNavigate, setShouldNavigate] = useState<boolean>(false);
    const [message, setMessage] = useState("");
    const accList = useSelector((state: any) => state.accountThird.accountsThirdInfo);
    const [accToEdit, setAccountToEdit] = useState<AccountThirdData>();
    const [missingData, setHasMissingData] = useState<boolean>(false);
    const banksList = useSelector((state: any) => state.institutions.institutionsInfo);
    const [banks, setBanks] = useState<CatalogBankResponse[]>([]);
    const [isValidAccNumber, setIsValidAccNumber] = useState<boolean>(true);
    const navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
    const dispatch = useDispatch();
    const params = useParams();

    const navigateNotification = () => {
        navigate("/admin/accountsThird")
    };

    useEffect(() => {
        console.log('??', session)
        if (session && session.isLogged) {
            if (session.userInfo.typeUser !== undefined) {
                setUserRol(session.userInfo.typeUser);
            }
        }
    }, [session]);

    const getAccountThird = useCallback(async (id: string) => {
        setIsLoadingData(true);
        await getAccountThirdById(id)
            .then((response) => {
                if (response.data.status === Status.OK && !_.isEmpty(response.data.data)) {
                    setAccountToEdit(response.data.data);
                    setAccountThirdId(response.data.data.id);
                } else {
                    setNotificationType("ERROR");
                    setMessage("No fue posible obtener los datos de la cuenta de tercero en este momento, intente más tarde.");
                    setShouldNavigate(true);
                    setShowNotification(true);
                    setIsLoadingData(false);
                }
            })
            .catch(() => {
                setNotificationType("ERROR");
                setMessage("No fue posible obtener los datos de la cuenta de tercero en este momento, intente más tarde.");
                setShouldNavigate(true);
                setShowNotification(true);
                setIsLoadingData(false);
            })
    }, [])

    useEffect(() => {
        if (params && params.accountThirdId) {
            getAccountThird(params.accountThirdId);
        }
        else if (accList && !_.isEmpty(accList)) {
            setIsLoadingData(true);
            const filteredResult: Array<AccountThirdData> = accList.filter(
                (data: AccountThirdData) => data.id === params.accountThirdId
            );
            setAccountToEdit(filteredResult[0]);
            setIsLoadingData(false);
        }
    }, [accList, params, getAccountThird]);

    useEffect(() => {
        if (banksList && _.isEmpty(banksList)) {
            getBanks()
                .then((response) => {
                    if (response.data.status === Status.OK && response.data.data && !_.isEmpty(response.data.data)) {
                        dispatch(setInstitution({ institutionsInfo: response.data.data }))
                    }
                })
                .catch(() => {
                    setNotificationType("ERROR");
                    setMessage("No fue posible obtener las instituciones bancarias en este momento, intente más tarde.");
                    setShouldNavigate(true);
                    setShowNotification(true);
                    setLoading(false);
                });
        } else {
            setBanks(banksList);
        }
    }, [banksList, dispatch]);

    useEffect(() => {
        if (accToEdit) {
            setNameForm(accToEdit.name);
            setTypeForm(accToEdit.accountType);
            setAliasForm(accToEdit.alias);
            setAccountNumberForm(accToEdit.bankAccountNumber);
            setBankForm(accToEdit.bankName);
            setRfcForm(accToEdit.rfc);
            setStreetForm(accToEdit.street);
            setStreetNumberForm(accToEdit.streetNumber)
            setZipcodeForm(accToEdit.zip);
            setStateForm(accToEdit.state);
            setCityForm(accToEdit.municipality);
            setAccountThirdId(accToEdit.id);
            setEnabled(accToEdit.enabled);
            getMunicipalityList(accToEdit.state);
            setIsLoadingData(false);
        }
    }, [accToEdit]);

    const handleSaveButton = (isEnable: boolean) => {
        if (!_.isEmpty(rfcForm) && validateRfc(rfcForm)) {
            setNotificationType("INFO");
            setMessage("El formato del RFC ingresado es incorrecto.");
            setHasMissingData(true)
            setShouldNavigate(false);
            setShowNotification(true);
        }
        // si la cuenta es menor a 10 digitos o mayor a 18
        // Validación del número de cuenta
        else if (accountNumberForm && (accountNumberForm.toString().length < 10 || accountNumberForm.toString().length > 18)) {
            setNotificationType("INFO");
            setMessage("El número de cuenta no puede ser menor a 10 dígitos o mayor a 18 dígitos.");
            setHasMissingData(true)
            setShouldNavigate(false);
            setShowNotification(true);
        }
        else if (
            // Validación de campos obligatorios
            !_.isEmpty(aliasForm) &&
            !_.isEmpty(nameForm) &&
            !_.isEmpty(typeForm) &&
            !_.isEmpty(bankForm) &&
            !_.isEmpty(accountNumberForm)
        ) {
            let isValidAcc = false;
            // Validación específica según el tipo de cuenta
            switch (typeForm) {
                case "CLABE":
                    isValidAcc = isValidClabe(accountNumberForm)
                    break;
                case "DEBIT":
                    isValidAcc = isValidDebit(accountNumberForm)
                    break;
                case "CEL":
                    isValidAcc = isValidCel(accountNumberForm)
                    break;
            }
            if (!isValidAcc) {
                setNotificationType("INFO");
                setMessage("El número de cuenta y/o el tipo de cuenta no son válidos.");
                setIsValidAccNumber(false);
                setShouldNavigate(false);
                setShowNotification(true);
            } else {
                // Construcción de los datos a enviar para la actualización
                setHasMissingData(false);
                setLoading(true);
                const bodyData: UpdateAccountThirdRequest = {
                    accountNumber: accountNumberForm,
                    name: nameForm,
                    bankName: bankForm,
                    accountType: typeForm,
                    alias: aliasForm,
                    enabled: isEnable,
                    rfc: rfcForm,
                    street: streetForm,
                    streetNumber: streetNumberForm,
                    zip: zipcodeForm,
                    state: stateForm,
                    municipality: cityForm,
                };
                // Llamada a la API para la actualización de la cuenta de tercero
                updateAccountThird(bodyData, accountThirdId)
                    .then((response) => {
                        if (response.data.status === Status.UPDATED) {
                            dispatch(clearAccountThird());
                            setNotificationType("SUCCESS");
                            setMessage(response.data.message);
                            setShouldNavigate(true);
                        } else {
                            setNotificationType("ERROR");
                            setMessage(response.data.message);
                            setShouldNavigate(false);
                        }
                        setLoading(false);
                        setShowNotification(true);
                    })
                    .catch(() => {
                        setLoading(false);
                        setNotificationType("ERROR");
                        setMessage("No fue posible actualizar la cuenta de tercero en este momento. Intente más tarde.");
                        setShouldNavigate(false);
                        setShowNotification(true);
                    });
            }
        } else {
            // Si hay campos obligatorios incompletos
            setHasMissingData(true);
            setNotificationType("INFO");
            setMessage("Hay campos obligatorios incompletos.");
            setShouldNavigate(false);
            setShowNotification(true);
        }
    };

    const getMunicipalityList = (text: string) => {
        const filteredCities = estadoCiudadData().filter((data) => data.state === text);
        const municipalitiesListTmp: Array<MunicipalityData> = [];
        filteredCities.forEach((city) => {
            municipalitiesListTmp.push({
                name: city.state,
                value: city.city
            });
        });
        setMunicipalitiesList(municipalitiesListTmp);
    };

    // const handleChangePersonType = (element: string) => {
    //     setPersonTypeForm(element);
    // };

    // Función para manejar cambios en el número de cuenta
    const handleChangeAccNumber = (inputValue: string) => {
        let typeForm = AccountType.CLABE;
        let bankCode;
        setIsValidAccNumber(true);
        setAccountNumberForm(inputValue.replace(/[^0-9]/g, ''));

        if (isValidCel(inputValue)) {
            typeForm = AccountType.CEL;
        }

        if (isValidDebit(inputValue)) {
            typeForm = AccountType.DEBIT;
        }

        if (isValidClabe(inputValue)) {
            const codeType: Array<CatalogBankResponse> = _.filter(banks, { code: inputValue.slice(0, 3) });
            if (!_.isEmpty(codeType)) {
                bankCode = codeType[0].bankCode as Institution;
                typeForm = AccountType.CLABE;
                setBankForm(bankCode);
            }
        }
        setTypeForm(typeForm);
    };

    const handleChangeTypeForm = (element: string) => {
        setIsValidAccNumber(true);
        setTypeForm(element as AccountType);
    };

    const handleChangeBank = (element: string) => {
        const values = Object.values(Institution)
        values.forEach((value) => {
            if (value === element) {
                setBankForm(value);

            }
        });
    };

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfAccounts} title="Cuentas">
            {
                loading ?
                    (
                        <div style={styles.contentView}>
                            <Spin
                                indicator={antIcon}
                                tip="Cargando..."
                                style={styles.loading}
                            />
                        </div>
                    ) : (
                        <>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                                    <CardDashboard
                                        title={"Guardar"}
                                        icon={image.saveIcon}
                                        height={135}
                                        isFunction={() => handleSaveButton(true)}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4} >
                                    <CardDashboard
                                        title={"Cancelar"}
                                        icon={image.cancelIcon}
                                        action={"/admin/accountsThird"}
                                        height={135} />
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                                    {
                                        enabled ? (
                                            <CardDashboard
                                                title={"Inhabilitar"}
                                                icon={image.disableIcon}
                                                height={135}
                                                isFunction={() => handleSaveButton(false)}
                                            />
                                        ) : (
                                            <CardDashboard
                                                title={"Habilitar"}
                                                icon={image.enableIcon}
                                                height={135}
                                                isFunction={() => handleSaveButton(true)}
                                            />
                                        )
                                    }
                                </Col>
                            </Row>
                            {/* Card de Datos de la cuenta */}
                            <Row style={{ marginTop: 10 }}>
                                <Col style={styles.divContainer} md={24} lg={22} xl={20} xxl={16}>
                                    {
                                        isLoadingData ?
                                            <Skeleton />
                                            :
                                            <>
                                                <div>
                                                    <Text style={styles.textTitle}>
                                                        Datos de la Cuenta
                                                    </Text>
                                                </div>
                                                <div>
                                                    <Text style={styles.textRequire}>
                                                        Todo los campos marcados con (*) son requeridos
                                                    </Text>
                                                </div>
                                                <Row gutter={[12, 12]} >
                                                    <Col xs={24} xl={24}>
                                                        {
                                                            userRol === "admin" ? (
                                                                <Input
                                                                    label="Nombre*"
                                                                    required
                                                                    value={nameForm}
                                                                    type="text"
                                                                    onChange={(element) => {
                                                                        setNameForm(element);
                                                                        setHasMissingData(false)
                                                                    }}
                                                                    disable={loading}
                                                                />
                                                            ) : (
                                                                <Input
                                                                    label="Nombre*"
                                                                    value={nameForm}
                                                                    type="text"
                                                                    onChange={(element) => {
                                                                        setNameForm(element);
                                                                        setHasMissingData(false)
                                                                    }}
                                                                    disable={true}
                                                                />
                                                            )
                                                        }
                                                    </Col>
                                                    <Col xs={24} xl={12}>
                                                        <Input
                                                            label="Alias*"
                                                            value={aliasForm}
                                                            type="text"
                                                            required
                                                            onChange={(element) => {
                                                                setAliasForm(element);
                                                                setHasMissingData(false)
                                                            }}
                                                            disable={loading}
                                                        />
                                                    </Col>
                                                    <Col xs={24} xl={12}>
                                                        {
                                                            userRol === "admin" ? (
                                                                <Input
                                                                    label="CLABE / Tarjeta / Teléfono*"
                                                                    value={accountNumberForm}
                                                                    type="text"
                                                                    required
                                                                    maxLength={18}
                                                                    onChange={(element) => handleChangeAccNumber(element)}
                                                                    disable={loading}
                                                                    errorLabel={(missingData && !_.isEmpty(accountNumberForm)) ? "error" : ""}
                                                                    withError={!isValidAccNumber}
                                                                />
                                                            ) : (
                                                                <Input
                                                                    label="CLABE / Tarjeta / Teléfono*"
                                                                    value={accountNumberForm}
                                                                    type="text"
                                                                    maxLength={18}
                                                                    disable
                                                                />
                                                            )
                                                        }
                                                    </Col>
                                                    <Col xs={24} xl={12}>
                                                        {
                                                            userRol === "admin" ? (
                                                                <SelectInput
                                                                    label="* Entidad"
                                                                    value={bankForm as string}
                                                                    options={banks.map(data => ({
                                                                        value: data.bankCode,
                                                                        label: data.bankName
                                                                    }))}
                                                                    onChange={(e) => {
                                                                        handleChangeBank(e);
                                                                    }}
                                                                />
                                                            ) :
                                                                <SelectInput
                                                                    label="* Entidad"
                                                                    value={bankForm as string}
                                                                    options={banks.map(data => ({
                                                                        value: data.bankCode,
                                                                        label: data.bankName
                                                                    }))}
                                                                    disable
                                                                />
                                                        }
                                                    </Col>
                                                    <Col xs={24} xl={12}>
                                                        {
                                                            userRol === "admin" ? (
                                                                <SelectInput
                                                                    label="* Tipo"
                                                                    value={typeForm as string}
                                                                    options={bankTypes.map(data => ({
                                                                        value: data.value,
                                                                        label: data.label
                                                                    }))}
                                                                    onChange={(e) => {
                                                                        handleChangeTypeForm(e);
                                                                    }}
                                                                />
                                                            ) :
                                                                <Input
                                                                    label="Tipo"
                                                                    value={
                                                                        typeForm === "DEBIT" ? "Tarjeta de Debito"
                                                                            : typeForm === "CEL" ? "Teléfono celular"
                                                                                : typeForm
                                                                    }
                                                                    type="text"
                                                                    maxLength={18}
                                                                    disable
                                                                />
                                                        }
                                                    </Col>
                                                </Row>
                                            </>
                                    }
                                </Col>
                            </Row>
                            {/* Card de Datos opcionales */}
                            <Row style={{ marginTop: 10 }}>
                                <Col style={styles.divContainer} md={24} lg={22} xl={20} xxl={16}>
                                    {
                                        isLoadingData ?
                                            <Skeleton />
                                            :
                                            <>
                                                <div>
                                                    <Text style={styles.textTitle}>
                                                        Datos de la Cuenta
                                                    </Text>
                                                </div>
                                                <div>
                                                    <Text style={styles.textRequire}>
                                                        Todo los campos marcados con (*) son requeridos
                                                    </Text>
                                                </div>
                                                <Row gutter={[12, 12]} >
                                                    <Col xs={24} xl={12}>
                                                        <Input
                                                            label="RFC"
                                                            value={rfcForm}
                                                            type="text"
                                                            disable={loading}
                                                            onChange={(element) => setRfcForm(element)}
                                                            errorLabel={!_.isEmpty(rfcForm) && validateRfc(rfcForm) ? "Por favor ingresa un RFC válido." : ""}
                                                            withError={!_.isEmpty(rfcForm) && validateRfc(rfcForm)}
                                                        />
                                                    </Col>
                                                    <Col xs={24} xl={12}>
                                                        <Input
                                                            label="Calle"
                                                            value={streetForm}
                                                            type="text"
                                                            onChange={(element) => setStreetForm(element)}
                                                            disable={loading}
                                                        />
                                                    </Col>
                                                    <Col xs={24} xl={12}>
                                                        <Input
                                                            label="Número"
                                                            value={streetNumberForm}
                                                            type="text"
                                                            onChange={(element) => setStreetNumberForm(element)}
                                                            disable={loading}
                                                        />
                                                    </Col>
                                                    <Col xs={24} xl={12}>
                                                        <Input
                                                            label="Código Postal"
                                                            value={zipcodeForm}
                                                            type="text"
                                                            maxLength={5}
                                                            onChange={(element) => {
                                                                const newValue = element.replace(/\D/g, '');
                                                                setZipcodeForm(newValue);
                                                            }}
                                                            disable={loading}
                                                        />
                                                    </Col>
                                                    <Col xs={24} xl={12}>
                                                        <SelectInput
                                                            label="Estado"
                                                            value={stateForm}
                                                            options={states.map(data => ({
                                                                value: data.value,
                                                                label: data.value
                                                            }))}
                                                            onChange={(e) => {
                                                                setStateForm(e);
                                                                setCityForm("");
                                                                getMunicipalityList(e);
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col xs={24} xl={12}>
                                                        {stateForm ? (
                                                            <SelectInput
                                                                label="Delegación o municipio"
                                                                value={cityForm}
                                                                options={municipalitiesList.map(data => ({
                                                                    value: data.value,
                                                                    label: data.value
                                                                }))}
                                                                onChange={(e) => {
                                                                    setCityForm(e);
                                                                }}
                                                            />
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            </>
                                    }
                                </Col>
                            </Row>
                        </>
                    )
            }
            <ModalNotification
                title={"Cuentas de Terceros"}
                message={message}
                alertType={notificationType}
                show={showNotification}
                onClose={() => shouldNavigate ? navigateNotification() : setShowNotification(false)}
            />
        </SubDefaultLayout>
    );
};

const styles = StyleSheet.create({
    divContainer: {
        backgroundColor: "white",
        marginTop: "1%",
        display: "flex",
        flexDirection: "column",
        borderRadius: 15,
        padding: 30,
        boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.10)"
    },
    inputTextTitle: {
        fontSize: 15,
        paddingLeft: 10
    },
    contentView: {
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loading: {
        maxHeight: "none",
    },
    textTitle: {
        fontSize: 18,
        color: '#434343',
        fontWeight: 600
    },
    textRequire: {
        fontSize: 13,
        color: '#676767'
    }
});

export default AccountsThirdEdit;
