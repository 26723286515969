//---------------------------------------------------------------------------------------------------------------------- Imports
import { Card, Col, Row, Spin, Typography } from "antd";
import { CSSProperties, ReactNode } from "react";
import { StyleSheet } from "../../../StyleSheet";
import { LoadingOutlined } from "@ant-design/icons";
const { Text } = Typography;

//---------------------------------------------------------------------------------------------------------------------- Interface
interface ActionCardProps {
    image: string;                  // URL de la imagen que se colocará en el card.
    color: string;                  // Color del cuadrito del card.
    title: string;                  // Título que se desea colocar.
    titleColor: string;             // Color de fuente del título.
    text?: string;                  // Texto que se desea agregar.
    textStyle?: CSSProperties;      // Estilos del texto.
    subText?: string;               // Sub texto.
    button: ReactNode;              // Botón de acción.
    height?: number;
    isBoldTitle?: boolean;
    isBoldText?: boolean;
    isLoading?: boolean;
};

//---------------------------------------------------------------------------------------------------------------------- Component
const ActionCard = (props: ActionCardProps) => {
    const { image, color, title, titleColor, text, textStyle, subText, button, height, isBoldTitle, isBoldText, isLoading } = props;
    const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

    return (
        <Card style={{ ...styles.cardStyle, height: (height ? height : "") }} bodyStyle={styles.bodyCardStyle}>
            {
                isLoading ? (
                    <div style={styles.spin}>
                        <Spin indicator={antIcon} />
                    </div>
                )
                    : (
                        <Row style={styles.mainContainer}>
                            <Col span={10}>
                                <Row style={styles.containerStyleImage}>
                                    <div style={styles.imageContainerStyle}>
                                        <img src={image} alt="actionImage" />
                                    </div>
                                    <Col span={12} style={{ ...styles.borderColStyle, ...{ backgroundColor: color } }} />
                                    <Col lg={0} xl={12} />
                                </Row>
                            </Col>
                            <Col span={14} style={styles.infoContiner}>
                                <div>
                                    <Text style={{ ...styles.titleStyle, color: titleColor, fontWeight: (isBoldTitle ? 600 : '') }}>
                                        {title}
                                    </Text>
                                </div>
                                {text && (
                                    <p style={{ ...styles.textStyle, fontWeight: (isBoldText ? 600 : ''), ...textStyle && textStyle }}>{text}</p>
                                )}
                                {subText && (
                                    <p style={{ ...styles.textStyle, ...styles.subTextStyle }}>{subText}</p>
                                )}
                                <div style={{ marginTop: 10 }}>
                                    {button}
                                </div>
                            </Col>
                        </Row>
                    )
            }
        </Card>
    );
};

//---------------------------------------------------------------------------------------------------------------------- Styles
const styles = StyleSheet.create({
    cardStyle: {
        boxShadow: "0px 0px 8px #00000029",
        borderRadius: 12,
        border: "none",
    },
    bodyCardStyle: {
        display: "flex",
        flexDirection: "row",
        padding: 0,
        height: '100%'
    },
    mainContainer: {
        display: "flex",
        width: "100%",
    },
    containerStyleImage: {
        display: "flex",
        width: "100%",
        height: "100%"
    },
    imageContainerStyle: {
        display: "flex",
        position: "absolute",
        width: 100,
        height: 100,
        alignItems: "center",
        justifyContent: "center",
        right: 0,
        left: 0,
        top: 0,
        bottom: 0,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "auto",
        marginTop: "auto",
        zIndex: 2,
    },
    infoContiner: {
        display: "flex",
        padding: "15px",
        justifyContent: "center",
        flexDirection: "column",
    },
    borderColStyle: {
        display: "flex",
        height: "100%",
        borderRadius: "12px 0px 0px 12px",
    },
    titleStyle: {
        margin: 0,
    },
    textStyle: {
        margin: 0,
    },
    subTextStyle: {
        color: "#6f6f6f",
    },
    spin: {
        display: "flex",
        justifyContent: "center",
        width: '100%',
        alignItems: 'center'
    }
});

//---------------------------------------------------------------------------------------------------------------------- Exports
export default ActionCard;
