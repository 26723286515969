import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { StyleSheet } from "../../../StyleSheet";
import { Col, Row, Typography, Transfer, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AccountResponse, CreateUserRequest, Role, Status, UserResponse } from "../../../types/types";
import { addNewUser, getAccountsByCompany } from "../../../methods/axiosMethods";
import { setUsers } from "../../../store/slices/companyUsers";
import { setAccountsByCompany } from "../../../store/slices/accountsByCompany";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import { subMenusOfSettings } from "../../../commons/subMenus";
import CardDashboard from "../../../components/CardDashboard";
import { image } from "../../../commons/ImageRoutes";
import Input from "../../../components/Input";
import ModalNotification, { AlertTypeNew } from "../../../components/ModalNotification";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

const { Text } = Typography;

const UserAdd = () => {
    const usersData = useSelector((state: any) => state.users.data);
    const accByCompanyData = useSelector((state: any) => state.accountsByCompany.accountsInfo);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [selectedAccounts, setSelectedAccounts] = useState<AccountResponse[]>([]);
    const [accountInfo, setAccountInfo] = useState<any[]>([]);
    const [accountsUniqueList, setAccountsUniqueList] = useState<Array<AccountResponse>>([]);
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [message, setMessage] = useState("");
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [shouldNavigate, setShouldNavigate] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
    const [missingData, setHasMissingData] = useState<boolean>(false);
    const [emailValid, setEmailValid] = useState<boolean>(false);


    const navigateNotification = () => {
        navigate("/settings")
    };

    const getAccountsByCompanyList = useCallback(() => {
        setIsLoading(true);
        getAccountsByCompany()
            .then((response) => {
                if (response.data && response.data.data) {
                    if (response.data.status === Status.OK && !_.isEmpty(response.data.data)) {
                        // Combina los dos arreglos
                        // Usar un objeto para realizar un seguimiento de los objetos por su ID
                        const idMap: { [id: string]: boolean } = {};
                        // Filtrar los objetos por su ID para eliminar los repetidos
                        const uniqueArray = response.data.data.filter((obj) => {
                            if (!idMap[obj.accountNumber]) {
                                idMap[obj.accountNumber] = true;
                                return true;
                            }
                            return false;
                        });
                        setAccountsUniqueList(uniqueArray);
                        dispatch(setAccountsByCompany({ accountsInfo: uniqueArray }))
                    }
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setNotificationType('ERROR');
                setMessage("Hubo un error al obtener la lista de cuentas, intente más tarde.");
                setShowNotification(true);
                setShouldNavigate(false);
            })
    }, [dispatch]);

    useEffect(() => {
        if (_.isEmpty(accByCompanyData)) {
            getAccountsByCompanyList();
        } else {
            setAccountsUniqueList(accByCompanyData);
        }
    }, [getAccountsByCompanyList, accByCompanyData]);


    useEffect(() => {
        if (accountsUniqueList.length > 0) {
            const formattedAccounts = accountsUniqueList.map((account: AccountResponse, index: number) => ({
                ...account,
                key: index.toString(),
            }));
            setAccountInfo(formattedAccounts);
        }
    }, [accountsUniqueList]);

    const handleChange = (newTargetKeys: string[]) => {
        setTargetKeys(newTargetKeys);
        const newSelectedAccounts = accountInfo.filter((account) => newTargetKeys.includes(account.key.toString()));
        setSelectedAccounts(newSelectedAccounts);
    };

    const renderItem = (item: AccountResponse) => {
        const customLabel = (
            <span>
                {item.alias} - {item.accountNumber}
            </span>
        );
        return {
            label: customLabel,
            value: item.alias || '',
        };
    };

    const validateEmail = (value: string) => {
        if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/i.test(value)) {
            setEmailValid(false);
        } else {
            setEmailValid(true);
            setEmail(value);
        }
    };


    const handleSaveButton = () => {
        if (!_.isEmpty(userName) &&
            emailValid &&
            !_.isEmpty(name) &&
            !_.isEmpty(lastName)) {
            const bodyData: CreateUserRequest = {
                username: userName,
                email: email,
                name: name,
                lastName: lastName,
                role: Role.USER,
                accounts: selectedAccounts
                    .filter(account => account.accountNumber !== undefined)
                    .map(account => account.accountNumber!)
            };
            setHasMissingData(false);
            setIsLoading(true);
            addNewUser(bodyData)
                .then((response) => {
                    if (response.data.status === Status.CREATED && response.data.data) {
                        const newUser: UserResponse = {
                            id: response.data.data.id,
                            username: userName,
                            email: email,
                            name: name,
                            lastName: lastName,
                            userType: Role.USER,
                            enabled: true,
                            accounts: selectedAccounts.map(account => account.accountNumber!),
                        };
                        setNotificationType('SUCCESS');
                        setMessage(response.data.message);
                        setShouldNavigate(true);
                        dispatch(setUsers({ data: [...usersData, newUser] }));
                    } else {
                        setNotificationType('ERROR');
                        setMessage(response.data.message);
                        setShouldNavigate(false);
                    }
                    setShowNotification(true);
                    setIsLoading(false);
                })
                .catch(() => {
                    setNotificationType('ERROR');
                    setIsLoading(false);
                    setMessage("Hubo un error al guardar el registro.");
                    setShowNotification(true);
                    setShouldNavigate(false);
                });
        } else {
            setHasMissingData(true);
            setNotificationType('INFO');
            setMessage("Hay campos obligatorios incompletos.");
            setShouldNavigate(false);
            setShowNotification(true);
        }
    };

    const transferLocale = {
        itemUnit: "cuenta",
        itemsUnit: "cuentas",
        titles: ["Cuentas Disponibles", "Cuentas Agregadas"],
        removeAll: "Deseleccionar todo",
        selectAll: "Seleccionar todo",
        selectInvert: "Invertir selección",
        notFoundContent: "Sin cuentas"
    };

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfSettings} title="Configuración">
            {
                isLoading ?
                    <div style={styles.contentView}>
                        <Spin
                            indicator={antIcon}
                            tip="Cargando..."
                            style={styles.loading}
                        />
                    </div>
                    :
                    <>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                                <CardDashboard
                                    title={"Guardar"}
                                    icon={image.saveIcon}
                                    height={135}
                                    isFunction={handleSaveButton}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} >
                                <CardDashboard
                                    title={"Cancelar"}
                                    icon={image.cancelIcon}
                                    action={"/settings"}
                                    height={135} />
                            </Col>
                        </Row>
                        <Row>
                            <Col style={styles.divContainer} md={24} lg={22} xl={20} xxl={16}>
                                <div>
                                    <Text style={{ fontSize: 18, color: '#434343', fontWeight: 600 }}>
                                        Agregar usuario
                                    </Text>
                                </div>
                                <div>
                                    <Text style={{ fontSize: 13, color: '#676767' }}>
                                        Todo los campos marcados con (*) son requeridos
                                    </Text>
                                </div>
                                <Row gutter={[12, 12]}>
                                    <Col xs={24} xl={12}>
                                        <Input
                                            label="Nombre*"
                                            required
                                            type="text"
                                            onChange={element => setName(element)}
                                            value={name}
                                            errorLabel={missingData && _.isEmpty(name) ? "Este campo es obligatorio." : ""}
                                            withError={missingData && _.isEmpty(name)}
                                        />
                                    </Col>
                                    <Col xs={24} xl={12}>
                                        <Input
                                            label="Apellido*"
                                            required
                                            type="text"
                                            value={lastName}
                                            onChange={element => setLastName(element)}
                                            errorLabel={missingData && _.isEmpty(lastName) ? "Este campo es obligatorio." : ""}
                                            withError={missingData && _.isEmpty(lastName)}
                                        />
                                    </Col>
                                    <Col xs={24} xl={12}>
                                        <Input
                                            label="Nombre de usuario*"
                                            required
                                            type="text"
                                            value={userName}
                                            onChange={(element) => setUserName(element)}
                                            errorLabel={missingData && _.isEmpty(userName) ? "Este campo es obligatorio." : ""}
                                            withError={missingData && _.isEmpty(userName)}
                                        />
                                    </Col>
                                    <Col xs={24} xl={12}>
                                        <Input
                                            label="Email*"
                                            required
                                            type="text"
                                            value={email}
                                            onChange={(element) => validateEmail(element)}
                                            errorLabel={!emailValid || (missingData && _.isEmpty(email)) ? "Este campo es obligatorio." : ""}
                                            withError={!emailValid || (missingData && _.isEmpty(email))}
                                        />
                                    </Col>
                                    <Col xs={24} xl={24}>
                                        <Input
                                            label="Rol"
                                            type="text"
                                            value={"USUARIO"}
                                            onChange={(element) => {
                                            }}
                                            disable
                                        />
                                    </Col>
                                    <Col xs={20} sm={20} md={22} lg={22} xl={24} xxl={24}>
                                        <Transfer
                                            dataSource={accountInfo}
                                            listStyle={styles.listTransfer}
                                            targetKeys={targetKeys}
                                            onChange={handleChange}
                                            render={renderItem}
                                            locale={transferLocale}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
            }
            <ModalNotification
                title={"Usuarios"}
                message={message}
                alertType={notificationType}
                show={showNotification}
                onClose={() => shouldNavigate ? navigateNotification() : setShowNotification(false)}
            />
        </SubDefaultLayout>
    );
};

const styles = StyleSheet.create({
    divContainer: {
        backgroundColor: "white",
        marginTop: "20px",
        display: "flex",
        flexDirection: "column",
        borderRadius: 15,
        padding: 30,
        boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.10)"
    },
    listTransfer: {
        width: '50%',
        borderRadius: 8,
        borderColor: 'black'
    },
    contentView: {
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loading: {
        maxHeight: "none",
    },
});

export default UserAdd;
