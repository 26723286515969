import React, { useEffect } from 'react';
import keycloak, { logout } from '../../context/keycloak';

const Login: React.FC = () => {
    useEffect(() => {
        keycloak.init({ onLoad: 'login-required' }).then((authenticated) => {
            if (authenticated) {
                console.log('User is authenticated');
            }
        });

        return () => {
            logout();
        };
    }, []);

    return <div>Iniciando sesión...</div>;
};

export default Login;
