import { Col, Row, Spin, Typography } from "antd";
import { StyleSheet } from "../../../../StyleSheet";
import _ from "lodash";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAccounts, getSchedulersTransactions } from "../../../../methods/axiosMethods";
import { AccountResponse, DispersionData, GenericResponse, ScheduledDispersionStatus, SchedulerResponse, Status, TableParams } from "../../../../types/types";
import Table, { ColumnsType, TableProps } from "antd/es/table";
import { InfoCircleOutlined, LoadingOutlined, RedoOutlined } from "@ant-design/icons";
import Buttons from "../../../../components/Buttons";
import SelectInput from "../../../../components/SelectInput";
import ModalNotification, { AlertTypeNew } from "../../../../components/ModalNotification";
import { dispatch } from "../../../../store";
import { setAccount } from "../../../../store/slices/account";
import moment from "moment";
import DrawerScheduledInfo from "../../scheduler/components/DrawerScheduledInfo";
const { Text } = Typography;

interface RejectedMainProps {
    selectHistoryType: ReactNode;
    scheduledType: ScheduledDispersionStatus
};


const ScheduledInfoMain = (props: RejectedMainProps) => {
    const MXNCurrency = new Intl.NumberFormat('es-MX', { style: "currency", currency: "MXN" });
    const { selectHistoryType, scheduledType } = props
    const [isLoading, setIsLoading] = useState(true);
    const accountsData = useSelector((state: any) => state.account.accountsInfo);
    const [accountsList, setAccountsList] = useState<Array<AccountResponse>>([]);
    const [scheduledList, setScheduledList] = useState<Array<SchedulerResponse>>([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<DispersionData>();
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    const [accDestTmp, setAccDestTmp] = useState("");

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            // Se define que inicia en la pag 1 y el tamaño de la pagina será de 10
            current: 1,
            pageSize: 10,
            showSizeChanger: false,
        },
    });


    const columns: ColumnsType<SchedulerResponse> = [
        {
            title: "Acciones",
            align: "center",
            className: 'custom-header',
            dataIndex: "dispersionData",
            key: "dispersionData",
            render: (record: DispersionData) => (
                <div>
                    <Buttons
                        title="Más detalles"
                        type="default"
                        action={() => showDrawer(record)}
                        color="#3d3dff"
                        icon={<InfoCircleOutlined />}
                    />
                </div>
            ),
        },
        {
            title: "Cuenta Origen",
            dataIndex: "dispersionData",
            key: "dispersionData",
            align: "center",
            className: 'custom-header',
            render: (data: DispersionData) => data.accountNumber
        },
        {
            title: "Cuenta Destino",
            dataIndex: "dispersionData",
            key: "dispersionData",
            align: "center",
            className: 'custom-header',
            render: (data: DispersionData) => data.foreignAccountNumber
        },
        {
            title: 'Concepto',
            dataIndex: "dispersionData",
            key: "dispersionData",
            className: 'custom-header',
            render: (data: DispersionData) => data.paymentConcept
        },
        {
            title: "Importe sin IVA",
            dataIndex: "dispersionData",
            key: "dispersionData",
            className: 'custom-header',
            render: (data: DispersionData) => MXNCurrency.format(data.balance)
        },
        {
            title: "IVA",
            dataIndex: "dispersionData",
            key: "dispersionData",
            className: 'custom-header',
            render: (data: DispersionData) => MXNCurrency.format(data.iva)
        },
        {
            title: "Fecha y Hora",
            align: "center",
            dataIndex: "dispersionData",
            key: "dispersionData",
            className: 'custom-header',
            render: (data: DispersionData) => moment(data.schedulerDate).format("DD/MM/YYYY HH:mm")
        },
    ];

    const getAccountsList = useCallback(() => {
        setIsLoading(true);
        getAccounts()
            .then((response) => {
                if (response.data && response.data.data && response.data.status === Status.OK) {
                    setAccountsList(response.data.data);
                    dispatch(setAccount({
                        accountsInfo: response.data.data
                    }));
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setNotificationType('ERROR');
                setMessage("Hubo un error al obtener las cuentas.");
                setShowNotification(true);
            })
    }, []);

    useEffect(() => {
        if (_.isEmpty(accountsData)) {
            getAccountsList()
        } else {
            setAccountsList(accountsData);
        }
    }, [accountsData, getAccountsList]);

    const handleChangeAccount = (event: string) => {
        getScheduler(event);
    };

    const getScheduler = useCallback((accNumber: string) => {
        let index = accountsList.findIndex((element) => element.accountNumber === accNumber)
        if (index >= 0) {
            setIsLoading(true);
            getSchedulersTransactions(accountsList[index].accountNumber, scheduledType)
                .then((response: GenericResponse<Array<SchedulerResponse>>) => {
                    if (response.status === Status.OK) {
                        setScheduledList(response.data)
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    setNotificationType('ERROR');
                    setMessage("Hubo un error al obtener la lista de  transferencias programadas.");
                    setShowNotification(true);
                });
        }
    }, [accountsList, scheduledType]);

    const showDrawer = (record: DispersionData) => {
        setOpenDrawer(true);
        setSelectedRecord(record);
    };

    const onCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const handleTableChange: TableProps<SchedulerResponse>["onChange"] = (
        pagination,
    ) => {
        setTableParams({
            pagination,
        });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setScheduledList([]);
        }
    };

    useEffect(() => {
        if (_.isEmpty(accDestTmp) && !_.isEmpty(accountsList)) {
            setAccDestTmp(accountsList[0].accountNumber);
            getScheduler(accountsList[0].accountNumber);
        }
    }, [accountsList, accDestTmp, getScheduler]);

    useEffect(() => {
        if (!_.isEmpty(accDestTmp)) {
            getScheduler(accDestTmp);
        }
    }, [scheduledType, accDestTmp, getScheduler])


    return (
        <>
            <Spin spinning={isLoading}>
                <Row justify={"center"} gutter={[15, 30]}>
                    <Col span={24}>
                        <Row gutter={[16, 16]} style={styles.searcherMainContainer}>
                            <Col xs={24} sm={24} lg={8} xl={6}>
                                {selectHistoryType}
                            </Col>
                            <Col xs={24} sm={24} lg={8} xl={15}>
                                <SelectInput
                                    value={accDestTmp}
                                    label="Seleccione una cuenta"
                                    onChange={handleChangeAccount}
                                    options={
                                        accountsList.map(account => ({
                                            value: account.accountNumber,
                                            label: (account.alias + " | " + account.name + " | " + account.accountNumber)
                                        }))
                                    }
                                />
                            </Col>
                            <Col
                                xs={24} sm={24} lg={8} xl={3}
                                style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}
                            >
                                <Buttons
                                    title="Actualizar"
                                    action={() => getScheduler(accDestTmp)}
                                    type="primary"
                                    color="#3d84ff"
                                    icon={<RedoOutlined />}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Text style={styles.textStyle}>
                            {"Historial de Transferencias " + (scheduledType === ScheduledDispersionStatus.DONE ? "Realizadas" : "Canceladas")}</Text>
                    </Col>
                    <Col span={24}>
                        <Row style={styles.rowRoot}>
                            <div style={styles.primaryContainer}>
                                <Col style={{ marginTop: "20px" }}>
                                    <Table
                                        dataSource={scheduledList}
                                        bordered
                                        columns={columns}
                                        size="small"
                                        onChange={handleTableChange}
                                        scroll={{ y: 400, x: 700 }}
                                        locale={{ emptyText: "No hay datos disponibles" }}
                                        pagination={tableParams.pagination}
                                        loading={{
                                            spinning: isLoading,
                                            indicator: <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />,
                                        }}
                                    />
                                </Col>
                            </div>
                        </Row>
                    </Col>
                </Row>
                {
                    selectedRecord && (
                        <DrawerScheduledInfo
                            selectedRecord={selectedRecord}
                            drawerVisible={openDrawer}
                            onCloseDrawer={onCloseDrawer}
                        />
                    )
                }

                <ModalNotification
                    title={"Programadas Rechazadas"}
                    message={message}
                    alertType={notificationType}
                    show={showNotification}
                    onClose={() => setShowNotification(false)}
                />
            </Spin>
        </>
    );
}

const styles = StyleSheet.create({
    rowRoot: {
        height: "100%",
        width: "100%",
    },
    primaryContainer: {
        width: "100%",
        padding: "20px",
        backgroundColor: 'white',
        borderRadius: 10,
        boxShadow: "0px 0px 8px #00000029",
    },
    searcherMainContainer: {
        padding: 20,
        borderRadius: 10,
        backgroundColor: "#ffffff",
        boxShadow: "0px 0px 8px #00000029",
    },
    textStyle: {
        fontSize: 20,
        fontWeight: "bold",
        color: "#000073"
    },
});

export default ScheduledInfoMain;
