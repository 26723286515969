export const image = {
    imageDivixpay: `${process.env.PUBLIC_URL}/imagotipo-divixpay.png`,
    divixpayLogo: `${process.env.PUBLIC_URL}/divixpay-logo.png`,
    logo: `${process.env.PUBLIC_URL}/logo192.png`,
    changeAcc: `${process.env.PUBLIC_URL}/cambiar-cuenta.png`,
    saveIcon: `${process.env.PUBLIC_URL}/guardar.png`,
    cancelIcon: `${process.env.PUBLIC_URL}/cancelar.png`,
    disableIcon: `${process.env.PUBLIC_URL}/inhabilitar.png`,
    enableIcon: `${process.env.PUBLIC_URL}/habilitar.png`,
    myAccountsIcon: `${process.env.PUBLIC_URL}/mis-cuentas.png`,
    myAccountsIconSelected: `${process.env.PUBLIC_URL}/mis-cuentas-blco.png`,
    thirdAccountsIcon: `${process.env.PUBLIC_URL}/cuentas-de-terceros.png`,
    thirdAccountsIconSelected: `${process.env.PUBLIC_URL}/cuentas-de-terceros-blco.png`,
    accountGroupsIcon: `${process.env.PUBLIC_URL}/grupos-de-cuenta.png`,
    accountGroupsIconSelected: `${process.env.PUBLIC_URL}/grupos-de-cuenta-blco.png`,
    usersMenu: `${process.env.PUBLIC_URL}/usuarios-menu.png`,
    usersMenuSelected: `${process.env.PUBLIC_URL}/usuarios-menu-blco.png`,
    historyIcon: `${process.env.PUBLIC_URL}/historial.png`,
    historyIconSelected: `${process.env.PUBLIC_URL}/historial-blco.png`,
    transferImmIcon: `${process.env.PUBLIC_URL}/transferencia.png`,
    transferImmIconBlue: `${process.env.PUBLIC_URL}/transferencia-blue.png`,
    transferImmIconSelected: `${process.env.PUBLIC_URL}/transferencia-blco.png`,
    transferSchIcon: `${process.env.PUBLIC_URL}/programar-transferencia.png`,
    transferSchIconSelected: `${process.env.PUBLIC_URL}/programar-transferencia-blco.png`,
    createThirdAccIcon: `${process.env.PUBLIC_URL}/crear-cuenta-de-tercero.png`,
    importIcon: `${process.env.PUBLIC_URL}/importar.png`,
    exportIcon: `${process.env.PUBLIC_URL}/exportar.png`,
    stadistics: `${process.env.PUBLIC_URL}/estadisticas.png`,
    stadisticsSelected: `${process.env.PUBLIC_URL}/estadisticas-blco.png`,
    transferTemplateIcon: `${process.env.PUBLIC_URL}/plantilla-de-transferencias.png`,
    createUserIcon: `${process.env.PUBLIC_URL}/crear-usuario.png`,
    generateToken: `${process.env.PUBLIC_URL}/generar-token.png`,
    tokenIcon: `${process.env.PUBLIC_URL}/token.svg`,
    transferCircleIcon: `${process.env.PUBLIC_URL}/Transferencia.svg`,
    downloadCircleIcon: `${process.env.PUBLIC_URL}/Descargar.svg`,
    printCircleIcon: `${process.env.PUBLIC_URL}/Imprimir.svg`,
    notifications: `${process.env.PUBLIC_URL}/notificaciones.png`,
    notificationsSelected: `${process.env.PUBLIC_URL}/notificaciones-white.png`,
}