import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { StyleSheet } from "../../../StyleSheet";
import { Col, Row, Typography, Transfer, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { AccountThirdData, AccountType, CreateAccountGroupRequest, Institution, Status } from "../../../types/types";
import { addAccountGroup, getAccountsThird } from "../../../methods/axiosMethods";
import { useNavigate } from "react-router-dom";
import { dispatch } from "../../../store";
import { setGroups } from "../../../store/slices/groups";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import { subMenusOfAccounts } from "../../../commons/subMenus";
import { image } from "../../../commons/ImageRoutes";
import CardDashboard from "../../../components/CardDashboard";
import ModalNotification, { AlertTypeNew } from "../../../components/ModalNotification";
import Input from "../../../components/Input";
import { setAccountThird } from "../../../store/slices/accountsThird";

const { Text } = Typography;

type Info = {
    id: string;
    bankAccountNumber: string;
    name: string;
    bankName: Institution;
    accountType: AccountType;
    alias: string;
    enabled: boolean;
    rfc: string;
    street: string;
    streetNumber: string;
    zip: string;
    state: string;
    municipality: string;
    country: string;
    key: string
};

const AccountsGroupAdd = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const accountsThirdData = useSelector((state: any) => state.accountThird.accountsThirdInfo);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [selectedAccounts, setSelectedAccounts] = useState<AccountThirdData[]>([]);
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [message, setMessage] = useState("");
    const [accountThirdInfo, setAccountThirdInfo] = useState<Info[]>([]);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [accountsThirdList, setAccountsThirdList] = useState<Array<AccountThirdData>>([]);
    const [shouldNavigate, setShouldNavigate] = useState<boolean>(false);
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;



    const getAccountsThirdList = useCallback(() => {
        setIsLoading(true);
        getAccountsThird()
            .then((response) => {
                if (response.data && response.data.data) {
                    if (response.data.status === Status.OK) {
                        setAccountsThirdList(response.data.data);
                        dispatch(setAccountThird({
                            accountsThirdInfo: response.data.data
                        }));
                    }
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setShouldNavigate(false);
                setNotificationType('ERROR');
                setMessage("No fue posible cargar la lista de cuentas de terceros.");
                setShowNotification(true);
            })
    }, []);

    useEffect(() => {
        if (!_.isEmpty(accountsThirdData)) {
            setAccountsThirdList(accountsThirdData);
        }
    }, [accountsThirdData]);


    useEffect(() => {
        getAccountsThirdList()
    }, [getAccountsThirdList]);

    useEffect(() => {
        if (accountsThirdList.length > 0) {
            const formattedAccounts = accountsThirdList.map((account: AccountThirdData, index: number) => ({
                ...account,
                key: index.toString(),
            }));
            setAccountThirdInfo(formattedAccounts);
        }
        setIsLoading(false);
    }, [accountsThirdList]);

    const handleChange = (newTargetKeys: string[]) => {
        setTargetKeys(newTargetKeys);
        const newSelectedAccounts = accountThirdInfo.filter((account) => newTargetKeys.includes(account.key.toString()));
        setSelectedAccounts(newSelectedAccounts);
    };

    const renderItem = (item: AccountThirdData) => {
        const customLabel = (
            <span>
                {item.alias} - {item.bankAccountNumber}
            </span>
        );
        return {
            label: customLabel,
            value: item.name,
        };
    };

    const handleSaveButton = () => {
        if (!_.isEmpty(name)) {
            const bodyData: CreateAccountGroupRequest = {
                name: name,
                description: description,
                accountsThird: selectedAccounts.map(account => account.id)
            };
            setIsLoading(true);
            addAccountGroup(bodyData)
                .then((response) => {
                    if (response.data && response.data.status && response.data.status === Status.CREATED) {
                        dispatch(setGroups({
                            "groups": response.data.data
                        }));
                        setNotificationType('SUCCESS');
                        setShouldNavigate(true);
                    } else {
                        setNotificationType('ERROR');
                        setShouldNavigate(false);
                    }
                    setMessage(response.data.message);
                    setShowNotification(true);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    setShouldNavigate(false);
                    setNotificationType('ERROR');
                    setMessage("No fue posible crear el grupo de cuentas, intente más tarde.");
                    setShowNotification(true);
                });
        } else {
            setNotificationType('INFO');
            setMessage("Hay campos obligatorios incompletos.");
            setShouldNavigate(false);
            setShowNotification(true);
        }
    };

    const navigateTo = () => {
        navigate('/admin/accountsGroups');
    };

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfAccounts} title="Grupos de Cuentas">
            {
                isLoading ?
                    <div style={styles.contentView}>
                        <Spin
                            indicator={antIcon}
                            tip="Cargando..."
                            style={styles.loading}
                        />
                    </div>
                    :
                    <>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                                <CardDashboard
                                    title={"Guardar"}
                                    icon={image.saveIcon}
                                    height={135}
                                    isFunction={handleSaveButton}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4} >
                                <CardDashboard
                                    title={"Cancelar"}
                                    icon={image.cancelIcon}
                                    action={"/admin/accountsGroups"}
                                    height={135} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                            <Col style={styles.divContainer} md={24} lg={22} xl={20} xxl={16}>
                                <div>
                                    <Text style={styles.textTitle}>
                                        Crear Grupo de Cuentas
                                    </Text>
                                </div>
                                <div>
                                    <Text style={styles.textRequire}>
                                        Todo los campos marcados con (*) son requeridos
                                    </Text>
                                </div>
                                <Row justify={"space-around"} gutter={[12, 12]} style={{ marginTop: 10 }}>
                                    <Col xs={24} xl={11}>
                                        <Input
                                            label="Nombre*"
                                            required
                                            type="text"
                                            onChange={
                                                element => {
                                                    setName(element);
                                                }
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} xl={11}>
                                        <Input
                                            label="Descripción"
                                            required
                                            type="text"
                                            onChange={
                                                element => {
                                                    setDescription(element);
                                                }
                                            }
                                        />
                                    </Col>
                                    <Col xs={24} xl={23}>
                                        <Transfer
                                            dataSource={accountThirdInfo}
                                            listStyle={styles.listTransfer}
                                            targetKeys={targetKeys}
                                            onChange={handleChange}
                                            render={renderItem}
                                            locale={transferLocale}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
            }
            <ModalNotification
                title={"Grupos de Cuentas"}
                message={message}
                alertType={notificationType}
                show={showNotification}
                onClose={() => shouldNavigate ? navigateTo() : setShowNotification(false)}
            />
        </SubDefaultLayout>
    );
};

const transferLocale = {
    itemUnit: "cuenta",
    itemsUnit: "cuentas",
    titles: ["Cuentas Disponibles", "Cuentas Agregadas"],
    removeAll: "Deseleccionar todo",
    selectAll: "Seleccionar todo",
    selectInvert: "Invertir selección",
};

const styles = StyleSheet.create({
    divContainer: {
        backgroundColor: "white",
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        borderRadius: 15,
        padding: 30,
        boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.10)"
    },
    transferContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: "20px",
    },
    listTransfer: {
        width: '50%',
        borderRadius: 8,
        borderColor: 'black'
    },
    contentView: {
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loading: {
        maxHeight: "none",
    },
    textTitle: {
        fontSize: 18,
        color: '#434343',
        fontWeight: 600
    },
    textRequire: {
        fontSize: 13,
        color: '#676767'
    }
});

export default AccountsGroupAdd;
