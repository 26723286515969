import { useCallback, useEffect, useState } from "react";
import { StyleSheet } from "../../../StyleSheet";
import { Col, Row, Typography, Transfer, Spin, Skeleton } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { AccountResponse, Status, UpdateUserRequest } from "../../../types/types";
import { disableToken, generateToken, getAccounts, getAccountsByCompany, getUserById, modifyUser, hasActiveToken } from "../../../methods/axiosMethods";
import { clearUser } from "../../../store/slices/companyUsers";
import { setAccount } from "../../../store/slices/account";
import { setAccountsByCompany } from "../../../store/slices/accountsByCompany";
import { logout } from "../../../context/keycloak";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import CardDashboard from "../../../components/CardDashboard";
import { subMenusOfSettings } from "../../../commons/subMenus";
import { image } from "../../../commons/ImageRoutes";
import ModalNotification, { AlertTypeNew } from "../../../components/ModalNotification";
import { LoadingOutlined } from "@ant-design/icons";
import ModalConfirmation from "../../../components/ModalConfirmation";
import Input from "../../../components/Input";
import SelectInput from "../../../components/SelectInput";

const { Text } = Typography;

const UserEdit = () => {
    const params = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userId, setUserId] = useState("");
    const [sesionUserId, setSesionUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [rol, setRol] = useState("");
    const [userRol, setUserRol] = useState("");
    const [lastName, setLastName] = useState("");
    const [enabledUser, setEnabledUser] = useState<boolean>(false);
    const [hasToken, setHasToken] = useState<boolean>(false);
    const [accountsByUser, setAccountByUser] = useState<string[]>([]);
    const accountsData = useSelector((state: any) => state.account.accountsInfo);
    const accByCompanyData = useSelector((state: any) => state.accountsByCompany.accountsInfo);
    const session = useSelector((state: any) => state.user);
    const [accountInfo, setAccountInfo] = useState<any[]>([]);
    const [accountsList, setAccountsList] = useState<Array<AccountResponse>>([]);
    const [accountsUniqueList, setAccountsUniqueList] = useState<Array<AccountResponse>>([]);
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [message, setMessage] = useState("");
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [shouldNavigate, setShouldNavigate] = useState<boolean>(false);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [confirmToken, setConfirmToken] = useState(false);
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

    const userTypes = [
        {
            value: 'ADMIN',
            label: 'ADMINISTRADOR'
        },
        {
            value: 'USER',
            label: 'USUARIO'
        }
    ];

    useEffect(() => {
        if (session && session.isLogged) {
            if (session.userInfo.id !== undefined) {
                setSesionUserId(session.userInfo.id);
            }
            if (session.userInfo.typeUser !== undefined) {
                setUserRol(session.userInfo.typeUser);
            }
        }
    }, [session]);

    const validityToken = useCallback(() => {
        if (params !== undefined && params.userId !== undefined) {
            hasActiveToken(params.userId)
                .then((resp) => {
                    if (resp.data.status && resp.data.status === Status.OK) {
                        setHasToken(resp.data.data.withActiveToken);
                    }
                })
                .catch(() => {
                    setHasToken(false);
                    setNotificationType('ERROR');
                    setMessage("No fue posible validar el token, intente mas tarde");
                    setShouldNavigate(false);
                    setShowNotification(true);
                });
        }
    }, [params]);

    useEffect(() => {
        if (params !== undefined && params.userId !== undefined) {
            setIsLoadingData(true)
            getUserById(params.userId)
                .then((response) => {
                    if (response.data.data && response.data.status && response.data.status === Status.OK) {
                        setUserId(response.data.data.id);
                        setUserName(response.data.data.username);
                        setEmail(response.data.data.email)
                        setName(response.data.data.name)
                        setRol(response.data.data.userType)
                        setLastName(response.data.data.lastName)
                        setEnabledUser(response.data.data.enabled ? response.data.data.enabled : false)
                        setAccountByUser(response.data.data.accounts)
                        validityToken();
                    }
                    setIsLoadingData(false)
                })
                .catch(() => {
                    setIsLoadingData(false)
                    setNotificationType('ERROR');
                    setMessage("No fue posible obtener los datos del usuario, intente más tarde.");
                    setShouldNavigate(false);
                    setShowNotification(true);
                })
        }
    }, [params, validityToken]);

    const getAccountsList = useCallback(() => {
        setIsLoading(true);
        getAccounts()
            .then((response) => {
                if (response.data && response.data.data) {
                    if (response.data.status === Status.OK && !_.isEmpty(response.data.data)) {
                        setAccountsList(response.data.data);
                        dispatch(setAccount({ accountsInfo: response.data.data }))
                    }
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setNotificationType('ERROR');
                setMessage("No fue posible obtner las cuentas, intente más tarde");
                setShouldNavigate(false);
                setShowNotification(true);
            })
    }, [dispatch]);

    const getAccountsByCompanyList = useCallback(() => {
        setIsLoading(true);
        getAccountsByCompany()
            .then((response) => {
                if (response.data && response.data.data) {
                    if (response.data.status === Status.OK && !_.isEmpty(response.data.data)) {
                        // Combina los dos arreglos
                        const combinedArray = [...accountsList, ...response.data.data];
                        // Usar un objeto para realizar un seguimiento de los objetos por su ID
                        const idMap: { [id: string]: boolean } = {};
                        // Filtrar los objetos por su ID para eliminar los repetidos
                        const uniqueArray = combinedArray.filter((obj) => {
                            if (!idMap[obj.accountNumber]) {
                                idMap[obj.accountNumber] = true;
                                return true;
                            }
                            return false;
                        });
                        setAccountsUniqueList(uniqueArray);
                        dispatch(setAccountsByCompany({ accountsInfo: uniqueArray }))
                    }
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setNotificationType('ERROR');
                setMessage("No fue posible obtener las cuentas de la compañia. intente más tarde.");
                setShouldNavigate(false);
                setShowNotification(true);
            })
    }, [accountsList, dispatch]);

    useEffect(() => {
        if (_.isEmpty(accountsData)) {
            getAccountsList();
        } else {
            setAccountsList(accountsData);
        }
    }, [getAccountsList, accountsData]);

    useEffect(() => {
        if (_.isEmpty(accByCompanyData)) {
            getAccountsByCompanyList();
        } else {
            setAccountsUniqueList(accByCompanyData);
        }
    }, [getAccountsByCompanyList, accByCompanyData]);

    useEffect(() => {
        if (accountsUniqueList.length > 0) {
            const formattedAccounts = accountsUniqueList.map((account: AccountResponse, index: number) => ({
                ...account,
                key: index.toString(),
            }));

            const tKeys: string[] = [];
            for (var i = 0; i < formattedAccounts.length; i++) {
                for (var j = 0; j < accountsByUser.length; j++) {
                    if (formattedAccounts[i].accountNumber === accountsByUser[j]) {
                        tKeys.push(formattedAccounts[i].key);
                    }
                }
            }
            setTargetKeys(tKeys);
            setAccountInfo(formattedAccounts);
        }
        setIsLoading(false);
    }, [accountsByUser, accountsUniqueList]);

    useEffect(() => {
        if (params !== undefined && params.userId !== undefined) {
            hasActiveToken(params.userId)
                .then((resp) => {
                    if (resp.data.status && resp.data.status === Status.OK) {
                        setHasToken(resp.data.data.withActiveToken);
                    }
                })
                .catch(() => {
                    setNotificationType('ERROR');
                    setMessage("No fue posible ontener los datos del usuario, intente más tarde.");
                    setShouldNavigate(true);
                    setShowNotification(true);
                });
        }
    }, [params]);

    const handleChange = (newTargetKeys: string[]) => {
        setTargetKeys(newTargetKeys);
    };

    const renderItem = (item: AccountResponse) => {
        const customLabel = (
            <span>
                {item.alias} - {item.accountNumber}
            </span>
        );
        return {
            label: customLabel,
            value: item.alias,
        };
    };

    const handleModifyEnabled = (enable: boolean) => {
        if (!_.isEmpty(name) && !_.isEmpty(lastName)) {
            const selectedAccounts = accountInfo.filter((account) => targetKeys.includes(account.key.toString()));
            if (!_.isEmpty(name) && !_.isEmpty(lastName)) {
                const bodyData: UpdateUserRequest = {
                    enabled: enable,
                    name: name,
                    lastName: lastName,
                    userRol: rol,
                    accounts: selectedAccounts
                        .filter(account => account.accountNumber !== undefined)
                        .map(account => account.accountNumber!)
                };
                setIsLoading(true);
                modifyUser(userId, bodyData)
                    .then((response) => {
                        if (response.data.status && response.data.status === Status.UPDATED) {
                            setNotificationType('SUCCESS');
                            setShouldNavigate(true);
                            dispatch(clearUser());
                            getAccountsList();
                            if (_.isEqual(userId, sesionUserId)) {
                                setMessage(response.data.message + " Es necesario que vuelva a iniciar sesión para que se apliquen los cambios.");
                            } else {
                                setMessage(response.data.message);
                            }
                        } else {
                            setNotificationType('ERROR');
                            setShouldNavigate(false);
                        }

                        setShowNotification(true);
                        setIsLoading(false);

                    })
                    .catch(() => {
                        setIsLoading(false);
                        setNotificationType('ERROR');
                        setShouldNavigate(false);
                        setShowNotification(true);
                        setMessage("Hubo un error al editar el registro.");
                    });
            }
        } else {
            setNotificationType('INFO');
            setMessage("Hay campos obligatorios incompletos.");
            setShouldNavigate(false);
            setShowNotification(true);
        }
    };

    const handleTokenGenerate = () => {
        setConfirmToken(true);
    };

    const handleGenerateToken = () => {
        setConfirmToken(false);
        generateToken(userId)
            .then((response) => {
                if (response.data && response.data.status) {
                    setNotificationType(response.data.status === Status.OK ? 'SUCCESS' : 'ERROR');
                    setMessage(response.data.message);
                    setShowNotification(true);
                    setHasToken(response.data.status === Status.OK ? true : false);
                } else {
                    setNotificationType('ERROR');
                    setMessage("Hubo un error al generar el token");
                    setShowNotification(true);
                }
            })
            .catch(() => {
                setNotificationType('ERROR');
                setMessage("Hubo un error inesperado al generar el token");
                setShowNotification(true);
            });
    };

    const handleTokenDesactivate = () => {
        disableToken(userId)
            .then((response) => {
                if (response.data.status && response.data.status === Status.OK) {
                    setNotificationType('SUCCESS');
                    setMessage(response.data.message);
                    setShowNotification(true);
                    setHasToken(false);
                }
            })
            .catch(() => {
                setNotificationType('ERROR');
                setMessage("Hubo un error al desactivar el token");
                setShowNotification(true);
            });
    };

    const navigateAlert = () => {
        if (_.isEqual(userId, sesionUserId)) {
            logout();
        } else {
            navigate("/settings");
        }
    };

    const labelChip = (element: String) => {
        switch (element) {
            case 'ADMIN':
                return 'ADMINISTRADOR';
            case 'USER':
                return 'USUARIO';
            default: {
                return '';
            }
        }
    };

    const handleChangeUserRol = (element: string) => {
        setRol(element);
    };

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfSettings} title="Configuración">
            {
                isLoading ?
                    <div style={styles.contentView}>
                        <Spin
                            indicator={antIcon}
                            tip="Cargando..."
                            style={styles.loading}
                        />
                    </div>
                    :
                    <>
                        <Row gutter={[16, 16]}>
                            {
                                enabledUser ?
                                    hasToken ? (
                                        <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                                            <CardDashboard
                                                title={"Desactivar Token"}
                                                icon={image.generateToken}
                                                height={135}
                                                isFunction={handleTokenDesactivate}
                                            />
                                        </Col>
                                    ) : (
                                        <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                                            <CardDashboard
                                                title={"Generar Token"}
                                                icon={image.generateToken}
                                                height={135}
                                                isFunction={handleTokenGenerate}
                                            />
                                        </Col>
                                    )
                                    : null
                            }
                            <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                                <CardDashboard
                                    title={"Guardar"}
                                    icon={image.saveIcon}
                                    height={135}
                                    isFunction={() => handleModifyEnabled(true)}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4} >
                                <CardDashboard
                                    title={"Cancelar"}
                                    icon={image.cancelIcon}
                                    action={"/settings"}
                                    height={135} />
                            </Col>
                            {sesionUserId === userId ? null : (
                                <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                                    {
                                        enabledUser ? (
                                            <CardDashboard
                                                title={"Inhabilitar"}
                                                icon={image.disableIcon}
                                                height={135}
                                                isFunction={() => handleModifyEnabled(false)}
                                            />
                                        ) : (
                                            <CardDashboard
                                                title={"Habilitar"}
                                                icon={image.enableIcon}
                                                height={135}
                                                isFunction={() => handleModifyEnabled(true)}
                                            />
                                        )
                                    }
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col style={styles.divContainer} md={24} lg={22} xl={20} xxl={16}>
                                {
                                    isLoadingData ?
                                        <Skeleton />
                                        :
                                        <>
                                            <div>
                                                <Text style={{ fontSize: 18, color: '#434343', fontWeight: 600 }}>
                                                    Editar usuario
                                                </Text>
                                            </div>
                                            <div>
                                                <Text style={{ fontSize: 13, color: '#676767' }}>
                                                    Todo los campos marcados con (*) son requeridos
                                                </Text>
                                            </div>
                                            <Row gutter={[12, 12]}>
                                                <Col xs={24} xl={12}>
                                                    <Input
                                                        label={"Nombre*"}
                                                        type={"text"}
                                                        required
                                                        value={name}
                                                        onChange={(element) =>
                                                            setName(element)
                                                        }
                                                    />
                                                </Col>
                                                <Col xs={24} xl={12}>
                                                    <Input
                                                        label={"Apellido*"}
                                                        type={"text"}
                                                        value={lastName}
                                                        required
                                                        onChange={(element) =>
                                                            setLastName(element)
                                                        }
                                                    />
                                                </Col>
                                                <Col xs={24} xl={12}>
                                                    <Input
                                                        label={"Nombre de usuario*"}
                                                        type={"text"}
                                                        value={userName}
                                                        disable
                                                        onChange={(element) => setUserName(element)}
                                                    />
                                                </Col>
                                                <Col xs={24} xl={12}>
                                                    <Input
                                                        label={"Email*"}
                                                        type={"text"}
                                                        value={email}
                                                        disable
                                                        onChange={(element) => setEmail(element)}
                                                    />
                                                </Col>
                                                <Col xs={24} xl={24}>
                                                    {
                                                        userRol === "admin" ?
                                                            <SelectInput
                                                                label="Rol*"
                                                                value={rol}
                                                                options={userTypes.map(data => ({
                                                                    value: data.value,
                                                                    label: data.label
                                                                }))}
                                                                onChange={(e) => {
                                                                    handleChangeUserRol(e);
                                                                }}
                                                            />
                                                            :
                                                            <Input
                                                                label={"Rol*"}
                                                                type={"text"}
                                                                value={labelChip(rol)}
                                                                onChange={(element) => setRol(element)}
                                                                disable
                                                            />
                                                    }

                                                </Col>
                                                <Col xs={20} sm={20} md={22} lg={22} xl={24} xxl={24}>
                                                    <Transfer
                                                        dataSource={accountInfo}
                                                        targetKeys={targetKeys}
                                                        onChange={handleChange}
                                                        render={renderItem}
                                                        locale={transferLocale}
                                                        listStyle={styles.listTransfer}
                                                        disabled={rol === "ADMIN"}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                }
                            </Col>
                        </Row>
                    </>
            }
            <ModalNotification
                title={"Usuarios"}
                message={message}
                alertType={notificationType}
                show={showNotification}
                onClose={() => shouldNavigate ? navigateAlert() : setShowNotification(false)}
            />
            <ModalConfirmation
                open={confirmToken}
                message={"Le informamos que hemos recibido su solicitud para generar su token, de clic en Enviar para que lo reciba vía correo electrónico."}
                handleConfirm={handleGenerateToken}
                handleClose={() => setConfirmToken(false)}
            />
        </SubDefaultLayout>
    );
}

const transferLocale = {
    itemUnit: "cuenta",
    itemsUnit: "cuentas",
    titles: ["Cuentas Disponibles", "Cuentas Agregadas"],
    removeAll: "Deseleccionar todo",
    selectAll: "Seleccionar todo",
    selectInvert: "Invertir selección",
    notFoundContent: "Sin cuentas"
};

const styles = StyleSheet.create({
    divContainer: {
        backgroundColor: "white",
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        borderRadius: 15,
        padding: 30,
        boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.10)"
    },
    inputTextTitle: {
        fontSize: 15,
        paddingLeft: 10
    },
    inputText: {
        borderRadius: 8,
        marginTop: 3,
        height: 40,
        color: 'black',
        fontWeight: "bold",
    },
    listTransfer: {
        width: '50%',
        borderRadius: 8,
        borderColor: 'black'
    },
    contentView: {
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loading: {
        maxHeight: "none",
    },
});

export default UserEdit;
