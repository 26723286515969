import { Col, Row, Spin, Table, TableProps, Tag } from "antd";
import { StyleSheet } from "../../../StyleSheet";
import { useCallback, useEffect, useState } from "react";
import { UserResponse, TableParams, Status, Role, UpdateMultipleUsersRequest, UserData } from "../../../types/types";
import { EditOutlined, LoadingOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { enabledOrDisabledUserList, getUsers } from "../../../methods/axiosMethods";
import { clearUser, setUsers } from "../../../store/slices/companyUsers";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import { subMenusOfSettings } from "../../../commons/subMenus";
import Buttons from "../../../components/Buttons";
import CardDashboard from "../../../components/CardDashboard";
import { image } from "../../../commons/ImageRoutes";
import ModalNotification, { AlertTypeNew } from "../../../components/ModalNotification";
import { logout } from "../../../context/keycloak";

const UsersTabContent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userList, setUserList] = useState<Array<UserResponse>>([]);
    const [userListTmp, setUserListTmp] = useState<Array<UserResponse>>([]);
    const usersGetList = useSelector((state: any) => state.users.data);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const hasSelected = selectedRowKeys.length > 0;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [openNotification, setOpenNotification] = useState(false);
    const [message, setMessage] = useState("");
    const session = useSelector((state: any) => state.user);
    const [sesionUserId, setSesionUserId] = useState("");
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            // Se define que inicia en la pag 1 y el tamaño de la pagina será de 10
            current: 1,
            pageSize: 10,
            showSizeChanger: false,
        },
    });

    useEffect(() => {
        if (session && session.isLogged) {
            if (session.userInfo.id !== undefined) {
                setSesionUserId(session.userInfo.id);
            }
        }
    }, [session]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // si el registro que se está checkeando es igual al del usuario logeado, no se permite checkear
        const updatedSelectedRowKeys = newSelectedRowKeys.filter(key => key !== sesionUserId);
        setSelectedRowKeys(updatedSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const columns: ColumnsType<UserResponse> = [
        {
            title: "Acciones",
            dataIndex: "operation",
            key: "operation",
            width: "10%",
            className: 'custom-header',
            align: "center",
            render: (event, record: UserResponse) => (
                <Buttons
                    type="primary"
                    title="Editar"
                    color="#3d3dff"
                    action={() => navigate(`/settings/${record.id}`, { state: record })}
                    icon={<EditOutlined />}
                />
            ),
        },
        {
            title: "Estatus",
            dataIndex: "enabled",
            key: "enabled",
            className: 'custom-header',
            render: (enabled: boolean) => (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            margin: 5,
                            backgroundColor: (enabled) ? "#0fe248" : "#ff3d6b",
                        }}
                    />
                    <label
                        style={{
                            color: (enabled) ? "#0fe248" : "#ff3d6b",
                        }}
                    >
                        {enabled ? "Activo" : "Inactivo"}
                    </label>
                </div>
            ),
        },
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
            className: 'custom-header',
        },
        {
            title: "Apellido",
            dataIndex: "lastName",
            key: "lastName",
            className: 'custom-header',
        },
        {
            title: "Nombre de Usuario",
            dataIndex: "username",
            key: "username",
            className: 'custom-header',
        },
        {
            title: "E-mail",
            dataIndex: "email",
            key: "email",
            className: 'custom-header',
        },
        {
            title: "Tipo de Usuario",
            dataIndex: "userType",
            key: "userType",
            className: 'custom-header',
            render: (userType: Role) => (
                <Tag style={{ fontSize: 12 }} color={userType === Role.ADMIN ? "blue" : "purple"}>
                    {userType === Role.ADMIN ? "ADMINISTRADOR" : "USUARIO"}
                </Tag>
            ),
        },
    ];

    const handleTableChange: TableProps<UserResponse>["onChange"] = (
        pagination,
    ) => {
        setTableParams({
            pagination,
        });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setUserListTmp([]);
        }
    };

    const getUserList = useCallback(() => {
        setIsLoading(true);
        getUsers()
            .then((response) => {
                if (response.data && response.data.data) {
                    if (response.data.status === Status.OK) {
                        dispatch(setUsers({ data: response.data.data }))
                        setUserList(response.data.data)
                        setUserListTmp(usersGetList);
                        dispatch(setUsers({
                            data: response.data.data
                        }));
                    }
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setNotificationType('ERROR');
                setMessage("Hubo un error al obtener la lista de usuarios, intente más tarde.");
                setOpenNotification(true);
            })
    }, [dispatch, usersGetList]);

    useEffect(() => {
        if (_.isEmpty(usersGetList)) {
            getUserList();
        } else {
            setUserList(usersGetList);
            setUserListTmp(usersGetList);
        }
    }, [usersGetList, getUserList]);

    const headerSearcher = (text: string) => {
        const filteredResults = userList.filter(
            (data: UserResponse) =>
                data.name.toLowerCase().includes(text.toLowerCase()) ||
                data.username.toLowerCase().includes(text.toLowerCase()) ||
                data.email.toLowerCase().includes(text.toLowerCase())
        );
        setUserListTmp(filteredResults);
        setSelectedRowKeys([])
    };

    const handleModifyEnabled = (enable: boolean) => {
        const dataUser: UpdateMultipleUsersRequest = { users: [] };
        selectedRowKeys.forEach((element) => {
            usersGetList.forEach((user: UserResponse) => {
                if (element.valueOf()) {
                    if (user.id === element) {
                        let usersData: UserData = {
                            userId: user.id,
                            enabled: enable
                        };
                        dataUser.users.push(usersData)
                    }
                }
            })
        })

        setIsLoading(true);
        enabledOrDisabledUserList(dataUser)
            .then((response) => {
                if (response.data && response.data.status) {
                    setOpenNotification(true);
                    setMessage(response.data.message);
                    if (response.data.status === Status.UPDATED) {
                        dispatch(clearUser());
                        setNotificationType("SUCCESS");
                        setSelectedRowKeys([]);
                    } else {
                        setNotificationType("ERROR");
                    }
                }
                setIsLoading(false);
            })
            .catch(() => {
                setNotificationType('ERROR');
                setMessage("Hubo un error al actualizar el estado");
                setOpenNotification(true);
                setIsLoading(false);
            })
    };

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfSettings} title="Configuración">
            <Row gutter={[15, 25]}>
                <Col span={24}>
                    <Row>
                        <Col span={24} md={6} lg={4}>
                            <CardDashboard
                                title={"Crear Usuario"}
                                icon={image.createUserIcon}
                                action={"/settings/add-user"}
                                height={135}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <div style={styles.primaryContainer}>
                        <Row gutter={[16, 12]} style={styles.rowContainer}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={5}>
                                <Search
                                    onSearch={(value: string) => headerSearcher(value)}
                                    onChange={(value) =>
                                        _.isEmpty(value.target.value) ?
                                            setUserListTmp(userList)
                                            : null}
                                    placeholder="Buscar por nombre, username o email"
                                />
                            </Col>
                            <Col xs={0} sm={0} md={0} lg={8} xl={8} xxl={14} />
                            <Col
                                xs={24}
                                sm={24}
                                md={12}
                                lg={8}
                                xl={8}
                                xxl={5}
                                style={{ display: 'flex', justifyContent: 'space-evenly' }}
                            >
                                <Buttons
                                    type="primary"
                                    title="Habilitar"
                                    action={() => handleModifyEnabled(true)}
                                    icon={<PlusOutlined />}
                                    isDisabled={!hasSelected}
                                    color="#40CD9D"
                                    iconLeft={true}
                                />
                                <Buttons
                                    type="primary"
                                    title="Inhabilitar"
                                    color="#FF3D6B"
                                    action={() => handleModifyEnabled(false)}
                                    icon={<MinusOutlined />}
                                    isDisabled={!hasSelected}
                                    iconLeft={true}
                                />
                            </Col>
                        </Row>
                        <Col xs={24} xl={24}>
                            <Table
                                rowSelection={rowSelection}
                                rowKey={(record) => record.id}
                                dataSource={userListTmp}
                                columns={columns}
                                onChange={handleTableChange}
                                scroll={{ y: 400, x: 1300 }}
                                locale={{ emptyText: "No hay datos disponibles" }}
                                pagination={tableParams.pagination}
                                loading={{
                                    spinning: isLoading,
                                    indicator: <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />,
                                }}
                            />
                        </Col>
                    </div>
                </Col>
            </Row>
            <ModalNotification
                title={"Usuarios"}
                message={message}
                alertType={notificationType}
                show={openNotification}
                onClose={() =>
                    message === "Hubo un error al obtener la lista de usuarios, intente más tarde." ?
                        logout()
                        :
                        setOpenNotification(false)
                }
            />
        </SubDefaultLayout>
    );
};

const styles = StyleSheet.create({
    primaryContainer: {
        width: "100%",
        margin: "20px auto",
        padding: "20px",
        borderRadius: 10,
        backgroundColor: 'white',
        boxShadow: "rgba(177, 179, 185, 0.51) 5px 8px 24px 5px",
    },
    title: {
        paddingLeft: "8px",
        fontSize: 18,
    },
    rowContainer: {
        marginBottom: '20px',
    },
});

export default UsersTabContent;
