import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountThirdData } from '../../types/types';

export interface AccountThirdState {
    accountsThirdInfo: Array<AccountThirdData>;
}

const initialState: AccountThirdState = {
    accountsThirdInfo: [],
};

export const accountThirdSlice = createSlice({
    name: 'accountThird',
    initialState,
    reducers: {
        setAccountThird: (state, action: PayloadAction<AccountThirdState>) => {
            state.accountsThirdInfo = action.payload.accountsThirdInfo;
        },
        clearAccountThird: (state) => {
            state.accountsThirdInfo = [];
        }
    }
});

export const { setAccountThird, clearAccountThird } = accountThirdSlice.actions;
export const getAccountsThirdData = (state: any) => (state.accountThird);
export default accountThirdSlice.reducer;
