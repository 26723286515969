import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountResponse } from '../../types/types';

export interface AccountsByCompanyState {
    accountsInfo: Array<AccountResponse>;
}

const initialState: AccountsByCompanyState = {
    accountsInfo: [],
};

export const accountSlice = createSlice({
    name: 'accountsByCompany',
    initialState,
    reducers: {
        setAccountsByCompany: (state, action: PayloadAction<AccountsByCompanyState>) => {
            state.accountsInfo = action.payload.accountsInfo;
        },
        clearAccountsByCompany: (state) => {
            state.accountsInfo = [];
        }
    }
});

export const { setAccountsByCompany, clearAccountsByCompany } = accountSlice.actions;
export const getAccountsByCompanyData = (state: any) => (state.accountsByCompany);
export default accountSlice.reducer;
