import { Card, Col, Popover, Row, Typography } from 'antd';
import { StyleSheet } from "../StyleSheet";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import stylesCss from "./css/cardMenu.module.css";
const { Text } = Typography;

interface CardMenuDashboardProps {
    title: string;
    color?: string;
    icon?: string;
    action?: string;
    state?: string;
    isFunction?: Function;
    directAccess?: boolean;
}

const CardMenuDashboard = (props: CardMenuDashboardProps) => {
    const { title, icon, action, isFunction, state, color, directAccess } = props;
    const [scaleOnHover, setScaleOnHover] = useState(false);
    const navigate = useNavigate();

    return (
        <div
            onClick={() =>
                isFunction ? isFunction()
                    // si existe action y state, lo manda con state
                    : action && state ? navigate(action, {
                        state: state
                    })
                        : action ? navigate(action)
                            : null
            }
            style={{ cursor: 'pointer' }}
            onMouseEnter={() => setScaleOnHover(true)}
            onMouseLeave={() => setScaleOnHover(false)}
        >
            <Card
                style={{ ...styles.cardRoot, height: (120), transform: scaleOnHover ? 'scale(0.95)' : 'scale(1)' }}
                bodyStyle={styles.bodyCardStyle}
            >
                <Row style={styles.mainContainer}>
                    <Col span={directAccess ? 3 : 6} style={{ ...styles.borderColStyle, ...{ backgroundColor: color } }} />
                    <Col span={directAccess ? 21 : 18}>
                        <Popover
                            content={<Text>{title}</Text>}
                            trigger="hover"
                        >
                            <div  style={styles.infoContainer}>
                                <div style={styles.divImage}>
                                    <img
                                        src={icon}
                                        alt={title}
                                        width={directAccess ? 50 : 45}
                                    />
                                </div>
                                <div style={styles.divText}>
                                    <Text className={stylesCss.textCard} >
                                        {title}
                                    </Text>
                                </div>
                            </div>
                        </Popover>
                    </Col>
                </Row>
            </Card>
        </div >
    );
};

const styles = StyleSheet.create({
    cardRoot: {
        boxShadow: "0px 0px 8px #00000029",
        borderRadius: 12,
        border: "none",
    },
    bodyCardStyle: {
        display: "flex",
        flexDirection: "row",
        padding: 0,
        height: '100%'
    },
    mainContainer: {
        display: "flex",
        width: "100%",
        height: "100%",
    },
    infoContainer: {
        display: "flex",
        paddingLeft:10,
        flexDirection: "column",
        overflow: 'hidden', // Asegura que el texto truncado no se muestre fuera del contenedor
        height: "100%",     
    },
    divImage: { 
        height: "60%", 
        alignItems: 'center', 
        display: 'flex',
    },
    divText: { 
        height: "40%", 
        display: 'flex', 
    },
    containerStyleImage: {
        display: "flex",
        width: "100%",
        height: "100%",
        padding: 0
    },
    borderColStyle: {
        display: "flex",
        height: "100%",
        width: '100%',
        borderRadius: "12px 0px 0px 12px",
    },
});

export default CardMenuDashboard;
