import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
type AlertType = "success" | "info" | "warning" | "error";


export interface IMessage {
    title: string;
    message: string;
}

const initMessage: IMessage = {
    title: '',
    message: ''
};

interface IAlert {
    type: AlertType;
    message: IMessage;
}

interface ICustomAlerts {
    open: boolean;
    autoHideDuration: number;
    alert: IAlert;
}

export interface IAlertState {
    customAlert: ICustomAlerts;
}

export const start: ICustomAlerts = {
    open: false,
    autoHideDuration: 6000,
    alert: {
        type: 'error',
        message: initMessage
    }
};

const initialState: IAlertState = {
    customAlert: start
};

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        setCustomAlert: (state, action: PayloadAction<ICustomAlerts | any>) => {
            state.customAlert = action.payload;
        },
        getCustomAlert: () => {}
    }
});

export const { setCustomAlert } = alertsSlice.actions;
export default alertsSlice.reducer;

export const handleCustomAlert =
    (message: IMessage | string, type: string = start.alert.type) =>
    async (dispatch: any): Promise<void> => {
        dispatch(
            setCustomAlert({
                ...start,
                open: true,
                alert: {
                    type,
                    message
                }
            })
        );
    };
