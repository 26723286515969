import { Col, Modal, Row, Typography } from "antd";
import Buttons from "./Buttons";
import { CheckCircleFilled, CloseCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import { StyleSheet } from "../StyleSheet";
const Text = Typography;

export type AlertTypeNew = "INFO" | "SUCCESS" | "ERROR";

interface ModalNotificationProps {
    show: boolean;
    title: string;
    message: string;
    alertType: AlertTypeNew;
    onClose: () => void;
};

const ModalNotification = (props: ModalNotificationProps) => {
    const { show, title, message, alertType, onClose } = props;
    const icons = {
        "INFO": <InfoCircleFilled style={{ ...styles.iconStyle, color: "#ffb13d" }} />,
        "SUCCESS": <CheckCircleFilled style={{ ...styles.iconStyle, color: "#40cd9d" }} />,
        "ERROR": <CloseCircleFilled style={{ ...styles.iconStyle, color: "#ff3d6b" }} />
    };

    return (
        <Modal
            centered
            open={show}
            title={title}
            onCancel={() => onClose && onClose()}
            footer={
                <Row gutter={[15, 15]} justify={"end"}>
                    <Col>
                        <Buttons
                            type={"primary"}
                            title={"Entendido"}
                            color="#3d3dff"
                            icon={<CheckCircleFilled color="#ffffff" />}
                            action={() => onClose && onClose()}
                        />
                    </Col>
                </Row>
            }
        >
            <div style={styles.divAlert}>
                <Row style={styles.mainContainer} align={"middle"}>
                    <Col span={4}>
                        {icons[alertType]}
                    </Col>
                    <Col span={20}>
                        <Text style={styles.messageTextStyle}>
                            {message}
                        </Text>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        margin: "40px 10px 30px 10px",
    },
    iconStyle: {
        fontSize: 50,
    },
    messageTextStyle: {
        fontSize: 16,
    },
    divAlert: {
        width: "100%",
        height: "100%",
        borderTop: "1px solid #b0b0b0"
    }
});

export default ModalNotification;
