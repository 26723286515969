import { Card, Row, Col, Typography } from 'antd';
import { StyleSheet } from "../StyleSheet";
import { useEffect, useState } from 'react';
import { image } from '../commons/ImageRoutes';
import { CatalogBankResponse } from '../types/types';
const { Text } = Typography;

interface CardProps {
    accountName: string;
    accountNumber: string;
    accountType?: string;
    accountBankData?: CatalogBankResponse;
    handleChangeAccount?: Function;
    backgroundColor: string;
    fontColor: string;
    radioOpt?: number;
    cardType: "origin" | "dest";
    isSqueduled?: boolean
}

const CardDataTransfer = (props: CardProps) => {
    const { accountName, accountNumber, accountType,
        accountBankData, handleChangeAccount, backgroundColor,
        fontColor, radioOpt, cardType, isSqueduled
    } = props;
    const [scaleOnHover, setScaleOnHover] = useState(false);
    const [accTypeDest, setAccTypeDest] = useState("");
    const [accBankDest, setAccBankDest] = useState("");

    useEffect(() => {
        if (accountType) {
            switch (accountType) {
                case "CLABE":
                    setAccTypeDest("CLABE");
                    break;
                case 'DEBIT':
                    setAccTypeDest("Tarjeta de Debito");
                    break;
                case 'CEL':
                    setAccTypeDest("Teléfono celular");
                    break;
            }
        } else {
            setAccTypeDest("CLABE");
        }

        if (accountBankData) {
            setAccBankDest(accountBankData.bankName)
        } else {
            setAccBankDest("STP")
        }
    }, [accountType, accountBankData])

    return (
        <Card style={{ ...styles.cardRoot, backgroundColor: backgroundColor }} bodyStyle={styles.bodyCard}>
            <Row style={styles.rowPrincipal}>
                <Col xs={{ span: handleChangeAccount ? 18 : 24 }}>
                    {
                        accountName || accountNumber ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Text style={{ ...styles.textTitle, color: fontColor }}>
                                    {cardType === "origin" ?
                                        "Cuenta Origen"
                                        : "Cuenta Destino"
                                    }
                                </Text>
                                <Text style={{ ...styles.textAccName, color: fontColor }}>
                                    {
                                        accountName.length > 22 ?
                                            accountName.substring(0, 22 - 3) + '...'
                                            : accountName
                                    }
                                </Text>
                                <Text style={{ ...styles.textAccNumber, color: fontColor }}>
                                    {accBankDest} | {accTypeDest}
                                </Text>
                                <Text style={{ ...styles.textAccNumber, color: fontColor }}>
                                    {accountNumber}
                                </Text>
                            </div>
                            :
                            <div>
                                <Text style={{ ...styles.textAccName, color: fontColor }}>
                                    {
                                        radioOpt !== 3 ?
                                            "Por favor elige una cuenta"
                                            : "Por favor comienza a escribir los datos de la cuenta"
                                    }
                                </Text>
                            </div>
                    }

                </Col>
                {
                    handleChangeAccount && !isSqueduled && (radioOpt !== 3 || cardType === "origin") ?
                        <Col xs={6} style={{ display: 'flex', justifyContent: 'end', alignItems: 'flex-end' }}>
                            <div style={{ cursor: 'pointer', transform: scaleOnHover ? 'scale(0.95)' : 'scale(1)' }}
                                onMouseEnter={() => setScaleOnHover(true)}
                                onMouseLeave={() => setScaleOnHover(false)}
                                onClick={() => {
                                    handleChangeAccount();
                                }}
                            >
                                <img
                                    src={image.changeAcc}
                                    alt={"changeAcc"}
                                    width={35}
                                />
                            </div>
                        </Col>
                        : null
                }
            </Row>
        </Card>
    );
};

const styles = StyleSheet.create({
    rowPrincipal: {
        width: "100%",
        height: "100%"
    },
    cardRoot: {
        width: '100%',
        borderRadius: 12,
        padding: 10,
        minHeight: 180,
        display: 'flex',
    },
    textTitle: {
        fontSize: 15
    },
    textAccNumber: {
        fontSize: 15
    },
    textAccName: {
        fontSize: 20,
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    bodyCard: {
        padding: 10,
        width: '100%',
        display: 'flex'
    }
});
export default CardDataTransfer;
