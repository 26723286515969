import { Col, Row, Spin, Table, Typography } from "antd";
import { StyleSheet } from "../../StyleSheet";
import { useCallback, useEffect, useState } from "react";
import { GenericResponse, NotificationRequest, NotificationResponse, NotificationType, Status, TableParams } from "../../types/types";
import { ColumnsType, TableProps } from "antd/es/table";
import { CheckCircleFilled, DeleteOutlined, InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { deleteNotifications, getNotifications, setReadNotifications } from "../../methods/axiosMethods";
import { deleteNotification, getNotificationsFromRedux, setNotifications, setReadNotification } from "../../store/slices/notificationsSlice";
import { UserInfo, getSessionData } from "../../store/slices/user";
import SubDefaultLayout from "../../components/SubDefaultLayout";
import { subMenusOfNotifications } from "../../commons/subMenus";
import CardDashboard from "../../components/CardDashboard";
import { image } from "../../commons/ImageRoutes";
import Buttons from "../../components/Buttons";
import dayjs from "dayjs";
import GenericModal from "../../components/GenericModal";
import ModalNotification, { AlertTypeNew } from "../../components/ModalNotification";

const { Text } = Typography;
const NotificationsHome = () => {
    const dispatch = useDispatch();
    const { userInfo }: { userInfo: UserInfo } = useSelector(getSessionData);
    const { notifications }: { notifications: Array<NotificationResponse> } = useSelector(getNotificationsFromRedux);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [showModalInfo, setShowModalInfo] = useState<boolean>(false);
    const [isRead, setIsRead] = useState<boolean>(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [notificationListTmp, setNotificationListTmp] = useState<Array<NotificationResponse>>(notifications);
    const [notificationData, setNotificationData] = useState<NotificationResponse>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const hasSelected = selectedRowKeys.length > 0;
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            // Se define que inicia en la pag 1 y el tamaño de la pagina será de 10
            current: 1,
            pageSize: 10,
            showSizeChanger: false,
        },
    });
    const colorRead = "#52d0a5";
    const colorUnread = "#fe587f";

    const columns: ColumnsType<NotificationResponse> = [
        {
            title: "Acciones",
            dataIndex: "operation",
            key: "operation",
            width: "15%",
            align: "center",
            className: 'custom-header',
            render: (_: any, record: NotificationResponse) => (
                <Buttons
                    type={"primary"}
                    title={"Más Detalles"}
                    action={() => handleOpenDetails(record)}
                    color="#444cf9"
                    icon={<InfoCircleOutlined />}
                />
            ),
        },
        {
            title: "Estado",
            dataIndex: "read",
            key: "read",
            className: 'custom-header',
            render: (read: boolean) => (
                <div style={styles.infoText}>
                    <>
                        {read ? (
                            <CheckCircleFilled
                                style={
                                    {
                                        margin: 5,
                                        color: colorRead,
                                    }}
                            />
                        ) : (
                            <InfoCircleOutlined
                                style={
                                    {
                                        margin: 5,
                                        color: colorUnread,
                                    }}
                            />
                        )
                        }
                        <label
                            style={{
                                color: (read) ? colorRead : colorUnread,
                            }}
                        >
                            {read ? "Leida" : "No Leida"}
                        </label>
                    </>
                </div>
            ),
        },
        {
            title: "Tipo de notificación",
            dataIndex: "notificationType",
            key: "notificationType",
            className: 'custom-header',
            render: (_: any, record: NotificationResponse) => (
                record.notificationType === NotificationType.SCHEDULED_DISPERSION ? "Transferencia Programada" : null
            ),
        },
        {
            title: "Título",
            dataIndex: "title",
            key: "title",
            className: 'custom-header',
        },
        {
            title: "Descripción",
            dataIndex: "message",
            key: "message",
            className: 'custom-header',
        },
        {
            title: "Fecha y Hora",
            dataIndex: "createdAt",
            key: "createdAt",
            className: 'custom-header',
            render: (_: any, record: NotificationResponse) => (
                dayjs(record.createdAt).format("DD/MM/YYYY HH:mm")
            ),
        },
    ];

    const handleTableChange: TableProps<NotificationResponse>["onChange"] = (
        pagination,
    ) => {
        setTableParams({
            pagination,
        });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setNotificationListTmp([]);
        }
    };

    const handleOpenDetails = (record: NotificationResponse) => {
        setShowModalInfo(true);
        setNotificationData(record);
        const request = {
            notificationIds: [record.notificationId]
        } as NotificationRequest;
        setReadNotifications(request)
            .then((response) => {
                if (response.status === Status.UPDATED) {
                    dispatch(setReadNotification({
                        notificationId: record.notificationId
                    }));
                    setIsRead(true);
                }
            }).catch(() => {
                setNotificationMessage("Ocurrió un error actualizar las notificaciones");
                setNotificationType('ERROR')
                setShowNotification(true);
            });
    }

    useEffect(() => {
        setNotificationListTmp(notifications)
    }, [notifications])

    const ftnDeleteNotification = () => {
        const request = {
            notificationIds: selectedRowKeys
        } as NotificationRequest;
        setIsLoading(true);
        dispatch(deleteNotification({
            notificationsIds: selectedRowKeys as string[]
        }));

        deleteNotifications(request)
            .then((response) => {
                if (response.status === Status.UPDATED) {
                    setNotificationType('SUCCESS')
                    dispatch(deleteNotification({
                        notificationsIds: selectedRowKeys as string[]
                    }));
                } else {
                    setNotificationType('ERROR')
                }
                setIsLoading(false);
                setNotificationMessage(response.message);
                setShowNotification(true);
            }).catch(() => {
                setNotificationMessage("Ocurrió un error eliminar las notificaciones");
                setNotificationType('ERROR')
                setShowNotification(true);
                setIsLoading(false);
            });
    };

    const getAllNotifications = useCallback(async () => {
        await getNotifications(userInfo.id)
            .then((response: GenericResponse<Array<NotificationResponse>>) => {
                let quantOfNotifys = response.data.filter((not) => not.read === false).length;
                dispatch(setNotifications({
                    notifications: response.data,
                    unreadNotifications: quantOfNotifys
                }));
                setNotificationListTmp(response.data);
            }).catch(() => {
                setNotificationMessage("Ocurrió un error al momento de obtener las notificaciones");
                setNotificationType('ERROR')
                setShowNotification(true);
                setShowNotification(true);
            });
    }, [dispatch, userInfo.id]);

    useEffect(() => {
        getAllNotifications();
    }, [getAllNotifications]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfNotifications} title="Notificaciones">
            <Row gutter={[15, 25]}>
                <Col span={24}>
                    <Row gutter={[10, 10]}>
                        <Col xs={24} md={6} xl={4}>
                            <CardDashboard
                                title={"Programar Transferencia"}
                                icon={image.transferSchIcon}
                                action={`/transfers/scheduled/new`}
                                height={130}
                            />
                        </Col>
                        <Col xs={24} md={6} xl={4}>
                            <CardDashboard
                                title={"Historial de Transferencias"}
                                icon={image.historyIcon}
                                action={`/transfers/history`}
                                height={130}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <div style={styles.primaryContainer}>
                        <Row gutter={[16, 16]} style={styles.rowContainer}>
                            <Col xs={24} xl={24} style={{ display: 'flex', justifyContent: 'end' }} >
                                <Buttons
                                    type="primary"
                                    title="Borrar"
                                    color="#765CE9"
                                    isDisabled={!hasSelected}
                                    action={ftnDeleteNotification}
                                    icon={<DeleteOutlined />}
                                    iconLeft
                                />
                            </Col>
                        </Row>
                        <Col xs={24} xl={24} style={{ marginTop: 15 }}>
                            <Table
                                rowSelection={rowSelection}
                                rowKey={(record) => record.notificationId}
                                dataSource={notificationListTmp}
                                columns={columns}
                                onChange={handleTableChange}
                                scroll={{ y: 400, x: 1300 }}
                                locale={{ emptyText: "No hay datos disponibles" }}
                                pagination={tableParams.pagination}
                                bordered
                                loading={{
                                    spinning: isLoading,
                                    indicator: <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />,
                                }}
                            />
                        </Col>
                    </div>
                </Col>
            </Row>
            <GenericModal
                title={"Más Detalles"}
                show={showModalInfo}
                onClose={() => setShowModalInfo(false)}
            >
                <div style={styles.divColumn} >
                    <Text style={styles.notiTitle}>Fecha y Hora</Text>
                    <Text style={styles.notiDesc}>{dayjs(notificationData?.createdAt).format("DD/MM/YYYY HH:mm")}</Text>
                    <Text style={styles.notiTitle}>Tipo de Notificación</Text>
                    <Text style={styles.notiDesc}>{notificationData?.notificationType === NotificationType.SCHEDULED_DISPERSION ?
                        "Transferencia Programada"
                        : null}
                    </Text>
                    <Text style={styles.notiTitle}>Titulo</Text>
                    <Text style={styles.notiDesc}>{notificationData?.title}</Text>
                    <Text style={styles.notiTitle}>Mensaje</Text>
                    <Text>{notificationData?.message}</Text>
                    <div style={{ ...styles.infoText, marginTop: 25 }}>
                        {isRead ? (
                            <CheckCircleFilled
                                style={
                                    {
                                        margin: 5,
                                        color: colorRead,
                                    }}
                            />
                        ) : (
                            <InfoCircleOutlined
                                style={
                                    {
                                        margin: 5,
                                        color: colorUnread,
                                    }}
                            />
                        )
                        }
                        <label
                            style={{
                                color: (isRead) ? colorRead : colorUnread,
                            }}
                        >
                            {isRead ? "Leida" : "No Leida"}
                        </label>
                    </div>
                </div>
            </GenericModal>
            <ModalNotification
                title={"Notificaciones"}
                message={notificationMessage}
                alertType={notificationType}
                show={showNotification}
                onClose={() => setShowNotification(false)}
            />
        </SubDefaultLayout >
    );
};

const styles = StyleSheet.create({
    primaryContainer: {
        width: "100%",
        margin: "20px auto",
        padding: "20px",
        borderRadius: 10,
        backgroundColor: 'white',
        boxShadow: "rgba(177, 179, 185, 0.51) 5px 8px 24px 5px",
    },
    rowContainer: {
        marginBottom: '20px',
    },
    notiTitle: {
        fontWeight: 'bold',
    },
    notiDesc: {
        marginBottom: 10
    },
    infoText: {
        display: "flex",
        alignItems: "center",
    },
    divColumn: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column'
    }
});

export default NotificationsHome;
