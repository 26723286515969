import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { NotificationResponse } from "../../types/types";



interface NotificationState {
    unreadNotifications: number;
    notifications: Array<NotificationResponse>;
};

interface ActionNotificationState {
    notificationId: string;
};

interface DeleteNotificationState {
    notificationsIds: Array<string>;
};

const notificationInitialState: NotificationState = {
    unreadNotifications: 0,
    notifications: []
};



const notificationSlice = createSlice({
    name: "notifications",
    initialState: notificationInitialState,
    reducers: {
        setNotifications: (state: NotificationState, actions: PayloadAction<NotificationState>) => {
            state.unreadNotifications = actions.payload.unreadNotifications;
            state.notifications = actions.payload.notifications;
        },
        setReadNotification: (state: NotificationState, actions: PayloadAction<ActionNotificationState>) => {
            let index = state.notifications.findIndex(elem => elem.notificationId === actions.payload.notificationId);
            if( index > -1 ){
                state.notifications[index].read = true;
            }
        },
        deleteNotification: (state: NotificationState, actions: PayloadAction<DeleteNotificationState>) => {
            let indexes: number[] = actions.payload.notificationsIds.map(id => (
                state.notifications.findIndex(elem => elem.notificationId === id)
            ));

            indexes.forEach(i => {
                if( i > -1 ){
                    state.notifications.splice(i,1);
                }
            });
        },
        clearNotifications: (state: NotificationState) => {
            state.unreadNotifications = notificationInitialState.unreadNotifications;
            state.notifications = notificationInitialState.notifications;
        }
    }
});



export const {setNotifications,setReadNotification, deleteNotification, clearNotifications} = notificationSlice.actions;
export const getNotificationsFromRedux = (state: any) => (state.notifications) as NotificationState;
export default notificationSlice.reducer;