import Keycloak from "keycloak-js";
import vars from "../vars";

// Para cualquier referencia con el funcionamiento de keycloak, vease en:
// https://blog.logrocket.com/implement-keycloak-authentication-react/
const keycloak = new Keycloak({
  url: vars.keycloakUrl,
  realm: vars.keycloakRealm,
  clientId: vars.keycloakClientId,
});

export const getToken = (): String => {
  return keycloak.token!;
};

export const refreshToken = (): String => {
  return keycloak.refreshToken!;
};

export const logout = () => {
  keycloak.logout({
    redirectUri: window.location.origin,
  });
};

export default keycloak;
