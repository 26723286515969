import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserType } from '../../types/types';

export interface UserInfo {
    id: string;
    userType?: UserType;
    typeUser?: string
    enabled: boolean;
    username: string;
    email: string;
    name: string;
    lastName: string;
    companyId: string;
    isRootUser: boolean;
    isEmailVerified: boolean;
}

export interface UserState {
    isLogged: boolean;
    userInfo: UserInfo;
}

const initialState: UserState = {
    isLogged: false,
    userInfo: {
        id: '',
        userType: undefined,
        typeUser: '',
        enabled: false,
        username: '',
        email: '',
        name: '',
        lastName: '',
        companyId: '',
        isRootUser: false,
        isEmailVerified: false
    }
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<UserInfo>) => {
            state.isLogged = true;
            state.userInfo = action.payload;
        },
        logout: (state) => {
            state.isLogged = false;
            state.userInfo = {
                id: '',
                userType: undefined,
                typeUser: '',
                enabled: false,
                username: '',
                email: '',
                name: '',
                lastName: '',
                companyId: '',
                isRootUser: false,
                isEmailVerified: false
            };
        }
    }
});

export const { login, logout } = userSlice.actions;
export const getSessionData = (state: any) => (state.user);
export default userSlice.reducer;