import { SearchOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { StyleSheet } from "../../../StyleSheet";
import _ from "lodash";

interface SearcherProps {
    placeholder: string;
    onSearch: (value: string) => void;
};

const Searcher = (props: SearcherProps) => {
    const { placeholder, onSearch } = props;
    const [text, setText] = useState<string>("");

    const keyUpFunction = (event: any) => {
        if( event.key === "Enter" || _.isEmpty(text)){
            onSearch(text);
        }
    };

    return (
        <div style={styles.inputContainer}>
            <input
                type="text"
                placeholder={placeholder}
                onChange={(e) => setText(e.target.value)}
                style={styles.inputStyle}
                onKeyUp={(event) => keyUpFunction(event)}
            />
            <Button
                icon={<SearchOutlined />}
                onClick={() => onSearch(text)}
                style={styles.buttonStyle}
            />
        </div>
    );
};

const styles = StyleSheet.create({
    inputContainer: {
        display: "flex",
        width: "100%",
        paddingBottom: 5,
        borderBottom: "1px solid #bfbfbf",
    },
    inputStyle: {
        width: "80%",
        border: "none",
        backgroundColor: "transparent",
        outline: "none"
    },
    buttonStyle: {
        border: "none",
        backgroundColor: "transparent"
    },
});

export default Searcher;
