import { Col, Row, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CatalogBankResponse, Status } from "../../../../types/types";
import { getBanks } from "../../../../methods/axiosMethods";
import { setInstitution } from "../../../../store/slices/institutions";
import _ from "lodash";
import GenericModal from "../../../../components/GenericModal";
import ModalNotification, { AlertTypeNew } from "../../../../components/ModalNotification";

interface DrawerMyAccountsProps {
    selectedRecord: any;
    drawerVisible: boolean;
    onCloseDrawer: () => void;
};

const formatAmount = Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    useGrouping: true,
    minimumFractionDigits: 2,
    notation: 'standard'
});

const ListItem = ({ title, value }: any) => (
    <Col span={24}>
        <Row>
            <Col span={24}>
                <span style={{ fontWeight: 'bold' }}>{`${title}`}</span>
            </Col>
            <Col span={24}>
                <span style={{ lineHeight: '25px' }}>{value}</span>
            </Col>
        </Row>
    </Col>
);

const labelChipcolorAccountType = (element: number) => {
    switch (element) {
        case 40:
            return 'green';
        case 3:
            return 'magenta';
        case 10:
            return 'blue';
        default: {
            return '';
        }
    }
};

const labelChipText = (element: number) => {
    switch (element) {
        case 40:
            return 'CLABE';
        case 3:
            return 'Tarjeta de Debito';
        case 10:
            return 'Teléfono celular';
        default: {
            return '';
        }
    }
};

const DrawerRejected = (props: DrawerMyAccountsProps) => {
    const { selectedRecord, drawerVisible, onCloseDrawer } = props;
    const dispatch = useDispatch();
    const banksList = useSelector((state: any) => state.institutions.institutionsInfo);
    const [, setBanks] = useState<CatalogBankResponse[]>([]);
    const [message, setMessage] = useState("");
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");

    useEffect(() => {
        if (banksList && _.isEmpty(banksList)) {
            getBanks()
                .then((response) => {
                    if (response.data.status === Status.OK && response.data.data && !_.isEmpty(response.data.data)) {
                        dispatch(setInstitution({ institutionsInfo: response.data.data }))
                    }
                })
                .catch(() => {
                    setNotificationType('ERROR');
                    setMessage("Hubo un error al obtener la lista Instituciones, intente más tarde...");
                    setShowNotification(true);
                });
        } else {
            setBanks(banksList);
        }
    }, [banksList, dispatch]);

    const getBankNameByCode = (bankCode: string) => {
        const bank = _.find(banksList, { bankCode: String(bankCode) });
        return bank ? bank.bankName : "No encontrado";
    };

    return (
        <>
            <GenericModal
                show={drawerVisible}
                title="Más Detalles"
                onClose={onCloseDrawer}
            >
                <Row style={{ padding: "40px 20px 40px 20px" }}>
                    <Col span={24}>
                        <Row gutter={[0, 10]}>
                            <ListItem title="Referencia" value={selectedRecord.reference} />
                            <ListItem title="Concepto de Pago" value={selectedRecord.paymentConcept} />
                            <ListItem title="IFPE Emisor" value={"STP"} />
                            <ListItem title="Cuenta Emisor" value={selectedRecord.accountNumber} />
                            <ListItem title="Entidad Receptor" value={getBankNameByCode(selectedRecord.foreignBankId)} />
                            <ListItem title="Cuenta Receptor" value={selectedRecord.foreignAccountNumber} />
                            <ListItem title="Monto" value={formatAmount.format(selectedRecord.balance)} />
                            <ListItem title="IVA" value={formatAmount.format(selectedRecord.iva)} />
                            <ListItem title="Total" value={formatAmount.format(selectedRecord.balance + selectedRecord.iva)} />
                            <div style={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap' }}>
                                <Tag
                                    style={{ fontSize: 14 }}
                                    color={labelChipcolorAccountType(selectedRecord.accountType)}
                                >
                                    {labelChipText(selectedRecord.accountType)}
                                </Tag>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </GenericModal>
            <ModalNotification
                title={"Tranferencias Programadas Rechazadas"}
                message={message}
                alertType={notificationType}
                show={showNotification}
                onClose={() => setShowNotification(false)} />
        </>
    );
};

//---------------------------------------------------------------------------------------------------------------------- Exports
export default DrawerRejected;
