import { image } from "./ImageRoutes";

export interface SubMenuItemsProps {
    title: string;
    icon: string;
    navigateTo: string;
    iconSelected?: string;
    color?: string;
    widthCard?: number
};


export const subMenusOfAccounts: SubMenuItemsProps[] = [
    {
        title: "Mis Cuentas",
        icon: image.myAccountsIcon,
        iconSelected: image.myAccountsIconSelected,
        navigateTo: "/admin/accounts",
    },
    {
        title: "Cuentas de Terceros",
        icon: image.thirdAccountsIcon,
        iconSelected: image.thirdAccountsIconSelected,
        navigateTo: "/admin/accountsThird",
    },
    {
        title: "Grupos de Cuentas",
        icon: image.accountGroupsIcon,
        iconSelected: image.accountGroupsIconSelected,
        navigateTo: "/admin/accountsGroups",
    }
];

export const subMenusOfSettings: SubMenuItemsProps[] = [
    {
        title: "Usuarios",
        icon: image.usersMenu,
        iconSelected: image.usersMenuSelected,
        navigateTo: "/settings",
    },
];

export const subMenusOfTransfers: SubMenuItemsProps[] = [
    {
        title: "Historial",
        icon: image.historyIcon,
        iconSelected: image.historyIconSelected,
        navigateTo: "/transfers/history",
    },
    {
        title: "Inmediatas",
        icon: image.transferImmIcon,
        iconSelected: image.transferImmIconSelected,
        navigateTo: "/transfers/immediate",
    },
    {
        title: "Programadas",
        icon: image.transferSchIcon,
        iconSelected: image.transferSchIconSelected,
        navigateTo: "/transfers/scheduled",
    }

];

export const subMenusAll: SubMenuItemsProps[] = [
    {
        title: "Mis Cuentas",
        icon: image.myAccountsIcon,
        navigateTo: "/admin/accounts",
        color: "#FFC3C2"
    },
    {
        title: "Cuentas de Terceros",
        icon: image.thirdAccountsIcon,
        navigateTo: "/admin/accountsThird",
        color: "#C8FFEC"
    },
    {
        title: "Grupos de Cuentas",
        icon: image.accountGroupsIcon,
        navigateTo: "/admin/accountsGroups",
        color: "#CDF3FC"
    },
    {
        title: "Transferencia",
        icon: image.transferImmIcon,
        navigateTo: "/transfers/immediate",
        color: "#D7CFFB"
    },
    {
        title: "Historial",
        icon: image.historyIcon,
        navigateTo: "/transfers/history",
        color: "#CCD0D8"

    },
    {
        title: "Programar Transferencia",
        icon: image.transferSchIcon,
        navigateTo: "/transfers/scheduled",
        color: "#FFDEFF"
    },
    {
        title: "Estadisticas",
        icon: image.stadistics,
        navigateTo: "/stadistics",
        color: "#F9DCCC"
    }
];

export const subMenusOfNotifications: SubMenuItemsProps[] = [
    {
        title: "Notificaciones",
        icon: image.stadistics,
        iconSelected: image.notificationsSelected,
        navigateTo: "/notifications",
    },
];

export const subMenusOfStadistics: SubMenuItemsProps[] = [
    {
        title: "Estadisticas",
        icon: image.stadistics,
        iconSelected: image.stadisticsSelected,
        navigateTo: "/stadistics",
    },
];