import React, { useEffect, useRef, useState } from "react";
import { Col, DatePicker, Row, Spin, Steps, Table, TimePicker } from "antd";
import { StyleSheet } from "../../../StyleSheet";
import { ArrowRightOutlined, CloseOutlined, LeftOutlined, LoadingOutlined, RightOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import ModalConfirmation from "../../../components/ModalConfirmation";
import { useSelector } from "react-redux";
import { CatalogBankResponse, CreateScheadulerRequest, SchedulerDispersionDataRequest, SchedulerResponse, Status } from "../../../types/types";
import { createSchedulersTransactions, getBanks } from "../../../methods/axiosMethods";
import { dispatch } from "../../../store";
import { setInstitution } from "../../../store/slices/institutions";
import { convertFromDayjsDateAndHour, disabledDate, formatAmount, getDisabledTime, isOutOfTime, isValidDate, isValidHour } from "../../../functions/Functions";
import SchedulerListResume from "./SchedulerListResume";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import { subMenusOfTransfers } from "../../../commons/subMenus";
import Buttons from "../../../components/Buttons";
import ModalNotification from "../../../components/ModalNotification";
import InfoCard from "../../../components/InfoCard";
import { image } from "../../../commons/ImageRoutes";
import Input from "../../../components/Input";
import SelectInput from "../../../components/SelectInput";
import InputAmount from "../../../components/InputAmount";
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';
dayjs.locale('es');

interface DataType {
    key: React.Key;
    accNumberOrigin: string;
    accNumberDest: string;
    accTypeDest: string;
    nameDest: string;
    rfcDest: string;
    bankNameDest: string;
    conceptDest: string;
    referenceDest: string;
    amountDest: string;
    amountIva: string;
    date: Dayjs;
    hour: Dayjs;
    validDate: boolean;
    validHour: boolean;
};

const SchedulerImportedMain = () => {
    const banksList = useSelector((state: any) => state.institutions.institutionsInfo);
    const [bankList, setBankList] = React.useState<CatalogBankResponse[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [notificationType, setNotificationType] = React.useState<"INFO" | "SUCCESS" | "ERROR">("INFO");
    const [showNotification, setShowNotification] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState("");
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [transferListTmp, setTransferListTmp] = React.useState<Array<DataType>>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const hasSelected = selectedRowKeys.length > 0;
    const [currentStep, setCurrentStep] = React.useState(0);
    const [transferFinished, setTransferFinished] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [tokenForm, setTokenForm] = React.useState("");
    const stateData: DataType[] = location.state;
    const [stepConfirmation, setStepConfirmation] = React.useState<boolean>(false);
    const [objTransferResponse, setObjTransferResponseResponse] = React.useState<Array<SchedulerResponse>>([]);
    const inputRefAmount = useRef<HTMLInputElement>(null);
    const inputRefIva = useRef<HTMLInputElement>(null);
    const [currLatitude, setCurrLatitude] = useState(0);
    const [currLongitude, setCurrLongitude] = useState(0);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [isLoadingPermission, setIsLoadingPermission] = useState(false);


    const bankTypes = [
        { value: 'CLABE', label: 'CLABE' },
        { value: 'DEBIT', label: 'Tarjeta de Debito' },
        { value: 'CEL', label: 'Teléfono celular' }
    ];

    const defaultColumns: (ColumnsType<DataType>[number] & { editable?: boolean; dataIndex: string })[] = [
        {
            title: 'Cuenta Origen',
            dataIndex: 'accNumberOrigin',
            className: 'custom-header',
            render: (val, record: DataType) => (
                <Input
                    type="text"
                    required
                    maxLength={18}
                    value={record.accNumberOrigin}
                    label="Cuenta Origen"
                    onChange={(value) => { record.accNumberOrigin = value }}
                    errorLabel={_.isEmpty(record.accNumberOrigin) ? "Este campo es obligatorio." : ""}
                    withError={_.isEmpty(record.accNumberOrigin)}
                    disable={stepConfirmation}
                    isTextNumeric
                />
            )
        },
        {
            title: 'Cuenta Destino',
            dataIndex: 'accNumberDest',
            className: 'custom-header',
            render: (val, record: DataType) => (
                <Input
                    type="text"
                    required
                    value={record.accNumberDest}
                    label="Cuenta Destino"
                    onChange={(value) => { record.accNumberDest = value }}
                    errorLabel={_.isEmpty(record.accNumberDest) ? "Este campo es obligatorio." : ""}
                    withError={_.isEmpty(record.accNumberDest)}
                    disable={stepConfirmation}
                    maxLength={18}
                    isTextNumeric
                />
            )
        },
        {
            title: 'Tipo',
            dataIndex: 'accTypeDest',
            className: 'custom-header',
            render: (val, record: DataType) => (
                <SelectInput
                    label="* Tipo"
                    value={record.accTypeDest}
                    onChange={(value) => { record.accTypeDest = value }}
                    errorLabel={_.isEmpty(record.accTypeDest) ? "Este campo es obligatorio." : ""}
                    withError={_.isEmpty(record.accTypeDest)}
                    disable={stepConfirmation}
                    options={bankTypes.map(data => ({
                        value: data.value,
                        label: data.label
                    }))}
                />
            )
        },
        {
            title: 'Nombre',
            dataIndex: 'nameDest',
            className: 'custom-header',
            render: (val, record: DataType) => (
                <Input
                    type="text"
                    label="Nombre"
                    required
                    value={record.nameDest}
                    onChange={(value) => { record.nameDest = value }}
                    errorLabel={_.isEmpty(record.nameDest) ? "Este campo es obligatorio." : ""}
                    withError={_.isEmpty(record.nameDest)}
                    disable={stepConfirmation}
                />
            )
        },
        {
            title: 'RFC',
            dataIndex: 'rfcDest',
            className: 'custom-header',
            render: (val, record: DataType) => (
                <Input
                    type="text"
                    label="RFC"
                    value={record.rfcDest}
                    onChange={(value) => { record.rfcDest = value }}
                    disable={stepConfirmation}
                />
            )
        },
        {
            title: 'Entidad',
            dataIndex: 'bankNameDest',
            className: 'custom-header',
            render: (val, record: DataType) => (
                <SelectInput
                    label="* Entidad"
                    value={record.bankNameDest}
                    onChange={(value) => record.bankNameDest = value}
                    errorLabel={_.isEmpty(record.bankNameDest) ? "Este campo es obligatorio." : ""}
                    withError={_.isEmpty(record.bankNameDest)}
                    disable={stepConfirmation}
                    options={bankList?.map((data: CatalogBankResponse) => ({
                        value: data.bankCode,
                        label: data.bankName
                    }))}
                />
            )
        },
        {
            title: 'Concepto',
            dataIndex: 'conceptDest',
            className: 'custom-header',
            render: (val, record: DataType) => (
                <Input
                    type="text"
                    required
                    value={record.conceptDest}
                    label="Concepto"
                    onChange={value => { record.conceptDest = value }}
                    errorLabel={_.isEmpty(record.conceptDest) ? "Este campo es obligatorio." : ""}
                    withError={_.isEmpty(record.conceptDest)}
                    disable={stepConfirmation}
                />
            )
        },
        {
            title: 'Referencia Numérica',
            dataIndex: 'referenceDest',
            className: 'custom-header',
            render: (val, record: DataType) => (
                <Input
                    type="text"
                    value={record.referenceDest}
                    label="Referencia Numérica"
                    onChange={(value) => onChangeNumRefEdit(record, value)}
                    disable={stepConfirmation}
                    maxLength={7}
                    isTextNumeric
                />
            )
        },
        {
            title: 'Importe sin Iva',
            dataIndex: 'amountDest',
            className: 'custom-header',
            render: (val, record: DataType) => (
                <InputAmount
                    value={formatAmount.format(Number(record.amountDest))}
                    label="Importe sin IVA"
                    onChange={value => { record.amountDest = value }}
                    required
                    errorLabel={_.isEmpty(record.amountDest) ? "Este campo es obligatorio." : ""}
                    withError={_.isEmpty(record.amountDest)}
                    inputRef={inputRefAmount}
                    disable={stepConfirmation}
                />
            )
        },
        {
            title: 'IVA',
            dataIndex: 'amountIva',
            className: 'custom-header',
            render: (val, record: DataType) => (
                <InputAmount
                    value={formatAmount.format(Number(record.amountIva))}
                    label="IVA"
                    onChange={value => { record.amountIva = value }}
                    required
                    errorLabel={_.isEmpty(record.amountIva) ? "Este campo es obligatorio." : ""}
                    withError={_.isEmpty(record.amountIva)}
                    inputRef={inputRefIva}
                    disable={stepConfirmation}
                />
            )
        },
        {
            title: 'Fecha',
            dataIndex: 'date',
            className: 'custom-header',
            render: (val, record: DataType) => (
                <DatePicker
                    style={{ padding: 10, width: "100%", borderRadius: 10 }}
                    format={"DD/MM/YYYY"}
                    placeholder="* Seleccione una Fecha"
                    value={record.date ? dayjs(record.date) : undefined}
                    onChange={(value) => handleChangeDate(record, value)}
                    status={!record.date ? "error" : ""}
                    locale={locale}
                    disabled={stepConfirmation}
                    disabledDate={disabledDate}
                />
            )
        },
        {
            title: 'Hora',
            dataIndex: 'hour',
            className: 'custom-header',
            render: (val, record: DataType) => (
                <TimePicker
                    style={{ padding: 10, width: "100%", borderRadius: 10 }}
                    format={"HH:mm"}
                    value={record.hour ? dayjs(record.hour) : undefined}
                    placeholder="* Seleccione una Hora"
                    showNow={false}
                    onChange={value => value ? handleChangeHour(record, value) : null}
                    status={!record.hour ? "error" : ""}
                    locale={locale}
                    disabled={stepConfirmation}
                    disabledTime={getDisabledTime}
                />
            )
        },
    ];

    useEffect(() => {
        setIsLoadingPermission(true);
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                setCurrLatitude(position.coords.latitude);
                setCurrLongitude(position.coords.longitude);
                setIsLoadingPermission(false);
            }, (error) => {
                setPermissionDenied(true);
                setIsLoadingPermission(false);
            })
        }
    }, []);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleSaveButton = () => {
        let messageErrors = "";
        switch (currentStep) {
            case 0:
                transferListTmp.forEach((elem: DataType, index: number) => {
                    let hasError = false;
                    // campos obligatorios en general
                    if (!elem.accNumberOrigin || !elem.accNumberDest ||
                        elem.accTypeDest == null || elem.nameDest == null ||
                        elem.bankNameDest == null || !elem.amountDest) {
                        hasError = true;
                    }
                    // fin campos obligatorios en general
                    if (hasError) {
                        const itemMessage = `El registro ${index + 1} tiene campos obligatorios inválidos.\n`;
                        messageErrors += itemMessage;
                    }
                    else if (!isValidDate(dayjs(elem.date).format("YYYY-MM-DD"))) {
                        elem.validDate = false;
                        const itemMessage = `El registro ${index + 1} tiene una fecha no valida.\n`;
                        messageErrors += itemMessage;
                    }
                    else if (!isValidHour(elem.hour, elem.date)) {
                        elem.validHour = false;
                        const itemMessage = `El registro ${index + 1} tiene una hora no valida.\n`;
                        messageErrors += itemMessage;
                    }
                });
                if (!_.isEmpty(messageErrors)) {
                    setNotificationType('INFO');
                    setMessage(messageErrors);
                    setShowNotification(true);

                } else {
                    setCurrentStep(1);
                    setStepConfirmation(true);
                }
                break;
            case 1:
                setCurrentStep(2);
                break;
        }
    };

    const goBack = () => {
        navigate('/transfers/scheduled');
    };

    const onChangeNumRefEdit = (elem: DataType, event: string) => {
        elem.referenceDest = event;
    };

    const handleChangeDate = (element: DataType, event: Dayjs | null) => {
        if (event) {
            let index = transferListTmp.findIndex(data => data.key === element.key);
            if (index >= 0) {
                // Clonar el array actual
                const newArray = [...transferListTmp];
                // Reemplazar el valor en la posición index con la nueva fecha
                newArray[index] = {
                    ...newArray[index],
                    date: event
                };
                // Actualizar el estado con el nuevo array modificado
                setTransferListTmp(newArray);
            }
        }
    };

    const handleChangeHour = (element: DataType, event: Dayjs | null) => {
        if (event) {
            let index = transferListTmp.findIndex(data => data.key === element.key);
            if (index >= 0) {
                // Clonar el array actual
                const newArray = [...transferListTmp];
                // Reemplazar el valor en la posición index con la nueva fecha
                newArray[index] = {
                    ...newArray[index],
                    hour: event
                };
                // Actualizar el estado con el nuevo array modificado
                setTransferListTmp(newArray);
            }
        }
    };

    const goBackStepButton = () => {
        setCurrentStep(currentStep - 1);
        if (currentStep === 1) {
            setStepConfirmation(false);
        }
        setTokenForm("");
    };

    const deleteTransferSelect = () => {
        const newTransferList = transferListTmp.filter((transfer) => !selectedRowKeys.includes(transfer.key.toString()));
        setTransferListTmp(newTransferList);
        setSelectedRowKeys([]);
        setConfirmDelete(false);
        if (_.isEmpty(newTransferList)) {
            goBack();
        }
    };

    const onChangeStep = (value: number) => {
        let messageErrors = "";
        transferListTmp.forEach((elem: DataType, index: number) => {
            let hasError = false;
            // campos obligatorios en general
            if (!elem.accNumberOrigin || !elem.accNumberDest ||
                elem.accTypeDest == null || elem.nameDest == null ||
                elem.bankNameDest == null || !elem.amountDest ||
                !elem.amountIva) {
                hasError = true;
            }
            // fin campos obligatorios en general
            if (hasError) {
                const itemMessage = `El registro ${index + 1} tiene campos obligatorios inválidos.\n`;
                messageErrors += itemMessage;
                //setNeedLineBreak(true);
            }
            else if (!isValidDate(dayjs(elem.date).format("YYYY-MM-DD"))) {
                elem.validDate = false;
                const itemMessage = `El registro ${index + 1} tiene una fecha no valida.\n`;
                messageErrors += itemMessage;
                //setNeedLineBreak(true);
            }
            else if (!isValidHour(elem.hour, elem.date)) {
                elem.validHour = false;
                const itemMessage = `El registro ${index + 1} tiene una hora no valida.\n`;
                messageErrors += itemMessage;
                //setNeedLineBreak(true);
            }
        });
        if (!_.isEmpty(messageErrors)) {
            setNotificationType('INFO');
            setMessage(messageErrors);

            setShowNotification(true);

        } else {
            if (value === 0) {
                setCurrentStep(value);
                setStepConfirmation(false);
            }
            if (value === 1) {
                setCurrentStep(value);
                setStepConfirmation(true);
            }
            // si se encuentra en el paso 2 de confirmacion de datos, mostrar el modal del token
            if (value === 2) {
                setCurrentStep(value);
            }
            if (value === 3 && tokenForm.length === 6) {
                createSchedulersT();
            }
        }
    };

    const createSchedulersT = () => {
        let transferListData: SchedulerDispersionDataRequest[] = [];
        transferListTmp.forEach((elem: DataType, index: number) => {
            elem.validDate = true;
            elem.validHour = true;
            let bankObj = bankList.find(obj => obj.bankCode === elem.bankNameDest);
            let squeduledDateTime: Date = new Date();
            if (elem.date && elem.hour) {
                squeduledDateTime = convertFromDayjsDateAndHour(elem.date, elem.hour);
            }
            let objTransfer: SchedulerDispersionDataRequest = {
                accountNumber: elem.accNumberOrigin,
                foreignAccountNumber: elem.accNumberDest,
                foreignBankId: bankObj ? bankObj.bankCode : "",
                reference: Number(elem.referenceDest),
                name: elem.nameDest,
                paymentConcept: elem.conceptDest,
                accountType: elem.accTypeDest === "CLABE" ? 40 :
                    elem.accTypeDest === "DEBIT" ? 3 : 10,
                paymentType: 1,
                rfcOrCurp: elem.rfcDest,
                balance: Number(elem.amountDest),
                iva: Number(elem.amountIva),
                scheduledDateTime: squeduledDateTime,
                latitude: currLatitude,
                longitude: currLongitude
            }
            transferListData.push(objTransfer);
        });
        const request: CreateScheadulerRequest = {
            token: tokenForm,
            dispersions: transferListData
        };
        setLoading(true);
        createSchedulersTransactions(request)
            .then((response) => {
                if (response) {
                    if (!_.isEmpty(response.data)) {
                        if (response.status === Status.CREATED) {
                            setNotificationType('SUCCESS');
                        } else {
                            setNotificationType('INFO');
                        }
                        setMessage(response.message);
                        setCurrentStep(3);
                        setTransferFinished(true);
                        setObjTransferResponseResponse(response.data);
                    } else {
                        setNotificationType('ERROR');
                        setMessage(response.message);
                        setCurrentStep(1);
                    }
                    setShowNotification(true);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setShowNotification(true);
                setNotificationType('ERROR');
                setMessage("Hubo un error al realizar las transferencias por importación");
            });
    };

    const handleChangeToken = (inputValue: string) => {
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        setTokenForm(numericValue)
    };

    useEffect(() => {
        if (banksList && _.isEmpty(banksList)) {
            setLoading(true);
            getBanks()
                .then((response) => {
                    if (response.data.status === Status.OK && response.data.data && !_.isEmpty(response.data.data)) {
                        dispatch(setInstitution({ institutionsInfo: response.data.data }))
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setNotificationType('ERROR');
                    setMessage("No fue posible obtener las instituciones bancarias en este momento, intente más tarde.");
                    setShowNotification(true);
                    setLoading(false);
                });
        } else {
            setBankList(banksList);
        }
    }, [banksList]);

    useEffect(() => {
        if (stateData) {
            setTransferListTmp(stateData);
        }
    }, [stateData]);


    return (
        <SubDefaultLayout
            title="Transferencias Programadas"
            childrenSubMenu={subMenusOfTransfers}
        >
            <Spin spinning={isLoadingPermission}>
                {
                    permissionDenied ?
                        <div style={{ marginTop: -15, marginBottom: 15 }}>
                            <InfoCard
                                title="¡Atención!"
                                description="Es necesario aceptar los permisos de ubicación para poder realizar transferencias. 
                                Si los rechazaste, por favor refresca el sitio y asegúrate de otorgarlos."
                                type="error"
                            />
                        </div>
                        :
                        <>
                            <Row gutter={[15, 15]}>
                                <Col xs={24} md={15} lg={6}>
                                    <Steps
                                        percent={100}
                                        direction="vertical"
                                        current={currentStep}
                                        onChange={!transferFinished ? onChangeStep : undefined}
                                        items={[
                                            {
                                                title: 'Datos de la Transferencia',
                                                description: "Emisor-Receptor",
                                            },
                                            {
                                                title: 'Confirmación',
                                                description: 'Confirmación de Datos',
                                            },
                                            {
                                                title: 'Token',
                                                description: "Validación de Token",
                                            },
                                            {
                                                title: 'Recibo',
                                                description: "Recibo de Transferencia",
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col xs={24} lg={18}>
                                    {(isOutOfTime()) && (
                                        <Row>
                                            <Col xs={24} lg={18} style={{ marginTop: -15, marginBottom: 15 }}>
                                                <InfoCard
                                                    title="¡Importante!"
                                                    description="Si estás realizando esta operación fuera de horario hábil (lunes a viernes de 6:00 am a 11:00 pm) la operación se hará efectivas el siguiente día hábil."
                                                    type="primary"
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                    <Row style={styles.mainTableContainer} gutter={[0, 20]}>
                                        <Col span={24} style={styles.buttonsContainer}>
                                            <Row gutter={[20, 20]} justify={"end"} style={{ padding: "20px 20px 20px 20px" }}>
                                                {(!transferFinished) ? (
                                                    <>
                                                        {(!stepConfirmation && currentStep !== 2) && (
                                                            <Col>
                                                                <Buttons
                                                                    title="Borrar"
                                                                    type="primary"
                                                                    color="#765CE9"
                                                                    isDisabled={!hasSelected}
                                                                    action={() => setConfirmDelete(true)}
                                                                    icon={<CloseOutlined />}
                                                                />
                                                            </Col>
                                                        )}
                                                        {(currentStep !== 0) && (
                                                            <Col >
                                                                <Buttons
                                                                    type="primary"
                                                                    action={goBackStepButton}
                                                                    icon={<LeftOutlined />}
                                                                    isDisabled={loading}
                                                                    title="Anterior"
                                                                />
                                                            </Col>
                                                        )}
                                                        <Col>
                                                            <Buttons
                                                                title="Siguiente"
                                                                type="primary"
                                                                color="#3d3dff"
                                                                action={() => (currentStep !== 2) ? handleSaveButton() : createSchedulersT()}
                                                                icon={<RightOutlined />}
                                                                isDisabled={(loading) || (currentStep === 2 && tokenForm.length < 6)}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Buttons
                                                                title="Cancelar"
                                                                color="#ffb13d"
                                                                type="default"
                                                                action={goBack}
                                                                icon={<CloseOutlined />}
                                                                isDisabled={loading}
                                                            />
                                                        </Col>
                                                    </>
                                                ) : (
                                                    <Col>
                                                        <Buttons
                                                            title="Finalizar"
                                                            type="primary"
                                                            action={() => navigate('/transfers/scheduled')}
                                                            color="#3d3dff"
                                                            icon={<ArrowRightOutlined />}
                                                        />
                                                    </Col>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col span={24} style={styles.contentContainer}>
                                            <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 50 }} />}>
                                                {(currentStep === 0 || currentStep === 1) ? (
                                                    <Table
                                                        locale={{ emptyText: "No hay datos disponibles" }}
                                                        rowSelection={!stepConfirmation ? rowSelection : undefined}
                                                        rowKey={(record) => record.key}
                                                        scroll={{ y: 400, x: 2300 }}
                                                        size="small"
                                                        bordered
                                                        dataSource={transferListTmp}
                                                        columns={defaultColumns}
                                                    />
                                                ) : ((currentStep === 2) ? (
                                                    <div style={styles.divStep2}>
                                                        <div style={{ width: "80%" }}>
                                                            <InfoCard
                                                                title="¡Importante!"
                                                                description="Le informamos que hemos recibido su solicitud y para proceder con su solicitud de
                                                        confirmación, se ha generado un token de confirmación único y seguro, que deberá
                                                        ingresar para completar el proceso."
                                                                type="warning"
                                                            />
                                                            <Row gutter={[12, 12]} style={{ marginTop: 20 }}>
                                                                <Col xs={24} sm={6} style={styles.colView}>
                                                                    <img
                                                                        src={image.tokenIcon}
                                                                        alt={"secureIcon"}
                                                                        style={styles.tokenIcon}
                                                                    />
                                                                </Col>
                                                                <Col xs={24} sm={16}>
                                                                    <h3 style={{ color: "#434343", paddingLeft: 10 }}>Ingrese Token</h3>
                                                                    <Input
                                                                        maxLength={6}
                                                                        value={tokenForm}
                                                                        label="Ingrese los 6 Dígitos de su Token"
                                                                        onChange={(value) => handleChangeToken(value)}
                                                                        type={"text"}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <SchedulerListResume listTransfer={objTransferResponse} />
                                                ))}
                                            </Spin>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <ModalNotification
                                show={showNotification}
                                alertType={notificationType}
                                title="Estimado usuario"
                                message={message}
                                onClose={() => setShowNotification(false)}
                            />
                            <ModalConfirmation
                                open={confirmDelete}
                                handleConfirm={deleteTransferSelect}
                                handleClose={() => setConfirmDelete(false)}
                                message={"¿Estas seguro que deseas eliminar las transferencias programadas seleccionadas?"}
                            />
                        </>
                }
            </Spin>
        </SubDefaultLayout >
    );
};

const styles = StyleSheet.create({
    mainTableContainer: {
        boxShadow: "0px 0px 8px #00000029",
        borderRadius: 20,
        backgroundColor: "#ffffff"
    },
    buttonsContainer: {
        borderBottom: "1px solid #bfbfbf"
    },
    contentContainer: {
        padding: "0px 20px 20px 20px",
    },
    divStep2: {
        width: "100%",
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 50,
    },
    colView: {
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center'
    },
    tokenIcon: {
        resizeMode: 'contain',
        width: 55,
    },
});



export default SchedulerImportedMain;

