import { UploadOutlined } from '@ant-design/icons';
import {Col, Row, Space, Spin, Upload, message, Typography, UploadFile, UploadProps } from 'antd';
import { StyleSheet } from "../../../StyleSheet";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import GenericModal from '../../../components/GenericModal';
import Buttons from '../../../components/Buttons';

interface ModalSelectProps {
    openModal: boolean;
    handleClose: Function;
    handleImportTransfer: (data: any) => void;
    isLoadingSpin: boolean;
    isScheduler: boolean;
};

const TransferModalImport = (props: ModalSelectProps) => {
    const { openModal, handleClose, handleImportTransfer, isLoadingSpin, isScheduler } = props;
    const { Text } = Typography;
    const [fileList, setFileList] = useState<UploadFile[]>();
    const [file, setFile] = useState<any>();
    const [disableConfirmButton, setDisableConfirmButton] = useState<boolean>(true);

    const handleConfirm = () => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e: any) => {
            const bufferArray = e?.target.result;
            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const heading = [
                [
                    'accNumberOrigin',
                    'accNumberDest',
                    'accTypeDest',
                    'nameDest',
                    'rfcDest',
                    'bankNameDest',
                    'conceptDest',
                    'referenceDest',
                    'amountDest',
                    'amountIva',
                    isScheduler ? "date" : null,
                    isScheduler ? "hour" : null
                ]
            ];

            XLSX.utils.sheet_add_aoa(ws, heading);
            const data = XLSX.utils.sheet_to_json(ws);
            handleImportTransfer(data)
        };
    };

    const onChangeFileUpload: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    useEffect(() => {
        setFileList([])
    }, [openModal]);

    useEffect(() => {
        if( file ){
            setDisableConfirmButton(false);
        }
    }, [file]);

    return (
        <>
            <GenericModal
                disableConfirmButton={disableConfirmButton}
                showActionButtons
                title={(isScheduler) ?
                    ("Importar Plantilla Para Transferencias Programadas") :
                    ("Importar Plantilla Para Transferencias")
                }
                show={openModal}
                onConfirm={() => handleConfirm()}
                onClose={() => handleClose()}
            >
                <Spin spinning={isLoadingSpin}>
                    <Row gutter={[16, 16]} style={{ padding: "40px 20px 40px 20px" }}>
                        <Col style={styles.inputUploadRoot}>
                            <div style={styles.inputContainerRow}>
                                <Text style={styles.inputTextTitleModal}>Descargar Plantilla</Text>
                                <Link
                                    style={{marginLeft: 10}}
                                    to={ isScheduler ?
                                        "/files/Plantilla_Transferencias_Programadas.xlsx" :
                                        "/files/Plantilla_Transferencias.xlsx"
                                    }
                                    target="_blank" download
                                >
                                    Plantilla
                                </Link>
                            </div>
                        </Col>
                        <Col style={styles.inputUploadRoot}>
                            <div style={styles.inputContainerRow}>
                                <Text style={styles.inputTextTitleModal}>Elegir archivo</Text>
                                <Space style={styles.buttonUploadModal}>
                                    <Upload maxCount={1}
                                        onRemove={() => setDisableConfirmButton(true)}
                                        fileList={fileList}
                                        onChange={onChangeFileUpload}
                                        beforeUpload={async file => {
                                            const isKeyFile = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                                            if (!isKeyFile) {
                                                message.error(`${file.name} no es de tipo .xlsx`);
                                            }
                                            else {
                                                setFile(file)
                                            }
                                            return isKeyFile || Upload.LIST_IGNORE;
                                        }}>
                                        <Buttons
                                            title="Elegir archivo"
                                            type='primary'
                                            icon={<UploadOutlined />}
                                            color="#3d84ff"
                                        />
                                    </Upload>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </Spin>
            </GenericModal>
        </>
    );
};

const styles = StyleSheet.create({
    inputTextTitleModal: {
        fontSize: 15,
        width: "30%"
    },
    buttonUploadModal: {
        width: "70%",
    },
    inputContainerRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    inputUploadRoot: {
        width: "100%",
    }
});

export default TransferModalImport;
