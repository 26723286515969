import React, { useEffect, useState } from "react";
import { Col, Row, Spin, Typography } from "antd";
import { StyleSheet } from "../../../StyleSheet";
import estadoCiudadData from "../../../components/EstadoCiudad";
import { addAccountThird, getBanks } from "../../../methods/axiosMethods";
import { AccountThirdDataRequest, AccountType, CatalogBankResponse, CreateMultipleAccountThirdRequest, Institution, MunicipalityData, Status } from "../../../types/types";
import states from '../../../components/states.json';
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { clearAccountThird } from "../../../store/slices/accountsThird";
import { useNavigate } from "react-router-dom";
import { setInstitution } from "../../../store/slices/institutions";
import { isValidCel, isValidClabe, isValidDebit, validateRfc } from "../../../functions/Functions";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import { subMenusOfAccounts } from "../../../commons/subMenus";
import CardDashboard from "../../../components/CardDashboard";
import Input from "../../../components/Input";
import SelectInput from "../../../components/SelectInput";
import ModalNotification, { AlertTypeNew } from "../../../components/ModalNotification";
import { LoadingOutlined } from "@ant-design/icons";
const { Text } = Typography;

const bankTypes = [
    {
        value: 'CLABE',
        label: 'CLABE'
    },
    {
        value: 'DEBIT',
        label: 'Tarjeta de Debito'
    },
    {
        value: 'CEL',
        label: 'Teléfono celular'
    }
];

const AccountsThirdAdd = () => {
    const [loading, setLoading] = useState(false);
    const [banks, setBanks] = useState<CatalogBankResponse[]>([]);
    const [nameForm, setNameForm] = useState('');
    const [aliasForm, setAliasForm] = useState('');
    const [accountNumberForm, setAccountNumberForm] = useState<string>("");
    const [bankForm, setBankForm] = useState<Institution>();
    const [type, setType] = useState('');
    const [typeForm, setTypeForm] = useState<AccountType>();
    const [rfcForm, setRfcForm] = useState('');
    const [streetForm, setStreetForm] = useState('');
    const [streetNumberForm, setStreetNumberForm] = useState('');
    const [zipcodeForm, setZipcodeForm] = useState('');
    const [stateForm, setStateForm] = useState('');
    const [cityForm, setCityForm] = useState('');
    const [municipalitiesList, setMunicipalitiesList] = useState<MunicipalityData[]>([]);
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [shouldNavigate, setShouldNavigate] = useState<boolean>(false);
    const [missingData, setHasMissingData] = useState<boolean>(false);
    const [isValidAccNumber, setIsValidAccNumber] = useState<boolean>(true);
    const [message, setMessage] = useState("");
    const banksList = useSelector((state: any) => state.institutions.institutionsInfo);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

    const navigateNotification = () => {
        navigate("/admin/accountsThird")
    };

    useEffect(() => {
        if (banksList && _.isEmpty(banksList)) {
            setLoading(true);
            getBanks()
                .then((response) => {
                    if (response.data.status === Status.OK && response.data.data && !_.isEmpty(response.data.data)) {
                        dispatch(setInstitution({ institutionsInfo: response.data.data }))
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setNotificationType("ERROR");
                    setMessage("No fue posible obtener las instituciones bancarias en este momento, intente más tarde.");
                    setShouldNavigate(true);
                    setShowNotification(true);
                    setLoading(false);
                });
        } else {
            setBanks(banksList);
        }
    }, [banksList, dispatch]);

    const changeState = (text: string) => {
        const filteredCities = estadoCiudadData().filter((data) => data.state === text);
        const municipalitiesListTmp: Array<MunicipalityData> = [];
        filteredCities.forEach((city) => {
            municipalitiesListTmp.push({
                name: city.state,
                value: city.city
            });
        });
        setMunicipalitiesList(municipalitiesListTmp);
    };

    const handleChangeTypeBank = (element: string) => {
        setIsValidAccNumber(true);
        if (element === "DEBIT") {
            setTypeForm(AccountType.DEBIT);
        } else if (element === "CEL") {
            setTypeForm(AccountType.CEL)
        } else if (element === "CLABE") {
            setTypeForm(AccountType.CLABE)
        }
        setType(element);
    };

    const handleChangeBank = (element: string) => {
        setBankForm(element as any);
    };

    const handleSaveButton = () => {
        if (!_.isEmpty(rfcForm) && validateRfc(rfcForm)) {
            setNotificationType("INFO");
            setMessage("El formato del RFC ingresado es incorrecto.");
            setHasMissingData(true)
            setShouldNavigate(false);
            setShowNotification(true);
        }
        // si la cuenta es menor a 10 digitos o mayor a 18
        else if (accountNumberForm && (accountNumberForm.toString().length < 10 || accountNumberForm.toString().length > 18)) {
            setNotificationType("INFO");
            setMessage("El número de cuenta no puede ser menor a 10 dígitos o mayor a 18 dígitos.");
            setHasMissingData(true)
            setShouldNavigate(false);
            setShowNotification(true);
        }
        else if (
            !_.isEmpty(nameForm) &&
            !_.isEmpty(aliasForm) &&
            !_.isEmpty(accountNumberForm)
        ) {
            let isValidAcc = false;
            let typeFormSel = typeForm ? typeForm : bankTypes[0].value
            switch (typeFormSel) {
                case "CLABE":
                    isValidAcc = isValidClabe(accountNumberForm)
                    break;
                case "DEBIT":
                    isValidAcc = isValidDebit(accountNumberForm)
                    break;
                case "CEL":
                    isValidAcc = isValidCel(accountNumberForm)
                    break;
            }
            if (!isValidAcc) {
                setNotificationType("INFO");
                setMessage("El número de cuenta y/o el tipo de cuenta no son validos.");
                setIsValidAccNumber(false);
                setShouldNavigate(false);
                setShowNotification(true);
            } else {
                const bodyData = {
                    name: nameForm,
                    typeAccount: typeFormSel as AccountType,
                    divisa: 'MXN',
                    alias: aliasForm,
                    bankAccountNumber: accountNumberForm,
                    bankName: bankForm ? bankForm : banks[0].bankCode as Institution,
                    enabled: true,
                    rfc: rfcForm,
                    street: streetForm,
                    streetNumber: streetNumberForm,
                    zip: zipcodeForm,
                    state: stateForm,
                    municipality: cityForm,
                    country: 'Mexico',
                };
                setHasMissingData(false);
                setLoading(true);
                let requestAccountThird: AccountThirdDataRequest[] = [];
                requestAccountThird.push(bodyData)
                let accountArray: CreateMultipleAccountThirdRequest = {
                    accountsThirds: requestAccountThird
                }
                addAccountThird(accountArray)
                    .then((response) => {
                        if (response.data.status === Status.CREATED) {
                            dispatch(clearAccountThird());
                            setNotificationType("SUCCESS");
                            setMessage(response.data.message);
                            setShouldNavigate(true);
                        } else {
                            setNotificationType("ERROR");
                            setMessage(response.data.message);
                            setShouldNavigate(false);
                        }
                        setLoading(false);
                        setShowNotification(true);
                    })
                    .catch(() => {
                        setLoading(false);
                        setNotificationType("ERROR");
                        setMessage("No fue posible crear la cuenta de tercero en este momento, intente más tarde.");
                        setShouldNavigate(false);
                        setShowNotification(true);
                    })
            }
        } else {
            setHasMissingData(true);
            setNotificationType("INFO");
            setMessage("Hay campos obligatorios incompletos.");
            setShouldNavigate(false);
            setShowNotification(true);
        }
    };

    const handleChangeAccNumber = (inputValue: string) => {
        let validCel = false;
        let validDebit = false;
        let validClabe = false;
        setIsValidAccNumber(true);
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        setAccountNumberForm(numericValue);
        if (isValidCel(inputValue)) {
            setTypeForm(AccountType.CEL);
            setType("CEL");
            validCel = true;
        }
        if (isValidDebit(inputValue)) {
            setTypeForm(AccountType.DEBIT);
            setType("DEBIT");
            validDebit = true;
        }
        if (isValidClabe(inputValue)) {
            validClabe = true;
            const codeType: Array<CatalogBankResponse> = _.filter(banks, { code: inputValue.slice(0, 3) });
            if (!_.isEmpty(codeType)) {
                setBankForm(codeType[0].bankCode as Institution);
                setTypeForm(AccountType.CLABE)
                setType("CLABE");
            }
        }
        if (!validCel && !validDebit && !validClabe) {
            setType("");
            setBankForm(undefined)
        }
    };

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfAccounts} title="Cuentas de Terceros">
            {
                loading ?
                    (
                        <div style={styles.contentView}>
                            <Spin
                                indicator={antIcon}
                                tip="Cargando..."
                                style={styles.loading}
                            />
                        </div>
                    )
                    : (
                        <>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                                    <CardDashboard
                                        title={"Guardar"}
                                        icon="../../guardar.png"
                                        height={135}
                                        isFunction={() => handleSaveButton()}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4} >
                                    <CardDashboard
                                        title={"Cancelar"}
                                        icon="../../cancelar.png"
                                        action={"/admin/accountsThird"}
                                        height={135} />
                                </Col>
                            </Row>
                            {/* Card de Datos de la cuenta */}
                            <Row style={{ marginTop: 10 }}>
                                <Col style={styles.divContainer} md={24} lg={22} xl={20} xxl={16}>
                                    <div>
                                        <Text style={styles.textTitle}>
                                            Datos de la Cuenta
                                        </Text>
                                    </div>
                                    <div>
                                        <Text style={styles.textRequire}>
                                            Todo los campos marcados con (*) son requeridos
                                        </Text>
                                    </div>
                                    <Row gutter={[12, 12]}>
                                        <Col xs={24}>
                                            <Input
                                                label="Nombre*"
                                                required
                                                value={nameForm}
                                                type="text"
                                                onChange={(element) => setNameForm(element)}
                                                disable={loading}
                                                errorLabel={missingData && _.isEmpty(nameForm) ? "Este campo es obligatorio." : ""}
                                                withError={missingData && _.isEmpty(nameForm)}
                                            />
                                        </Col>
                                        <Col xs={24} xl={12}>
                                            <Input
                                                label="Alias*"
                                                required
                                                value={aliasForm}
                                                type="text"
                                                onChange={(element) => setAliasForm(element)}
                                                disable={loading}
                                                errorLabel={missingData && _.isEmpty(aliasForm) ? "Este campo es obligatorio." : ""}
                                                withError={missingData && _.isEmpty(aliasForm)}
                                            />
                                        </Col>
                                        <Col xs={24} xl={12}>
                                            <Input
                                                label="CLABE / Tarjeta / Teléfono*"
                                                value={accountNumberForm}
                                                type="text"
                                                required
                                                maxLength={18}
                                                onChange={(element) => handleChangeAccNumber(element)}
                                                disable={loading}
                                                errorLabel={!isValidAccNumber || (missingData && _.isEmpty(accountNumberForm)) ? "Este campo es obligatorio." : ""}
                                                withError={!isValidAccNumber || (missingData && _.isEmpty(accountNumberForm))}
                                            />
                                        </Col>
                                        <Col xs={24} xl={12}>
                                            <SelectInput
                                                label="Entidad*"
                                                value={bankForm as string}
                                                options={banks.map(data => ({
                                                    label: data.bankName,
                                                    value: data.bankCode
                                                }))}
                                                onChange={handleChangeBank}
                                            />
                                        </Col>
                                        <Col xs={24} xl={12}>
                                            <SelectInput
                                                label="Tipo*"
                                                value={type}
                                                options={bankTypes.map(data => ({
                                                    value: data.value,
                                                    label: data.label
                                                }))}
                                                onChange={handleChangeTypeBank}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {/* Card de Datos opcionales */}
                            <Row style={{ marginTop: 10 }}>
                                <Col style={styles.divContainer} md={24} lg={22} xl={20} xxl={16}>
                                    <div style={{ marginBottom: 10 }}>
                                        <Text style={styles.textTitle}>
                                            Datos Opcionales
                                        </Text>
                                    </div>
                                    <Row gutter={[12, 12]} >
                                        <Col xs={24} xl={12}>
                                            <Input
                                                label="RFC"
                                                value={rfcForm}
                                                type="text"
                                                disable={loading}
                                                maxLength={13}
                                                onChange={(element) => setRfcForm(element)}
                                                errorLabel={!_.isEmpty(rfcForm) && validateRfc(rfcForm) ? "Por favor ingresa un RFC válido." : ""}
                                                withError={!_.isEmpty(rfcForm) && validateRfc(rfcForm)}
                                            />
                                        </Col>
                                        <Col xs={24} xl={12}>
                                            <Input
                                                label="Calle"
                                                value={streetForm}
                                                type="text"
                                                onChange={(element) => setStreetForm(element)}
                                                disable={loading}
                                            />
                                        </Col>
                                        <Col xs={24} xl={12}>
                                            <Input
                                                label="Número"
                                                value={streetNumberForm}
                                                type="text"
                                                onChange={(element) => setStreetNumberForm(element)}
                                                disable={loading}
                                            />
                                        </Col>
                                        <Col xs={24} xl={12}>
                                            <Input
                                                label="Código Postal"
                                                value={zipcodeForm}
                                                type="text"
                                                maxLength={5}
                                                onChange={(element) => {
                                                    const newValue = element.replace(/\D/g, '');
                                                    setZipcodeForm(newValue);
                                                }}
                                                disable={loading}
                                            />
                                        </Col>
                                        <Col xs={24} xl={12}>
                                            <SelectInput
                                                label="Estado"
                                                value={stateForm}
                                                options={states.map(data => ({
                                                    value: data.value,
                                                    label: data.value
                                                }))}
                                                onChange={(e) => {
                                                    setStateForm(e);
                                                    changeState(e);
                                                }}
                                            />
                                        </Col>
                                        <Col xs={24} xl={12}>
                                            {stateForm ? (
                                                <SelectInput
                                                    label="Delegación o municipio"
                                                    value={cityForm}
                                                    options={municipalitiesList.map((data) => ({
                                                        value: data.value,
                                                        label: data.value
                                                    }))}
                                                    onChange={(e) => {
                                                        setCityForm(e);
                                                    }}
                                                />
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )
            }
            <ModalNotification
                title={"Cuentas de Terceros"}
                message={message}
                alertType={notificationType}
                show={showNotification}
                onClose={() => shouldNavigate ? navigateNotification() : setShowNotification(false)}
            />
        </SubDefaultLayout >
    );
};

const styles = StyleSheet.create({
    divContainer: {
        backgroundColor: "white",
        marginTop: "1%",
        display: "flex",
        flexDirection: "column",
        borderRadius: 15,
        padding: 30,
        boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.10)"
    },
    contentView: {
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loading: {
        maxHeight: "none",
    },
    textTitle: {
        fontSize: 18,
        color: '#434343',
        fontWeight: 600
    },
    textRequire: {
        fontSize: 13,
        color: '#676767'
    }
});

export default AccountsThirdAdd;
