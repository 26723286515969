import { Col, Row, Typography } from 'antd';
import GenericModal from './GenericModal';
const Text = Typography;

interface ModalSelectProps {
    open: boolean;
    handleConfirm: Function;
    handleClose: Function;
    message: string;
};

const ModalConfirmation = (props: ModalSelectProps) => {
    const { open, handleClose, handleConfirm, message } = props;

    return (
        <GenericModal
            showActionButtons
            title="Estimado Usuario"
            show={open}
            onConfirm={() => handleConfirm()}
            onClose={() => handleClose()}
        >
            <Row style={{padding: 20}}>
                <Col span={24}>
                    <Text>{message}</Text>
                </Col>
            </Row>
        </GenericModal>
    );
};

export default ModalConfirmation;
