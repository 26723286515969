import { Button, Modal } from 'antd';
import { logout } from '../context/keycloak';
import { IAlertState, setCustomAlert, start } from '../store/slices/customAlert';
import { useDispatch, useSelector } from 'react-redux';

const AlertDialog = () => {
    const dispatch = useDispatch();
    const customAlert = useSelector((state: IAlertState) => state.customAlert);

    const handleClose = () => {
        dispatch(setCustomAlert(start)); // Recetamos el valor de la alerta
        logout(); // Logout
    };

    if (!customAlert) {
        return null; // Manejar el caso en que customAlert sea undefined
    }

    return (
        <div>
            <Button type="primary" onClick={() => setCustomAlert({ open: true, alert: { message: { title: 'Título del Modal', message: 'Contenido del Modal' } } })}>
                Abrir Modal
            </Button>
            <Modal
                title={customAlert.alert.message.title}
                visible={customAlert.open}
                onCancel={handleClose}
                footer={[
                    <Button key="aceptar" type="primary" onClick={handleClose}>
                        ACEPTAR
                    </Button>
                ]}
            >
                <p>{customAlert.alert.message.message}</p>
            </Modal>
        </div>
    );
};

export default AlertDialog;
