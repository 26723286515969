/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.24.612 on 2023-08-31 11:40:05.

import { FilterValue, TablePaginationConfig } from "antd/es/table/interface";

export interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
}

export interface AccountResponse {
    accountNumber: string;
    alias: string;
    name: string;
    bank?: Institution;
    accountType?: AccountType;
    enabled: boolean;
    balance: Balance;
}

export interface AccountThirdData {
    id: string;
    bankAccountNumber: string;
    name: string;
    bankName: Institution;
    accountType: AccountType;
    alias: string;
    enabled: boolean;
    rfc: string;
    street: string;
    streetNumber: string;
    zip: string;
    state: string;
    municipality: string;
    country: string;
}

export interface AccountThirdDataRequest {
    name: string;
    typeAccount?: AccountType;
    divisa: string;
    alias: string;
    bankAccountNumber: string;
    bankName?: Institution;
    enabled: boolean;
    rfc: string;
    street: string;
    streetNumber: string;
    zip: string;
    state: string;
    municipality: string;
    country: string;
}

export interface Balance {
    accountNumber: string;
    companyId: string;
    balance: number;
    virtualBalance: number;
    period: string;
    update: Date;
    transactions: string[];
    transaction: BankTransaction;
    incomings: number;
    outcomings: number;
    initBalance: number;
}

export interface BalanceDetails {
    accountNumber: string;
    period: string;
    date: Date;
    balance: number;
    virtualBalance: number;
    incomings: number;
    outcomings: number;
    initBalance: number;
}

export interface BankTransaction extends Serializable {
    transactionType: TransactionType;
    accountNumber: string;
    companyId: string;
    balance: number;
    iva: number;
    foreignBankId: string;
    foreignAccountNumber: string;
    trackingKey: string;
    reference: number;
    name: string;
    paymentConcept: string;
    accountType: number;
    paymentType: number;
    transactionDate: Date;
    referenceId: string;
    transactionStates: TransactionState[];
    finished: boolean;
    rejected: boolean;
    username: string;
    rfcOrCurp: string;
}

export interface CancelTransferRequest {
    idsTransfersToDelete: string[];
}

export interface CatalogBankResponse {
    bankName: string;
    code: string;
    bankCode: string;
}

export interface CompanyRequest {
    name: string;
    rfc: string;
    companyType: CompanyType;
    stpAlias: string;
    centerNumber: string;
    address: string;
}

export interface CompanyResponse {
    id: string;
    name: string;
    rfc: string;
    companyType: CompanyType;
    enabled: boolean;
    stpAlias: string;
    stpAccountNumber: string;
    address: string;
    contractNumber: string;
}

export interface ConciliationRequest {
    empresa: string;
    fechaOperacion: string;
    companyId: string;
}

export interface CreateAccountGroupRequest {
    name: string;
    description: string;
    accountsThird: string[];
}

export interface CreateAccountThirdRequest {
    name: string;
    typeAccount?: AccountType;
    divisa: string;
    alias: string;
    bankAccountNumber: string;
    bankName?: Institution;
    enabled: boolean;
    rfc: string;
    street: string;
    streetNumber: string;
    zip: string;
    state: string;
    municipality: string;
    country: string;
}

export interface CreateMultipleAccountThirdRequest {
    accountsThirds: AccountThirdDataRequest[];
}

export interface CreateScheadulerRequest {
    token: string;
    dispersions: SchedulerDispersionDataRequest[];
}

export interface CreateUserRequest {
    username: string;
    email: string;
    name: string;
    lastName: string;
    role: Role;
    accounts: string[];
}

export interface DeleteGroupsRequest {
    accountsGroup: string[];
}

export interface DisableMultipleAccountThirdRequest {
    accountThirdDataRequestList: AccountThirdDisableRequest[];
}

export interface DeleteAccountThirdRequest {
    thirdAccountsIds: string[];
}

export interface DisableTokenRequest {
    registrationCode: string;
    deviceUniqueId: string;
}

export interface DispersionData {
    accountNumber: string;
    foreignAccountNumber: string;
    foreignBankId: string;
    reference: number;
    name: string;
    paymentConcept: string;
    accountType: number;
    paymentType: number;
    rfcOrCurp?: string;
    balance: number;
    iva: number;
    date: Date;
    schedulerDate?: Date;
    token?: string;
    latitude: number;
    longitude: number;
}

export interface DispersionResponse {
    transactionResume: TransactionResume;
    trackingKey: string;
    reference: number;
    referenceId: string;
}

export interface ErrorDispersions { // Nuevo
    dispersionDataFailed: DispersionData;
    cause: Error;
}

export interface MultipleDispersionRequest { // Nuevo
    token: string;
    dispersions: DispersionData[];
}

export interface MultipleDispersionResponse { // Nuevo
    successDispersions: DispersionResponse[];
    dispersionsWithError: ErrorDispersions[];
}

export interface EstadoCuenta {
    accountNumber: string;
    period: string;
    company: string;
    companyRfc: string;
    balanceActual: BalanceDetails;
    saldoPromedio: number;
    daysOfPeriod: number;
    // personType: string;
    periodsMonths: string;
    balanceBefore: BalanceDetails;
    transactions: TransactionCompuest[];
    address: string;
    contractNumber: string;
}

export interface GenericResponse<T> {
    status: Status;
    message: string;
    error: Error;
    data: T;
}

export interface GetTransactionResponse {
    accountNumber: string;
    transactions: TransactionResume[];
}

export interface GetAllTransactionResponse {
    transactions: TransactionResume[];
}
export interface GroupResponse {
    id: string;
    description: string;
    name: string;
    createdAt: Date;
    companyUUID: string;
    thirdAccounts: AccountThirdData[];
}

export interface NotificationRequest {
    notificationIds: string[];
}

export interface NotificationResponse {
    notificationId: string;
    notificationType: NotificationType;
    title: string;
    message: string;
    read: boolean;
    createdAt: Date;
    additionalData: Object;
}

export interface SchedulerDispersionDataRequest {
    accountNumber: string;
    foreignAccountNumber: string;
    foreignBankId: string;
    reference: number;
    name: string;
    paymentConcept: string;
    accountType: number;
    paymentType: number;
    rfcOrCurp: string;
    balance: number;
    iva: number;
    scheduledDateTime: Date;
    latitude: number;
    longitude: number;
}

export interface SchedulerResponse {
    id: string;
    status: ScheduledDispersionStatus;
    dispersionData: DispersionData;
    errorDescription: string;
}

export interface TokenRequest {
    registrationCode: string;
    deviceUniqueId: string;
    userID: string;
}

export interface TokenResponse {
    userId: string;
    registrationCode: string;
    secret: string;
    tokenStatus: TokenStatus;
    uniqueId: string;
}

export interface TransactionCompuest {
    date: Date;
    sourceBank: string;
    sourceAccountNumber: string;
    sourceName: string;
    paymentConcept: string;
    receiverBank: string;
    receiverAccountNumber: string;
    receiverName: string;
    trackingKey: string;
    transactionType: TransactionType;
    incoming: number;
    outcoming: number;
    status: string;
    username: string;
    reference: string;
    balanceTransaction: number;
    iva: number;
    concatDescription: string;
}

export interface TransactionPeriodResponse {
    periods: Period[];
}

export interface TransactionResume {
    accountType: AccountType;
    date: Date;
    sourceBank: string;
    sourceAccountNumber: string;
    sourceName: string;
    paymentConcept: string;
    receiverBank: string;
    receiverAccountNumber: string;
    receiverName: string;
    trackingKey: string;
    transactionType: TransactionType;
    incoming: number;
    outcoming: number;
    iva: number;
    status: string;
    username: string;
    reference: string;
    description: string;
    rfcOrCurp: string;
    formatted: string;
}

export interface ComprobanteRequest {
    trackingKey: string;
    accountNumberReceiver: string;
    accountNumberSource: string;
}

export interface TransactionSimple {
    date: Date;
    transactionType: TransactionType;
    paymentConcept: string;
    trackingKey: string;
    accountNumber: string;
    foreignAccountNumber: string;
    foreignBankId: string;
    ingress: number;
    egress: number;
    accountType: AccountType;
    status: string;
    username: string;
    reference: string;
    rejected: boolean;
    name: string;
    rfc: string;
    description: string;
    iva: number;
}

export interface TransactionState {
    transactionStateTime: Date;
    state: State;
    code: number;
    desc: string;
    note: string;
}

export interface UpdateAccountGroupRequest {
    name: string;
    description: string;
    accountsThird: string[];
}

export interface UpdateAccountRequest {
    alias: string;
    enable: boolean;
}

export interface UpdateAccountThirdRequest {
    accountNumber?: string;
    name?: string,
    bankName?: Institution,
    accountType?: AccountType,
    alias: string;
    enabled: boolean;
    rfc: string;
    street: string;
    streetNumber: string;
    zip: string;
    state: string;
    municipality: string;
}

export interface UpdateCompanyRequest {
    address: string;
    enable: boolean;
}

export interface UpdateMultipleAccount {
    accounts: AccountData[];
}

export interface UpdateMultipleUsersRequest {
    users: UserData[];
}

export interface UpdateUserRequest {
    enabled: boolean;
    name: string;
    lastName: string;
    userRol: string;
    accounts: string[];
}

export interface UserData {
    userId: string;
    enabled: boolean;
}

export interface UserResponse {
    id: string;
    userType: Role;
    enabled: boolean;
    username: string;
    email: string;
    name: string;
    lastName: string;
    companyId?: string;
    isEmailVerified?: boolean;
    accounts: string[];
    rootUser?: boolean;
}

export interface ValidateTokenResponse {
    valid: boolean;
    secret: string;
}

export interface Serializable {
}

export interface AccountThirdDisableRequest {
    accountThirdId: string;
    enable: boolean;
}

export interface Error extends Throwable {
}

export interface Period {
    period: string;
}

export interface AccountData {
    accountId: string;
    alias: string;
    enable: boolean;
}

export interface Throwable extends Serializable {
    cause: Throwable;
    stackTrace: StackTraceElement[];
    message: string;
    suppressed: Throwable[];
    localizedMessage: string;
}

export interface StackTraceElement extends Serializable {
    classLoaderName: string;
    moduleName: string;
    moduleVersion: string;
    methodName: string;
    fileName: string;
    lineNumber: number;
    nativeMethod: boolean;
    className: string;
}

export enum AccountType {
    DEBIT = "DEBIT",
    CEL = "CEL",
    CLABE = "CLABE",
}

export enum CompanyType {
    PERSON = "PERSON",
    COMPANY = "COMPANY",
}

export enum Institution {
    BANXICO = "2001",
    BANCOMEXT = "37006",
    BANOBRAS = "37009",
    BANJERCITO = "37019",
    NAFIN = "37135",
    BANSEFI = "37166",
    HIPOTECARIA_FED = "37168",
    BANAMEX = "40002",
    BBVA_MEXICO = "40012",
    SANTANDER = "40014",
    HSBC = "40021",
    BAJIO = "40030",
    INBURSA = "40036",
    MIFEL = "40042",
    SCOTIABANK = "40044",
    BANREGIO = "40058",
    INVEX = "40059",
    BANSI = "40060",
    AFIRME = "40062",
    BANORTE = "40072",
    BANK_OF_AMERICA = "40106",
    MUFG = "40108",
    JP_MORGAN = "40110",
    BMONEX = "40112",
    VE_POR_MAS = "40113",
    CREDIT_SUISSE = "40126",
    AZTECA = "40127",
    AUTOFIN = "40128",
    BARCLAYS = "40129",
    COMPARTAMOS = "40130",
    MULTIVA_BANCO = "40132",
    ACTINVER = "40133",
    INTERCAM_BANCO = "40136",
    BANCOPPEL = "40137",
    ABC_CAPITAL = "40138",
    CONSUBANCO = "40140",
    VOLKSWAGEN = "40141",
    CIBANCO = "40143",
    BBASE = "40145",
    BANKAOOL = "40147",
    PAGATODO = "40148",
    INMOBILIARIO = "40150",
    DONDE = "40151",
    BANCREA = "40152",
    BANCO_FINTERRA = "40154",
    ICBC = "40155",
    SABADELL = "40156",
    SHINHAN = "40157",
    MIZUHO_BANK = "40158",
    BANCO_S3 = "40160",
    MONEXCB = "90600",
    GBM = "90601",
    MASARI = "90602",
    VALUE = "90605",
    VECTOR = "90608",
    MULTIVA_CBOLSA = "90613",
    FINAMEX = "90616",
    VALMEX = "90617",
    PROFUTURO = "90620",
    CB_INTERCAM = "90630",
    CI_BOLSA = "90631",
    FINCOMUN = "90634",
    AKALA = "90638",
    REFORMA = "90642",
    STP = "90646",
    EVERCORE = "90648",
    CREDICAPITAL = "90652",
    KUSPIT = "90653",
    UNAGRA = "90656",
    ASP_INTEGRA_OPC = "90659",
    LIBERTAD = "90670",
    CAJA_POP_MEXICA = "90677",
    CRISTOBAL_COLON = "90680",
    CAJA_TELEFONIST = "90683",
    TACTIV_CB = "90684",
    FONDO_FIRA = "90685",
    INVERCAP = "90686",
    FOMPED = "90689",
    INDEVAL = "90902",
    CoDi_Valida = "90903",
    BBVA_BANCOMER2 = "91812",
    SANTANDER2 = "91814",
    HSBC2 = "91821",
    AZTECA2 = "91927",
    BANORTE2 = "91872",
    ARCUS = "90706",
    BANAMEX2 = "91802",
}

export enum NotificationType {
    SCHEDULED_DISPERSION = "SCHEDULED_DISPERSION",
}

export enum OrderType {
    SENT = "SENT",
    RECEIVED = "RECEIVED",
}

export enum Role {
    ADMIN = "ADMIN",
    USER = "USER",
    UNDEFINED = "UNDEFINED",
}

export enum ScheduledDispersionStatus {
    SCHEDULED = "SCHEDULED",
    DONE = "DONE",
    REJECTED = "REJECTED",
    CANCELED = "CANCELED",
}

export enum Status {
    OK = "OK",
    CREATED = "CREATED",
    ERROR_ACCOUNT_TYPE = "ERROR_ACCOUNT_TYPE",
    ERROR_ACCOUNT_BANK = "ERROR_ACCOUNT_BANK",
    ERROR_ACCOUNT = "ERROR_ACCOUNT",
    ERROR_NAME = "ERROR_NAME",
    ERROR_ALIAS = "ERROR_ALIAS",
    ERROR_ACCOUNT_NUMBER = "ERROR_ACCOUNT_NUMBER",
    DUPLICATED = "DUPLICATED",
    DELETED = "DELETED",
    ERROR = "ERROR",
    UPDATED = "UPDATED",
    ERROR_FIELD_REQUIRED = "ERROR_FIELD_REQUIRED",
    USER_TOKEN_INVALID = "USER_TOKEN_INVALID",
    ACTIVE = "ACTIVE",
    BURNED = "BURNED",
}

export enum TokenStatus {
    CREATED = "CREATED",
    ACTIVE = "ACTIVE",
    BURNED = "BURNED",
}

export enum TransactionType {
    INCOMING = "INCOMING",
    OUTCOMING = "OUTCOMING",
}

export enum UserType {
    ADMINISTRATOR = "ADMINISTRATOR",
    USER = "USER",
}

export enum State {
    CREATED = "CREATED",
    REJECTED = "REJECTED",
    PROCESSING = "PROCESSING",
    PENDING = "PENDING",
    CANCELED = "CANCELED",
    DONE = "DONE",
    UNDEFINED = "UNDEFINED",
}

export interface AccountThirdObj {
    name: string;
    alias: string;
    bankAccountNumber: string;
    bankName: string;
    typeAccount: string;
    rfc: string;
    street: string;
    streetNumber: string;
    zip: string;
    state: string;
    municipality: string;
}

export interface TransferData {
    accNumberOrigin: string;
    accNumberDest: string;
    accTypeDest: string;
    nameDest: string;
    rfcDest: string;
    bankNameDest: string;
    conceptDest: string;
    referenceDest: string;
    amountDest: string;
    amountIva: string;
    date?: number;
    hour?: number;
}

export interface TransferObj {
    conceptDest: string;
    numRefDest: string | null;
    amountDest: string | null;
    ivaDest: string | null;
    radioOpt: number;
    dateTimeDest?: Date;
}

export interface MunicipalityData {
    name: string;
    value: string;
}

export interface AccountOrigin {
    accountNumber: string;
    accountName: string
}

export interface AccountDest {
    accountNumber: string;
    accountName: string;
    bankDataDest?: CatalogBankResponse;
    accType?: AccountType;
    radioOpt: number,
    rfc?: string
}