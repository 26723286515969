const estadoCiudadData = () => [
    { state: 'Aguascalientes', city: 'Aguascalientes' },
    { state: 'Aguascalientes', city: 'Asientos' },
    { state: 'Aguascalientes', city: 'Calvillo' },
    { state: 'Aguascalientes', city: 'Cosío' },
    { state: 'Aguascalientes', city: 'El Llano' },
    { state: 'Aguascalientes', city: 'Jesús María' },
    { state: 'Aguascalientes', city: 'Pabellón de Arteaga' },
    { state: 'Aguascalientes', city: 'Rincón de Romos' },
    { state: 'Aguascalientes', city: 'San Francisco de los Romo' },
    { state: 'Aguascalientes', city: 'San José de Gracia' },
    { state: 'Aguascalientes', city: 'Tepezalá' },
    { state: 'Baja California', city: 'Ensenada' },
    { state: 'Baja California', city: 'Mexicali' },
    { state: 'Baja California', city: 'Playas de Rosarito' },
    { state: 'Baja California', city: 'Tecate' },
    { state: 'Baja California', city: 'Tijuana' },
    { state: 'Baja California Sur', city: 'Comondú' },
    { state: 'Baja California Sur', city: 'La Paz' },
    { state: 'Baja California Sur', city: 'Loreto' },
    { state: 'Baja California Sur', city: 'Los Cabos' },
    { state: 'Baja California Sur', city: 'Mulegé' },
    { state: 'Campeche', city: 'Calakmul' },
    { state: 'Campeche', city: 'Calkiní' },
    { state: 'Campeche', city: 'Campeche' },
    { state: 'Campeche', city: 'Candelaria' },
    { state: 'Campeche', city: 'Carmen' },
    { state: 'Campeche', city: 'Champotón' },
    { state: 'Campeche', city: 'Escárcega' },
    { state: 'Campeche', city: 'Hecelchakán' },
    { state: 'Campeche', city: 'Hopelchén' },
    { state: 'Campeche', city: 'Palizada' },
    { state: 'Campeche', city: 'Tenabo' },
    { state: 'Chiapas', city: 'Acacoyagua' },
    { state: 'Chiapas', city: 'Acala' },
    { state: 'Chiapas', city: 'Acapetahua' },
    { state: 'Chiapas', city: 'Aldama' },
    { state: 'Chiapas', city: 'Altamirano' },
    { state: 'Chiapas', city: 'Amatán' },
    { state: 'Chiapas', city: 'Amatenango de la Frontera' },
    { state: 'Chiapas', city: 'Amatenango del Valle' },
    { state: 'Chiapas', city: 'Angel Albino Corzo' },
    { state: 'Chiapas', city: 'Arriaga' },
    { state: 'Chiapas', city: 'Bejucal de Ocampo' },
    { state: 'Chiapas', city: 'Bella Vista' },
    { state: 'Chiapas', city: 'Benemérito de las Américas' },
    { state: 'Chiapas', city: 'Berriozábal' },
    { state: 'Chiapas', city: 'Bochil' },
    { state: 'Chiapas', city: 'Cacahoatán' },
    { state: 'Chiapas', city: 'Capitán Luis Ángel Vidal' },
    { state: 'Chiapas', city: 'Catazajá' },
    { state: 'Chiapas', city: 'Chalchihuitán' },
    { state: 'Chiapas', city: 'Chamula' },
    { state: 'Chiapas', city: 'Chanal' },
    { state: 'Chiapas', city: 'Chapultenango' },
    { state: 'Chiapas', city: 'Chenalhó' },
    { state: 'Chiapas', city: 'Chiapa de Corzo' },
    { state: 'Chiapas', city: 'Chiapilla' },
    { state: 'Chiapas', city: 'Chicoasén' },
    { state: 'Chiapas', city: 'Chicomuselo' },
    { state: 'Chiapas', city: 'Chilón' },
    { state: 'Chiapas', city: 'Cintalapa' },
    { state: 'Chiapas', city: 'Coapilla' },
    { state: 'Chiapas', city: 'Comitán de Domínguez' },
    { state: 'Chiapas', city: 'Copainalá' },
    { state: 'Chiapas', city: 'El Bosque' },
    { state: 'Chiapas', city: 'El Parral' },
    { state: 'Chiapas', city: 'El Porvenir' },
    { state: 'Chiapas', city: 'Emiliano Zapata' },
    { state: 'Chiapas', city: 'Escuintla' },
    { state: 'Chiapas', city: 'Francisco León' },
    { state: 'Chiapas', city: 'Frontera Comalapa' },
    { state: 'Chiapas', city: 'Frontera Hidalgo' },
    { state: 'Chiapas', city: 'Huehuetán' },
    { state: 'Chiapas', city: 'Huitiupán' },
    { state: 'Chiapas', city: 'Huixtán' },
    { state: 'Chiapas', city: 'Huixtla' },
    { state: 'Chiapas', city: 'Ixhuatán' },
    { state: 'Chiapas', city: 'Ixtacomitán' },
    { state: 'Chiapas', city: 'Ixtapa' },
    { state: 'Chiapas', city: 'Ixtapangajoya' },
    { state: 'Chiapas', city: 'Jiquipilas' },
    { state: 'Chiapas', city: 'Jitotol' },
    { state: 'Chiapas', city: 'Juárez' },
    { state: 'Chiapas', city: 'La Concordia' },
    { state: 'Chiapas', city: 'La Grandeza' },
    { state: 'Chiapas', city: 'La Independencia' },
    { state: 'Chiapas', city: 'La Libertad' },
    { state: 'Chiapas', city: 'La Trinitaria' },
    { state: 'Chiapas', city: 'Larráinzar' },
    { state: 'Chiapas', city: 'Las Margaritas' },
    { state: 'Chiapas', city: 'Las Rosas' },
    { state: 'Chiapas', city: 'Mapastepec' },
    { state: 'Chiapas', city: 'Maravilla Tenejapa' },
    { state: 'Chiapas', city: 'Marqués de Comillas' },
    { state: 'Chiapas', city: 'Mazapa de Madero' },
    { state: 'Chiapas', city: 'Mazatán' },
    { state: 'Chiapas', city: 'Metapa' },
    { state: 'Chiapas', city: 'Mezcalapa' },
    { state: 'Chiapas', city: 'Mitontic' },
    { state: 'Chiapas', city: 'Montecristo de Guerrero' },
    { state: 'Chiapas', city: 'Motozintla' },
    { state: 'Chiapas', city: 'Nicolás Ruíz' },
    { state: 'Chiapas', city: 'Ocosingo' },
    { state: 'Chiapas', city: 'Ocotepec' },
    { state: 'Chiapas', city: 'Ocozocoautla de Espinosa' },
    { state: 'Chiapas', city: 'Ostuacán' },
    { state: 'Chiapas', city: 'Osumacinta' },
    { state: 'Chiapas', city: 'Oxchuc' },
    { state: 'Chiapas', city: 'Palenque' },
    { state: 'Chiapas', city: 'Pantelhó' },
    { state: 'Chiapas', city: 'Pantepec' },
    { state: 'Chiapas', city: 'Pichucalco' },
    { state: 'Chiapas', city: 'Pijijiapan' },
    { state: 'Chiapas', city: 'Pueblo Nuevo Solistahuacán' },
    { state: 'Chiapas', city: 'Rayón' },
    { state: 'Chiapas', city: 'Reforma' },
    { state: 'Chiapas', city: 'Rincón Chamula San Pedro' },
    { state: 'Chiapas', city: 'Sabanilla' },
    { state: 'Chiapas', city: 'Salto de Agua' },
    { state: 'Chiapas', city: 'San Andrés Duraznal' },
    { state: 'Chiapas', city: 'San Cristóbal de las Casas' },
    { state: 'Chiapas', city: 'San Fernando' },
    { state: 'Chiapas', city: 'San Juan Cancuc' },
    { state: 'Chiapas', city: 'San Lucas' },
    { state: 'Chiapas', city: 'Santiago el Pinar' },
    { state: 'Chiapas', city: 'Siltepec' },
    { state: 'Chiapas', city: 'Simojovel' },
    { state: 'Chiapas', city: 'Sitalá' },
    { state: 'Chiapas', city: 'Socoltenango' },
    { state: 'Chiapas', city: 'Solosuchiapa' },
    { state: 'Chiapas', city: 'Soyaló' },
    { state: 'Chiapas', city: 'Suchiapa' },
    { state: 'Chiapas', city: 'Suchiate' },
    { state: 'Chiapas', city: 'Sunuapa' },
    { state: 'Chiapas', city: 'Tapachula' },
    { state: 'Chiapas', city: 'Tapalapa' },
    { state: 'Chiapas', city: 'Tapilula' },
    { state: 'Chiapas', city: 'Tecpatán' },
    { state: 'Chiapas', city: 'Tenejapa' },
    { state: 'Chiapas', city: 'Teopisca' },
    { state: 'Chiapas', city: 'Tila' },
    { state: 'Chiapas', city: 'Tonalá' },
    { state: 'Chiapas', city: 'Totolapa' },
    { state: 'Chiapas', city: 'Tumbalá' },
    { state: 'Chiapas', city: 'Tuxtla Chico' },
    { state: 'Chiapas', city: 'Tuxtla Gutiérrez' },
    { state: 'Chiapas', city: 'Tuzantán' },
    { state: 'Chiapas', city: 'Tzimol' },
    { state: 'Chiapas', city: 'Unión Juárez' },
    { state: 'Chiapas', city: 'Venustiano Carranza' },
    { state: 'Chiapas', city: 'Villa Comaltitlán' },
    { state: 'Chiapas', city: 'Villa Corzo' },
    { state: 'Chiapas', city: 'Villaflores' },
    { state: 'Chiapas', city: 'Yajalón' },
    { state: 'Chiapas', city: 'Zinacantán' },
    { state: 'Chihuahua', city: 'Ahumada' },
    { state: 'Chihuahua', city: 'Aldama' },
    { state: 'Chihuahua', city: 'Allende' },
    { state: 'Chihuahua', city: 'Aquiles Serdán' },
    { state: 'Chihuahua', city: 'Ascensión' },
    { state: 'Chihuahua', city: 'Bachíniva' },
    { state: 'Chihuahua', city: 'Balleza' },
    { state: 'Chihuahua', city: 'Batopilas de Manuel Gómez Morín' },
    { state: 'Chihuahua', city: 'Bocoyna' },
    { state: 'Chihuahua', city: 'Buenaventura' },
    { state: 'Chihuahua', city: 'Camargo' },
    { state: 'Chihuahua', city: 'Carichí' },
    { state: 'Chihuahua', city: 'Casas Grandes' },
    { state: 'Chihuahua', city: 'Chihuahua' },
    { state: 'Chihuahua', city: 'Chínipas' },
    { state: 'Chihuahua', city: 'Coronado' },
    { state: 'Chihuahua', city: 'Coyame del Sotol' },
    { state: 'Chihuahua', city: 'Cuauhtémoc' },
    { state: 'Chihuahua', city: 'Cusihuiriachi' },
    { state: 'Chihuahua', city: 'Delicias' },
    { state: 'Chihuahua', city: 'Dr. Belisario Domínguez' },
    { state: 'Chihuahua', city: 'El Tule' },
    { state: 'Chihuahua', city: 'Galeana' },
    { state: 'Chihuahua', city: 'Gómez Farías' },
    { state: 'Chihuahua', city: 'Gran Morelos' },
    { state: 'Chihuahua', city: 'Guachochi' },
    { state: 'Chihuahua', city: 'Guadalupe' },
    { state: 'Chihuahua', city: 'Guadalupe y Calvo' },
    { state: 'Chihuahua', city: 'Guazapares' },
    { state: 'Chihuahua', city: 'Guerrero' },
    { state: 'Chihuahua', city: 'Hidalgo del Parral' },
    { state: 'Chihuahua', city: 'Huejotitán' },
    { state: 'Chihuahua', city: 'Ignacio Zaragoza' },
    { state: 'Chihuahua', city: 'Janos' },
    { state: 'Chihuahua', city: 'Jiménez' },
    { state: 'Chihuahua', city: 'Juárez' },
    { state: 'Chihuahua', city: 'Julimes' },
    { state: 'Chihuahua', city: 'La Cruz' },
    { state: 'Chihuahua', city: 'López' },
    { state: 'Chihuahua', city: 'Madera' },
    { state: 'Chihuahua', city: 'Maguarichi' },
    { state: 'Chihuahua', city: 'Manuel Benavides' },
    { state: 'Chihuahua', city: 'Matachí' },
    { state: 'Chihuahua', city: 'Matamoros' },
    { state: 'Chihuahua', city: 'Meoqui' },
    { state: 'Chihuahua', city: 'Morelos' },
    { state: 'Chihuahua', city: 'Moris' },
    { state: 'Chihuahua', city: 'Namiquipa' },
    { state: 'Chihuahua', city: 'Nonoava' },
    { state: 'Chihuahua', city: 'Nuevo Casas Grandes' },
    { state: 'Chihuahua', city: 'Ocampo' },
    { state: 'Chihuahua', city: 'Ojinaga' },
    { state: 'Chihuahua', city: 'Praxedis G. Guerrero' },
    { state: 'Chihuahua', city: 'Riva Palacio' },
    { state: 'Chihuahua', city: 'Rosales' },
    { state: 'Chihuahua', city: 'Rosario' },
    { state: 'Chihuahua', city: 'San Francisco de Borja' },
    { state: 'Chihuahua', city: 'San Francisco de Conchos' },
    { state: 'Chihuahua', city: 'San Francisco del Oro' },
    { state: 'Chihuahua', city: 'Santa Bárbara' },
    { state: 'Chihuahua', city: 'Santa Isabel' },
    { state: 'Chihuahua', city: 'Satevó' },
    { state: 'Chihuahua', city: 'Saucillo' },
    { state: 'Chihuahua', city: 'Temósachic' },
    { state: 'Chihuahua', city: 'Urique' },
    { state: 'Chihuahua', city: 'Uruachi' },
    { state: 'Chihuahua', city: 'Valle de Zaragoza' },
    { state: 'Ciudad De México', city: 'Álvaro Obregón' },
    { state: 'Ciudad De México', city: 'Azcapotzalco' },
    { state: 'Ciudad De México', city: 'Benito Juárez' },
    { state: 'Ciudad De México', city: 'Coyoacán' },
    { state: 'Ciudad De México', city: 'Cuajimalpa de Morelos' },
    { state: 'Ciudad De México', city: 'Cuauhtémoc' },
    { state: 'Ciudad De México', city: 'Gustavo A. Madero' },
    { state: 'Ciudad De México', city: 'Iztacalco' },
    { state: 'Ciudad De México', city: 'Iztapalapa' },
    { state: 'Ciudad De México', city: 'La Magdalena Contreras' },
    { state: 'Ciudad De México', city: 'Miguel Hidalgo' },
    { state: 'Ciudad De México', city: 'Milpa Alta' },
    { state: 'Ciudad De México', city: 'Tláhuac' },
    { state: 'Ciudad De México', city: 'Tlalpan' },
    { state: 'Ciudad De México', city: 'Venustiano Carranza' },
    { state: 'Ciudad De México', city: 'Xochimilco' },
    { state: 'Coahuila', city: 'Abasolo' },
    { state: 'Coahuila', city: 'Acuña' },
    { state: 'Coahuila', city: 'Allende' },
    { state: 'Coahuila', city: 'Arteaga' },
    { state: 'Coahuila', city: 'Candela' },
    { state: 'Coahuila', city: 'Castaños' },
    { state: 'Coahuila', city: 'Cuatro Ciénegas' },
    { state: 'Coahuila', city: 'Escobedo' },
    { state: 'Coahuila', city: 'Francisco I. Madero' },
    { state: 'Coahuila', city: 'Frontera' },
    { state: 'Coahuila', city: 'General Cepeda' },
    { state: 'Coahuila', city: 'Guerrero' },
    { state: 'Coahuila', city: 'Hidalgo' },
    { state: 'Coahuila', city: 'Jiménez' },
    { state: 'Coahuila', city: 'Juárez' },
    { state: 'Coahuila', city: 'Lamadrid' },
    { state: 'Coahuila', city: 'Matamoros' },
    { state: 'Coahuila', city: 'Monclova' },
    { state: 'Coahuila', city: 'Morelos' },
    { state: 'Coahuila', city: 'Múzquiz' },
    { state: 'Coahuila', city: 'Nadadores' },
    { state: 'Coahuila', city: 'Nava' },
    { state: 'Coahuila', city: 'Ocampo' },
    { state: 'Coahuila', city: 'Parras' },
    { state: 'Coahuila', city: 'Piedras Negras' },
    { state: 'Coahuila', city: 'Progreso' },
    { state: 'Coahuila', city: 'Ramos Arizpe' },
    { state: 'Coahuila', city: 'Sabinas' },
    { state: 'Coahuila', city: 'Sacramento' },
    { state: 'Coahuila', city: 'Saltillo' },
    { state: 'Coahuila', city: 'San Buenaventura' },
    { state: 'Coahuila', city: 'San Juan de Sabinas' },
    { state: 'Coahuila', city: 'San Pedro' },
    { state: 'Coahuila', city: 'Sierra Mojada' },
    { state: 'Coahuila', city: 'Torreón' },
    { state: 'Coahuila', city: 'Viesca' },
    { state: 'Coahuila', city: 'Villa Unión' },
    { state: 'Coahuila', city: 'Zaragoza' },
    { state: 'Colima', city: 'Armería' },
    { state: 'Colima', city: 'Colima' },
    { state: 'Colima', city: 'Comala' },
    { state: 'Colima', city: 'Coquimatlán' },
    { state: 'Colima', city: 'Cuauhtémoc' },
    { state: 'Colima', city: 'Ixtlahuacán' },
    { state: 'Colima', city: 'Manzanillo' },
    { state: 'Colima', city: 'Minatitlán' },
    { state: 'Colima', city: 'Tecomán' },
    { state: 'Colima', city: 'Villa de Álvarez' },
    { state: 'Durango', city: 'Canatlán' },
    { state: 'Durango', city: 'Canelas' },
    { state: 'Durango', city: 'Coneto de Comonfort' },
    { state: 'Durango', city: 'Cuencamé' },
    { state: 'Durango', city: 'Durango' },
    { state: 'Durango', city: 'El Oro' },
    { state: 'Durango', city: 'General Simón Bolívar' },
    { state: 'Durango', city: 'Gómez Palacio' },
    { state: 'Durango', city: 'Guadalupe Victoria' },
    { state: 'Durango', city: 'Guanaceví' },
    { state: 'Durango', city: 'Hidalgo' },
    { state: 'Durango', city: 'Indé' },
    { state: 'Durango', city: 'Lerdo' },
    { state: 'Durango', city: 'Mapimí' },
    { state: 'Durango', city: 'Mezquital' },
    { state: 'Durango', city: 'Nazas' },
    { state: 'Durango', city: 'Nombre de Dios' },
    { state: 'Durango', city: 'Nuevo Ideal' },
    { state: 'Durango', city: 'Ocampo' },
    { state: 'Durango', city: 'Otáez' },
    { state: 'Durango', city: 'Pánuco de Coronado' },
    { state: 'Durango', city: 'Peñón Blanco' },
    { state: 'Durango', city: 'Poanas' },
    { state: 'Durango', city: 'Pueblo Nuevo' },
    { state: 'Durango', city: 'Rodeo' },
    { state: 'Durango', city: 'San Bernardo' },
    { state: 'Durango', city: 'San Dimas' },
    { state: 'Durango', city: 'San Juan de Guadalupe' },
    { state: 'Durango', city: 'San Juan del Río' },
    { state: 'Durango', city: 'San Luis del Cordero' },
    { state: 'Durango', city: 'San Pedro del Gallo' },
    { state: 'Durango', city: 'Santa Clara' },
    { state: 'Durango', city: 'Santiago Papasquiaro' },
    { state: 'Durango', city: 'Súchil' },
    { state: 'Durango', city: 'Tamazula' },
    { state: 'Durango', city: 'Tepehuanes' },
    { state: 'Durango', city: 'Tlahualilo' },
    { state: 'Durango', city: 'Topia' },
    { state: 'Durango', city: 'Vicente Guerrero' },
    { state: 'Guanajuato', city: 'Abasolo' },
    { state: 'Guanajuato', city: 'Acámbaro' },
    { state: 'Guanajuato', city: 'Apaseo el Alto' },
    { state: 'Guanajuato', city: 'Apaseo el Grande' },
    { state: 'Guanajuato', city: 'Atarjea' },
    { state: 'Guanajuato', city: 'Celaya' },
    { state: 'Guanajuato', city: 'Comonfort' },
    { state: 'Guanajuato', city: 'Coroneo' },
    { state: 'Guanajuato', city: 'Cortazar' },
    { state: 'Guanajuato', city: 'Cuerámaro' },
    { state: 'Guanajuato', city: 'Doctor Mora' },
    { state: 'Guanajuato', city: 'Dolores Hidalgo Cuna de la Independencia Nacional' },
    { state: 'Guanajuato', city: 'Guanajuato' },
    { state: 'Guanajuato', city: 'Huanímaro' },
    { state: 'Guanajuato', city: 'Irapuato' },
    { state: 'Guanajuato', city: 'Jaral del Progreso' },
    { state: 'Guanajuato', city: 'Jerécuaro' },
    { state: 'Guanajuato', city: 'León' },
    { state: 'Guanajuato', city: 'Manuel Doblado' },
    { state: 'Guanajuato', city: 'Moroleón' },
    { state: 'Guanajuato', city: 'Ocampo' },
    { state: 'Guanajuato', city: 'Pénjamo' },
    { state: 'Guanajuato', city: 'Pueblo Nuevo' },
    { state: 'Guanajuato', city: 'Purísima del Rincón' },
    { state: 'Guanajuato', city: 'Romita' },
    { state: 'Guanajuato', city: 'Salamanca' },
    { state: 'Guanajuato', city: 'Salvatierra' },
    { state: 'Guanajuato', city: 'San Diego de la Unión' },
    { state: 'Guanajuato', city: 'San Felipe' },
    { state: 'Guanajuato', city: 'San Francisco del Rincón' },
    { state: 'Guanajuato', city: 'San José Iturbide' },
    { state: 'Guanajuato', city: 'San Luis de la Paz' },
    { state: 'Guanajuato', city: 'San Miguel de Allende' },
    { state: 'Guanajuato', city: 'Santa Catarina' },
    { state: 'Guanajuato', city: 'Santa Cruz de Juventino Rosas' },
    { state: 'Guanajuato', city: 'Santiago Maravatío' },
    { state: 'Guanajuato', city: 'Silao de la Victoria' },
    { state: 'Guanajuato', city: 'Tarandacuao' },
    { state: 'Guanajuato', city: 'Tarimoro' },
    { state: 'Guanajuato', city: 'Tierra Blanca' },
    { state: 'Guanajuato', city: 'Uriangato' },
    { state: 'Guanajuato', city: 'Valle de Santiago' },
    { state: 'Guanajuato', city: 'Victoria' },
    { state: 'Guanajuato', city: 'Villagrán' },
    { state: 'Guanajuato', city: 'Xichú' },
    { state: 'Guanajuato', city: 'Yuriria' },
    { state: 'Guerrero', city: 'Acapulco de Juárez' },
    { state: 'Guerrero', city: 'Acatepec' },
    { state: 'Guerrero', city: 'Ahuacuotzingo' },
    { state: 'Guerrero', city: 'Ajuchitlán del Progreso' },
    { state: 'Guerrero', city: 'Alcozauca de Guerrero' },
    { state: 'Guerrero', city: 'Alpoyeca' },
    { state: 'Guerrero', city: 'Apaxtla' },
    { state: 'Guerrero', city: 'Arcelia' },
    { state: 'Guerrero', city: 'Atenango del Río' },
    { state: 'Guerrero', city: 'Atlamajalcingo del Monte' },
    { state: 'Guerrero', city: 'Atlixtac' },
    { state: 'Guerrero', city: 'Atoyac de Álvarez' },
    { state: 'Guerrero', city: 'Ayutla de los Libres' },
    { state: 'Guerrero', city: 'Azoyú' },
    { state: 'Guerrero', city: 'Benito Juárez' },
    { state: 'Guerrero', city: 'Buenavista de Cuéllar' },
    { state: 'Guerrero', city: 'Chilapa de Álvarez' },
    { state: 'Guerrero', city: 'Chilpancingo de los Bravo' },
    { state: 'Guerrero', city: 'Coahuayutla de José María Izazaga' },
    { state: 'Guerrero', city: 'Cochoapa el Grande' },
    { state: 'Guerrero', city: 'Cocula' },
    { state: 'Guerrero', city: 'Copala' },
    { state: 'Guerrero', city: 'Copalillo' },
    { state: 'Guerrero', city: 'Copanatoyac' },
    { state: 'Guerrero', city: 'Coyuca de Benítez' },
    { state: 'Guerrero', city: 'Coyuca de Catalán' },
    { state: 'Guerrero', city: 'Cuajinicuilapa' },
    { state: 'Guerrero', city: 'Cualác' },
    { state: 'Guerrero', city: 'Cuautepec' },
    { state: 'Guerrero', city: 'Cuetzala del Progreso' },
    { state: 'Guerrero', city: 'Cutzamala de Pinzón' },
    { state: 'Guerrero', city: 'Eduardo Neri' },
    { state: 'Guerrero', city: 'Florencio Villarreal' },
    { state: 'Guerrero', city: 'General Canuto A. Neri' },
    { state: 'Guerrero', city: 'General Heliodoro Castillo' },
    { state: 'Guerrero', city: 'Huamuxtitlán' },
    { state: 'Guerrero', city: 'Huitzuco de los Figueroa' },
    { state: 'Guerrero', city: 'Iguala de la Independencia' },
    { state: 'Guerrero', city: 'Igualapa' },
    { state: 'Guerrero', city: 'Iliatenco' },
    { state: 'Guerrero', city: 'Ixcateopan de Cuauhtémoc' },
    { state: 'Guerrero', city: 'José Joaquín de Herrera' },
    { state: 'Guerrero', city: 'Juan R. Escudero' },
    { state: 'Guerrero', city: 'Juchitán' },
    { state: 'Guerrero', city: 'La Unión de Isidoro Montes de Oca' },
    { state: 'Guerrero', city: 'Leonardo Bravo' },
    { state: 'Guerrero', city: 'Malinaltepec' },
    { state: 'Guerrero', city: 'Marquelia' },
    { state: 'Guerrero', city: 'Mártir de Cuilapan' },
    { state: 'Guerrero', city: 'Metlatónoc' },
    { state: 'Guerrero', city: 'Mochitlán' },
    { state: 'Guerrero', city: 'Olinalá' },
    { state: 'Guerrero', city: 'Ometepec' },
    { state: 'Guerrero', city: 'Pedro Ascencio Alquisiras' },
    { state: 'Guerrero', city: 'Petatlán' },
    { state: 'Guerrero', city: 'Pilcaya' },
    { state: 'Guerrero', city: 'Pungarabato' },
    { state: 'Guerrero', city: 'Quechultenango' },
    { state: 'Guerrero', city: 'San Luis Acatlán' },
    { state: 'Guerrero', city: 'San Marcos' },
    { state: 'Guerrero', city: 'San Miguel Totolapan' },
    { state: 'Guerrero', city: 'Taxco de Alarcón' },
    { state: 'Guerrero', city: 'Tecoanapa' },
    { state: 'Guerrero', city: 'Técpan de Galeana' },
    { state: 'Guerrero', city: 'Teloloapan' },
    { state: 'Guerrero', city: 'Tepecoacuilco de Trujano' },
    { state: 'Guerrero', city: 'Tetipac' },
    { state: 'Guerrero', city: 'Tixtla de Guerrero' },
    { state: 'Guerrero', city: 'Tlacoachistlahuaca' },
    { state: 'Guerrero', city: 'Tlacoapa' },
    { state: 'Guerrero', city: 'Tlalchapa' },
    { state: 'Guerrero', city: 'Tlalixtaquilla de Maldonado' },
    { state: 'Guerrero', city: 'Tlapa de Comonfort' },
    { state: 'Guerrero', city: 'Tlapehuala' },
    { state: 'Guerrero', city: 'Xalpatláhuac' },
    { state: 'Guerrero', city: 'Xochihuehuetlán' },
    { state: 'Guerrero', city: 'Xochistlahuaca' },
    { state: 'Guerrero', city: 'Zapotitlán Tablas' },
    { state: 'Guerrero', city: 'Zihuatanejo de Azueta' },
    { state: 'Guerrero', city: 'Zirándaro' },
    { state: 'Guerrero', city: 'Zitlala' },
    { state: 'Hidalgo', city: 'Acatlán' },
    { state: 'Hidalgo', city: 'Acaxochitlán' },
    { state: 'Hidalgo', city: 'Actopan' },
    { state: 'Hidalgo', city: 'Agua Blanca de Iturbide' },
    { state: 'Hidalgo', city: 'Ajacuba' },
    { state: 'Hidalgo', city: 'Alfajayucan' },
    { state: 'Hidalgo', city: 'Almoloya' },
    { state: 'Hidalgo', city: 'Apan' },
    { state: 'Hidalgo', city: 'Atitalaquia' },
    { state: 'Hidalgo', city: 'Atlapexco' },
    { state: 'Hidalgo', city: 'Atotonilco de Tula' },
    { state: 'Hidalgo', city: 'Atotonilco el Grande' },
    { state: 'Hidalgo', city: 'Calnali' },
    { state: 'Hidalgo', city: 'Cardonal' },
    { state: 'Hidalgo', city: 'Chapantongo' },
    { state: 'Hidalgo', city: 'Chapulhuacán' },
    { state: 'Hidalgo', city: 'Chilcuautla' },
    { state: 'Hidalgo', city: 'Cuautepec de Hinojosa' },
    { state: 'Hidalgo', city: 'El Arenal' },
    { state: 'Hidalgo', city: 'Eloxochitlán' },
    { state: 'Hidalgo', city: 'Emiliano Zapata' },
    { state: 'Hidalgo', city: 'Epazoyucan' },
    { state: 'Hidalgo', city: 'Francisco I. Madero' },
    { state: 'Hidalgo', city: 'Huasca de Ocampo' },
    { state: 'Hidalgo', city: 'Huautla' },
    { state: 'Hidalgo', city: 'Huazalingo' },
    { state: 'Hidalgo', city: 'Huehuetla' },
    { state: 'Hidalgo', city: 'Huejutla de Reyes' },
    { state: 'Hidalgo', city: 'Huichapan' },
    { state: 'Hidalgo', city: 'Ixmiquilpan' },
    { state: 'Hidalgo', city: 'Jacala de Ledezma' },
    { state: 'Hidalgo', city: 'Jaltocán' },
    { state: 'Hidalgo', city: 'Juárez Hidalgo' },
    { state: 'Hidalgo', city: 'La Misión' },
    { state: 'Hidalgo', city: 'Lolotla' },
    { state: 'Hidalgo', city: 'Metepec' },
    { state: 'Hidalgo', city: 'Metztitlán' },
    { state: 'Hidalgo', city: 'Mineral de la Reforma' },
    { state: 'Hidalgo', city: 'Mineral del Chico' },
    { state: 'Hidalgo', city: 'Mineral del Monte' },
    { state: 'Hidalgo', city: 'Mixquiahuala de Juárez' },
    { state: 'Hidalgo', city: 'Molango de Escamilla' },
    { state: 'Hidalgo', city: 'Nicolás Flores' },
    { state: 'Hidalgo', city: 'Nopala de Villagrán' },
    { state: 'Hidalgo', city: 'Omitlán de Juárez' },
    { state: 'Hidalgo', city: 'Pachuca de Soto' },
    { state: 'Hidalgo', city: 'Pacula' },
    { state: 'Hidalgo', city: 'Pisaflores' },
    { state: 'Hidalgo', city: 'Progreso de Obregón' },
    { state: 'Hidalgo', city: 'San Agustín Metzquititlán' },
    { state: 'Hidalgo', city: 'San Agustín Tlaxiaca' },
    { state: 'Hidalgo', city: 'San Bartolo Tutotepec' },
    { state: 'Hidalgo', city: 'San Felipe Orizatlán' },
    { state: 'Hidalgo', city: 'San Salvador' },
    { state: 'Hidalgo', city: 'Santiago de Anaya' },
    { state: 'Hidalgo', city: 'Santiago Tulantepec de Lugo Guerrero' },
    { state: 'Hidalgo', city: 'Singuilucan' },
    { state: 'Hidalgo', city: 'Tasquillo' },
    { state: 'Hidalgo', city: 'Tecozautla' },
    { state: 'Hidalgo', city: 'Tenango de Doria' },
    { state: 'Hidalgo', city: 'Tepeapulco' },
    { state: 'Hidalgo', city: 'Tepehuacán de Guerrero' },
    { state: 'Hidalgo', city: 'Tepeji del Río de Ocampo' },
    { state: 'Hidalgo', city: 'Tepetitlán' },
    { state: 'Hidalgo', city: 'Tetepango' },
    { state: 'Hidalgo', city: 'Tezontepec de Aldama' },
    { state: 'Hidalgo', city: 'Tianguistengo' },
    { state: 'Hidalgo', city: 'Tizayuca' },
    { state: 'Hidalgo', city: 'Tlahuelilpan' },
    { state: 'Hidalgo', city: 'Tlahuiltepa' },
    { state: 'Hidalgo', city: 'Tlanalapa' },
    { state: 'Hidalgo', city: 'Tlanchinol' },
    { state: 'Hidalgo', city: 'Tlaxcoapan' },
    { state: 'Hidalgo', city: 'Tolcayuca' },
    { state: 'Hidalgo', city: 'Tula de Allende' },
    { state: 'Hidalgo', city: 'Tulancingo de Bravo' },
    { state: 'Hidalgo', city: 'Villa de Tezontepec' },
    { state: 'Hidalgo', city: 'Xochiatipan' },
    { state: 'Hidalgo', city: 'Xochicoatlán' },
    { state: 'Hidalgo', city: 'Yahualica' },
    { state: 'Hidalgo', city: 'Zacualtipán de Ángeles' },
    { state: 'Hidalgo', city: 'Zapotlán de Juárez' },
    { state: 'Hidalgo', city: 'Zempoala' },
    { state: 'Hidalgo', city: 'Zimapán' },
    { state: 'Jalisco', city: 'Acatic' },
    { state: 'Jalisco', city: 'Acatlán de Juárez' },
    { state: 'Jalisco', city: 'Ahualulco de Mercado' },
    { state: 'Jalisco', city: 'Amacueca' },
    { state: 'Jalisco', city: 'Amatitán' },
    { state: 'Jalisco', city: 'Ameca' },
    { state: 'Jalisco', city: 'Arandas' },
    { state: 'Jalisco', city: 'Atemajac de Brizuela' },
    { state: 'Jalisco', city: 'Atengo' },
    { state: 'Jalisco', city: 'Atenguillo' },
    { state: 'Jalisco', city: 'Atotonilco el Alto' },
    { state: 'Jalisco', city: 'Atoyac' },
    { state: 'Jalisco', city: 'Autlán de Navarro' },
    { state: 'Jalisco', city: 'Ayotlán' },
    { state: 'Jalisco', city: 'Ayutla' },
    { state: 'Jalisco', city: 'Bolaños' },
    { state: 'Jalisco', city: 'Cabo Corrientes' },
    { state: 'Jalisco', city: 'Cañadas de Obregón' },
    { state: 'Jalisco', city: 'Casimiro Castillo' },
    { state: 'Jalisco', city: 'Chapala' },
    { state: 'Jalisco', city: 'Chimaltitán' },
    { state: 'Jalisco', city: 'Chiquilistlán' },
    { state: 'Jalisco', city: 'Cihuatlán' },
    { state: 'Jalisco', city: 'Cocula' },
    { state: 'Jalisco', city: 'Colotlán' },
    { state: 'Jalisco', city: 'Concepción de Buenos Aires' },
    { state: 'Jalisco', city: 'Cuautitlán de García Barragán' },
    { state: 'Jalisco', city: 'Cuautla' },
    { state: 'Jalisco', city: 'Cuquío' },
    { state: 'Jalisco', city: 'Degollado' },
    { state: 'Jalisco', city: 'Ejutla' },
    { state: 'Jalisco', city: 'El Arenal' },
    { state: 'Jalisco', city: 'El Grullo' },
    { state: 'Jalisco', city: 'El Limón' },
    { state: 'Jalisco', city: 'El Salto' },
    { state: 'Jalisco', city: 'Encarnación de Díaz' },
    { state: 'Jalisco', city: 'Etzatlán' },
    { state: 'Jalisco', city: 'Gómez Farías' },
    { state: 'Jalisco', city: 'Guachinango' },
    { state: 'Jalisco', city: 'Guadalajara' },
    { state: 'Jalisco', city: 'Hostotipaquillo' },
    { state: 'Jalisco', city: 'Huejúcar' },
    { state: 'Jalisco', city: 'Huejuquilla el Alto' },
    { state: 'Jalisco', city: 'Ixtlahuacán de los Membrillos' },
    { state: 'Jalisco', city: 'Ixtlahuacán del Río' },
    { state: 'Jalisco', city: 'Jalostotitlán' },
    { state: 'Jalisco', city: 'Jamay' },
    { state: 'Jalisco', city: 'Jesús María' },
    { state: 'Jalisco', city: 'Jilotlán de los Dolores' },
    { state: 'Jalisco', city: 'Jocotepec' },
    { state: 'Jalisco', city: 'Juanacatlán' },
    { state: 'Jalisco', city: 'Juchitlán' },
    { state: 'Jalisco', city: 'La Barca' },
    { state: 'Jalisco', city: 'La Huerta' },
    { state: 'Jalisco', city: 'La Manzanilla de la Paz' },
    { state: 'Jalisco', city: 'Lagos de Moreno' },
    { state: 'Jalisco', city: 'Magdalena' },
    { state: 'Jalisco', city: 'Mascota' },
    { state: 'Jalisco', city: 'Mazamitla' },
    { state: 'Jalisco', city: 'Mexticacán' },
    { state: 'Jalisco', city: 'Mezquitic' },
    { state: 'Jalisco', city: 'Mixtlán' },
    { state: 'Jalisco', city: 'Ocotlán' },
    { state: 'Jalisco', city: 'Ojuelos de Jalisco' },
    { state: 'Jalisco', city: 'Pihuamo' },
    { state: 'Jalisco', city: 'Poncitlán' },
    { state: 'Jalisco', city: 'Puerto Vallarta' },
    { state: 'Jalisco', city: 'Quitupan' },
    { state: 'Jalisco', city: 'San Cristóbal de la Barranca' },
    { state: 'Jalisco', city: 'San Diego de Alejandría' },
    { state: 'Jalisco', city: 'San Gabriel' },
    { state: 'Jalisco', city: 'San Ignacio Cerro Gordo' },
    { state: 'Jalisco', city: 'San Juan de los Lagos' },
    { state: 'Jalisco', city: 'San Juanito de Escobedo' },
    { state: 'Jalisco', city: 'San Julián' },
    { state: 'Jalisco', city: 'San Marcos' },
    { state: 'Jalisco', city: 'San Martín de Bolaños' },
    { state: 'Jalisco', city: 'San Martín Hidalgo' },
    { state: 'Jalisco', city: 'San Miguel el Alto' },
    { state: 'Jalisco', city: 'San Pedro Tlaquepaque' },
    { state: 'Jalisco', city: 'San Sebastián del Oeste' },
    { state: 'Jalisco', city: 'Santa María de los Ángeles' },
    { state: 'Jalisco', city: 'Sayula' },
    { state: 'Jalisco', city: 'Tala' },
    { state: 'Jalisco', city: 'Talpa de Allende' },
    { state: 'Jalisco', city: 'Tamazula de Gordiano' },
    { state: 'Jalisco', city: 'Tapalpa' },
    { state: 'Jalisco', city: 'Tecalitlán' },
    { state: 'Jalisco', city: 'Techaluta de Montenegro' },
    { state: 'Jalisco', city: 'Tecolotlán' },
    { state: 'Jalisco', city: 'Tenamaxtlán' },
    { state: 'Jalisco', city: 'Teocaltiche' },
    { state: 'Jalisco', city: 'Teocuitatlán de Corona' },
    { state: 'Jalisco', city: 'Tepatitlán de Morelos' },
    { state: 'Jalisco', city: 'Tequila' },
    { state: 'Jalisco', city: 'Teuchitlán' },
    { state: 'Jalisco', city: 'Tizapán el Alto' },
    { state: 'Jalisco', city: 'Tlajomulco de Zúñiga' },
    { state: 'Jalisco', city: 'Tolimán' },
    { state: 'Jalisco', city: 'Tomatlán' },
    { state: 'Jalisco', city: 'Tonalá' },
    { state: 'Jalisco', city: 'Tonaya' },
    { state: 'Jalisco', city: 'Tonila' },
    { state: 'Jalisco', city: 'Totatiche' },
    { state: 'Jalisco', city: 'Tototlán' },
    { state: 'Jalisco', city: 'Tuxcacuesco' },
    { state: 'Jalisco', city: 'Tuxcueca' },
    { state: 'Jalisco', city: 'Tuxpan' },
    { state: 'Jalisco', city: 'Unión de San Antonio' },
    { state: 'Jalisco', city: 'Unión de Tula' },
    { state: 'Jalisco', city: 'Valle de Guadalupe' },
    { state: 'Jalisco', city: 'Valle de Juárez' },
    { state: 'Jalisco', city: 'Villa Corona' },
    { state: 'Jalisco', city: 'Villa Guerrero' },
    { state: 'Jalisco', city: 'Villa Hidalgo' },
    { state: 'Jalisco', city: 'Villa Purificación' },
    { state: 'Jalisco', city: 'Yahualica de González Gallo' },
    { state: 'Jalisco', city: 'Zacoalco de Torres' },
    { state: 'Jalisco', city: 'Zapopan' },
    { state: 'Jalisco', city: 'Zapotiltic' },
    { state: 'Jalisco', city: 'Zapotitlán de Vadillo' },
    { state: 'Jalisco', city: 'Zapotlán del Rey' },
    { state: 'Jalisco', city: 'Zapotlán el Grande' },
    { state: 'Jalisco', city: 'Zapotlanejo' },
    { state: 'Estado De México', city: 'Acambay de Ruíz Castañeda' },
    { state: 'Estado De México', city: 'Acolman' },
    { state: 'Estado De México', city: 'Aculco' },
    { state: 'Estado De México', city: 'Almoloya de Alquisiras' },
    { state: 'Estado De México', city: 'Almoloya de Juárez' },
    { state: 'Estado De México', city: 'Almoloya del Río' },
    { state: 'Estado De México', city: 'Amanalco' },
    { state: 'Estado De México', city: 'Amatepec' },
    { state: 'Estado De México', city: 'Amecameca' },
    { state: 'Estado De México', city: 'Apaxco' },
    { state: 'Estado De México', city: 'Atenco' },
    { state: 'Estado De México', city: 'Atizapán' },
    { state: 'Estado De México', city: 'Atizapán de Zaragoza' },
    { state: 'Estado De México', city: 'Atlacomulco' },
    { state: 'Estado De México', city: 'Atlautla' },
    { state: 'Estado De México', city: 'Axapusco' },
    { state: 'Estado De México', city: 'Ayapango' },
    { state: 'Estado De México', city: 'Calimaya' },
    { state: 'Estado De México', city: 'Capulhuac' },
    { state: 'Estado De México', city: 'Chalco' },
    { state: 'Estado De México', city: 'Chapa de Mota' },
    { state: 'Estado De México', city: 'Chapultepec' },
    { state: 'Estado De México', city: 'Chiautla' },
    { state: 'Estado De México', city: 'Chicoloapan' },
    { state: 'Estado De México', city: 'Chiconcuac' },
    { state: 'Estado De México', city: 'Chimalhuacán' },
    { state: 'Estado De México', city: 'Coacalco de Berriozábal' },
    { state: 'Estado De México', city: 'Coatepec Harinas' },
    { state: 'Estado De México', city: 'Cocotitlán' },
    { state: 'Estado De México', city: 'Coyotepec' },
    { state: 'Estado De México', city: 'Cuautitlán' },
    { state: 'Estado De México', city: 'Cuautitlán Izcalli' },
    { state: 'Estado De México', city: 'Donato Guerra' },
    { state: 'Estado De México', city: 'Ecatepec de Morelos' },
    { state: 'Estado De México', city: 'Ecatzingo' },
    { state: 'Estado De México', city: 'El Oro' },
    { state: 'Estado De México', city: 'Huehuetoca' },
    { state: 'Estado De México', city: 'Hueypoxtla' },
    { state: 'Estado De México', city: 'Huixquilucan' },
    { state: 'Estado De México', city: 'Isidro Fabela' },
    { state: 'Estado De México', city: 'Ixtapaluca' },
    { state: 'Estado De México', city: 'Ixtapan de la Sal' },
    { state: 'Estado De México', city: 'Ixtapan del Oro' },
    { state: 'Estado De México', city: 'Ixtlahuaca' },
    { state: 'Estado De México', city: 'Jaltenco' },
    { state: 'Estado De México', city: 'Jilotepec' },
    { state: 'Estado De México', city: 'Jilotzingo' },
    { state: 'Estado De México', city: 'Jiquipilco' },
    { state: 'Estado De México', city: 'Jocotitlán' },
    { state: 'Estado De México', city: 'Joquicingo' },
    { state: 'Estado De México', city: 'Juchitepec' },
    { state: 'Estado De México', city: 'La Paz' },
    { state: 'Estado De México', city: 'Lerma' },
    { state: 'Estado De México', city: 'Luvianos' },
    { state: 'Estado De México', city: 'Malinalco' },
    { state: 'Estado De México', city: 'Melchor Ocampo' },
    { state: 'Estado De México', city: 'Metepec' },
    { state: 'Estado De México', city: 'Mexicaltzingo' },
    { state: 'Estado De México', city: 'Morelos' },
    { state: 'Estado De México', city: 'Naucalpan de Juárez' },
    { state: 'Estado De México', city: 'Nextlalpan' },
    { state: 'Estado De México', city: 'Nezahualcóyotl' },
    { state: 'Estado De México', city: 'Nicolás Romero' },
    { state: 'Estado De México', city: 'Nopaltepec' },
    { state: 'Estado De México', city: 'Ocoyoacac' },
    { state: 'Estado De México', city: 'Ocuilan' },
    { state: 'Estado De México', city: 'Otumba' },
    { state: 'Estado De México', city: 'Otzoloapan' },
    { state: 'Estado De México', city: 'Otzolotepec' },
    { state: 'Estado De México', city: 'Ozumba' },
    { state: 'Estado De México', city: 'Papalotla' },
    { state: 'Estado De México', city: 'Polotitlán' },
    { state: 'Estado De México', city: 'Rayón' },
    { state: 'Estado De México', city: 'San Antonio la Isla' },
    { state: 'Estado De México', city: 'San Felipe del Progreso' },
    { state: 'Estado De México', city: 'San José del Rincón' },
    { state: 'Estado De México', city: 'San Martín de las Pirámides' },
    { state: 'Estado De México', city: 'San Mateo Atenco' },
    { state: 'Estado De México', city: 'San Simón de Guerrero' },
    { state: 'Estado De México', city: 'Santo Tomás' },
    { state: 'Estado De México', city: 'Soyaniquilpan de Juárez' },
    { state: 'Estado De México', city: 'Sultepec' },
    { state: 'Estado De México', city: 'Tecámac' },
    { state: 'Estado De México', city: 'Tejupilco' },
    { state: 'Estado De México', city: 'Temamatla' },
    { state: 'Estado De México', city: 'Temascalapa' },
    { state: 'Estado De México', city: 'Temascalcingo' },
    { state: 'Estado De México', city: 'Temascaltepec' },
    { state: 'Estado De México', city: 'Temoaya' },
    { state: 'Estado De México', city: 'Tenancingo' },
    { state: 'Estado De México', city: 'Tenango del Aire' },
    { state: 'Estado De México', city: 'Tenango del Valle' },
    { state: 'Estado De México', city: 'Teoloyucan' },
    { state: 'Estado De México', city: 'Teotihuacán' },
    { state: 'Estado De México', city: 'Tepetlaoxtoc' },
    { state: 'Estado De México', city: 'Tepetlixpa' },
    { state: 'Estado De México', city: 'Tepotzotlán' },
    { state: 'Estado De México', city: 'Tequixquiac' },
    { state: 'Estado De México', city: 'Texcaltitlán' },
    { state: 'Estado De México', city: 'Texcalyacac' },
    { state: 'Estado De México', city: 'Texcoco' },
    { state: 'Estado De México', city: 'Tezoyuca' },
    { state: 'Estado De México', city: 'Tianguistenco' },
    { state: 'Estado De México', city: 'Timilpan' },
    { state: 'Estado De México', city: 'Tlalmanalco' },
    { state: 'Estado De México', city: 'Tlalnepantla de Baz' },
    { state: 'Estado De México', city: 'Tlatlaya' },
    { state: 'Estado De México', city: 'Toluca' },
    { state: 'Estado De México', city: 'Tonanitla' },
    { state: 'Estado De México', city: 'Tonatico' },
    { state: 'Estado De México', city: 'Tultepec' },
    { state: 'Estado De México', city: 'Tultitlán' },
    { state: 'Estado De México', city: 'Valle de Bravo' },
    { state: 'Estado De México', city: 'Valle de Chalco Solidaridad' },
    { state: 'Estado De México', city: 'Villa de Allende' },
    { state: 'Estado De México', city: 'Villa del Carbón' },
    { state: 'Estado De México', city: 'Villa Guerrero' },
    { state: 'Estado De México', city: 'Villa Victoria' },
    { state: 'Estado De México', city: 'Xalatlaco' },
    { state: 'Estado De México', city: 'Xonacatlán' },
    { state: 'Estado De México', city: 'Zacazonapan' },
    { state: 'Estado De México', city: 'Zacualpan' },
    { state: 'Estado De México', city: 'Zinacantepec' },
    { state: 'Estado De México', city: 'Zumpahuacán' },
    { state: 'Estado De México', city: 'Zumpango' },
    { state: 'Michoacán', city: 'Acuitzio ' },
    { state: 'Michoacán', city: 'Aguililla ' },
    { state: 'Michoacán', city: 'Álvaro Obregón ' },
    { state: 'Michoacán', city: 'Angamacutiro ' },
    { state: 'Michoacán', city: 'Angangueo ' },
    { state: 'Michoacán', city: 'Apatzingán ' },
    { state: 'Michoacán', city: 'Aporo ' },
    { state: 'Michoacán', city: 'Aquila ' },
    { state: 'Michoacán', city: 'Ario ' },
    { state: 'Michoacán', city: 'Arteaga ' },
    { state: 'Michoacán', city: 'Briseñas ' },
    { state: 'Michoacán', city: 'Buenavista ' },
    { state: 'Michoacán', city: 'Carácuaro ' },
    { state: 'Michoacán', city: 'Charapan ' },
    { state: 'Michoacán', city: 'Charo ' },
    { state: 'Michoacán', city: 'Chavinda ' },
    { state: 'Michoacán', city: 'Cherán ' },
    { state: 'Michoacán', city: 'Chilchota ' },
    { state: 'Michoacán', city: 'Chinicuila ' },
    { state: 'Michoacán', city: 'Chucándiro ' },
    { state: 'Michoacán', city: 'Churintzio ' },
    { state: 'Michoacán', city: 'Churumuco ' },
    { state: 'Michoacán', city: 'Coahuayana ' },
    { state: 'Michoacán', city: 'Coalcomán de Vázquez Pallares ' },
    { state: 'Michoacán', city: 'Coeneo ' },
    { state: 'Michoacán', city: 'Cojumatlán de Régules ' },
    { state: 'Michoacán', city: 'Contepec ' },
    { state: 'Michoacán', city: 'Copándaro ' },
    { state: 'Michoacán', city: 'Cotija ' },
    { state: 'Michoacán', city: 'Cuitzeo ' },
    { state: 'Michoacán', city: 'Ecuandureo ' },
    { state: 'Michoacán', city: 'Epitacio Huerta ' },
    { state: 'Michoacán', city: 'Erongarícuaro ' },
    { state: 'Michoacán', city: 'Gabriel Zamora ' },
    { state: 'Michoacán', city: 'Hidalgo ' },
    { state: 'Michoacán', city: 'Huandacareo ' },
    { state: 'Michoacán', city: 'Huaniqueo ' },
    { state: 'Michoacán', city: 'Huetamo ' },
    { state: 'Michoacán', city: 'Huiramba ' },
    { state: 'Michoacán', city: 'Indaparapeo ' },
    { state: 'Michoacán', city: 'Irimbo ' },
    { state: 'Michoacán', city: 'Ixtlán ' },
    { state: 'Michoacán', city: 'Jacona ' },
    { state: 'Michoacán', city: 'Jiménez ' },
    { state: 'Michoacán', city: 'Jiquilpan ' },
    { state: 'Michoacán', city: 'José Sixto Verduzco ' },
    { state: 'Michoacán', city: 'Juárez ' },
    { state: 'Michoacán', city: 'Jungapeo ' },
    { state: 'Michoacán', city: 'La Huacana ' },
    { state: 'Michoacán', city: 'La Piedad ' },
    { state: 'Michoacán', city: 'Lagunillas ' },
    { state: 'Michoacán', city: 'Lázaro Cárdenas ' },
    { state: 'Michoacán', city: 'Los Reyes ' },
    { state: 'Michoacán', city: 'Madero ' },
    { state: 'Michoacán', city: 'Maravatío ' },
    { state: 'Michoacán', city: 'Marcos Castellanos ' },
    { state: 'Michoacán', city: 'Morelia ' },
    { state: 'Michoacán', city: 'Morelos ' },
    { state: 'Michoacán', city: 'Múgica ' },
    { state: 'Michoacán', city: 'Nahuatzen ' },
    { state: 'Michoacán', city: 'Nocupétaro ' },
    { state: 'Michoacán', city: 'Nuevo Parangaricutiro ' },
    { state: 'Michoacán', city: 'Nuevo Urecho ' },
    { state: 'Michoacán', city: 'Numarán ' },
    { state: 'Michoacán', city: 'Ocampo ' },
    { state: 'Michoacán', city: 'Pajacuarán ' },
    { state: 'Michoacán', city: 'Panindícuaro ' },
    { state: 'Michoacán', city: 'Paracho ' },
    { state: 'Michoacán', city: 'Parácuaro ' },
    { state: 'Michoacán', city: 'Pátzcuaro ' },
    { state: 'Michoacán', city: 'Penjamillo ' },
    { state: 'Michoacán', city: 'Peribán ' },
    { state: 'Michoacán', city: 'Purépero ' },
    { state: 'Michoacán', city: 'Puruándiro ' },
    { state: 'Michoacán', city: 'Queréndaro ' },
    { state: 'Michoacán', city: 'Quiroga ' },
    { state: 'Michoacán', city: 'Sahuayo ' },
    { state: 'Michoacán', city: 'Salvador Escalante ' },
    { state: 'Michoacán', city: 'San Lucas ' },
    { state: 'Michoacán', city: 'Santa Ana Maya ' },
    { state: 'Michoacán', city: 'Senguio ' },
    { state: 'Michoacán', city: 'Susupuato ' },
    { state: 'Michoacán', city: 'Tacámbaro ' },
    { state: 'Michoacán', city: 'Tancítaro ' },
    { state: 'Michoacán', city: 'Tangamandapio ' },
    { state: 'Michoacán', city: 'Tangancícuaro ' },
    { state: 'Michoacán', city: 'Tanhuato ' },
    { state: 'Michoacán', city: 'Taretan ' },
    { state: 'Michoacán', city: 'Tarímbaro ' },
    { state: 'Michoacán', city: 'Tepalcatepec ' },
    { state: 'Michoacán', city: 'Tingambato ' },
    { state: 'Michoacán', city: 'Tingüindín ' },
    { state: 'Michoacán', city: 'Tiquicheo de Nicolás Romero ' },
    { state: 'Michoacán', city: 'Tlalpujahua ' },
    { state: 'Michoacán', city: 'Tlazazalca ' },
    { state: 'Michoacán', city: 'Tocumbo ' },
    { state: 'Michoacán', city: 'Tumbiscatío ' },
    { state: 'Michoacán', city: 'Turicato ' },
    { state: 'Michoacán', city: 'Tuxpan ' },
    { state: 'Michoacán', city: 'Tuzantla ' },
    { state: 'Michoacán', city: 'Tzintzuntzan ' },
    { state: 'Michoacán', city: 'Tzitzio ' },
    { state: 'Michoacán', city: 'Uruapan ' },
    { state: 'Michoacán', city: 'Venustiano Carranza ' },
    { state: 'Michoacán', city: 'Villamar ' },
    { state: 'Michoacán', city: 'Vista Hermosa ' },
    { state: 'Michoacán', city: 'Yurécuaro ' },
    { state: 'Michoacán', city: 'Zacapu ' },
    { state: 'Michoacán', city: 'Zamora ' },
    { state: 'Michoacán', city: 'Zináparo ' },
    { state: 'Michoacán', city: 'Zinapécuaro ' },
    { state: 'Michoacán', city: 'Ziracuaretiro ' },
    { state: 'Michoacán', city: 'Zitácuaro ' },
    { state: 'Morelos', city: 'Amacuzac ' },
    { state: 'Morelos', city: 'Atlatlahucan ' },
    { state: 'Morelos', city: 'Axochiapan ' },
    { state: 'Morelos', city: 'Ayala ' },
    { state: 'Morelos', city: 'Coatetelco ' },
    { state: 'Morelos', city: 'Coatlán del Río ' },
    { state: 'Morelos', city: 'Cuautla ' },
    { state: 'Morelos', city: 'Cuernavaca ' },
    { state: 'Morelos', city: 'Emiliano Zapata ' },
    { state: 'Morelos', city: 'Hueyapan ' },
    { state: 'Morelos', city: 'Huitzilac ' },
    { state: 'Morelos', city: 'Jantetelco ' },
    { state: 'Morelos', city: 'Jiutepec ' },
    { state: 'Morelos', city: 'Jojutla ' },
    { state: 'Morelos', city: 'Jonacatepec ' },
    { state: 'Morelos', city: 'Mazatepec ' },
    { state: 'Morelos', city: 'Miacatlán ' },
    { state: 'Morelos', city: 'Ocuituco ' },
    { state: 'Morelos', city: 'Puente de Ixtla ' },
    { state: 'Morelos', city: 'Temixco ' },
    { state: 'Morelos', city: 'Temoac ' },
    { state: 'Morelos', city: 'Tepalcingo ' },
    { state: 'Morelos', city: 'Tepoztlán ' },
    { state: 'Morelos', city: 'Tetecala ' },
    { state: 'Morelos', city: 'Tetela del Volcán ' },
    { state: 'Morelos', city: 'Tlalnepantla ' },
    { state: 'Morelos', city: 'Tlaltizapán ' },
    { state: 'Morelos', city: 'Tlaquiltenango ' },
    { state: 'Morelos', city: 'Tlayacapan ' },
    { state: 'Morelos', city: 'Totolapan ' },
    { state: 'Morelos', city: 'Xochitepec ' },
    { state: 'Morelos', city: 'Xoxocotla ' },
    { state: 'Morelos', city: 'Yautepec ' },
    { state: 'Morelos', city: 'Yecapixtla ' },
    { state: 'Morelos', city: 'Zacatepec ' },
    { state: 'Morelos', city: 'Zacualpan ' },
    { state: 'Nayarit', city: 'Acaponeta' },
    { state: 'Nayarit', city: 'Ahuacatlán' },
    { state: 'Nayarit', city: 'Amatlán de Cañas' },
    { state: 'Nayarit', city: 'Bahía de Banderas' },
    { state: 'Nayarit', city: 'Compostela' },
    { state: 'Nayarit', city: 'Del Nayar' },
    { state: 'Nayarit', city: 'Huajicori' },
    { state: 'Nayarit', city: 'Ixtlán del Río' },
    { state: 'Nayarit', city: 'Jala' },
    { state: 'Nayarit', city: 'La Yesca' },
    { state: 'Nayarit', city: 'Rosamorada' },
    { state: 'Nayarit', city: 'Ruiz' },
    { state: 'Nayarit', city: 'San Blas' },
    { state: 'Nayarit', city: 'San Pedro Lagunillas' },
    { state: 'Nayarit', city: 'Santa María del Oro' },
    { state: 'Nayarit', city: 'Santiago Ixcuintla' },
    { state: 'Nayarit', city: 'Tecuala' },
    { state: 'Nayarit', city: 'Tepic' },
    { state: 'Nayarit', city: 'Tuxpan' },
    { state: 'Nayarit', city: 'Xalisco' },
    { state: 'Nuevo León', city: 'Abasolo ' },
    { state: 'Nuevo León', city: 'Agualeguas ' },
    { state: 'Nuevo León', city: 'Allende ' },
    { state: 'Nuevo León', city: 'Anáhuac ' },
    { state: 'Nuevo León', city: 'Apodaca ' },
    { state: 'Nuevo León', city: 'Aramberri ' },
    { state: 'Nuevo León', city: 'Bustamante ' },
    { state: 'Nuevo León', city: 'Cadereyta Jiménez ' },
    { state: 'Nuevo León', city: 'Carmen ' },
    { state: 'Nuevo León', city: 'Cerralvo ' },
    { state: 'Nuevo León', city: 'China ' },
    { state: 'Nuevo León', city: 'Ciénega de Flores ' },
    { state: 'Nuevo León', city: 'Doctor Arroyo ' },
    { state: 'Nuevo León', city: 'Doctor Coss ' },
    { state: 'Nuevo León', city: 'Doctor González ' },
    { state: 'Nuevo León', city: 'Galeana ' },
    { state: 'Nuevo León', city: 'García ' },
    { state: 'Nuevo León', city: 'General Bravo ' },
    { state: 'Nuevo León', city: 'General Escobedo ' },
    { state: 'Nuevo León', city: 'General Terán ' },
    { state: 'Nuevo León', city: 'General Treviño ' },
    { state: 'Nuevo León', city: 'General Zaragoza ' },
    { state: 'Nuevo León', city: 'General Zuazua ' },
    { state: 'Nuevo León', city: 'Guadalupe ' },
    { state: 'Nuevo León', city: 'Hidalgo ' },
    { state: 'Nuevo León', city: 'Higueras ' },
    { state: 'Nuevo León', city: 'Hualahuises ' },
    { state: 'Nuevo León', city: 'Iturbide ' },
    { state: 'Nuevo León', city: 'Juárez ' },
    { state: 'Nuevo León', city: 'Lampazos de Naranjo ' },
    { state: 'Nuevo León', city: 'Linares ' },
    { state: 'Nuevo León', city: 'Los Aldamas ' },
    { state: 'Nuevo León', city: 'Los Herreras ' },
    { state: 'Nuevo León', city: 'Los Ramones ' },
    { state: 'Nuevo León', city: 'Marín ' },
    { state: 'Nuevo León', city: 'Melchor Ocampo ' },
    { state: 'Nuevo León', city: 'Mier y Noriega ' },
    { state: 'Nuevo León', city: 'Mina ' },
    { state: 'Nuevo León', city: 'Montemorelos ' },
    { state: 'Nuevo León', city: 'Monterrey ' },
    { state: 'Nuevo León', city: 'Parás ' },
    { state: 'Nuevo León', city: 'Pesquería ' },
    { state: 'Nuevo León', city: 'Rayones ' },
    { state: 'Nuevo León', city: 'Sabinas Hidalgo ' },
    { state: 'Nuevo León', city: 'Salinas Victoria ' },
    { state: 'Nuevo León', city: 'San Nicolás de los Garza ' },
    { state: 'Nuevo León', city: 'San Pedro Garza García ' },
    { state: 'Nuevo León', city: 'Santa Catarina ' },
    { state: 'Nuevo León', city: 'Santiago ' },
    { state: 'Nuevo León', city: 'Vallecillo ' },
    { state: 'Nuevo León', city: 'Villaldama ' },
    { state: 'Oaxaca', city: 'Abejones' },
    { state: 'Oaxaca', city: 'Acatlán de Pérez Figueroa' },
    { state: 'Oaxaca', city: 'Ánimas Trujano' },
    { state: 'Oaxaca', city: 'Asunción Cacalotepec' },
    { state: 'Oaxaca', city: 'Asunción Cuyotepeji' },
    { state: 'Oaxaca', city: 'Asunción Ixtaltepec' },
    { state: 'Oaxaca', city: 'Asunción Nochixtlán' },
    { state: 'Oaxaca', city: 'Asunción Ocotlán' },
    { state: 'Oaxaca', city: 'Asunción Tlacolulita' },
    { state: 'Oaxaca', city: 'Ayoquezco de Aldama' },
    { state: 'Oaxaca', city: 'Ayotzintepec' },
    { state: 'Oaxaca', city: 'Calihualá' },
    { state: 'Oaxaca', city: 'Candelaria Loxicha' },
    { state: 'Oaxaca', city: 'Capulálpam de Méndez' },
    { state: 'Oaxaca', city: 'Chahuites' },
    { state: 'Oaxaca', city: 'Chalcatongo de Hidalgo' },
    { state: 'Oaxaca', city: 'Chiquihuitlán de Benito Juárez' },
    { state: 'Oaxaca', city: 'Ciénega de Zimatlán' },
    { state: 'Oaxaca', city: 'Ciudad Ixtepec' },
    { state: 'Oaxaca', city: 'Coatecas Altas' },
    { state: 'Oaxaca', city: 'Coicoyán de las Flores' },
    { state: 'Oaxaca', city: 'Concepción Buenavista' },
    { state: 'Oaxaca', city: 'Concepción Pápalo' },
    { state: 'Oaxaca', city: 'Constancia del Rosario' },
    { state: 'Oaxaca', city: 'Cosolapa' },
    { state: 'Oaxaca', city: 'Cosoltepec' },
    { state: 'Oaxaca', city: 'Cuilápam de Guerrero' },
    { state: 'Oaxaca', city: 'Cuyamecalco Villa de Zaragoza' },
    { state: 'Oaxaca', city: 'El Barrio de la Soledad' },
    { state: 'Oaxaca', city: 'El Espinal' },
    { state: 'Oaxaca', city: 'Eloxochitlán de Flores Magón' },
    { state: 'Oaxaca', city: 'Fresnillo de Trujano' },
    { state: 'Oaxaca', city: 'Guadalupe de Ramírez' },
    { state: 'Oaxaca', city: 'Guadalupe Etla' },
    { state: 'Oaxaca', city: 'Guelatao de Juárez' },
    { state: 'Oaxaca', city: 'Guevea de Humboldt' },
    { state: 'Oaxaca', city: 'Heroica Ciudad de Ejutla de Crespo' },
    { state: 'Oaxaca', city: 'Heroica Ciudad de Huajuapan de León' },
    { state: 'Oaxaca', city: 'Heroica Ciudad de Juchitán de Zaragoza' },
    { state: 'Oaxaca', city: 'Heroica Ciudad de Tlaxiaco' },
    { state: 'Oaxaca', city: 'Huautepec' },
    { state: 'Oaxaca', city: 'Huautla de Jiménez' },
    { state: 'Oaxaca', city: 'Ixpantepec Nieves' },
    { state: 'Oaxaca', city: 'Ixtlán de Juárez' },
    { state: 'Oaxaca', city: 'La Compañía' },
    { state: 'Oaxaca', city: 'La Pe' },
    { state: 'Oaxaca', city: 'La Reforma' },
    { state: 'Oaxaca', city: 'La Trinidad Vista Hermosa' },
    { state: 'Oaxaca', city: 'Loma Bonita' },
    { state: 'Oaxaca', city: 'Magdalena Apasco' },
    { state: 'Oaxaca', city: 'Magdalena Jaltepec' },
    { state: 'Oaxaca', city: 'Magdalena Mixtepec' },
    { state: 'Oaxaca', city: 'Magdalena Ocotlán' },
    { state: 'Oaxaca', city: 'Magdalena Peñasco' },
    { state: 'Oaxaca', city: 'Magdalena Teitipac' },
    { state: 'Oaxaca', city: 'Magdalena Tequisistlán' },
    { state: 'Oaxaca', city: 'Magdalena Tlacotepec' },
    { state: 'Oaxaca', city: 'Magdalena Yodocono de Porfirio Díaz' },
    { state: 'Oaxaca', city: 'Magdalena Zahuatlán' },
    { state: 'Oaxaca', city: 'Mariscala de Juárez' },
    { state: 'Oaxaca', city: 'Mártires de Tacubaya' },
    { state: 'Oaxaca', city: 'Matías Romero Avendaño' },
    { state: 'Oaxaca', city: 'Mazatlán Villa de Flores' },
    { state: 'Oaxaca', city: 'Mesones Hidalgo' },
    { state: 'Oaxaca', city: 'Miahuatlán de Porfirio Díaz' },
    { state: 'Oaxaca', city: 'Mixistlán de la Reforma' },
    { state: 'Oaxaca', city: 'Monjas' },
    { state: 'Oaxaca', city: 'Natividad' },
    { state: 'Oaxaca', city: 'Nazareno Etla' },
    { state: 'Oaxaca', city: 'Nejapa de Madero' },
    { state: 'Oaxaca', city: 'Nuevo Zoquiapam' },
    { state: 'Oaxaca', city: 'Oaxaca de Juárez' },
    { state: 'Oaxaca', city: 'Ocotlán de Morelos' },
    { state: 'Oaxaca', city: 'Pinotepa de Don Luis' },
    { state: 'Oaxaca', city: 'Pluma Hidalgo' },
    { state: 'Oaxaca', city: 'Putla Villa de Guerrero' },
    { state: 'Oaxaca', city: 'Reforma de Pineda' },
    { state: 'Oaxaca', city: 'Reyes Etla' },
    { state: 'Oaxaca', city: 'Rojas de Cuauhtémoc' },
    { state: 'Oaxaca', city: 'Salina Cruz' },
    { state: 'Oaxaca', city: 'San Agustín Amatengo' },
    { state: 'Oaxaca', city: 'San Agustín Atenango' },
    { state: 'Oaxaca', city: 'San Agustín Chayuco' },
    { state: 'Oaxaca', city: 'San Agustín de las Juntas' },
    { state: 'Oaxaca', city: 'San Agustín Etla' },
    { state: 'Oaxaca', city: 'San Agustín Loxicha' },
    { state: 'Oaxaca', city: 'San Agustín Tlacotepec' },
    { state: 'Oaxaca', city: 'San Agustín Yatareni' },
    { state: 'Oaxaca', city: 'San Andrés Cabecera Nueva' },
    { state: 'Oaxaca', city: 'San Andrés Dinicuiti' },
    { state: 'Oaxaca', city: 'San Andrés Huaxpaltepec' },
    { state: 'Oaxaca', city: 'San Andrés Huayapam' },
    { state: 'Oaxaca', city: 'San Andrés Ixtlahuaca' },
    { state: 'Oaxaca', city: 'San Andrés Lagunas' },
    { state: 'Oaxaca', city: 'San Andrés Nuxiño' },
    { state: 'Oaxaca', city: 'San Andrés Paxtlán' },
    { state: 'Oaxaca', city: 'San Andrés Sinaxtla' },
    { state: 'Oaxaca', city: 'San Andrés Solaga' },
    { state: 'Oaxaca', city: 'San Andrés Teotilálpam' },
    { state: 'Oaxaca', city: 'San Andrés Tepetlapa' },
    { state: 'Oaxaca', city: 'San Andrés Yaá' },
    { state: 'Oaxaca', city: 'San Andrés Zabache' },
    { state: 'Oaxaca', city: 'San Andrés Zautla' },
    { state: 'Oaxaca', city: 'San Antonino Castillo Velasco' },
    { state: 'Oaxaca', city: 'San Antonino el Alto' },
    { state: 'Oaxaca', city: 'San Antonino Monte Verde' },
    { state: 'Oaxaca', city: 'San Antonio Acutla' },
    { state: 'Oaxaca', city: 'San Antonio de la Cal' },
    { state: 'Oaxaca', city: 'San Antonio Huitepec' },
    { state: 'Oaxaca', city: 'San Antonio Nanahuatipam' },
    { state: 'Oaxaca', city: 'San Antonio Sinicahua' },
    { state: 'Oaxaca', city: 'San Antonio Tepetlapa' },
    { state: 'Oaxaca', city: 'San Baltazar Chichicapam' },
    { state: 'Oaxaca', city: 'San Baltazar Loxicha' },
    { state: 'Oaxaca', city: 'San Baltazar Yatzachi el Bajo' },
    { state: 'Oaxaca', city: 'San Bartolo Coyotepec' },
    { state: 'Oaxaca', city: 'San Bartolo Soyaltepec' },
    { state: 'Oaxaca', city: 'San Bartolo Yautepec' },
    { state: 'Oaxaca', city: 'San Bartolomé Ayautla' },
    { state: 'Oaxaca', city: 'San Bartolomé Loxicha' },
    { state: 'Oaxaca', city: 'San Bartolomé Quialana' },
    { state: 'Oaxaca', city: 'San Bartolomé Yucuañe' },
    { state: 'Oaxaca', city: 'San Bartolomé Zoogocho' },
    { state: 'Oaxaca', city: 'San Bernardo Mixtepec' },
    { state: 'Oaxaca', city: 'San Blas Atempa' },
    { state: 'Oaxaca', city: 'San Carlos Yautepec' },
    { state: 'Oaxaca', city: 'San Cristóbal Amatlán' },
    { state: 'Oaxaca', city: 'San Cristóbal Amoltepec' },
    { state: 'Oaxaca', city: 'San Cristóbal Lachirioag' },
    { state: 'Oaxaca', city: 'San Cristóbal Suchixtlahuaca' },
    { state: 'Oaxaca', city: 'San Dionisio del Mar' },
    { state: 'Oaxaca', city: 'San Dionisio Ocotepec' },
    { state: 'Oaxaca', city: 'San Dionisio Ocotlán' },
    { state: 'Oaxaca', city: 'San Esteban Atatlahuca' },
    { state: 'Oaxaca', city: 'San Felipe Jalapa de Díaz' },
    { state: 'Oaxaca', city: 'San Felipe Tejalapam' },
    { state: 'Oaxaca', city: 'San Felipe Usila' },
    { state: 'Oaxaca', city: 'San Francisco Cahuacúa' },
    { state: 'Oaxaca', city: 'San Francisco Cajonos' },
    { state: 'Oaxaca', city: 'San Francisco Chapulapa' },
    { state: 'Oaxaca', city: 'San Francisco Chindúa' },
    { state: 'Oaxaca', city: 'San Francisco del Mar' },
    { state: 'Oaxaca', city: 'San Francisco Huehuetlán' },
    { state: 'Oaxaca', city: 'San Francisco Ixhuatán' },
    { state: 'Oaxaca', city: 'San Francisco Jaltepetongo' },
    { state: 'Oaxaca', city: 'San Francisco Lachigoló' },
    { state: 'Oaxaca', city: 'San Francisco Logueche' },
    { state: 'Oaxaca', city: 'San Francisco Nuxaño' },
    { state: 'Oaxaca', city: 'San Francisco Ozolotepec' },
    { state: 'Oaxaca', city: 'San Francisco Sola' },
    { state: 'Oaxaca', city: 'San Francisco Telixtlahuaca' },
    { state: 'Oaxaca', city: 'San Francisco Teopan' },
    { state: 'Oaxaca', city: 'San Francisco Tlapancingo' },
    { state: 'Oaxaca', city: 'San Gabriel Mixtepec' },
    { state: 'Oaxaca', city: 'San Ildefonso Amatlán' },
    { state: 'Oaxaca', city: 'San Ildefonso Sola' },
    { state: 'Oaxaca', city: 'San Ildefonso Villa Alta' },
    { state: 'Oaxaca', city: 'San Jacinto Amilpas' },
    { state: 'Oaxaca', city: 'San Jacinto Tlacotepec' },
    { state: 'Oaxaca', city: 'San Jerónimo Coatlán' },
    { state: 'Oaxaca', city: 'San Jerónimo Silacayoapilla' },
    { state: 'Oaxaca', city: 'San Jerónimo Sosola' },
    { state: 'Oaxaca', city: 'San Jerónimo Taviche' },
    { state: 'Oaxaca', city: 'San Jerónimo Tecoatl' },
    { state: 'Oaxaca', city: 'San Jerónimo Tlacochahuaya' },
    { state: 'Oaxaca', city: 'San Jorge Nuchita' },
    { state: 'Oaxaca', city: 'San José Ayuquila' },
    { state: 'Oaxaca', city: 'San José Chiltepec' },
    { state: 'Oaxaca', city: 'San José del Peñasco' },
    { state: 'Oaxaca', city: 'San José del Progreso' },
    { state: 'Oaxaca', city: 'San José Estancia Grande' },
    { state: 'Oaxaca', city: 'San José Independencia' },
    { state: 'Oaxaca', city: 'San José Lachiguirí' },
    { state: 'Oaxaca', city: 'San José Tenango' },
    { state: 'Oaxaca', city: 'San Juan Achiutla' },
    { state: 'Oaxaca', city: 'San Juan Atepec' },
    { state: 'Oaxaca', city: 'San Juan Bautista Atatlahuca' },
    { state: 'Oaxaca', city: 'San Juan Bautista Coixtlahuaca' },
    { state: 'Oaxaca', city: 'San Juan Bautista Cuicatlán' },
    { state: 'Oaxaca', city: 'San Juan Bautista Guelache' },
    { state: 'Oaxaca', city: 'San Juan Bautista Jayacatlán' },
    { state: 'Oaxaca', city: 'San Juan Bautista Lo de Soto' },
    { state: 'Oaxaca', city: 'San Juan Bautista Suchitepec' },
    { state: 'Oaxaca', city: 'San Juan Bautista Tlachichilco' },
    { state: 'Oaxaca', city: 'San Juan Bautista Tlacoatzintepec' },
    { state: 'Oaxaca', city: 'San Juan Bautista Tuxtepec' },
    { state: 'Oaxaca', city: 'San Juan Bautista Valle Nacional' },
    { state: 'Oaxaca', city: 'San Juan Cacahuatepec' },
    { state: 'Oaxaca', city: 'San Juan Chicomezúchil' },
    { state: 'Oaxaca', city: 'San Juan Chilateca' },
    { state: 'Oaxaca', city: 'San Juan Cieneguilla' },
    { state: 'Oaxaca', city: 'San Juan Coatzospam' },
    { state: 'Oaxaca', city: 'San Juan Colorado' },
    { state: 'Oaxaca', city: 'San Juan Comaltepec' },
    { state: 'Oaxaca', city: 'San Juan Cotzocón' },
    { state: 'Oaxaca', city: 'San Juan de los Cués' },
    { state: 'Oaxaca', city: 'San Juan del Estado' },
    { state: 'Oaxaca', city: 'San Juan del Río' },
    { state: 'Oaxaca', city: 'San Juan Diuxi' },
    { state: 'Oaxaca', city: 'San Juan Evangelista Analco' },
    { state: 'Oaxaca', city: 'San Juan Guelavía' },
    { state: 'Oaxaca', city: 'San Juan Guichicovi' },
    { state: 'Oaxaca', city: 'San Juan Ihualtepec' },
    { state: 'Oaxaca', city: 'San Juan Juquila Mixes' },
    { state: 'Oaxaca', city: 'San Juan Juquila Vijanos' },
    { state: 'Oaxaca', city: 'San Juan Lachao' },
    { state: 'Oaxaca', city: 'San Juan Lachigalla' },
    { state: 'Oaxaca', city: 'San Juan Lajarcia' },
    { state: 'Oaxaca', city: 'San Juan Lalana' },
    { state: 'Oaxaca', city: 'San Juan Mazatlán' },
    { state: 'Oaxaca', city: 'San Juan Mixtepec -Distrito 08-' },
    { state: 'Oaxaca', city: 'San Juan Mixtepec -Distrito 26-' },
    { state: 'Oaxaca', city: 'San Juan Ñumí' },
    { state: 'Oaxaca', city: 'San Juan Ozolotepec' },
    { state: 'Oaxaca', city: 'San Juan Petlapa' },
    { state: 'Oaxaca', city: 'San Juan Quiahije' },
    { state: 'Oaxaca', city: 'San Juan Quiotepec' },
    { state: 'Oaxaca', city: 'San Juan Sayultepec' },
    { state: 'Oaxaca', city: 'San Juan Tabaá' },
    { state: 'Oaxaca', city: 'San Juan Tamazola' },
    { state: 'Oaxaca', city: 'San Juan Teita' },
    { state: 'Oaxaca', city: 'San Juan Teitipac' },
    { state: 'Oaxaca', city: 'San Juan Tepeuxila' },
    { state: 'Oaxaca', city: 'San Juan Teposcolula' },
    { state: 'Oaxaca', city: 'San Juan Yaeé' },
    { state: 'Oaxaca', city: 'San Juan Yatzona' },
    { state: 'Oaxaca', city: 'San Juan Yucuita' },
    { state: 'Oaxaca', city: 'San Lorenzo' },
    { state: 'Oaxaca', city: 'San Lorenzo Albarradas' },
    { state: 'Oaxaca', city: 'San Lorenzo Cacaotepec' },
    { state: 'Oaxaca', city: 'San Lorenzo Cuaunecuiltitla' },
    { state: 'Oaxaca', city: 'San Lorenzo Texmelucan' },
    { state: 'Oaxaca', city: 'San Lorenzo Victoria' },
    { state: 'Oaxaca', city: 'San Lucas Camotlán' },
    { state: 'Oaxaca', city: 'San Lucas Ojitlán' },
    { state: 'Oaxaca', city: 'San Lucas Quiaviní' },
    { state: 'Oaxaca', city: 'San Lucas Zoquiápam' },
    { state: 'Oaxaca', city: 'San Luis Amatlán' },
    { state: 'Oaxaca', city: 'San Marcial Ozolotepec' },
    { state: 'Oaxaca', city: 'San Marcos Arteaga' },
    { state: 'Oaxaca', city: 'San Martín de los Cansecos' },
    { state: 'Oaxaca', city: 'San Martín Huamelúlpam' },
    { state: 'Oaxaca', city: 'San Martín Itunyoso' },
    { state: 'Oaxaca', city: 'San Martín Lachilá' },
    { state: 'Oaxaca', city: 'San Martín Peras' },
    { state: 'Oaxaca', city: 'San Martín Tilcajete' },
    { state: 'Oaxaca', city: 'San Martín Toxpalan' },
    { state: 'Oaxaca', city: 'San Martín Zacatepec' },
    { state: 'Oaxaca', city: 'San Mateo Cajonos' },
    { state: 'Oaxaca', city: 'San Mateo del Mar' },
    { state: 'Oaxaca', city: 'San Mateo Etlatongo' },
    { state: 'Oaxaca', city: 'San Mateo Nejapam' },
    { state: 'Oaxaca', city: 'San Mateo Peñasco' },
    { state: 'Oaxaca', city: 'San Mateo Piñas' },
    { state: 'Oaxaca', city: 'San Mateo Río Hondo' },
    { state: 'Oaxaca', city: 'San Mateo Sindihui' },
    { state: 'Oaxaca', city: 'San Mateo Tlapiltepec' },
    { state: 'Oaxaca', city: 'San Mateo Yacutindoo' },
    { state: 'Oaxaca', city: 'San Mateo Yoloxochitlán' },
    { state: 'Oaxaca', city: 'San Melchor Betaza' },
    { state: 'Oaxaca', city: 'San Miguel Achiutla' },
    { state: 'Oaxaca', city: 'San Miguel Ahuehuetitlán' },
    { state: 'Oaxaca', city: 'San Miguel Aloápam' },
    { state: 'Oaxaca', city: 'San Miguel Amatitlán' },
    { state: 'Oaxaca', city: 'San Miguel Amatlán' },
    { state: 'Oaxaca', city: 'San Miguel Chicahua' },
    { state: 'Oaxaca', city: 'San Miguel Chimalapa' },
    { state: 'Oaxaca', city: 'San Miguel Coatlán' },
    { state: 'Oaxaca', city: 'San Miguel del Puerto' },
    { state: 'Oaxaca', city: 'San Miguel del Río' },
    { state: 'Oaxaca', city: 'San Miguel Ejutla' },
    { state: 'Oaxaca', city: 'San Miguel el Grande' },
    { state: 'Oaxaca', city: 'San Miguel Huautla' },
    { state: 'Oaxaca', city: 'San Miguel Mixtepec' },
    { state: 'Oaxaca', city: 'San Miguel Panixtlahuaca' },
    { state: 'Oaxaca', city: 'San Miguel Peras' },
    { state: 'Oaxaca', city: 'San Miguel Piedras' },
    { state: 'Oaxaca', city: 'San Miguel Quetzaltepec' },
    { state: 'Oaxaca', city: 'San Miguel Santa Flor' },
    { state: 'Oaxaca', city: 'San Miguel Soyaltepec' },
    { state: 'Oaxaca', city: 'San Miguel Suchixtepec' },
    { state: 'Oaxaca', city: 'San Miguel Tecomatlán' },
    { state: 'Oaxaca', city: 'San Miguel Tenango' },
    { state: 'Oaxaca', city: 'San Miguel Tequixtepec' },
    { state: 'Oaxaca', city: 'San Miguel Tilquiápam' },
    { state: 'Oaxaca', city: 'San Miguel Tlacamama' },
    { state: 'Oaxaca', city: 'San Miguel Tlacotepec' },
    { state: 'Oaxaca', city: 'San Miguel Tulancingo' },
    { state: 'Oaxaca', city: 'San Miguel Yotao' },
    { state: 'Oaxaca', city: 'San Nicolás' },
    { state: 'Oaxaca', city: 'San Nicolás Hidalgo ' },
    { state: 'Oaxaca', city: 'San Pablo Coatlán' },
    { state: 'Oaxaca', city: 'San Pablo Cuatro Venados' },
    { state: 'Oaxaca', city: 'San Pablo Etla' },
    { state: 'Oaxaca', city: 'San Pablo Huitzo' },
    { state: 'Oaxaca', city: 'San Pablo Huixtepec' },
    { state: 'Oaxaca', city: 'San Pablo Macuiltianguis' },
    { state: 'Oaxaca', city: 'San Pablo Tijaltepec' },
    { state: 'Oaxaca', city: 'San Pablo Villa de Mitla' },
    { state: 'Oaxaca', city: 'San Pablo Yaganiza' },
    { state: 'Oaxaca', city: 'San Pedro Amuzgos' },
    { state: 'Oaxaca', city: 'San Pedro Apóstol' },
    { state: 'Oaxaca', city: 'San Pedro Atoyac' },
    { state: 'Oaxaca', city: 'San Pedro Cajonos' },
    { state: 'Oaxaca', city: 'San Pedro Comitancillo' },
    { state: 'Oaxaca', city: 'San Pedro Coxcaltepec Cántaros' },
    { state: 'Oaxaca', city: 'San Pedro El Alto' },
    { state: 'Oaxaca', city: 'San Pedro Huamelula' },
    { state: 'Oaxaca', city: 'San Pedro Huilotepec' },
    { state: 'Oaxaca', city: 'San Pedro Ixcatlán' },
    { state: 'Oaxaca', city: 'San Pedro Ixtlahuaca' },
    { state: 'Oaxaca', city: 'San Pedro Jaltepetongo' },
    { state: 'Oaxaca', city: 'San Pedro Jicayán' },
    { state: 'Oaxaca', city: 'San Pedro Jocotipac' },
    { state: 'Oaxaca', city: 'San Pedro Juchatengo' },
    { state: 'Oaxaca', city: 'San Pedro Mártir' },
    { state: 'Oaxaca', city: 'San Pedro Mártir Quiechapa' },
    { state: 'Oaxaca', city: 'San Pedro Mártir Yucuxaco' },
    { state: 'Oaxaca', city: 'San Pedro Mixtepec -Distrito 22-' },
    { state: 'Oaxaca', city: 'San Pedro Mixtepec -Distrito 26-' },
    { state: 'Oaxaca', city: 'San Pedro Molinos' },
    { state: 'Oaxaca', city: 'San Pedro Nopala ' },
    { state: 'Oaxaca', city: 'San Pedro Ocopetatillo' },
    { state: 'Oaxaca', city: 'San Pedro Ocotepec' },
    { state: 'Oaxaca', city: 'San Pedro Pochutla' },
    { state: 'Oaxaca', city: 'San Pedro Quiatoni' },
    { state: 'Oaxaca', city: 'San Pedro Sochiapam' },
    { state: 'Oaxaca', city: 'San Pedro Tapanatepec' },
    { state: 'Oaxaca', city: 'San Pedro Taviche' },
    { state: 'Oaxaca', city: 'San Pedro Teozacoalco' },
    { state: 'Oaxaca', city: 'San Pedro Teutila' },
    { state: 'Oaxaca', city: 'San Pedro Tidaá' },
    { state: 'Oaxaca', city: 'San Pedro Topiltepec' },
    { state: 'Oaxaca', city: 'San Pedro Totolapa' },
    { state: 'Oaxaca', city: 'San Pedro y San Pablo Ayutla' },
    { state: 'Oaxaca', city: 'San Pedro y San Pablo Teposcolula' },
    { state: 'Oaxaca', city: 'San Pedro y San Pablo Tequixtepec' },
    { state: 'Oaxaca', city: 'San Pedro Yaneri' },
    { state: 'Oaxaca', city: 'San Pedro Yólox' },
    { state: 'Oaxaca', city: 'San Pedro Yucunama' },
    { state: 'Oaxaca', city: 'San Raymundo Jalpan' },
    { state: 'Oaxaca', city: 'San Sebastián Abasolo' },
    { state: 'Oaxaca', city: 'San Sebastián Coatlán' },
    { state: 'Oaxaca', city: 'San Sebastián Ixcapa' },
    { state: 'Oaxaca', city: 'San Sebastián Nicananduta' },
    { state: 'Oaxaca', city: 'San Sebastián Río Hondo' },
    { state: 'Oaxaca', city: 'San Sebastián Tecomaxtlahuaca' },
    { state: 'Oaxaca', city: 'San Sebastián Teitipac' },
    { state: 'Oaxaca', city: 'San Sebastián Tutla' },
    { state: 'Oaxaca', city: 'San Simón Almolongas' },
    { state: 'Oaxaca', city: 'San Simón Zahuatlán' },
    { state: 'Oaxaca', city: 'San Vicente Coatlán' },
    { state: 'Oaxaca', city: 'San Vicente Lachixío' },
    { state: 'Oaxaca', city: 'San Vicente Nuñú' },
    { state: 'Oaxaca', city: 'Santa Ana' },
    { state: 'Oaxaca', city: 'Santa Ana Ateixtlahuaca' },
    { state: 'Oaxaca', city: 'Santa Ana Cuauhtémoc' },
    { state: 'Oaxaca', city: 'Santa Ana del Valle' },
    { state: 'Oaxaca', city: 'Santa Ana Tavela' },
    { state: 'Oaxaca', city: 'Santa Ana Tlapacoyan' },
    { state: 'Oaxaca', city: 'Santa Ana Yareni' },
    { state: 'Oaxaca', city: 'Santa Ana Zegache' },
    { state: 'Oaxaca', city: 'Santa Catalina Quieri' },
    { state: 'Oaxaca', city: 'Santa Catarina Cuixtla' },
    { state: 'Oaxaca', city: 'Santa Catarina Ixtepeji' },
    { state: 'Oaxaca', city: 'Santa Catarina Juquila' },
    { state: 'Oaxaca', city: 'Santa Catarina Lachatao' },
    { state: 'Oaxaca', city: 'Santa Catarina Loxicha' },
    { state: 'Oaxaca', city: 'Santa Catarina Mechoacán' },
    { state: 'Oaxaca', city: 'Santa Catarina Minas' },
    { state: 'Oaxaca', city: 'Santa Catarina Quiané' },
    { state: 'Oaxaca', city: 'Santa Catarina Quioquitani' },
    { state: 'Oaxaca', city: 'Santa Catarina Tayata' },
    { state: 'Oaxaca', city: 'Santa Catarina Ticuá' },
    { state: 'Oaxaca', city: 'Santa Catarina Yosonotú' },
    { state: 'Oaxaca', city: 'Santa Catarina Zapoquila' },
    { state: 'Oaxaca', city: 'Santa Cruz Acatepec' },
    { state: 'Oaxaca', city: 'Santa Cruz Amilpas' },
    { state: 'Oaxaca', city: 'Santa Cruz de Bravo' },
    { state: 'Oaxaca', city: 'Santa Cruz Itundujia' },
    { state: 'Oaxaca', city: 'Santa Cruz Mixtepec' },
    { state: 'Oaxaca', city: 'Santa Cruz Nundaco' },
    { state: 'Oaxaca', city: 'Santa Cruz Papalutla' },
    { state: 'Oaxaca', city: 'Santa Cruz Tacache de Mina' },
    { state: 'Oaxaca', city: 'Santa Cruz Tacahua' },
    { state: 'Oaxaca', city: 'Santa Cruz Tayata' },
    { state: 'Oaxaca', city: 'Santa Cruz Xitla' },
    { state: 'Oaxaca', city: 'Santa Cruz Xoxocotlán' },
    { state: 'Oaxaca', city: 'Santa Cruz Zenzontepec' },
    { state: 'Oaxaca', city: 'Santa Gertrudis' },
    { state: 'Oaxaca', city: 'Santa Inés de Zaragoza' },
    { state: 'Oaxaca', city: 'Santa Inés del Monte' },
    { state: 'Oaxaca', city: 'Santa Inés Yatzeche' },
    { state: 'Oaxaca', city: 'Santa Lucía del Camino' },
    { state: 'Oaxaca', city: 'Santa Lucía Miahuatlán' },
    { state: 'Oaxaca', city: 'Santa Lucía Monteverde' },
    { state: 'Oaxaca', city: 'Santa Lucía Ocotlán' },
    { state: 'Oaxaca', city: 'Santa Magdalena Jicotlán' },
    { state: 'Oaxaca', city: 'Santa María Alotepec' },
    { state: 'Oaxaca', city: 'Santa María Apazco' },
    { state: 'Oaxaca', city: 'Santa María Atzompa' },
    { state: 'Oaxaca', city: 'Santa María Camotlán' },
    { state: 'Oaxaca', city: 'Santa María Chachoápam' },
    { state: 'Oaxaca', city: 'Santa María Chilchotla' },
    { state: 'Oaxaca', city: 'Santa María Chimalapa' },
    { state: 'Oaxaca', city: 'Santa María Colotepec' },
    { state: 'Oaxaca', city: 'Santa María Cortijo' },
    { state: 'Oaxaca', city: 'Santa María Coyotepec' },
    { state: 'Oaxaca', city: 'Santa María del Rosario' },
    { state: 'Oaxaca', city: 'Santa María del Tule' },
    { state: 'Oaxaca', city: 'Santa María Ecatepec' },
    { state: 'Oaxaca', city: 'Santa María Guelacé' },
    { state: 'Oaxaca', city: 'Santa María Guienagati' },
    { state: 'Oaxaca', city: 'Santa María Huatulco' },
    { state: 'Oaxaca', city: 'Santa María Huazolotitlán' },
    { state: 'Oaxaca', city: 'Santa María Ipalapa' },
    { state: 'Oaxaca', city: 'Santa María Ixcatlán' },
    { state: 'Oaxaca', city: 'Santa María Jacatepec' },
    { state: 'Oaxaca', city: 'Santa María Jalapa del Marqués' },
    { state: 'Oaxaca', city: 'Santa María Jaltianguis' },
    { state: 'Oaxaca', city: 'Santa María la Asunción' },
    { state: 'Oaxaca', city: 'Santa María Lachixío' },
    { state: 'Oaxaca', city: 'Santa María Mixtequilla' },
    { state: 'Oaxaca', city: 'Santa María Nativitas' },
    { state: 'Oaxaca', city: 'Santa María Nduayaco' },
    { state: 'Oaxaca', city: 'Santa María Ozolotepec' },
    { state: 'Oaxaca', city: 'Santa María Pápalo' },
    { state: 'Oaxaca', city: 'Santa María Peñoles' },
    { state: 'Oaxaca', city: 'Santa María Petapa' },
    { state: 'Oaxaca', city: 'Santa María Quiegolani' },
    { state: 'Oaxaca', city: 'Santa María Sola' },
    { state: 'Oaxaca', city: 'Santa María Tataltepec' },
    { state: 'Oaxaca', city: 'Santa María Tecomavaca' },
    { state: 'Oaxaca', city: 'Santa María Temaxcalapa' },
    { state: 'Oaxaca', city: 'Santa María Temaxcaltepec' },
    { state: 'Oaxaca', city: 'Santa María Teopoxco' },
    { state: 'Oaxaca', city: 'Santa María Tepantlali' },
    { state: 'Oaxaca', city: 'Santa María Texcatitlán' },
    { state: 'Oaxaca', city: 'Santa María Tlahuitoltepec' },
    { state: 'Oaxaca', city: 'Santa María Tlalixtac' },
    { state: 'Oaxaca', city: 'Santa María Tonameca' },
    { state: 'Oaxaca', city: 'Santa María Totolapilla' },
    { state: 'Oaxaca', city: 'Santa María Xadani' },
    { state: 'Oaxaca', city: 'Santa María Yalina' },
    { state: 'Oaxaca', city: 'Santa María Yavesía' },
    { state: 'Oaxaca', city: 'Santa María Yolotepec' },
    { state: 'Oaxaca', city: 'Santa María Yosoyúa' },
    { state: 'Oaxaca', city: 'Santa María Yucuhiti' },
    { state: 'Oaxaca', city: 'Santa María Zacatepec' },
    { state: 'Oaxaca', city: 'Santa María Zaniza' },
    { state: 'Oaxaca', city: 'Santa María Zoquitlán' },
    { state: 'Oaxaca', city: 'Santiago Amoltepec' },
    { state: 'Oaxaca', city: 'Santiago Apoala' },
    { state: 'Oaxaca', city: 'Santiago Apóstol' },
    { state: 'Oaxaca', city: 'Santiago Astata' },
    { state: 'Oaxaca', city: 'Santiago Atitlán' },
    { state: 'Oaxaca', city: 'Santiago Ayuquililla' },
    { state: 'Oaxaca', city: 'Santiago Cacaloxtepec' },
    { state: 'Oaxaca', city: 'Santiago Camotlán' },
    { state: 'Oaxaca', city: 'Santiago Chazumba' },
    { state: 'Oaxaca', city: 'Santiago Choápam' },
    { state: 'Oaxaca', city: 'Santiago Comaltepec' },
    { state: 'Oaxaca', city: 'Santiago del Río' },
    { state: 'Oaxaca', city: 'Santiago Huajolotitlán' },
    { state: 'Oaxaca', city: 'Santiago Huauclilla' },
    { state: 'Oaxaca', city: 'Santiago Ihuitlán Plumas' },
    { state: 'Oaxaca', city: 'Santiago Ixcuintepec' },
    { state: 'Oaxaca', city: 'Santiago Ixtayutla' },
    { state: 'Oaxaca', city: 'Santiago Jamiltepec' },
    { state: 'Oaxaca', city: 'Santiago Jocotepec' },
    { state: 'Oaxaca', city: 'Santiago Juxtlahuaca' },
    { state: 'Oaxaca', city: 'Santiago Lachiguiri' },
    { state: 'Oaxaca', city: 'Santiago Lalopa' },
    { state: 'Oaxaca', city: 'Santiago Laollaga' },
    { state: 'Oaxaca', city: 'Santiago Laxopa' },
    { state: 'Oaxaca', city: 'Santiago Llano Grande' },
    { state: 'Oaxaca', city: 'Santiago Matatlán' },
    { state: 'Oaxaca', city: 'Santiago Miltepec' },
    { state: 'Oaxaca', city: 'Santiago Minas' },
    { state: 'Oaxaca', city: 'Santiago Nacaltepec' },
    { state: 'Oaxaca', city: 'Santiago Nejapilla' },
    { state: 'Oaxaca', city: 'Santiago Niltepec' },
    { state: 'Oaxaca', city: 'Santiago Nundiche' },
    { state: 'Oaxaca', city: 'Santiago Nuyoó' },
    { state: 'Oaxaca', city: 'Santiago Pinotepa Nacional' },
    { state: 'Oaxaca', city: 'Santiago Suchilquitongo' },
    { state: 'Oaxaca', city: 'Santiago Tamazola' },
    { state: 'Oaxaca', city: 'Santiago Tapextla' },
    { state: 'Oaxaca', city: 'Santiago Tenango' },
    { state: 'Oaxaca', city: 'Santiago Tepetlapa' },
    { state: 'Oaxaca', city: 'Santiago Tetepec' },
    { state: 'Oaxaca', city: 'Santiago Texcalcingo' },
    { state: 'Oaxaca', city: 'Santiago Textitlán' },
    { state: 'Oaxaca', city: 'Santiago Tilantongo' },
    { state: 'Oaxaca', city: 'Santiago Tillo' },
    { state: 'Oaxaca', city: 'Santiago Tlazoyaltepec' },
    { state: 'Oaxaca', city: 'Santiago Xanica' },
    { state: 'Oaxaca', city: 'Santiago Xiacuí' },
    { state: 'Oaxaca', city: 'Santiago Yaitepec' },
    { state: 'Oaxaca', city: 'Santiago Yaveo' },
    { state: 'Oaxaca', city: 'Santiago Yolomécatl' },
    { state: 'Oaxaca', city: 'Santiago Yosondúa' },
    { state: 'Oaxaca', city: 'Santiago Yucuyachi' },
    { state: 'Oaxaca', city: 'Santiago Zacatepec' },
    { state: 'Oaxaca', city: 'Santiago Zoochila' },
    { state: 'Oaxaca', city: 'Santo Domingo Albarradas' },
    { state: 'Oaxaca', city: 'Santo Domingo Armenta' },
    { state: 'Oaxaca', city: 'Santo Domingo Chihuitán' },
    { state: 'Oaxaca', city: 'Santo Domingo de Morelos' },
    { state: 'Oaxaca', city: 'Santo Domingo Ingenio' },
    { state: 'Oaxaca', city: 'Santo Domingo Ixcatlán' },
    { state: 'Oaxaca', city: 'Santo Domingo Nuxaá' },
    { state: 'Oaxaca', city: 'Santo Domingo Ozolotepec' },
    { state: 'Oaxaca', city: 'Santo Domingo Petapa' },
    { state: 'Oaxaca', city: 'Santo Domingo Roayaga' },
    { state: 'Oaxaca', city: 'Santo Domingo Tehuantepec' },
    { state: 'Oaxaca', city: 'Santo Domingo Teojomulco' },
    { state: 'Oaxaca', city: 'Santo Domingo Tepuxtepec' },
    { state: 'Oaxaca', city: 'Santo Domingo Tlatayapam' },
    { state: 'Oaxaca', city: 'Santo Domingo Tomaltepec' },
    { state: 'Oaxaca', city: 'Santo Domingo Tonalá' },
    { state: 'Oaxaca', city: 'Santo Domingo Tonaltepec' },
    { state: 'Oaxaca', city: 'Santo Domingo Xagacía' },
    { state: 'Oaxaca', city: 'Santo Domingo Yanhuitlán' },
    { state: 'Oaxaca', city: 'Santo Domingo Yodohino' },
    { state: 'Oaxaca', city: 'Santo Domingo Zanatepec' },
    { state: 'Oaxaca', city: 'Santo Tomás Jalieza' },
    { state: 'Oaxaca', city: 'Santo Tomás Mazaltepec' },
    { state: 'Oaxaca', city: 'Santo Tomás Ocotepec' },
    { state: 'Oaxaca', city: 'Santo Tomás Tamazulapan' },
    { state: 'Oaxaca', city: 'Santos Reyes Nopala' },
    { state: 'Oaxaca', city: 'Santos Reyes Pápalo' },
    { state: 'Oaxaca', city: 'Santos Reyes Tepejillo' },
    { state: 'Oaxaca', city: 'Santos Reyes Yucuná' },
    { state: 'Oaxaca', city: 'Silacayoápam' },
    { state: 'Oaxaca', city: 'Sitio de Xitlapehua' },
    { state: 'Oaxaca', city: 'Soledad Etla' },
    { state: 'Oaxaca', city: 'Tamazulápam del Espíritu Santo' },
    { state: 'Oaxaca', city: 'Tanetze de Zaragoza' },
    { state: 'Oaxaca', city: 'Taniche' },
    { state: 'Oaxaca', city: 'Tataltepec de Valdés' },
    { state: 'Oaxaca', city: 'Teococuilco de Marcos Pérez' },
    { state: 'Oaxaca', city: 'Teotitlán de Flores Magón' },
    { state: 'Oaxaca', city: 'Teotitlán del Valle' },
    { state: 'Oaxaca', city: 'Teotongo' },
    { state: 'Oaxaca', city: 'Tepelmeme Villa de Morelos' },
    { state: 'Oaxaca', city: 'Tezoatlán de Segura y Luna' },
    { state: 'Oaxaca', city: 'Tlacolula de Matamoros' },
    { state: 'Oaxaca', city: 'Tlacotepec Plumas' },
    { state: 'Oaxaca', city: 'Tlalixtac de Cabrera' },
    { state: 'Oaxaca', city: 'Totontepec Villa de Morelos' },
    { state: 'Oaxaca', city: 'Trinidad Zaachila' },
    { state: 'Oaxaca', city: 'Unión Hidalgo' },
    { state: 'Oaxaca', city: 'Valerio Trujano' },
    { state: 'Oaxaca', city: 'Villa de Chilapa de Díaz' },
    { state: 'Oaxaca', city: 'Villa de Etla' },
    { state: 'Oaxaca', city: 'Villa de Tamazulapam del Progreso' },
    { state: 'Oaxaca', city: 'Villa de Tututepec de Melchor Ocampo' },
    { state: 'Oaxaca', city: 'Villa de Zaachila' },
    { state: 'Oaxaca', city: 'Villa Díaz Ordaz' },
    { state: 'Oaxaca', city: 'Villa Hidalgo' },
    { state: 'Oaxaca', city: 'Villa Sola de Vega' },
    { state: 'Oaxaca', city: 'Villa Talea de Castro' },
    { state: 'Oaxaca', city: 'Villa Tejúpam de la Unión' },
    { state: 'Oaxaca', city: 'Yaxe' },
    { state: 'Oaxaca', city: 'Yogana' },
    { state: 'Oaxaca', city: 'Yutanduchi de Guerrero' },
    { state: 'Oaxaca', city: 'Zapotitlán Lagunas' },
    { state: 'Oaxaca', city: 'Zapotitlán Palmas' },
    { state: 'Oaxaca', city: 'Zimatlán de Álvarez' },
    { state: 'Puebla', city: 'Acajete ' },
    { state: 'Puebla', city: 'Acateno ' },
    { state: 'Puebla', city: 'Acatlán ' },
    { state: 'Puebla', city: 'Acatzingo ' },
    { state: 'Puebla', city: 'Acteopan ' },
    { state: 'Puebla', city: 'Ahuacatlán ' },
    { state: 'Puebla', city: 'Ahuatlán ' },
    { state: 'Puebla', city: 'Ahuazotepec ' },
    { state: 'Puebla', city: 'Ahuehuetitla ' },
    { state: 'Puebla', city: 'Ajalpan ' },
    { state: 'Puebla', city: 'Albino Zertuche ' },
    { state: 'Puebla', city: 'Aljojuca ' },
    { state: 'Puebla', city: 'Altepexi ' },
    { state: 'Puebla', city: 'Amixtlán ' },
    { state: 'Puebla', city: 'Amozoc ' },
    { state: 'Puebla', city: 'Aquixtla ' },
    { state: 'Puebla', city: 'Atempan ' },
    { state: 'Puebla', city: 'Atexcal ' },
    { state: 'Puebla', city: 'Atlequizayan ' },
    { state: 'Puebla', city: 'Atlixco ' },
    { state: 'Puebla', city: 'Atoyatempan ' },
    { state: 'Puebla', city: 'Atzala ' },
    { state: 'Puebla', city: 'Atzitzihuacán ' },
    { state: 'Puebla', city: 'Atzitzintla ' },
    { state: 'Puebla', city: 'Axutla ' },
    { state: 'Puebla', city: 'Ayotoxco de Guerrero ' },
    { state: 'Puebla', city: 'Calpan ' },
    { state: 'Puebla', city: 'Caltepec ' },
    { state: 'Puebla', city: 'Camocuautla ' },
    { state: 'Puebla', city: 'Cañada Morelos ' },
    { state: 'Puebla', city: 'Caxhuacán ' },
    { state: 'Puebla', city: 'Chalchicomula de Sesma ' },
    { state: 'Puebla', city: 'Chapulco ' },
    { state: 'Puebla', city: 'Chiautla ' },
    { state: 'Puebla', city: 'Chiautzingo ' },
    { state: 'Puebla', city: 'Chichiquila ' },
    { state: 'Puebla', city: 'Chiconcuautla ' },
    { state: 'Puebla', city: 'Chietla ' },
    { state: 'Puebla', city: 'Chigmecatitlán ' },
    { state: 'Puebla', city: 'Chignahuapan ' },
    { state: 'Puebla', city: 'Chignautla ' },
    { state: 'Puebla', city: 'Chila ' },
    { state: 'Puebla', city: 'Chila de la Sal ' },
    { state: 'Puebla', city: 'Chilchotla ' },
    { state: 'Puebla', city: 'Chinantla ' },
    { state: 'Puebla', city: 'Coatepec ' },
    { state: 'Puebla', city: 'Coatzingo ' },
    { state: 'Puebla', city: 'Cohetzala ' },
    { state: 'Puebla', city: 'Cohuecan ' },
    { state: 'Puebla', city: 'Coronango ' },
    { state: 'Puebla', city: 'Coxcatlán ' },
    { state: 'Puebla', city: 'Coyomeapan ' },
    { state: 'Puebla', city: 'Coyotepec ' },
    { state: 'Puebla', city: 'Cuapiaxtla de Madero ' },
    { state: 'Puebla', city: 'Cuautempan ' },
    { state: 'Puebla', city: 'Cuautinchán ' },
    { state: 'Puebla', city: 'Cuautlancingo ' },
    { state: 'Puebla', city: 'Cuayuca de Andrade ' },
    { state: 'Puebla', city: 'Cuetzalan del Progreso ' },
    { state: 'Puebla', city: 'Cuyoaco ' },
    { state: 'Puebla', city: 'Domingo Arenas ' },
    { state: 'Puebla', city: 'Eloxochitlán ' },
    { state: 'Puebla', city: 'Epatlán ' },
    { state: 'Puebla', city: 'Esperanza ' },
    { state: 'Puebla', city: 'Francisco Z. Mena ' },
    { state: 'Puebla', city: 'General Felipe Ángeles ' },
    { state: 'Puebla', city: 'Guadalupe ' },
    { state: 'Puebla', city: 'Guadalupe Victoria ' },
    { state: 'Puebla', city: 'Hermenegildo Galeana ' },
    { state: 'Puebla', city: 'Honey ' },
    { state: 'Puebla', city: 'Huaquechula ' },
    { state: 'Puebla', city: 'Huatlatlauca ' },
    { state: 'Puebla', city: 'Huauchinango ' },
    { state: 'Puebla', city: 'Huehuetla ' },
    { state: 'Puebla', city: 'Huehuetlán el Chico ' },
    { state: 'Puebla', city: 'Huehuetlán el Grande ' },
    { state: 'Puebla', city: 'Huejotzingo ' },
    { state: 'Puebla', city: 'Hueyapan ' },
    { state: 'Puebla', city: 'Hueytamalco ' },
    { state: 'Puebla', city: 'Hueytlalpan ' },
    { state: 'Puebla', city: 'Huitzilan de Serdán ' },
    { state: 'Puebla', city: 'Huitziltepec ' },
    { state: 'Puebla', city: 'Ixcamilpa de Guerrero ' },
    { state: 'Puebla', city: 'Ixcaquixtla ' },
    { state: 'Puebla', city: 'Ixtacamaxtitlán ' },
    { state: 'Puebla', city: 'Ixtepec ' },
    { state: 'Puebla', city: 'Izúcar de Matamoros ' },
    { state: 'Puebla', city: 'Jalpan ' },
    { state: 'Puebla', city: 'Jolalpan ' },
    { state: 'Puebla', city: 'Jonotla ' },
    { state: 'Puebla', city: 'Jopala ' },
    { state: 'Puebla', city: 'Juan C. Bonilla ' },
    { state: 'Puebla', city: 'Juan Galindo ' },
    { state: 'Puebla', city: 'Juan N. Méndez ' },
    { state: 'Puebla', city: 'La Magdalena Tlatlauquitepec ' },
    { state: 'Puebla', city: 'Lafragua ' },
    { state: 'Puebla', city: 'Libres ' },
    { state: 'Puebla', city: 'Los Reyes de Juárez ' },
    { state: 'Puebla', city: 'Mazapiltepec de Juárez ' },
    { state: 'Puebla', city: 'Mixtla ' },
    { state: 'Puebla', city: 'Molcaxac ' },
    { state: 'Puebla', city: 'Naupan ' },
    { state: 'Puebla', city: 'Nauzontla ' },
    { state: 'Puebla', city: 'Nealtican ' },
    { state: 'Puebla', city: 'Nicolás Bravo ' },
    { state: 'Puebla', city: 'Nopalucan ' },
    { state: 'Puebla', city: 'Ocotepec ' },
    { state: 'Puebla', city: 'Ocoyucan ' },
    { state: 'Puebla', city: 'Olintla ' },
    { state: 'Puebla', city: 'Oriental ' },
    { state: 'Puebla', city: 'Pahuatlán ' },
    { state: 'Puebla', city: 'Palmar de Bravo ' },
    { state: 'Puebla', city: 'Pantepec ' },
    { state: 'Puebla', city: 'Petlalcingo ' },
    { state: 'Puebla', city: 'Piaxtla ' },
    { state: 'Puebla', city: 'Puebla ' },
    { state: 'Puebla', city: 'Quecholac ' },
    { state: 'Puebla', city: 'Quimixtlán ' },
    { state: 'Puebla', city: 'Rafael Lara Grajales ' },
    { state: 'Puebla', city: 'San Andrés Cholula ' },
    { state: 'Puebla', city: 'San Antonio Cañada ' },
    { state: 'Puebla', city: 'San Diego La Mesa Tochimiltzingo ' },
    { state: 'Puebla', city: 'San Felipe Teotlalcingo ' },
    { state: 'Puebla', city: 'San Felipe Tepatlán ' },
    { state: 'Puebla', city: 'San Gabriel Chilac ' },
    { state: 'Puebla', city: 'San Gregorio Atzompa ' },
    { state: 'Puebla', city: 'San Jerónimo Tecuanipan ' },
    { state: 'Puebla', city: 'San Jerónimo Xayacatlán ' },
    { state: 'Puebla', city: 'San José Chiapa ' },
    { state: 'Puebla', city: 'San José Miahuatlán ' },
    { state: 'Puebla', city: 'San Juan Atenco ' },
    { state: 'Puebla', city: 'San Juan Atzompa ' },
    { state: 'Puebla', city: 'San Martín Texmelucan ' },
    { state: 'Puebla', city: 'San Martín Totoltepec ' },
    { state: 'Puebla', city: 'San Matías Tlalancaleca ' },
    { state: 'Puebla', city: 'San Miguel Ixitlán ' },
    { state: 'Puebla', city: 'San Miguel Xoxtla ' },
    { state: 'Puebla', city: 'San Nicolás Buenos Aires ' },
    { state: 'Puebla', city: 'San Nicolás de los Ranchos ' },
    { state: 'Puebla', city: 'San Pablo Anicano ' },
    { state: 'Puebla', city: 'San Pedro Cholula ' },
    { state: 'Puebla', city: 'San Pedro Yeloixtlahuaca ' },
    { state: 'Puebla', city: 'San Salvador el Seco ' },
    { state: 'Puebla', city: 'San Salvador el Verde ' },
    { state: 'Puebla', city: 'San Salvador Huixcolotla ' },
    { state: 'Puebla', city: 'San Sebastián Tlacotepec ' },
    { state: 'Puebla', city: 'Santa Catarina Tlaltempan ' },
    { state: 'Puebla', city: 'Santa Inés Ahuatempan ' },
    { state: 'Puebla', city: 'Santa Isabel Cholula ' },
    { state: 'Puebla', city: 'Santiago Miahuatlán ' },
    { state: 'Puebla', city: 'Santo Tomás Hueyotlipan ' },
    { state: 'Puebla', city: 'Soltepec ' },
    { state: 'Puebla', city: 'Tecali de Herrera ' },
    { state: 'Puebla', city: 'Tecamachalco ' },
    { state: 'Puebla', city: 'Tecomatlán ' },
    { state: 'Puebla', city: 'Tehuacán ' },
    { state: 'Puebla', city: 'Tehuitzingo ' },
    { state: 'Puebla', city: 'Tenampulco ' },
    { state: 'Puebla', city: 'Teopantlán ' },
    { state: 'Puebla', city: 'Teotlalco ' },
    { state: 'Puebla', city: 'Tepanco de López ' },
    { state: 'Puebla', city: 'Tepango de Rodríguez ' },
    { state: 'Puebla', city: 'Tepatlaxco de Hidalgo ' },
    { state: 'Puebla', city: 'Tepeaca ' },
    { state: 'Puebla', city: 'Tepemaxalco ' },
    { state: 'Puebla', city: 'Tepeojuma ' },
    { state: 'Puebla', city: 'Tepetzintla ' },
    { state: 'Puebla', city: 'Tepexco ' },
    { state: 'Puebla', city: 'Tepexi de Rodríguez ' },
    { state: 'Puebla', city: 'Tepeyahualco ' },
    { state: 'Puebla', city: 'Tepeyahualco de Cuauhtémoc ' },
    { state: 'Puebla', city: 'Tetela de Ocampo ' },
    { state: 'Puebla', city: 'Teteles de Ávila Castillo ' },
    { state: 'Puebla', city: 'Teziutlán ' },
    { state: 'Puebla', city: 'Tianguismanalco ' },
    { state: 'Puebla', city: 'Tilapa ' },
    { state: 'Puebla', city: 'Tlachichuca ' },
    { state: 'Puebla', city: 'Tlacotepec de Benito Juárez ' },
    { state: 'Puebla', city: 'Tlacuilotepec ' },
    { state: 'Puebla', city: 'Tlahuapan ' },
    { state: 'Puebla', city: 'Tlaltenango ' },
    { state: 'Puebla', city: 'Tlanepantla ' },
    { state: 'Puebla', city: 'Tlaola ' },
    { state: 'Puebla', city: 'Tlapacoya ' },
    { state: 'Puebla', city: 'Tlapanalá ' },
    { state: 'Puebla', city: 'Tlatlauquitepec ' },
    { state: 'Puebla', city: 'Tlaxco ' },
    { state: 'Puebla', city: 'Tochimilco ' },
    { state: 'Puebla', city: 'Tochtepec ' },
    { state: 'Puebla', city: 'Totoltepec de Guerrero ' },
    { state: 'Puebla', city: 'Tulcingo ' },
    { state: 'Puebla', city: 'Tuzamapan de Galeana ' },
    { state: 'Puebla', city: 'Tzicatlacoyan ' },
    { state: 'Puebla', city: 'Venustiano Carranza ' },
    { state: 'Puebla', city: 'Vicente Guerrero ' },
    { state: 'Puebla', city: 'Xayacatlán de Bravo ' },
    { state: 'Puebla', city: 'Xicotepec ' },
    { state: 'Puebla', city: 'Xicotlán ' },
    { state: 'Puebla', city: 'Xiutetelco ' },
    { state: 'Puebla', city: 'Xochiapulco ' },
    { state: 'Puebla', city: 'Xochiltepec ' },
    { state: 'Puebla', city: 'Xochitlán de Vicente Suárez ' },
    { state: 'Puebla', city: 'Xochitlán Todos Santos ' },
    { state: 'Puebla', city: 'Yaonáhuac ' },
    { state: 'Puebla', city: 'Yehualtepec ' },
    { state: 'Puebla', city: 'Zacapala ' },
    { state: 'Puebla', city: 'Zacapoaxtla ' },
    { state: 'Puebla', city: 'Zacatlán ' },
    { state: 'Puebla', city: 'Zapotitlán ' },
    { state: 'Puebla', city: 'Zapotitlán de Méndez ' },
    { state: 'Puebla', city: 'Zaragoza ' },
    { state: 'Puebla', city: 'Zautla ' },
    { state: 'Puebla', city: 'Zihuateutla ' },
    { state: 'Puebla', city: 'Zinacatepec ' },
    { state: 'Puebla', city: 'Zongozotla ' },
    { state: 'Puebla', city: 'Zoquiapan ' },
    { state: 'Puebla', city: 'Zoquitlán ' },
    { state: 'Querétaro', city: 'Amealco de Bonfil ' },
    { state: 'Querétaro', city: 'Arroyo Seco ' },
    { state: 'Querétaro', city: 'Cadereyta de Montes ' },
    { state: 'Querétaro', city: 'Colón ' },
    { state: 'Querétaro', city: 'Corregidora ' },
    { state: 'Querétaro', city: 'El Marqués ' },
    { state: 'Querétaro', city: 'Ezequiel Montes ' },
    { state: 'Querétaro', city: 'Huimilpan ' },
    { state: 'Querétaro', city: 'Jalpan de Serra ' },
    { state: 'Querétaro', city: 'Landa de Matamoros ' },
    { state: 'Querétaro', city: 'Pedro Escobedo ' },
    { state: 'Querétaro', city: 'Peñamiller ' },
    { state: 'Querétaro', city: 'Pinal de Amoles ' },
    { state: 'Querétaro', city: 'Querétaro ' },
    { state: 'Querétaro', city: 'San Joaquín ' },
    { state: 'Querétaro', city: 'San Juan del Río ' },
    { state: 'Querétaro', city: 'Tequisquiapan ' },
    { state: 'Querétaro', city: 'Tolimán ' },
    { state: 'Quintana Roo', city: 'Bacalar ' },
    { state: 'Quintana Roo', city: 'Benito Juárez ' },
    { state: 'Quintana Roo', city: 'Cozumel ' },
    { state: 'Quintana Roo', city: 'Felipe Carrillo Puerto ' },
    { state: 'Quintana Roo', city: 'Isla Mujeres ' },
    { state: 'Quintana Roo', city: 'José María Morelos ' },
    { state: 'Quintana Roo', city: 'Lázaro Cárdenas ' },
    { state: 'Quintana Roo', city: 'Othón P. Blanco ' },
    { state: 'Quintana Roo', city: 'Puerto Morelos ' },
    { state: 'Quintana Roo', city: 'Solidaridad ' },
    { state: 'Quintana Roo', city: 'Tulum ' },
    { state: 'San Luis Potosí', city: 'Ahualulco ' },
    { state: 'San Luis Potosí', city: 'Alaquines ' },
    { state: 'San Luis Potosí', city: 'Aquismón ' },
    { state: 'San Luis Potosí', city: 'Armadillo de los Infante ' },
    { state: 'San Luis Potosí', city: 'Axtla de Terrazas ' },
    { state: 'San Luis Potosí', city: 'Cárdenas ' },
    { state: 'San Luis Potosí', city: 'Catorce ' },
    { state: 'San Luis Potosí', city: 'Cedral ' },
    { state: 'San Luis Potosí', city: 'Cerritos ' },
    { state: 'San Luis Potosí', city: 'Cerro de San Pedro ' },
    { state: 'San Luis Potosí', city: 'Charcas ' },
    { state: 'San Luis Potosí', city: 'Ciudad del Maíz ' },
    { state: 'San Luis Potosí', city: 'Ciudad Fernández ' },
    { state: 'San Luis Potosí', city: 'Ciudad Valles ' },
    { state: 'San Luis Potosí', city: 'Coxcatlán ' },
    { state: 'San Luis Potosí', city: 'Ébano ' },
    { state: 'San Luis Potosí', city: 'El Naranjo ' },
    { state: 'San Luis Potosí', city: 'Guadalcázar ' },
    { state: 'San Luis Potosí', city: 'Huehuetlán ' },
    { state: 'San Luis Potosí', city: 'Lagunillas ' },
    { state: 'San Luis Potosí', city: 'Matehuala ' },
    { state: 'San Luis Potosí', city: 'Matlapa ' },
    { state: 'San Luis Potosí', city: 'Mexquitic de Carmona ' },
    { state: 'San Luis Potosí', city: 'Moctezuma ' },
    { state: 'San Luis Potosí', city: 'Rayón ' },
    { state: 'San Luis Potosí', city: 'Rioverde ' },
    { state: 'San Luis Potosí', city: 'Salinas ' },
    { state: 'San Luis Potosí', city: 'San Antonio ' },
    { state: 'San Luis Potosí', city: 'San Ciro de Acosta ' },
    { state: 'San Luis Potosí', city: 'San Luis Potosí ' },
    { state: 'San Luis Potosí', city: 'San Martín Chalchicuautla ' },
    { state: 'San Luis Potosí', city: 'San Nicolás Tolentino ' },
    { state: 'San Luis Potosí', city: 'San Vicente Tancuayalab ' },
    { state: 'San Luis Potosí', city: 'Santa Catarina ' },
    { state: 'San Luis Potosí', city: 'Santa María del Río ' },
    { state: 'San Luis Potosí', city: 'Santo Domingo ' },
    { state: 'San Luis Potosí', city: 'Soledad de Graciano Sánchez ' },
    { state: 'San Luis Potosí', city: 'Tamasopo ' },
    { state: 'San Luis Potosí', city: 'Tamazunchale ' },
    { state: 'San Luis Potosí', city: 'Tampacán ' },
    { state: 'San Luis Potosí', city: 'Tampamolón Corona ' },
    { state: 'San Luis Potosí', city: 'Tamuín ' },
    { state: 'San Luis Potosí', city: 'Tancanhuitz ' },
    { state: 'San Luis Potosí', city: 'Tanlajás ' },
    { state: 'San Luis Potosí', city: 'Tanquián de Escobedo ' },
    { state: 'San Luis Potosí', city: 'Tierra Nueva ' },
    { state: 'San Luis Potosí', city: 'Vanegas ' },
    { state: 'San Luis Potosí', city: 'Venado ' },
    { state: 'San Luis Potosí', city: 'Villa de Arista ' },
    { state: 'San Luis Potosí', city: 'Villa de Arriaga ' },
    { state: 'San Luis Potosí', city: 'Villa de Guadalupe ' },
    { state: 'San Luis Potosí', city: 'Villa de la Paz ' },
    { state: 'San Luis Potosí', city: 'Villa de Ramos ' },
    { state: 'San Luis Potosí', city: 'Villa de Reyes ' },
    { state: 'San Luis Potosí', city: 'Villa Hidalgo ' },
    { state: 'San Luis Potosí', city: 'Villa Juárez ' },
    { state: 'San Luis Potosí', city: 'Xilitla ' },
    { state: 'San Luis Potosí', city: 'Zaragoza ' },
    { state: 'Sinaloa', city: 'Ahome' },
    { state: 'Sinaloa', city: 'Angostura' },
    { state: 'Sinaloa', city: 'Badiraguato' },
    { state: 'Sinaloa', city: 'Choix' },
    { state: 'Sinaloa', city: 'Concordia' },
    { state: 'Sinaloa', city: 'Cosalá' },
    { state: 'Sinaloa', city: 'Culiacán' },
    { state: 'Sinaloa', city: 'El Fuerte' },
    { state: 'Sinaloa', city: 'El Rosario' },
    { state: 'Sinaloa', city: 'Elota' },
    { state: 'Sinaloa', city: 'Escuinapa' },
    { state: 'Sinaloa', city: 'Guasave' },
    { state: 'Sinaloa', city: 'Mazatlán' },
    { state: 'Sinaloa', city: 'Mocorito' },
    { state: 'Sinaloa', city: 'Navolato' },
    { state: 'Sinaloa', city: 'Salvador Alvarado' },
    { state: 'Sinaloa', city: 'San Ignacio' },
    { state: 'Sinaloa', city: 'Sinaloa' },
    { state: 'Sonora', city: 'Aconchi ' },
    { state: 'Sonora', city: 'Agua Prieta ' },
    { state: 'Sonora', city: 'Álamos ' },
    { state: 'Sonora', city: 'Altar ' },
    { state: 'Sonora', city: 'Arivechi ' },
    { state: 'Sonora', city: 'Arizpe ' },
    { state: 'Sonora', city: 'Átil ' },
    { state: 'Sonora', city: 'Bacadéhuachi ' },
    { state: 'Sonora', city: 'Bacanora ' },
    { state: 'Sonora', city: 'Bacerac ' },
    { state: 'Sonora', city: 'Bacoachi ' },
    { state: 'Sonora', city: 'Bácum ' },
    { state: 'Sonora', city: 'Banámichi ' },
    { state: 'Sonora', city: 'Baviácora ' },
    { state: 'Sonora', city: 'Bavispe ' },
    { state: 'Sonora', city: 'Benito Juárez ' },
    { state: 'Sonora', city: 'Benjamín Hill ' },
    { state: 'Sonora', city: 'Caborca ' },
    { state: 'Sonora', city: 'Cajeme ' },
    { state: 'Sonora', city: 'Cananea ' },
    { state: 'Sonora', city: 'Carbó ' },
    { state: 'Sonora', city: 'Cucurpe ' },
    { state: 'Sonora', city: 'Cumpas ' },
    { state: 'Sonora', city: 'Divisaderos ' },
    { state: 'Sonora', city: 'Empalme ' },
    { state: 'Sonora', city: 'Etchojoa ' },
    { state: 'Sonora', city: 'Fronteras ' },
    { state: 'Sonora', city: 'General Plutarco Elías Calles ' },
    { state: 'Sonora', city: 'Granados ' },
    { state: 'Sonora', city: 'Guaymas ' },
    { state: 'Sonora', city: 'Hermosillo ' },
    { state: 'Sonora', city: 'Huachinera ' },
    { state: 'Sonora', city: 'Huásabas ' },
    { state: 'Sonora', city: 'Huatabampo ' },
    { state: 'Sonora', city: 'Huépac ' },
    { state: 'Sonora', city: 'Ímuris ' },
    { state: 'Sonora', city: 'La Colorada ' },
    { state: 'Sonora', city: 'Magdalena ' },
    { state: 'Sonora', city: 'Mazatán ' },
    { state: 'Sonora', city: 'Moctezuma ' },
    { state: 'Sonora', city: 'Naco ' },
    { state: 'Sonora', city: 'Nácori Chico ' },
    { state: 'Sonora', city: 'Nacozari de García ' },
    { state: 'Sonora', city: 'Navojoa ' },
    { state: 'Sonora', city: 'Nogales ' },
    { state: 'Sonora', city: 'Ónavas ' },
    { state: 'Sonora', city: 'Opodepe ' },
    { state: 'Sonora', city: 'Oquitoa ' },
    { state: 'Sonora', city: 'Pitiquito ' },
    { state: 'Sonora', city: 'Puerto Peñasco ' },
    { state: 'Sonora', city: 'Quiriego ' },
    { state: 'Sonora', city: 'Rayón ' },
    { state: 'Sonora', city: 'Rosario ' },
    { state: 'Sonora', city: 'Sahuaripa ' },
    { state: 'Sonora', city: 'San Felipe de Jesús ' },
    { state: 'Sonora', city: 'San Ignacio Río Muerto ' },
    { state: 'Sonora', city: 'San Javier ' },
    { state: 'Sonora', city: 'San Luis Río Colorado ' },
    { state: 'Sonora', city: 'San Miguel de Horcasitas ' },
    { state: 'Sonora', city: 'San Pedro de la Cueva ' },
    { state: 'Sonora', city: 'Santa Ana ' },
    { state: 'Sonora', city: 'Santa Cruz ' },
    { state: 'Sonora', city: 'Sáric ' },
    { state: 'Sonora', city: 'Soyopa ' },
    { state: 'Sonora', city: 'Suaqui Grande ' },
    { state: 'Sonora', city: 'Tepache ' },
    { state: 'Sonora', city: 'Trincheras ' },
    { state: 'Sonora', city: 'Tubutama ' },
    { state: 'Sonora', city: 'Ures ' },
    { state: 'Sonora', city: 'Villa Hidalgo ' },
    { state: 'Sonora', city: 'Villa Pesqueira ' },
    { state: 'Sonora', city: 'Yécora ' },
    { state: 'Tabasco', city: 'Balancán ' },
    { state: 'Tabasco', city: 'Cárdenas ' },
    { state: 'Tabasco', city: 'Centla ' },
    { state: 'Tabasco', city: 'Centro ' },
    { state: 'Tabasco', city: 'Comalcalco ' },
    { state: 'Tabasco', city: 'Cunduacán ' },
    { state: 'Tabasco', city: 'Emiliano Zapata ' },
    { state: 'Tabasco', city: 'Huimanguillo ' },
    { state: 'Tabasco', city: 'Jalapa ' },
    { state: 'Tabasco', city: 'Jalpa de Méndez ' },
    { state: 'Tabasco', city: 'Jonuta ' },
    { state: 'Tabasco', city: 'Macuspana ' },
    { state: 'Tabasco', city: 'Nacajuca ' },
    { state: 'Tabasco', city: 'Paraíso ' },
    { state: 'Tabasco', city: 'Tacotalpa ' },
    { state: 'Tabasco', city: 'Teapa ' },
    { state: 'Tabasco', city: 'Tenosique ' },
    { state: 'Tamaulipas', city: 'Abasolo ' },
    { state: 'Tamaulipas', city: 'Aldama ' },
    { state: 'Tamaulipas', city: 'Altamira ' },
    { state: 'Tamaulipas', city: 'Antiguo Morelos ' },
    { state: 'Tamaulipas', city: 'Burgos ' },
    { state: 'Tamaulipas', city: 'Bustamante ' },
    { state: 'Tamaulipas', city: 'Camargo ' },
    { state: 'Tamaulipas', city: 'Casas ' },
    { state: 'Tamaulipas', city: 'Ciudad Madero ' },
    { state: 'Tamaulipas', city: 'Cruillas ' },
    { state: 'Tamaulipas', city: 'El Mante ' },
    { state: 'Tamaulipas', city: 'Gomez Farías ' },
    { state: 'Tamaulipas', city: 'González ' },
    { state: 'Tamaulipas', city: 'Güémez ' },
    { state: 'Tamaulipas', city: 'Guerrero ' },
    { state: 'Tamaulipas', city: 'Gustavo Díaz Ordaz ' },
    { state: 'Tamaulipas', city: 'Hidalgo ' },
    { state: 'Tamaulipas', city: 'Jaumave ' },
    { state: 'Tamaulipas', city: 'Jiménez ' },
    { state: 'Tamaulipas', city: 'Llera ' },
    { state: 'Tamaulipas', city: 'Mainero ' },
    { state: 'Tamaulipas', city: 'Matamoros ' },
    { state: 'Tamaulipas', city: 'Méndez ' },
    { state: 'Tamaulipas', city: 'Mier ' },
    { state: 'Tamaulipas', city: 'Miguel Alemán ' },
    { state: 'Tamaulipas', city: 'Miquihuana ' },
    { state: 'Tamaulipas', city: 'Nuevo Laredo ' },
    { state: 'Tamaulipas', city: 'Nuevo Morelos ' },
    { state: 'Tamaulipas', city: 'Ocampo ' },
    { state: 'Tamaulipas', city: 'Padilla ' },
    { state: 'Tamaulipas', city: 'Palmillas ' },
    { state: 'Tamaulipas', city: 'Reynosa ' },
    { state: 'Tamaulipas', city: 'Río Bravo ' },
    { state: 'Tamaulipas', city: 'San Carlos ' },
    { state: 'Tamaulipas', city: 'San Fernando ' },
    { state: 'Tamaulipas', city: 'San Nicolás ' },
    { state: 'Tamaulipas', city: 'Soto la Marina ' },
    { state: 'Tamaulipas', city: 'Tampico ' },
    { state: 'Tamaulipas', city: 'Tula ' },
    { state: 'Tamaulipas', city: 'Valle Hermoso ' },
    { state: 'Tamaulipas', city: 'Victoria ' },
    { state: 'Tamaulipas', city: 'Villagrán ' },
    { state: 'Tamaulipas', city: 'Xicoténcatl ' },
    { state: 'Tlaxcala', city: 'Acuamanala de Miguel Hidalgo ' },
    { state: 'Tlaxcala', city: 'Amaxac de Guerrero ' },
    { state: 'Tlaxcala', city: 'Apetatitlán de Antonio Carvajal ' },
    { state: 'Tlaxcala', city: 'Apizaco ' },
    { state: 'Tlaxcala', city: 'Atlangatepec ' },
    { state: 'Tlaxcala', city: 'Atltzayanca ' },
    { state: 'Tlaxcala', city: 'Benito Juárez ' },
    { state: 'Tlaxcala', city: 'Calpulalpan ' },
    { state: 'Tlaxcala', city: 'Chiautempan ' },
    { state: 'Tlaxcala', city: 'Contla de Juan Cuamatzi ' },
    { state: 'Tlaxcala', city: 'Cuapiaxtla ' },
    { state: 'Tlaxcala', city: 'Cuaxomulco ' },
    { state: 'Tlaxcala', city: 'El Carmen Tequexquitla ' },
    { state: 'Tlaxcala', city: 'Emiliano Zapata ' },
    { state: 'Tlaxcala', city: 'Españita ' },
    { state: 'Tlaxcala', city: 'Huamantla ' },
    { state: 'Tlaxcala', city: 'Hueyotlipan ' },
    { state: 'Tlaxcala', city: 'Ixtacuixtla de Mariano Matamoros ' },
    { state: 'Tlaxcala', city: 'Ixtenco ' },
    { state: 'Tlaxcala', city: 'La Magdalena Tlaltelulco ' },
    { state: 'Tlaxcala', city: 'Lázaro Cárdenas ' },
    { state: 'Tlaxcala', city: 'Mazatecochco de José María Morelos ' },
    { state: 'Tlaxcala', city: 'Muñoz de Domingo Arenas ' },
    { state: 'Tlaxcala', city: 'Nanacamilpa de Mariano Arista ' },
    { state: 'Tlaxcala', city: 'Natívitas ' },
    { state: 'Tlaxcala', city: 'Panotla ' },
    { state: 'Tlaxcala', city: 'Papalotla de Xicohténcatl ' },
    { state: 'Tlaxcala', city: 'San Damián Texoloc ' },
    { state: 'Tlaxcala', city: 'San Francisco Tetlanohcan ' },
    { state: 'Tlaxcala', city: 'San Jerónimo Zacualpan ' },
    { state: 'Tlaxcala', city: 'San José Teacalco ' },
    { state: 'Tlaxcala', city: 'San Juan Huactzinco ' },
    { state: 'Tlaxcala', city: 'San Lorenzo Axocomanitla ' },
    { state: 'Tlaxcala', city: 'San Lucas Tecopilco ' },
    { state: 'Tlaxcala', city: 'San Pablo del Monte ' },
    { state: 'Tlaxcala', city: 'Sanctórum de Lázaro Cárdenas ' },
    { state: 'Tlaxcala', city: 'Santa Ana Nopalucan ' },
    { state: 'Tlaxcala', city: 'Santa Apolonia Teacalco ' },
    { state: 'Tlaxcala', city: 'Santa Catarina Ayometla ' },
    { state: 'Tlaxcala', city: 'Santa Cruz Quilehtla ' },
    { state: 'Tlaxcala', city: 'Santa Cruz Tlaxcala ' },
    { state: 'Tlaxcala', city: 'Santa Isabel Xiloxoxtla ' },
    { state: 'Tlaxcala', city: 'Tenancingo ' },
    { state: 'Tlaxcala', city: 'Teolocholco ' },
    { state: 'Tlaxcala', city: 'Tepetitla de Lardizábal ' },
    { state: 'Tlaxcala', city: 'Tepeyanco ' },
    { state: 'Tlaxcala', city: 'Terrenate ' },
    { state: 'Tlaxcala', city: 'Tetla de la Solidaridad ' },
    { state: 'Tlaxcala', city: 'Tetlatlahuca ' },
    { state: 'Tlaxcala', city: 'Tlaxcala ' },
    { state: 'Tlaxcala', city: 'Tlaxco ' },
    { state: 'Tlaxcala', city: 'Tocatlán ' },
    { state: 'Tlaxcala', city: 'Totolac ' },
    { state: 'Tlaxcala', city: 'Tzompantepec ' },
    { state: 'Tlaxcala', city: 'Xalostoc ' },
    { state: 'Tlaxcala', city: 'Xaltocan ' },
    { state: 'Tlaxcala', city: 'Xicohtzinco ' },
    { state: 'Tlaxcala', city: 'Yauhquemecan ' },
    { state: 'Tlaxcala', city: 'Zacatelco ' },
    { state: 'Tlaxcala', city: 'Zitlaltépec de Trinidad Sánchez Santos ' },
    { state: 'Veracruz', city: 'Acajete' },
    { state: 'Veracruz', city: 'Acatlán' },
    { state: 'Veracruz', city: 'Acayucan' },
    { state: 'Veracruz', city: 'Actopan' },
    { state: 'Veracruz', city: 'Acula' },
    { state: 'Veracruz', city: 'Acultzingo' },
    { state: 'Veracruz', city: 'Agua Dulce' },
    { state: 'Veracruz', city: 'Álamo Temapache' },
    { state: 'Veracruz', city: 'Alpatláhuac' },
    { state: 'Veracruz', city: 'Alto Lucero de Gutiérrez Barrios' },
    { state: 'Veracruz', city: 'Altotonga' },
    { state: 'Veracruz', city: 'Alvarado' },
    { state: 'Veracruz', city: 'Amatitlán' },
    { state: 'Veracruz', city: 'Amatlán de los Reyes' },
    { state: 'Veracruz', city: 'Ángel R. Cabada' },
    { state: 'Veracruz', city: 'Apazapan' },
    { state: 'Veracruz', city: 'Aquila' },
    { state: 'Veracruz', city: 'Astacinga' },
    { state: 'Veracruz', city: 'Atlahuilco' },
    { state: 'Veracruz', city: 'Atoyac' },
    { state: 'Veracruz', city: 'Atzacan' },
    { state: 'Veracruz', city: 'Atzalan' },
    { state: 'Veracruz', city: 'Ayahualulco' },
    { state: 'Veracruz', city: 'Banderilla' },
    { state: 'Veracruz', city: 'Benito Juárez' },
    { state: 'Veracruz', city: 'Boca del Río' },
    { state: 'Veracruz', city: 'Calcahualco' },
    { state: 'Veracruz', city: 'Camarón de Tejeda' },
    { state: 'Veracruz', city: 'Camerino Z. Mendoza' },
    { state: 'Veracruz', city: 'Carlos A. Carrillo' },
    { state: 'Veracruz', city: 'Carrillo Puerto' },
    { state: 'Veracruz', city: 'Castillo de Teayo' },
    { state: 'Veracruz', city: 'Catemaco' },
    { state: 'Veracruz', city: 'Cazones de Herrera' },
    { state: 'Veracruz', city: 'Cerro Azul' },
    { state: 'Veracruz', city: 'Chacaltianguis' },
    { state: 'Veracruz', city: 'Chalma' },
    { state: 'Veracruz', city: 'Chiconamel' },
    { state: 'Veracruz', city: 'Chiconquiaco' },
    { state: 'Veracruz', city: 'Chicontepec' },
    { state: 'Veracruz', city: 'Chinameca' },
    { state: 'Veracruz', city: 'Chinampa de Gorostiza' },
    { state: 'Veracruz', city: 'Chocamán' },
    { state: 'Veracruz', city: 'Chontla' },
    { state: 'Veracruz', city: 'Chumatlán' },
    { state: 'Veracruz', city: 'Citlaltépetl' },
    { state: 'Veracruz', city: 'Coacoatzintla' },
    { state: 'Veracruz', city: 'Coahuitlán' },
    { state: 'Veracruz', city: 'Coatepec' },
    { state: 'Veracruz', city: 'Coatzacoalcos' },
    { state: 'Veracruz', city: 'Coatzintla' },
    { state: 'Veracruz', city: 'Coetzala' },
    { state: 'Veracruz', city: 'Colipa' },
    { state: 'Veracruz', city: 'Comapa' },
    { state: 'Veracruz', city: 'Córdoba' },
    { state: 'Veracruz', city: 'Cosamaloapan de Carpio' },
    { state: 'Veracruz', city: 'Cosautlán de Carvajal' },
    { state: 'Veracruz', city: 'Coscomatepec' },
    { state: 'Veracruz', city: 'Cosoleacaque' },
    { state: 'Veracruz', city: 'Cotaxtla' },
    { state: 'Veracruz', city: 'Coxquihui' },
    { state: 'Veracruz', city: 'Coyutla' },
    { state: 'Veracruz', city: 'Cuichapa' },
    { state: 'Veracruz', city: 'Cuitláhuac' },
    { state: 'Veracruz', city: 'El Higo' },
    { state: 'Veracruz', city: 'Emiliano Zapata' },
    { state: 'Veracruz', city: 'Espinal' },
    { state: 'Veracruz', city: 'Filomeno Mata' },
    { state: 'Veracruz', city: 'Fortín' },
    { state: 'Veracruz', city: 'Gutiérrez Zamora' },
    { state: 'Veracruz', city: 'Hidalgotitlán' },
    { state: 'Veracruz', city: 'Huatusco' },
    { state: 'Veracruz', city: 'Huayacocotla' },
    { state: 'Veracruz', city: 'Hueyapan de Ocampo' },
    { state: 'Veracruz', city: 'Huiloapan de Cuauhtémoc' },
    { state: 'Veracruz', city: 'Ignacio de la Llave' },
    { state: 'Veracruz', city: 'Ilamatlán' },
    { state: 'Veracruz', city: 'Isla' },
    { state: 'Veracruz', city: 'Ixcatepec' },
    { state: 'Veracruz', city: 'Ixhuacán de los Reyes' },
    { state: 'Veracruz', city: 'Ixhuatlán de Madero' },
    { state: 'Veracruz', city: 'Ixhuatlán del Café' },
    { state: 'Veracruz', city: 'Ixhuatlán del Sureste' },
    { state: 'Veracruz', city: 'Ixhuatlancillo' },
    { state: 'Veracruz', city: 'Ixmatlahuacan' },
    { state: 'Veracruz', city: 'Ixtaczoquitlán' },
    { state: 'Veracruz', city: 'Jalacingo' },
    { state: 'Veracruz', city: 'Jalcomulco' },
    { state: 'Veracruz', city: 'Jáltipan' },
    { state: 'Veracruz', city: 'Jamapa' },
    { state: 'Veracruz', city: 'Jesús Carranza' },
    { state: 'Veracruz', city: 'Jilotepec' },
    { state: 'Veracruz', city: 'José Azueta' },
    { state: 'Veracruz', city: 'Juan Rodríguez Clara' },
    { state: 'Veracruz', city: 'Juchique de Ferrer' },
    { state: 'Veracruz', city: 'La Antigua' },
    { state: 'Veracruz', city: 'La Perla' },
    { state: 'Veracruz', city: 'Landero y Coss' },
    { state: 'Veracruz', city: 'Las Choapas' },
    { state: 'Veracruz', city: 'Las Minas' },
    { state: 'Veracruz', city: 'Las Vigas de Ramírez' },
    { state: 'Veracruz', city: 'Lerdo de Tejada' },
    { state: 'Veracruz', city: 'Los Reyes' },
    { state: 'Veracruz', city: 'Magdalena' },
    { state: 'Veracruz', city: 'Maltrata' },
    { state: 'Veracruz', city: 'Manlio Fabio Altamirano' },
    { state: 'Veracruz', city: 'Mariano Escobedo' },
    { state: 'Veracruz', city: 'Martínez de la Torre' },
    { state: 'Veracruz', city: 'Mecatlán' },
    { state: 'Veracruz', city: 'Mecayapan' },
    { state: 'Veracruz', city: 'Medellín' },
    { state: 'Veracruz', city: 'Miahuatlán' },
    { state: 'Veracruz', city: 'Minatitlán' },
    { state: 'Veracruz', city: 'Misantla' },
    { state: 'Veracruz', city: 'Mixtla de Altamirano' },
    { state: 'Veracruz', city: 'Moloacán' },
    { state: 'Veracruz', city: 'Nanchital de Lázaro Cárdenas del Río' },
    { state: 'Veracruz', city: 'Naolinco' },
    { state: 'Veracruz', city: 'Naranjal' },
    { state: 'Veracruz', city: 'Naranjos Amatlán' },
    { state: 'Veracruz', city: 'Nautla' },
    { state: 'Veracruz', city: 'Nogales' },
    { state: 'Veracruz', city: 'Oluta' },
    { state: 'Veracruz', city: 'Omealca' },
    { state: 'Veracruz', city: 'Orizaba' },
    { state: 'Veracruz', city: 'Otatitlán' },
    { state: 'Veracruz', city: 'Oteapan' },
    { state: 'Veracruz', city: 'Ozuluama de Mascareñas' },
    { state: 'Veracruz', city: 'Pajapan' },
    { state: 'Veracruz', city: 'Pánuco' },
    { state: 'Veracruz', city: 'Papantla' },
    { state: 'Veracruz', city: 'Paso de Ovejas' },
    { state: 'Veracruz', city: 'Paso del Macho' },
    { state: 'Veracruz', city: 'Perote' },
    { state: 'Veracruz', city: 'Platón Sánchez' },
    { state: 'Veracruz', city: 'Playa Vicente' },
    { state: 'Veracruz', city: 'Poza Rica de Hidalgo' },
    { state: 'Veracruz', city: 'Pueblo Viejo' },
    { state: 'Veracruz', city: 'Puente Nacional' },
    { state: 'Veracruz', city: 'Rafael Delgado' },
    { state: 'Veracruz', city: 'Rafael Lucio' },
    { state: 'Veracruz', city: 'Río Blanco' },
    { state: 'Veracruz', city: 'Saltabarranca' },
    { state: 'Veracruz', city: 'San Andrés Tenejapan' },
    { state: 'Veracruz', city: 'San Andrés Tuxtla' },
    { state: 'Veracruz', city: 'San Juan Evangelista' },
    { state: 'Veracruz', city: 'San Rafael' },
    { state: 'Veracruz', city: 'Santiago Sochiapan' },
    { state: 'Veracruz', city: 'Santiago Tuxtla' },
    { state: 'Veracruz', city: 'Sayula de Alemán' },
    { state: 'Veracruz', city: 'Sochiapa' },
    { state: 'Veracruz', city: 'Soconusco' },
    { state: 'Veracruz', city: 'Soledad Atzompa' },
    { state: 'Veracruz', city: 'Soledad de Doblado' },
    { state: 'Veracruz', city: 'Soteapan' },
    { state: 'Veracruz', city: 'Tamalín' },
    { state: 'Veracruz', city: 'Tamiahua' },
    { state: 'Veracruz', city: 'Tampico Alto' },
    { state: 'Veracruz', city: 'Tancoco' },
    { state: 'Veracruz', city: 'Tantima' },
    { state: 'Veracruz', city: 'Tantoyuca' },
    { state: 'Veracruz', city: 'Tatahuicapan de Juárez' },
    { state: 'Veracruz', city: 'Tatatila' },
    { state: 'Veracruz', city: 'Tecolutla' },
    { state: 'Veracruz', city: 'Tehuipango' },
    { state: 'Veracruz', city: 'Tempoal' },
    { state: 'Veracruz', city: 'Tenampa' },
    { state: 'Veracruz', city: 'Tenochtitlán' },
    { state: 'Veracruz', city: 'Teocelo' },
    { state: 'Veracruz', city: 'Tepatlaxco' },
    { state: 'Veracruz', city: 'Tepetlán' },
    { state: 'Veracruz', city: 'Tepetzintla' },
    { state: 'Veracruz', city: 'Tequila' },
    { state: 'Veracruz', city: 'Texcatepec' },
    { state: 'Veracruz', city: 'Texhuacán' },
    { state: 'Veracruz', city: 'Texistepec' },
    { state: 'Veracruz', city: 'Tezonapa' },
    { state: 'Veracruz', city: 'Tierra Blanca' },
    { state: 'Veracruz', city: 'Tihuatlán' },
    { state: 'Veracruz', city: 'Tlachichilco' },
    { state: 'Veracruz', city: 'Tlacojalpan' },
    { state: 'Veracruz', city: 'Tlacolulan' },
    { state: 'Veracruz', city: 'Tlacotalpan' },
    { state: 'Veracruz', city: 'Tlacotepec de Mejía' },
    { state: 'Veracruz', city: 'Tlalixcoyan' },
    { state: 'Veracruz', city: 'Tlalnelhuayocan' },
    { state: 'Veracruz', city: 'Tlaltetela' },
    { state: 'Veracruz', city: 'Tlapacoyan' },
    { state: 'Veracruz', city: 'Tlaquilpa' },
    { state: 'Veracruz', city: 'Tlilapan' },
    { state: 'Veracruz', city: 'Tomatlán' },
    { state: 'Veracruz', city: 'Tonayán' },
    { state: 'Veracruz', city: 'Totutla' },
    { state: 'Veracruz', city: 'Tres Valles' },
    { state: 'Veracruz', city: 'Tuxpan' },
    { state: 'Veracruz', city: 'Tuxtilla' },
    { state: 'Veracruz', city: 'Úrsulo Galván' },
    { state: 'Veracruz', city: 'Uxpanapa' },
    { state: 'Veracruz', city: 'Vega de Alatorre' },
    { state: 'Veracruz', city: 'Veracruz' },
    { state: 'Veracruz', city: 'Villa Aldama' },
    { state: 'Veracruz', city: 'Xalapa' },
    { state: 'Veracruz', city: 'Xico' },
    { state: 'Veracruz', city: 'Xoxocotla' },
    { state: 'Veracruz', city: 'Yanga' },
    { state: 'Veracruz', city: 'Yecuatla' },
    { state: 'Veracruz', city: 'Zacualpan' },
    { state: 'Veracruz', city: 'Zaragoza' },
    { state: 'Veracruz', city: 'Zentla' },
    { state: 'Veracruz', city: 'Zongolica' },
    { state: 'Veracruz', city: 'Zontecomatlán de López y Fuentes' },
    { state: 'Veracruz', city: 'Zozocolco de Hidalgo' },
    { state: 'Yucatán', city: 'Abalá' },
    { state: 'Yucatán', city: 'Acanceh' },
    { state: 'Yucatán', city: 'Akil' },
    { state: 'Yucatán', city: 'Baca' },
    { state: 'Yucatán', city: 'Bokobá' },
    { state: 'Yucatán', city: 'Buctzotz' },
    { state: 'Yucatán', city: 'Cacalchén' },
    { state: 'Yucatán', city: 'Calotmul' },
    { state: 'Yucatán', city: 'Cansahcab' },
    { state: 'Yucatán', city: 'Cantamayec' },
    { state: 'Yucatán', city: 'Celestún' },
    { state: 'Yucatán', city: 'Cenotillo' },
    { state: 'Yucatán', city: 'Chacsinkín' },
    { state: 'Yucatán', city: 'Chankom' },
    { state: 'Yucatán', city: 'Chapab' },
    { state: 'Yucatán', city: 'Chemax' },
    { state: 'Yucatán', city: 'Chichimilá' },
    { state: 'Yucatán', city: 'Chicxulub Pueblo' },
    { state: 'Yucatán', city: 'Chikindzonot' },
    { state: 'Yucatán', city: 'Chocholá' },
    { state: 'Yucatán', city: 'Chumayel' },
    { state: 'Yucatán', city: 'Conkal' },
    { state: 'Yucatán', city: 'Cuncunul' },
    { state: 'Yucatán', city: 'Cuzamá' },
    { state: 'Yucatán', city: 'Dzan' },
    { state: 'Yucatán', city: 'Dzemul' },
    { state: 'Yucatán', city: 'Dzidzantún' },
    { state: 'Yucatán', city: 'Dzilam de Bravo' },
    { state: 'Yucatán', city: 'Dzilam González' },
    { state: 'Yucatán', city: 'Dzitás' },
    { state: 'Yucatán', city: 'Dzoncauich' },
    { state: 'Yucatán', city: 'Espita' },
    { state: 'Yucatán', city: 'Halachó' },
    { state: 'Yucatán', city: 'Hocabá' },
    { state: 'Yucatán', city: 'Hoctún' },
    { state: 'Yucatán', city: 'Homún' },
    { state: 'Yucatán', city: 'Huhí' },
    { state: 'Yucatán', city: 'Hunucmá' },
    { state: 'Yucatán', city: 'Ixil' },
    { state: 'Yucatán', city: 'Izamal' },
    { state: 'Yucatán', city: 'Kanasín' },
    { state: 'Yucatán', city: 'Kantunil' },
    { state: 'Yucatán', city: 'Kaua' },
    { state: 'Yucatán', city: 'Kinchil' },
    { state: 'Yucatán', city: 'Kopomá' },
    { state: 'Yucatán', city: 'Mama' },
    { state: 'Yucatán', city: 'Maní' },
    { state: 'Yucatán', city: 'Maxcanú' },
    { state: 'Yucatán', city: 'Mayapán' },
    { state: 'Yucatán', city: 'Mérida' },
    { state: 'Yucatán', city: 'Mocochá' },
    { state: 'Yucatán', city: 'Motul' },
    { state: 'Yucatán', city: 'Muna' },
    { state: 'Yucatán', city: 'Muxupip' },
    { state: 'Yucatán', city: 'Opichén' },
    { state: 'Yucatán', city: 'Oxkutzcab' },
    { state: 'Yucatán', city: 'Panabá' },
    { state: 'Yucatán', city: 'Peto' },
    { state: 'Yucatán', city: 'Progreso' },
    { state: 'Yucatán', city: 'Quintana Roo' },
    { state: 'Yucatán', city: 'Río Lagartos' },
    { state: 'Yucatán', city: 'Sacalum' },
    { state: 'Yucatán', city: 'Samahil' },
    { state: 'Yucatán', city: 'San Felipe' },
    { state: 'Yucatán', city: 'Sanahcat' },
    { state: 'Yucatán', city: 'Santa Elena' },
    { state: 'Yucatán', city: 'Seyé' },
    { state: 'Yucatán', city: 'Sinanché' },
    { state: 'Yucatán', city: 'Sotuta' },
    { state: 'Yucatán', city: 'Sucilá' },
    { state: 'Yucatán', city: 'Sudzal' },
    { state: 'Yucatán', city: 'Suma' },
    { state: 'Yucatán', city: 'Tahdziú' },
    { state: 'Yucatán', city: 'Tahmek' },
    { state: 'Yucatán', city: 'Teabo' },
    { state: 'Yucatán', city: 'Tecoh' },
    { state: 'Yucatán', city: 'Tekal de Venegas' },
    { state: 'Yucatán', city: 'Tekantó' },
    { state: 'Yucatán', city: 'Tekax' },
    { state: 'Yucatán', city: 'Tekit' },
    { state: 'Yucatán', city: 'Tekom' },
    { state: 'Yucatán', city: 'Telchac Pueblo' },
    { state: 'Yucatán', city: 'Telchac Puerto' },
    { state: 'Yucatán', city: 'Temax' },
    { state: 'Yucatán', city: 'Temozón' },
    { state: 'Yucatán', city: 'Tepakán' },
    { state: 'Yucatán', city: 'Tetiz' },
    { state: 'Yucatán', city: 'Teya' },
    { state: 'Yucatán', city: 'Ticul' },
    { state: 'Yucatán', city: 'Timucuy' },
    { state: 'Yucatán', city: 'Tinum' },
    { state: 'Yucatán', city: 'Tixcacalcupul' },
    { state: 'Yucatán', city: 'Tixkokob' },
    { state: 'Yucatán', city: 'Tixméhuac' },
    { state: 'Yucatán', city: 'Tixpéhual' },
    { state: 'Yucatán', city: 'Tizimín' },
    { state: 'Yucatán', city: 'Tunkás' },
    { state: 'Yucatán', city: 'Tzucacab' },
    { state: 'Yucatán', city: 'Uayma' },
    { state: 'Yucatán', city: 'Ucú' },
    { state: 'Yucatán', city: 'Umán' },
    { state: 'Yucatán', city: 'Valladolid' },
    { state: 'Yucatán', city: 'Xocchel' },
    { state: 'Yucatán', city: 'Yaxcabá' },
    { state: 'Yucatán', city: 'Yaxkukul' },
    { state: 'Yucatán', city: 'Yobaín' },
    { state: 'Zacatecas', city: 'Apozol ' },
    { state: 'Zacatecas', city: 'Apulco ' },
    { state: 'Zacatecas', city: 'Atolinga ' },
    { state: 'Zacatecas', city: 'Benito Juárez ' },
    { state: 'Zacatecas', city: 'Calera ' },
    { state: 'Zacatecas', city: 'Cañitas de Felipe Pescador ' },
    { state: 'Zacatecas', city: 'Chalchihuites ' },
    { state: 'Zacatecas', city: 'Concepción del Oro ' },
    { state: 'Zacatecas', city: 'Cuauhtémoc ' },
    { state: 'Zacatecas', city: 'El Plateado de Joaquín Amaro ' },
    { state: 'Zacatecas', city: 'El Salvador ' },
    { state: 'Zacatecas', city: 'Fresnillo ' },
    { state: 'Zacatecas', city: 'Genaro Codina ' },
    { state: 'Zacatecas', city: 'General Enrique Estrada ' },
    { state: 'Zacatecas', city: 'General Francisco R. Murguía ' },
    { state: 'Zacatecas', city: 'General Pánfilo Natera ' },
    { state: 'Zacatecas', city: 'Guadalupe ' },
    { state: 'Zacatecas', city: 'Huanusco ' },
    { state: 'Zacatecas', city: 'Jalpa ' },
    { state: 'Zacatecas', city: 'Jerez ' },
    { state: 'Zacatecas', city: 'Jiménez del Teul ' },
    { state: 'Zacatecas', city: 'Juan Aldama ' },
    { state: 'Zacatecas', city: 'Juchipila ' },
    { state: 'Zacatecas', city: 'Loreto ' },
    { state: 'Zacatecas', city: 'Luis Moya ' },
    { state: 'Zacatecas', city: 'Mazapil ' },
    { state: 'Zacatecas', city: 'Melchor Ocampo ' },
    { state: 'Zacatecas', city: 'Mezquital del Oro ' },
    { state: 'Zacatecas', city: 'Miguel Auza ' },
    { state: 'Zacatecas', city: 'Momax ' },
    { state: 'Zacatecas', city: 'Monte Escobedo ' },
    { state: 'Zacatecas', city: 'Morelos ' },
    { state: 'Zacatecas', city: 'Moyahua de Estrada ' },
    { state: 'Zacatecas', city: 'Nochistlán de Mejía ' },
    { state: 'Zacatecas', city: 'Noria de Ángeles ' },
    { state: 'Zacatecas', city: 'Ojocaliente ' },
    { state: 'Zacatecas', city: 'Pánuco ' },
    { state: 'Zacatecas', city: 'Pinos ' },
    { state: 'Zacatecas', city: 'Río Grande ' },
    { state: 'Zacatecas', city: 'Saín Alto ' },
    { state: 'Zacatecas', city: 'Santa María de la Paz ' },
    { state: 'Zacatecas', city: 'Sombrerete ' },
    { state: 'Zacatecas', city: 'Susticacán ' },
    { state: 'Zacatecas', city: 'Tabasco ' },
    { state: 'Zacatecas', city: 'Tepechitlán ' },
    { state: 'Zacatecas', city: 'Tepetongo ' },
    { state: 'Zacatecas', city: 'Teúl de González Ortega ' },
    { state: 'Zacatecas', city: 'Tlaltenango de Sánchez Román ' },
    { state: 'Zacatecas', city: 'Trancoso ' },
    { state: 'Zacatecas', city: 'Trinidad García de la Cadena ' },
    { state: 'Zacatecas', city: 'Valparaíso ' },
    { state: 'Zacatecas', city: 'Vetagrande ' },
    { state: 'Zacatecas', city: 'Villa de Cos ' },
    { state: 'Zacatecas', city: 'Villa García ' },
    { state: 'Zacatecas', city: 'Villa González Ortega ' },
    { state: 'Zacatecas', city: 'Villa Hidalgo ' },
    { state: 'Zacatecas', city: 'Villanueva ' },
    { state: 'Zacatecas', city: 'Zacatecas ' }
];

export default estadoCiudadData;
