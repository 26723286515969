import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogBankResponse } from '../../types/types';

export interface InstitutionState {
    institutionsInfo: Array<CatalogBankResponse>;
}

const initialState: InstitutionState = {
    institutionsInfo: []
};

export const institutionSlice = createSlice({
    name: 'institutions',
    initialState,
    reducers: {
        setInstitution: (state, action: PayloadAction<InstitutionState>) => {
            state.institutionsInfo = action.payload.institutionsInfo;
        },
        clearInstitution: (state) => {
            state.institutionsInfo = [];
        }
    }
});

export const { setInstitution, clearInstitution } = institutionSlice.actions;
export const getInstitutionData = (state: any) => (state.institutions);
export default institutionSlice.reducer;
