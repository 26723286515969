import {
    DownCircleFilled,
    HourglassOutlined, InfoCircleOutlined, LoadingOutlined, PrinterOutlined,
    RedoOutlined,
    UpCircleFilled
} from "@ant-design/icons";
import { Col, Row, Spin, Table, TableProps, Typography } from "antd";
import { StyleSheet } from "../../../../StyleSheet";
import { Period, State, Status, TableParams, TransactionResume } from "../../../../types/types";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { ColumnsType } from "antd/es/table";
import _ from "lodash";
import { setPeriod } from "../../../../store/slices/periods";
import { getAllPeriodList, getAllTransactions } from "../../../../methods/axiosMethods";
import DrawerHistory from "./DrawerHistory";
import { dispatch } from "../../../../store";
import Buttons from "../../../../components/Buttons";
import SelectInput from "../../../../components/SelectInput";
import Searcher from "../../components/Searcher";
import { PeriodInterface, splicePeriodsLists } from "../../../../functions/Functions";
import ModalNotification, { AlertTypeNew } from "../../../../components/ModalNotification";
const Text = Typography;

const formatAmount = Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    useGrouping: true,
    minimumFractionDigits: 2,
    notation: 'standard'
});

interface Props {
    printReceipt: (transaction: TransactionResume) => void;
    selectHistoryType: ReactNode;
    loadingPrint: boolean;
    notificationMessage: string;
    notificationType: AlertTypeNew;
    notificationShown: boolean;
    closeNot: () => void;
};

const PerformedMain = (props: Props) => {
    const { printReceipt, selectHistoryType, loadingPrint, notificationMessage, notificationType, notificationShown, closeNot } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [periods, setPeriods] = useState<PeriodInterface>({ periods: [] });
    const [indexOfCurrentYear, setIndexOfCurrentYear] = useState<number>(0);
    const [indexOfCurrentMonth, setIndexOfCurrentMonth] = useState<number>(0);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<TransactionResume>();
    const [periodsList, setPeriodsList] = useState<Array<Period>>([]);
    const [transactionsList, setTransactionsList] = useState<Array<TransactionResume>>([]);
    const [transactionsListTmp, setTransactionsListTmp] = useState<Array<TransactionResume>>([]);
    const [typeNotification, setTypeNotification] = useState<AlertTypeNew>("SUCCESS");
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    const [notificationContent, setNotificationContent] = useState<boolean>(false);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            // Se define que inicia en la pag 1 y el tamaño de la pagina será de 10
            current: 1,
            pageSize: 10,
            showSizeChanger: false,
        },
    });

    const columns: ColumnsType<TransactionResume> = [
        {
            title: "Acciones",
            dataIndex: "operation",
            key: "operation",
            className: 'custom-header',
            align: 'center',
            render: (event, record: TransactionResume) => (
                <div>
                    <div>
                        <Buttons
                            type={"default"}
                            title={"Detalles"}
                            action={() => showDrawer(record)}
                            color="#444cf9"
                            icon={<InfoCircleOutlined />} />
                    </div>
                    {
                        record.status === State.DONE || record.status === State.PROCESSING || record.status === State.PENDING ?
                            <div>
                                <br />
                                <Buttons
                                    type={"default"}
                                    title={"Imprimir"}
                                    action={() => printReceipt(record)}
                                    color="#fcba50"
                                    icon={<PrinterOutlined />} />
                            </div>
                            :
                            null
                    }
                </div>
            ),
        },
        {
            title: "Tipo",
            dataIndex: "transactionType",
            key: "transactionType",
            className: 'custom-header',
            render: (text, record) => {
                const statusInfo: any = {
                    DONE: {
                        textColor: record.incoming > 0 ? "#2ed2a7" : "#ea915f",
                        tagText: record.incoming > 0 ? "Ingreso" : "Egreso",
                        icon: record.incoming > 0 ? <DownCircleFilled style={{ color: "#2ed2a7" }} /> : <UpCircleFilled style={{ color: "#ea915f" }} />,
                    },
                    REJECTED: {
                        textColor: "#ff5780",
                        tagText: "Rechazada",
                        icon: <InfoCircleOutlined style={{ color: "red" }} />,
                    },
                    CANCELED: {
                        textColor: "#fd0061",
                        tagText: "Cancelada",
                        icon: <InfoCircleOutlined style={{ color: "#fd0061" }} />,
                    },
                    CREATED: {
                        textColor: "#ff5780",
                        tagText: "Rechazada",
                        icon: <InfoCircleOutlined style={{ color: "red" }} />,
                    },
                    PROCESSING: {
                        textColor: "blue",
                        tagText: "Procesando",
                        icon: <HourglassOutlined style={{ color: "#00abfb" }} />,
                    },
                    PENDING: {
                        textColor: "blue",
                        tagText: "Procesando",
                        icon: <HourglassOutlined style={{ color: "#00abfb" }} />,
                    },
                };
                const { tagText, icon, textColor } = statusInfo[record.status] || {};

                return (
                    <>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {icon && icon} &nbsp;&nbsp;
                            {textColor && (
                                <Text style={{ fontSize: 14, color: textColor }}>
                                    {tagText}
                                </Text>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            title: "Fecha",
            dataIndex: "formatted",
            key: "formatted",
            className: 'custom-header',
        },
        {
            title: "Cuentas",
            dataIndex: "sourceAccountNumber" || "receiverAccountNumber",
            className: 'custom-header',
            key: "sourceAccountNumber" || "receiverAccountNumber",
            render: (text, record) => (
                <div>
                    <div>
                        Cuenta Emisor: {record.sourceAccountNumber}
                    </div>
                    <br />
                    <div>
                        Cuenta Receptor: {record.receiverAccountNumber}
                    </div>
                </div>
            ),
        },
        {
            title: "Concepto",
            className: 'custom-header',
            dataIndex: "paymentConcept",
            key: "paymentConcept",
        },
        {
            title: "Rastreo",
            className: 'custom-header',
            dataIndex: "trackingKey",
            key: "trackingKey",
        },
        {
            title: "Monto",
            dataIndex: "incoming" || "outcoming",
            align: "center",
            className: 'custom-header',
            key: "incoming" || "outcoming",
            render: (text, record) => (
                <span style={{ color: record.incoming > 0 ? "#52c41a" : "orange", fontWeight: 'bold' }}>
                    {record.incoming > 0 ?
                        (record.incoming !== 0 ?
                            formatAmount.format(record.incoming + record.iva) : '$0.00')
                        : (record.outcoming !== 0 ?
                            formatAmount.format(record.outcoming + record.iva) : '$0.00')}
                </span>
            ),
        },
    ];

    const getPeriodsAll = useCallback(() => {
        setIsLoading(true);
        getAllPeriodList()
            .then((response) => {
                if (response.data && response.data.data && response.data.status === Status.OK && !_.isEmpty(response.data.data.periods)) {

                    dispatch(setPeriod({ periodsInfo: response.data.data.periods }))
                    setPeriodsList(_.orderBy(response.data.data.periods, 'period', 'desc'));
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setShowNotification(true);
                setMessage("Hubo un error al obtener los periodos.")
                setTypeNotification("ERROR");
                setNotificationContent(true);
            });
    }, []);

    const getTransactionsAll = useCallback(() => {
        if (periods && periods.periods[indexOfCurrentYear] && periods.periods[indexOfCurrentYear].months[indexOfCurrentMonth]) {
            const periodComplete = `${periods.periods[indexOfCurrentYear].months[indexOfCurrentMonth]}-${periods.periods[indexOfCurrentYear].year}`;
            setIsLoading(true);
            getAllTransactions(periodComplete)
                .then((response) => {
                    if (response.data && response.data.data && response.data.data.length > 0 && response.data.status === Status.OK) {
                        setTransactionsListTmp(response.data.data);
                        setTransactionsList(response.data.data);
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    setShowNotification(true);
                    setMessage("Hubo un error al obtener la lista de transacciones.")
                    setTypeNotification("ERROR");
                    setNotificationContent(true);
                });
        }
    }, [indexOfCurrentYear, indexOfCurrentMonth, periods]);

    const headerSearcher = (text: string) => {
        const filteredResults = transactionsList.filter(
            (data: TransactionResume) =>
                data.receiverName.toLowerCase().includes(text.toLowerCase()) ||
                data.trackingKey.toLowerCase().includes(text.toLowerCase()) ||
                data.paymentConcept.toLowerCase().includes(text.toLowerCase()) ||
                data.receiverAccountNumber.toLowerCase().includes(text.toLowerCase())
        );
        setTransactionsListTmp(filteredResults);
    };

    const showDrawer = (record: TransactionResume) => {
        setOpenDrawer(true);
        setSelectedRecord(record);
    };

    const onCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const handleTableChange: TableProps<TransactionResume>["onChange"] = (
        pagination,
    ) => {
        setTableParams({
            pagination,
        });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setTransactionsListTmp([]);
        }
    };

    const labelMonth = (element: string): string => {
        const arrayOfMonths = [
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre'
        ];
        return arrayOfMonths[parseInt(element, 10) - 1];
    };

    useEffect(() => {
        getPeriodsAll();
    }, [getPeriodsAll]);

    useEffect(() => {
        if (!_.isEmpty(periodsList)) {
            setPeriods(splicePeriodsLists(periodsList));
        }
    }, [periodsList]);

    useEffect(() => {
        if (!_.isEmpty(periods)) {
            setIndexOfCurrentYear(0);
            setIndexOfCurrentMonth(0);
        }
    }, [periods]);

    useEffect(() => {
        getTransactionsAll();
    }, [getTransactionsAll]);

    console.log("transactionsListTmp", transactionsListTmp)

    return (
        <>
            <Row justify={"center"} gutter={[16, 16]}>
                <Col span={24}>
                    <Spin spinning={isLoading}>
                        <Row gutter={[16, 16]} style={styles.searcherMainContainer}>
                            {(periods && periods.periods[indexOfCurrentYear]) && (
                                <>
                                    <Col xs={24} sm={24} lg={12} xl={6}>
                                        {selectHistoryType}
                                    </Col>
                                    <Col
                                        xs={24} sm={24} lg={12} xl={6}
                                        style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}
                                    >
                                        <Searcher
                                            placeholder="Buscar"
                                            onSearch={(value: string) => headerSearcher(value)}
                                        />
                                    </Col>
                                    <Col xs={24} sm={24} lg={8} xl={4}>
                                        <SelectInput
                                            label="Seleccione un año"
                                            value={periods.periods[indexOfCurrentYear].year}
                                            options={periods.periods.map((elem) => ({
                                                label: elem.year,
                                                value: elem.year
                                            }))}
                                            onChange={(value) => {
                                                setIndexOfCurrentMonth(0);
                                                setIndexOfCurrentYear(
                                                    periods.periods.findIndex(elem => elem.year === value)
                                                )
                                            }}
                                        />
                                    </Col>
                                    <Col xs={24} sm={24} lg={8} xl={5}>
                                        <SelectInput
                                            label="Seleccione un mes"
                                            value={periods.periods[indexOfCurrentYear].months[indexOfCurrentMonth]}
                                            options={periods.periods[indexOfCurrentYear].months.map(elem => ({
                                                value: elem,
                                                label: labelMonth(elem)
                                            }))}
                                            onChange={(value) => setIndexOfCurrentMonth(
                                                periods.periods[indexOfCurrentYear].months.findIndex(elem => elem === value)
                                            )}
                                        />
                                    </Col>
                                    <Col
                                        xs={24} sm={24} lg={8} xl={3}
                                        style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: 10 }}
                                    >
                                        <Buttons
                                            title="Actualizar"
                                            action={getTransactionsAll}
                                            type="primary"
                                            color="#3d84ff"
                                            icon={<RedoOutlined />}
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Spin>
                </Col>
                <Col span={24}>
                    <Text style={styles.textStyle}>
                        Historial de Todas las Transferencias
                    </Text>
                </Col>
                <Col span={24}>
                    <div style={styles.primaryContainer}>
                        <Row style={styles.tableContainer}>
                            <Col span={24} >
                                <Table
                                    bordered
                                    dataSource={transactionsListTmp}
                                    columns={columns}
                                    onChange={handleTableChange}
                                    scroll={{ y: 400, x: 700 }}
                                    locale={{ emptyText: "No hay datos disponibles" }}
                                    pagination={tableParams.pagination}
                                    loading={{
                                        spinning: isLoading || loadingPrint,
                                        indicator: <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />,
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <DrawerHistory
                selectedRecord={selectedRecord}
                drawerVisible={openDrawer}
                onCloseDrawer={onCloseDrawer}
                onClickPrint={() => selectedRecord && printReceipt(selectedRecord)}
                loadingPrint={loadingPrint}
            />
            <ModalNotification
                title={notificationContent ? "Historial de Transferencias" : "Comprobante de Pago"}
                message={notificationContent ? message : notificationMessage}
                alertType={notificationContent ? typeNotification : notificationType}
                show={notificationContent ? showNotification : notificationShown}
                onClose={notificationContent ? () => setShowNotification(false) : closeNot}
            />
        </>
    );
};

const styles = StyleSheet.create({
    primaryContainer: {
        padding: "20px",
        borderRadius: 10,
        backgroundColor: 'white',
        boxShadow: "rgba(177, 179, 185, 0.51) 5px 8px 24px 5px",
    },
    searcherMainContainer: {
        padding: 20,
        borderRadius: 10,
        backgroundColor: "#ffffff",
        boxShadow: "0px 0px 8px #00000029",
    },
    textStyle: {
        fontSize: 20,
        fontWeight: "bold",
        color: "#000073"
    },
    tableContainer: {
        marginTop: "40px"
    },
});

export default PerformedMain;
