import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../store/slices/user";
import { ReactKeycloakProvider } from "@react-keycloak-fork/web";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import keycloak from "./keycloak";

const KeycloakProvider = ({ children }: { children: React.ReactElement }) => {
  const [keycloakReady, setKeycloakReady] = useState(false);

  const dispatch = useDispatch();

  const eventLogger = (event: unknown, error: unknown) => {
    if (event && event === "onReady") {
      setKeycloakReady(true);
    }

    if (event && event === "onAuthSuccess") {
      dispatch(
        login({
          email: keycloak.idTokenParsed?.email,
          name: keycloak.idTokenParsed?.name,
          id: keycloak.idTokenParsed?.sub ? keycloak.idTokenParsed?.sub : "",
          typeUser: keycloak.realmAccess?.roles.includes("USER")
            ? "USER"
            : keycloak.realmAccess?.roles.includes("ADMIN")
            ? "admin"
            : "",
          enabled: false,
          username: keycloak.idTokenParsed?.username,
          lastName: keycloak.idTokenParsed?.lastname,
          companyId: keycloak.idTokenParsed?.companyId,
          isRootUser: false,
          isEmailVerified: false,
        })
      );
    }
    if (event && event === "onTokenExpired") {
      console.log("Token Expirado");
    }
  };

  const tokenLogger = (tokens: unknown) => {
    setKeycloakReady(true);
  };

  return (
    <ReactKeycloakProvider
      initOptions={{ onLoad: "login-required", checkLoginIframe: false , scope: 'openid'}}
      authClient={keycloak}
      onEvent={eventLogger}
      onTokens={tokenLogger}
      //    autoRefreshToken
      // LoadingComponent={<Loader />}
    >
      {keycloakReady ? (
        children
      ) : (
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          style={{
            display: "flex",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      )}
    </ReactKeycloakProvider>
  );
};

export default KeycloakProvider;
