import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { StyleSheet } from "../../../StyleSheet";
import { Col, Row, Typography, Transfer, Spin, Skeleton } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AccountThirdData, AccountType, CatalogBankResponse, GroupResponse, Institution, Status, UpdateAccountGroupRequest } from "../../../types/types";
import { getAccountGroupById, getAccountsThird, getBanks, updateAccountGroup } from "../../../methods/axiosMethods";
import { useNavigate, useParams } from "react-router-dom";
import { clearGroups } from "../../../store/slices/groups";
import * as XLSX from 'xlsx';
import { setInstitution } from "../../../store/slices/institutions";
import ModalNotification, { AlertTypeNew } from "../../../components/ModalNotification";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import { subMenusOfAccounts } from "../../../commons/subMenus";
import CardDashboard from "../../../components/CardDashboard";
import { image } from "../../../commons/ImageRoutes";
import Input from "../../../components/Input";
import { setAccountThird } from "../../../store/slices/accountsThird";

const { Text } = Typography;

type Info = {
    id: string;
    bankAccountNumber: string;
    name: string;
    bankName: Institution;
    accountType: AccountType;
    alias: string;
    enabled: boolean;
    rfc: string;
    street: string;
    streetNumber: string;
    zip: string;
    state: string;
    municipality: string;
    country: string;
    key: string
};

const AccountsGroupEdit = () => {
    const navigate = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
    const [groupId, setGroupId] = useState("");
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const accountsThirdData = useSelector((state: any) => state.accountThird.accountsThirdInfo);
    const groupsData = useSelector((state: any) => state.group.groups);
    const [accGroupToEdit, setAccGroupToEdit] = useState<GroupResponse>();
    const banksList = useSelector((state: any) => state.institutions.institutionsInfo);
    const [bankList, setBankList] = useState<CatalogBankResponse[]>([]);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [shouldNavigate, setShouldNavigate] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [message, setMessage] = useState("");
    const [accountThirdInfo, setAccountThirdInfo] = useState<Info[]>([]);
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [accountsThirdByGroup, setAccountsThirdByGroup] = useState<AccountThirdData[]>([]);
    const [accountsThirdList, setAccountsThirdList] = useState<Array<AccountThirdData>>([]);
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
    const exportOptImmediate = { key: 'immediately', label: 'Inmediata' };
    const exportOptProgrammed = { key: 'scheduled', label: 'Programada' };



    const getAccountsGroup = useCallback(async (groupId: string) => {
        setIsLoadingData(true);
        await getAccountGroupById(groupId)
            .then((response) => {
                if (response.data.status === Status.OK && !_.isEmpty(response.data.data)) {
                    setAccGroupToEdit(response.data.data)
                } else {
                    setNotificationType("ERROR");
                    setMessage("No fue posible obtener los datos del Grupo de cuentas, intente más tarde.");
                    setShouldNavigate(true);
                    setShowNotification(true);
                    setIsLoadingData(false);
                }

            })
            .catch(() => {
                setIsLoadingData(false);
                setShouldNavigate(false);
                setNotificationType('ERROR');
                setMessage("No fue posible cargar la información del grupo.");
                setShowNotification(true);
            })
    }, []);

    useEffect(() => {
        if (params && params.groupId) {
            getAccountsGroup(params.groupId)
        } else if (groupsData && !_.isEmpty(groupsData)) {
            setIsLoadingData(true);
            const filteredResult: Array<GroupResponse> = groupsData.filter(
                (data: GroupResponse) => data.id === params.groupId
            );
            setAccGroupToEdit(filteredResult[0]);
            setIsLoadingData(false);
        }
    }, [getAccountsGroup, groupsData, params]);

    useEffect(() => {
        if (accGroupToEdit) {
            setGroupId(accGroupToEdit.id)
            setName(accGroupToEdit.name)
            setDescription(accGroupToEdit.description)
            setAccountsThirdByGroup(accGroupToEdit.thirdAccounts)
            setIsLoadingData(false);
        }
    }, [accGroupToEdit]);

    const getAccountsThirdList = useCallback(() => {
        setIsLoading(true);
        getAccountsThird()
            .then((response) => {
                if (response.data && response.data.data) {
                    if (response.data.status === Status.OK) {
                        setAccountsThirdList(response.data.data)
                        dispatch(setAccountThird({
                            accountsThirdInfo: response.data.data
                        }));
                    }
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setShouldNavigate(false);
                setNotificationType('ERROR');
                setMessage("No fue posible cargar la lista de cuentas de terceros.");
                setShowNotification(true);
            })
    }, [dispatch]);

    useEffect(() => {
        if (_.isEmpty(accountsThirdData)) {
            getAccountsThirdList()
        } else {
            setAccountsThirdList(accountsThirdData);
        }
    }, [accountsThirdData, getAccountsThirdList]);

    useEffect(() => {
        if (accountsThirdList.length > 0) {
            const formattedAccounts = accountsThirdList.map((account: AccountThirdData, index: number) => ({
                ...account,
                key: index.toString(),
            }));
            const tKeys: string[] = [];
            for (var i = 0; i < formattedAccounts.length; i++) {
                for (var j = 0; j < accountsThirdByGroup.length; j++) {
                    if (formattedAccounts[i].id === accountsThirdByGroup[j].id) {
                        tKeys.push(formattedAccounts[i].key);
                    }
                }
            }
            setTargetKeys(tKeys);
            setAccountThirdInfo(formattedAccounts);
        }
        setIsLoading(false);
    }, [accountsThirdByGroup, accountsThirdList]);

    const handleChange = (newTargetKeys: string[]) => {
        setTargetKeys(newTargetKeys);
    };

    const renderItem = (item: AccountThirdData) => {
        const customLabel = (
            <span>
                {item.alias} - {item.bankAccountNumber}
            </span>
        );
        return {
            label: customLabel,
            value: item.name,
        };
    };


    const handleSaveButton = () => {
        if (!_.isEmpty(name)) {
            const selectedAccountThirds = accountThirdInfo.filter((account) => targetKeys.includes(account.key.toString()));
            const bodyData: UpdateAccountGroupRequest = {
                name: name,
                description: description,
                accountsThird: selectedAccountThirds
                    .filter(account => account.id !== undefined)
                    .map(account => account.id!)
            };
            setIsLoading(true);
            updateAccountGroup(bodyData, groupId)
                .then((response) => {
                    setMessage(response.data.message ? response.data.message : "");
                    setShowNotification(true);
                    if (response.data && response.data.data &&
                        response.data.status === Status.UPDATED) {
                        setNotificationType('SUCCESS');
                        setShouldNavigate(true);
                        dispatch(clearGroups());
                    } else {
                        setShouldNavigate(false);
                        setNotificationType('ERROR');
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    setShouldNavigate(false);
                    setNotificationType('ERROR');
                    setMessage("No fue posible modificar el grupo de cuentas, intente más tarde.");
                    setShowNotification(true);
                });
        } else {
            setNotificationType('INFO');
            setMessage("Hay campos obligatorios incompletos.");
            setShouldNavigate(false);
            setShowNotification(true);
        }
    };

    useEffect(() => {
        if (banksList && _.isEmpty(banksList)) {
            getBanks()
                .then((response) => {
                    if (response.data.status === Status.OK && response.data.data && !_.isEmpty(response.data.data)) {
                        dispatch(setInstitution({ institutionsInfo: response.data.data }))
                    }
                })
                .catch(() => {
                    setShouldNavigate(false);
                    setNotificationType('ERROR');
                    setMessage("Hubo un error al cargar la lista de Instituciones, intente más tarde.");
                    setShowNotification(true);
                });
        } else {
            setBankList(banksList);
        }
    }, [banksList, dispatch]);


    // Funcion para crear la exportación a XLSX
    const exportToXLSX = (templateType: any) => {
        const accountThirds: Array<AccountThirdData> =
            accountThirdInfo.filter((account) => targetKeys.includes(account.key.toString()));
        const arrayToXLSX: any = [];
        const Heading = [
            [
                'Cuenta origen',
                'Cuenta destino CLABE/Tarjeta/Teléfono',
                'Tipo',
                'Nombre',
                'RFC',
                'Entidad',
                // estos datos los va a poner el usuario
                'Concepto',
                'Referencia númerica',
                'Importe Sin IVA',
                'IVA',
                _.isEqual(templateType.key, "scheduled") ? "Fecha (DD/MM/AAAA)" : null,
                _.isEqual(templateType.key, "scheduled") ? "Hora (HH:MM)" : null,
            ]
        ];

        accountThirds.forEach(async element => {
            let accountTypeFormated = _.isEqual(element.accountType.toString(), "DEBIT") ? "Tarjeta de Debito"
                : _.isEqual(element.accountType.toString(), "CEL") ? "Teléfono celular"
                    : "CLABE"

            const objToDefine = {
                foreignAccountNumber: '',
                typeAccount: '',
                name: '',
                foreignBankId: '',
                rfc: '',
            };
            objToDefine.foreignAccountNumber = element.bankAccountNumber;
            objToDefine.typeAccount = accountTypeFormated;
            objToDefine.name = element.name;
            objToDefine.rfc = element.rfc;
            let dataResp = _.find(bankList, { bankCode: element.bankName });
            objToDefine.foreignBankId = dataResp ? dataResp.bankName : "";

            const objToOrder = Object.assign(objToDefine, element);
            arrayToXLSX.push({
                accountNumber: "'".toString(),
                foreignAccountNumber: objToOrder.foreignAccountNumber,
                typeAccount: objToOrder.typeAccount,
                name: objToOrder.name,
                rfc: objToOrder.rfc,
                foreignBankId: objToOrder.foreignBankId,
            });
        });
        const ws = XLSX.utils.book_new();
        const wb = XLSX.utils.book_new();
        const exportFileName = _.isEqual(templateType.key, "scheduled") ? 'Plantilla_programadas.xlsx' : "Plantilla_inmediatas.xlsx";

        XLSX.utils.sheet_add_aoa(ws, Heading);
        XLSX.utils.sheet_add_json(ws, arrayToXLSX, { origin: 'A2', skipHeader: true });
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
        const sheet: XLSX.Sheet = wb.Sheets['Hoja1'];
        sheet['!cols'] = [{ width: 25 }, { width: 35 }, {}, { width: 20 }, {}, { width: 15 }, {}, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }]; // Ajusta los valores de las columnas

        XLSX.writeFile(wb, exportFileName);
    };

    const navigateTo = () => {
        navigate('/admin/accountsGroups')
    }

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfAccounts} title="Grupos de Cuentas">
            {
                isLoading ?
                    <div style={styles.contentView}>
                        <Spin
                            indicator={antIcon}
                            tip="Cargando..."
                            style={styles.loading}
                        />
                    </div>
                    :
                    <>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                                <CardDashboard
                                    title={"Guardar"}
                                    icon={image.saveIcon}
                                    height={135}
                                    isFunction={handleSaveButton}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                                <CardDashboard
                                    title={"Cancelar"}
                                    icon={image.cancelIcon}
                                    action={"/admin/accountsGroups"}
                                    height={135}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                                <CardDashboard
                                    title={"Exportar Plantilla Inmediata"}
                                    icon={image.exportIcon}
                                    height={135}
                                    isFunction={() => exportToXLSX(exportOptImmediate)}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={4}>
                                <CardDashboard
                                    title={"Exportar Plantilla Programada"}
                                    icon={image.exportIcon}
                                    height={135}
                                    isFunction={() => exportToXLSX(exportOptProgrammed)}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                            <Col style={styles.divContainer} md={24} lg={22} xl={20} xxl={16}>
                                {
                                    isLoadingData ?
                                        <Skeleton />
                                        :
                                        <>
                                            <div>
                                                <Text style={styles.textTitle}>
                                                    Editar Grupo de Cuentas
                                                </Text>
                                            </div>
                                            <div>
                                                <Text style={styles.textRequire}>
                                                    Todo los campos marcados con (*) son requeridos
                                                </Text>
                                            </div>
                                            <Row justify={"space-around"} gutter={[12, 12]} style={{ marginTop: 10 }}>
                                                <Col xs={24} xl={11}>
                                                    <Input
                                                        value={name}
                                                        label="Nombre*"
                                                        required
                                                        type="text"
                                                        onChange={
                                                            element => {
                                                                setName(element);
                                                            }
                                                        }
                                                    />
                                                </Col>
                                                <Col xs={24} xl={11}>
                                                    <Input
                                                        value={description}
                                                        label="Descripción"
                                                        required
                                                        type="text"
                                                        onChange={
                                                            element => {
                                                                setDescription(element);
                                                            }
                                                        }
                                                    />
                                                </Col>
                                                <Col xs={24} xl={23}>
                                                        <Transfer
                                                            dataSource={accountThirdInfo}
                                                            listStyle={styles.listTransfer}
                                                            targetKeys={targetKeys}
                                                            onChange={handleChange}
                                                            render={renderItem}
                                                            locale={transferLocale}
                                                        />
                                                </Col>
                                            </Row>
                                        </>
                                }
                            </Col>
                        </Row>
                    </>
            }
            <ModalNotification
                title={"Grupos de Cuentas"}
                message={message}
                alertType={notificationType}
                show={showNotification}
                onClose={() => shouldNavigate ? navigateTo() : setShowNotification(false)}
            />
        </SubDefaultLayout>
    );
}

const transferLocale = {
    itemUnit: "cuenta",
    itemsUnit: "cuentas",
    titles: ["Cuentas de terceros", "Cuentas de terceros seleccionadas"],
    removeAll: "Deseleccionar todo",
    selectAll: "Seleccionar todo",
    selectInvert: "Invertir selección",
};

const styles = StyleSheet.create({
    divContainer: {
        backgroundColor: "white",
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        borderRadius: 15,
        padding: 30,
        boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.10)"
    },
    transferContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: "20px",
    },
    listTransfer: {
        width: '50%',
        borderRadius: 8,
        borderColor: 'black',
    },
    contentView: {
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loading: {
        maxHeight: "none",
    },
    textTitle: {
        fontSize: 18,
        color: '#434343',
        fontWeight: 600
    },
    textRequire: {
        fontSize: 13,
        color: '#676767'
    }
});

export default AccountsGroupEdit;
