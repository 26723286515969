import { Col, Row, Skeleton, Typography } from "antd";
import { StyleSheet } from "../../../../StyleSheet";
import { image } from "../../../../commons/ImageRoutes";
const Text = Typography;

interface ChangeAccountCardProps {
    accountName?: string;
    accountInfo?: string;
    onClickChange: Function;
    loading: boolean;
};

const ChangeAccountCard = (props: ChangeAccountCardProps) => {
    const { accountName, accountInfo, onClickChange, loading } = props;

    return (
        <Row style={styles.mainContainer} align={"bottom"}>
            <Skeleton
                loading={loading}
                active
                title={false}
                paragraph
            >
                <Col span={20}>
                    <Row gutter={[0, 5]}>
                        <Col span={24} >
                            <Text style={styles.text2Style}>
                                Cuenta Origen
                            </Text>
                        </Col>
                        <Col span={24}>
                            <Text style={styles.text1Style}>
                                {accountName}
                            </Text>
                        </Col>
                        <Col span={24}>
                            <Text style={styles.text2Style}>
                                {accountInfo}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col span={4}>
                    <Row justify={"end"}>
                        <div style={styles.buttonIcon} onClick={() => onClickChange()}>
                            <img
                                src={image.changeAcc}
                                alt={"actualizar"}
                                width={25}
                            />
                        </div>
                    </Row>
                </Col>
            </Skeleton>
        </Row>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        padding: 20,
        borderRadius: 20,
        boxShadow: "0px 0px 8px #00000029",
        backgroundColor: "#eaeaff",
    },
    text1Style: {
        fontSize: 18,
        color: "#000089",
        fontWeight: "bold"
    },
    text2Style: {
        fontSize: 16,
        color: "#000089",
    },
    buttonIcon: {
        cursor: "pointer",
    }
});

export default ChangeAccountCard; 
