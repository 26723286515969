import { ReactNode } from "react";
import { Col, Modal, Row } from "antd";
import { CheckCircleFilled, CloseOutlined } from "@ant-design/icons";
import Buttons from "./Buttons";

interface GenericModalProps {
    show: boolean;
    title: string;
    children?: ReactNode;
    showActionButtons?: boolean;
    onClose?: Function;
    onConfirm?: Function;
    disableConfirmButton?: boolean;
};

const GenericModal = (pros: GenericModalProps) => {
    const { show, title, children, showActionButtons, onClose, onConfirm, disableConfirmButton } = pros;

    return (
        <Modal
            centered
            open={show}
            title={title}
            onCancel={() => onClose && onClose()}
            footer={showActionButtons ?
                <Row gutter={[15, 15]} justify={"end"}>
                    <Col>
                        <Buttons
                            type={"default"}
                            title={"Cerrar"}
                            color="#ffb13d"
                            icon={<CloseOutlined />}
                            action={() => onClose && onClose()}
                        />
                    </Col>
                    <Col>
                        <Buttons
                            isDisabled={disableConfirmButton}
                            type={"primary"}
                            title={"Confirmar"}
                            color="#3d3dff"
                            icon={<CheckCircleFilled />}
                            action={() => onConfirm && onConfirm()}
                        />
                    </Col>
                </Row>
                : null
            }
        >
            <div style={{width: "100%", height: "100%", borderTop: "1px solid #b0b0b0"}}>
                {children}
            </div>
        </Modal>
    );
};

export default GenericModal;
