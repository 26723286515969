import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GroupResponse } from '../../types/types';

export interface GroupState {
    groups: Array<GroupResponse>;
}

const initialState: GroupState = {
    groups: [],
};

export const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        setGroups: (state, action: PayloadAction<GroupState>) => {
            state.groups = action.payload.groups;
        },
        clearGroups: (state) => {
            state.groups = [];
        }
    }
});

export const { setGroups, clearGroups } = groupSlice.actions;
export const getGroupsData = (state: any) => (state.group);
export default groupSlice.reducer;
