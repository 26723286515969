import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserResponse } from "../../types/types";

interface ICompanyUsers {
    data: Array<UserResponse>;
}

const initialState: ICompanyUsers = {
    data: [],
};

const companyUsersSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<ICompanyUsers>) => {
            state.data = action.payload.data;
        },
        clearUser: (state) => {
            state.data = [];
        }
    },
});

export const { setUsers, clearUser } = companyUsersSlice.actions;
export const getUsersData = (state: any) => (state.users);
export default companyUsersSlice.reducer;
