//---------------------------------------------------------------------------------------------------------------------- Imports
import React, { CSSProperties, useEffect, useState } from "react";
import { StyleSheet } from "../StyleSheet";
import {

    LogoutOutlined, SettingOutlined, UserOutlined, BellOutlined
} from "@ant-design/icons";
import {
    Layout, Menu, MenuProps, Row, Col, Button, Dropdown, Avatar, Typography,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../context/keycloak";
import { useSelector } from "react-redux";
import { UserState } from "../store/slices/user";
import { MenuInfo } from "rc-menu/lib/interface";
import { resetPasswordByUserId } from "../methods/axiosMethods";
import { Status } from "../types/types";
import { image } from "../commons/ImageRoutes";
import ModalNotification from "./ModalNotification";
const { Text } = Typography;

//----------------------------------------------------------------------------------------------------------------------  Types
type MenuItem = Required<MenuProps>["items"][number];
type DefaultLayoutProps = {
    children?: React.ReactNode;
    currentPage: string;
    showNotification?: boolean;
    navigateAlert?: Function;
    shouldNavigate?: boolean;
};


//---------------------------------------------------------------------------------------------------------------------- Functions
function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    style?: CSSProperties,
    type?: "group"
): MenuItem {
    return {
        key, icon, children, label, style, type,
    } as MenuItem;
};

//---------------------------------------------------------------------------------------------------------------------- Component
const DefaultLayout = (props: DefaultLayoutProps) => {
    const {
        children, currentPage, showNotification, shouldNavigate
    } = props;
    const { Header, Content } = Layout;
    const [current, setCurrent] = useState(currentPage);
    const navigate = useNavigate();
    const session: UserState = useSelector((state: any) => state.user);
    const [userRol, setUserRol] = useState("");
    const [userName, setUserName] = useState("");
    const [userId, setUserId] = useState("");
    const [openNotification, setOpenNotification] = React.useState(false);
    const [showNoti, setShowNoti] = useState<boolean>(false);
    const [notiMessage, setNotiMessage] = useState("");
    const [notiType, setNotiType] = useState<"INFO" | "SUCCESS" | "ERROR">("SUCCESS");
    const avatarOpt = [
        { key: 'reset-passwd', label: 'Restablecer contraseña' },
    ];
    const location = useLocation();

    useEffect(() => {
        let locationPath = location.pathname.split("/");
        let newCurrent = "";
        if (locationPath && locationPath.length >= 2) {
            switch (locationPath[1]) {
                case "admin":
                    newCurrent = "/admin/accounts";
                    break;
                case "transfers":
                    newCurrent = "/transfers/history";
                    break;
                case "stadistics":
                    newCurrent = "/stadistics"
                    break;
                case "notifications":
                    newCurrent = "/notifications"
                    break;
                default:
                    newCurrent = "/"
            }
            setCurrent(newCurrent);
        }
    }, [location.pathname]);

    const items: MenuItem[] = [
        getItem("Dashboard", "/"),
        getItem("Cuentas", "/admin/accounts"),
        getItem("Transferencias", "/transfers/history"),
        getItem("Estadísticas", "/stadistics"),
        getItem("Notificaciones", "/notifications", <BellOutlined />),
    ];

    const onClick: MenuProps["onClick"] = (e) => {
        setCurrent(e.key);
        navigate(e.key);
    };

    const settingsView = () => {
        navigate("/settings");
    };

    const avatarOption = (option: MenuInfo) => {
        if (option.key === "reset-passwd") {
            resetPasswordByUserId(userId)
                .then((response) => {
                    setNotiMessage(response.data.message);
                    if (response.data.status === Status.CREATED) {
                        setNotiType('SUCCESS');
                    } else {
                        setNotiType('ERROR');
                    }
                    setShowNoti(true);
                })
                .catch(() => {
                    setNotiType('ERROR');
                    setShowNoti(true);
                    setNotiMessage("No fue posible restablecer la contraseña, intente más tarde.");
                });
        }
    };

    useEffect(() => {
        if (session && session.isLogged && session.userInfo && session.userInfo.name) {
            setUserName(session.userInfo.name)
            setUserId(session.userInfo.id)
            if (session.userInfo.typeUser !== undefined) {
                setUserRol(session.userInfo.typeUser);
            }
        }

    }, [session]);

    useEffect(() => {
        if (showNoti) {
            setOpenNotification(true);
        }
    }, [showNoti]);

    useEffect(() => {
        setShowNoti(showNotification ? showNotification : false);
    }, [showNotification, shouldNavigate]);

    return (
        <>
            <Layout style={styles.mainContainer}>
                <Header style={styles.mainHeaderContainer}>
                    <Row>
                        <Col xs={14} sm={12} md={12} lg={16} xl={18} xxl={20}>
                            <div style={styles.logoContainer} onClick={() => navigate("/")}>
                                <img
                                    src={image.divixpayLogo}
                                    alt="logo"
                                    height={"auto"}
                                    width={"80%"}
                                />
                            </div>
                        </Col>
                        <Col xs={10} sm={12} md={12} lg={8} xl={6} xxl={4}>
                            <Row>
                                <Col xs={8} md={4} lg={3} xl={3} xxl={4}>
                                    {
                                        userRol === "admin" ? (
                                            <Button
                                                style={styles.iconButtons}
                                                onClick={settingsView}
                                                icon={<SettingOutlined />}
                                            />
                                        ) : null
                                    }
                                </Col>
                                <Col xs={8} md={4} lg={3} xl={3} xxl={4}>
                                    <Dropdown
                                        placement="bottomRight"
                                        menu={{
                                            onClick: (event) => avatarOption(event),
                                            items: avatarOpt,
                                        }}
                                        arrow={{ pointAtCenter: true }}
                                    >
                                        <Col>
                                            <Button
                                                style={styles.iconButtons}
                                                icon={
                                                    <Avatar
                                                        style={{ backgroundColor: "#000097" }}
                                                        size="small"
                                                        icon={<UserOutlined />}
                                                    />
                                                }
                                            >
                                            </Button>
                                        </Col>
                                    </Dropdown>
                                </Col>
                                <Col xs={0} md={12} lg={15} xl={15} xxl={12}>
                                    {
                                        userName.length > 18 ?
                                            <Text>
                                                {userName.substring(0, 18 - 3) + '...'}
                                            </Text>

                                            :
                                            <Text>
                                                {userName}
                                            </Text>
                                    }
                                </Col>
                                <Col xs={8} md={4} lg={3} xl={3} xxl={4}>
                                    <Button
                                        style={styles.iconButtons}
                                        icon={<LogoutOutlined />}
                                        onClick={() => logout()}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Header>
                <Menu
                    mode="horizontal"
                    items={items}
                    onClick={onClick}
                    selectedKeys={[current]}
                    style={styles.menuStyles}
                />
                <Content style={styles.contentContainer}>
                    {children}
                </Content>
            </Layout >

            <ModalNotification
                show={openNotification}
                alertType={notiType}
                title="Restablecer contraseña"
                message={notiMessage}
                onClose={() => setOpenNotification(false)}
            />
        </>
    );
};



//---------------------------------------------------------------------------------------------------------------------- Styles
const styles = StyleSheet.create({
    mainContainer: {
        height: "100vh",
    },
    mainHeaderContainer: {
        height: 70,
        padding: "0px 20px 0px 20px",
        background: "linear-gradient(90deg, rgba(255,255,255,1) 30%, rgba(255,204,0,1) 100%)",
        borderBottom: "2px solid rgb(255, 237, 164)",
    },
    iconButtons: {
        border: 0,
        color: "#000000",
        boxShadow: "none",
        backgroundColor: "transparent",
    },
    logoContainer: {
        display: "flex",
        width: "200px",
        alignItems: "center",
        marginTop: 4,
        cursor: 'pointer'
    },
    menuStyles: {
        lineHeight: 3,
        background: "linear-gradient(90deg, rgba(255,255,255,1) 30%, rgba(255,204,0,1) 100%)",
        padding: "0px 20px 0px 20px",
    },
    contentContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        backgroundColor: "#ebebeb",
        overflow: "auto",
    },
});



//---------------------------------------------------------------------------------------------------------------------- Exports
export default DefaultLayout;
