import { combineReducers } from 'redux';
import userReducer from './slices/user';
import accountReducer from './slices/account';
import accountsThirdReducer from './slices/accountsThird';
import institutionsReducer from './slices/institutions';
import periodsReducer from './slices/periods';
import companyUsers from './slices/companyUsers';
import groupReducer from './slices/groups';
import accountsByCompany from './slices/accountsByCompany';
import notificationsSlice from './slices/notificationsSlice';



const reducer = combineReducers({
    user: userReducer,
    users: companyUsers,
    account: accountReducer,
    accountsByCompany: accountsByCompany,
    accountThird: accountsThirdReducer,
    group: groupReducer,
    institutions: institutionsReducer,
    period: periodsReducer,
    notifications: notificationsSlice
});



export default reducer;
