import { Breadcrumb, Card, Col, Row } from 'antd';
import { StyleSheet } from "../StyleSheet";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { NavLink, useNavigate } from 'react-router-dom';
import routes from "../types/routes";
import { Content } from 'antd/es/layout/layout';
import { ReactNode } from 'react';
import { SubMenuItemsProps } from '../commons/subMenus';

type SubDefaultLayoutProps = {
    children: ReactNode;
    title?: string;
    childrenSubMenu: SubMenuItemsProps[];
    extraButton?: ReactNode
}

const SubDefaultLayout = (props: SubDefaultLayoutProps) => {
    const { title, children, childrenSubMenu, extraButton } = props;
    const navigate = useNavigate();
    const breadcrumbs = useBreadcrumbs(routes);

    const handleCardClick = (index: number, navigateTo: string) => {
        navigate(navigateTo);
    };

    return (
        <Row style={styles.rowRoot} gutter={[18, 18]}>
            <Col xs={24} md={6} lg={4} style={styles.colRoot}>
                <div style={styles.divTitle}>
                    {title}
                </div>
                <Content style={{ width: '100%' }}>
                    <Row style={styles.rowContent}>
                        {childrenSubMenu.map((element, index) => (
                            <Card
                                key={index}
                                style={{ ...styles.cardRoot, backgroundColor: element.navigateTo === window.location.pathname ? '#3d3dff' : 'white' }}
                                onClick={() => handleCardClick(index, element.navigateTo)}
                            >
                                <Row gutter={[16, 16]} style={styles.rowCard}>
                                    <Col
                                        xs={5}
                                    >
                                        {element.navigateTo === window.location.pathname && element.iconSelected ? (
                                            <img
                                                src={element.iconSelected}
                                                alt={element.title}
                                                width={22}
                                            />
                                        ) : (
                                            <img
                                                src={element.icon}
                                                alt={element.title}
                                                width={22}
                                            />
                                        )}
                                    </Col>
                                    <Col
                                        xs={19}
                                        style={{ color: element.navigateTo === window.location.pathname ? 'white' : 'black' }}
                                    >
                                        {element.title}
                                    </Col>
                                </Row>
                            </Card>
                        ))}
                    </Row>
                </Content>
            </Col>
            <Col xs={24} md={18} lg={20}>
                <div style={styles.divRoot}>
                    <Content style={styles.headerContent}>
                        <Row>
                            <Col style={{ marginTop: 20 }} xs={24} sm={20}>
                                <Breadcrumb>
                                    {breadcrumbs.map(({ match, breadcrumb }) => (
                                        <Breadcrumb.Item>
                                            <NavLink
                                                key={match.pathname}
                                                to={match.pathname}
                                                style={styles.breadcrumbStyle}
                                            >
                                                {breadcrumb}
                                            </NavLink>
                                        </Breadcrumb.Item>
                                    ))}
                                </Breadcrumb>
                            </Col>
                            <Col style={{ marginTop: 20 }} xs={24} sm={4}>
                                <Row style={{ justifyContent: 'flex-end' }}>
                                    {extraButton && extraButton}
                                </Row>
                            </Col>
                        </Row>
                    </Content>
                    <Content style={styles.contentView}>
                        {children}
                    </Content>
                </div>
            </Col>
        </Row >

    );
};

const styles = StyleSheet.create({
    rowRoot: {
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        marginTop: 10,
        display: "flex",
        backgroundColor: "#ebebeb"
    },
    colRoot: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    divTitle: {
        marginTop: 20,
        width: '80%',
        textAlign: 'start',
        fontSize: 20,
        color: 'black',
        fontWeight: 600
    },
    rowContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rowCard: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    divRoot: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: 20
    },
    cardRoot: {
        marginTop: '15px',
        width: '80%',
        boxShadow: "0px 0px 8px #00000029",
        borderRadius: 12,
        padding: 0,
        cursor: "pointer",
        border: "none",
    },
    headerContent: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    contentView: {
        marginTop: 20,
        width: "100%",
        height: "100%",
    },
    breadcrumbStyle: {
        color: '#1a1a80',
        fontWeight: 600
    }
});

export default SubDefaultLayout;
