import { Button } from 'antd';
import { ReactNode } from 'react';
import { StyleSheet } from "../StyleSheet";

interface ButtonsProps {
    type: "link" | "text" | "default" | "primary" | "dashed" | undefined;
    color?: string;
    title: string;
    icon?: ReactNode;
    imageIcon?: string;
    iconLeft?: boolean;
    isDisabled?: boolean;
    action?: Function;
}

const Buttons = (props: ButtonsProps) => {
    const { type, title, icon, isDisabled, color, action, iconLeft, imageIcon } = props;

    return (
        <Button
            type={type}
            shape='round'
            icon={iconLeft ? icon : null}
            style={
                isDisabled
                    ? styles.disabledButton
                    : color
                        ? {
                            borderColor: color,
                            color: type === 'default' ? color : 'white',
                            backgroundColor: type === 'default' ? 'transparent' : color,
                        }
                        : {}
            }
            disabled={isDisabled}
            onClick={() => action && action()}
        >
            <span>
                {title}
                {iconLeft ? null :
                    (imageIcon ?
                        <>
                            <img
                                src={imageIcon}
                                alt={"button"}
                                width={14}
                                style={styles.styleButton}
                            />
                        </>
                        : icon ? <span style={styles.styleButton}>{icon}</span> : null
                    )}
            </span>
        </Button>
    );
};

const styles = StyleSheet.create({
    disabledButton: {
        backgroundColor: '#a2a2a2',
        color: 'white',
    },
    styleButton: {
        marginLeft: 5
    }
});

export default Buttons;
