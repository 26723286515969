import { DotChartOutlined } from "@ant-design/icons";
import { Col, Row, Skeleton, Typography } from "antd";
import { XYPlot, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, VerticalBarSeries, VerticalBarSeriesPoint, RadialChart } from "react-vis";
import { StyleSheet } from "../../../StyleSheet";
import { formatAmount } from "../../../functions/Functions";
import { useEffect, useState } from "react";
const Text = Typography;

interface GraphProps {
    isLoadingT: boolean;
    yDomain: number;
    balanceAcc: number;
    transactionsList: any;
    dimensions: {
        height: number;
        width: number;
    }
    totalInAndOut: Array<
        {
            label: string,
            angle: number,
            total: number,
            color: string
        }>
};

const StadisticsGraph = (props: GraphProps) => {
    const { isLoadingT, yDomain, transactionsList,
        totalInAndOut, dimensions, balanceAcc } = props;
    const [hoveredValue, setHoveredValue] = useState<VerticalBarSeriesPoint>();
    const [flexDirection, setFlexDirection] = useState<"row" | "column">();
    const [widthChart, setWidthChart] = useState<number>(0);
    const [widthBar, setWidthBar] = useState<number>(0);
    const [flexDirAmounts, setFlexDirAmount] = useState<"row" | "column">();
    const [centerAmounts, setCenterAmount] = useState<"center" | "space-evenly">();
    const [totalIn, setTotalIn] = useState<number>(0);
    const [totalOut, setTotalOut] = useState<number>(0);

    useEffect(() => {
        if (dimensions) {
            if (dimensions.width >= 768) {
                setFlexDirection("row");
                let chart = dimensions.width * 0.30;
                let bar = dimensions.width * 0.70;
                setWidthBar(bar);
                setWidthChart(chart);
            } else {
                setFlexDirection("column");
                setWidthBar(dimensions.width);
                setWidthChart(dimensions.width);
            }
        }
    }, [dimensions])

    useEffect(() => {
        if (totalInAndOut && totalInAndOut[0]) {
            setTotalIn(totalInAndOut[0].total);
            setTotalOut(totalInAndOut[1].total);
            if (totalInAndOut[0].total > 50000 && totalInAndOut[1].total > 50000) {
                setFlexDirAmount("column");
                setCenterAmount("center");
            } else {
                setFlexDirAmount("row");
                setCenterAmount("space-evenly");
            }
        }
    }, [totalInAndOut])

    return (
        <>
            {
                isLoadingT ?
                    <div style={styles.skeleton}>
                        <Skeleton />
                        <Skeleton.Node active={isLoadingT}>
                            <DotChartOutlined style={{ fontSize: 40, color: '#bfbfbf' }} />
                        </Skeleton.Node>
                        <Skeleton />
                    </div>
                    :
                    <>
                        <div style={{ display: 'flex', flexDirection: flexDirection }}>
                            <div>
                                {
                                    totalInAndOut[0] && (
                                        <Row justify={"end"} align={"middle"}>
                                            <Col xs={8} sm={4} md={5} lg={4}>
                                                <Text style={styles.textAmount}>Detalle: </Text>
                                            </Col>
                                            <Col xs={6} sm={5} xxl={3}>
                                                {
                                                    hoveredValue ?
                                                        <div style={styles.totalAmountBar}>
                                                            {hoveredValue.y < 0 ? "Egreso " : "Ingreso "}
                                                            {formatAmount.format(hoveredValue.y)}
                                                        </div>
                                                        : null
                                                }
                                            </Col>
                                        </Row>
                                    )}
                                <XYPlot
                                    width={widthBar}
                                    height={500}
                                    xType="ordinal"
                                    yDomain={
                                        [-yDomain,
                                            yDomain]}
                                    strokeWidth={2}
                                    opacity="0.9"
                                >
                                    <VerticalGridLines />
                                    <HorizontalGridLines />
                                    <XAxis title="Día"
                                        style={{
                                            title: {
                                                transform: "translate(10px, 10px)",
                                                fontSize: 12,
                                            },
                                        }}
                                    />
                                    <YAxis
                                        title="Monto"
                                        tickFormat={(v) => formatAmount.format(v)}
                                        style={{
                                            text: {
                                                stroke: "none",
                                                fill: "black",
                                                fontSize: 10,
                                                transform: "translate(-10px, 0)",
                                            },
                                            title: {
                                                fontSize: 12,
                                                transform: "translate(30px, 0)",
                                            }
                                        }}
                                    />
                                    <VerticalBarSeries
                                        data={transactionsList}
                                        barWidth={0.5}
                                        colorType="literal"
                                        onValueMouseOver={(datapoint) => setHoveredValue(datapoint)}
                                        onValueMouseOut={() => setHoveredValue(undefined)}
                                    />

                                </XYPlot>
                            </div>
                            <div>
                                <RadialChart
                                    data={totalInAndOut}
                                    width={widthChart}
                                    height={300}
                                    innerRadius={70}
                                    radius={100}
                                    showLabels={true}
                                    colorType="literal"
                                    opacity="0.9"
                                />
                                {
                                    totalInAndOut[0] && (
                                        <>
                                            <div style={{ display: 'flex', flexDirection: flexDirAmounts, width: "100%", justifyContent: centerAmounts, alignItems: centerAmounts }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    <Text style={styles.textTitle}>Ingresos</Text>
                                                    <Text style={styles.textAmount}>{formatAmount.format(totalIn)}</Text>
                                                </div>
                                                <div style={{ textAlign: 'left' }}>
                                                    <Text style={styles.textTitle}>Egresos</Text>
                                                    <Text style={styles.textAmount}>{formatAmount.format(totalOut)}</Text>
                                                </div>
                                            </div>
                                            <div style={{ textAlign: 'center', marginTop: 15 }}>
                                                <Text style={styles.textTitle}>Saldo</Text>
                                                <Text style={styles.textAmount}>{formatAmount.format(balanceAcc)}</Text>
                                            </div>
                                        </>
                                    )
                                }

                            </div>
                        </div>
                    </>

            }
        </>
    )
}

const styles = StyleSheet.create({
    skeleton: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    totalAmount: {
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    totalAmountBar: {
        textAlign: 'center',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'end'
    },
    textTitle: {
        fontSize: 14,
        color: "#34425b"
    },
    textAmount: {
        fontSize: 20,
        color: "#34425b",
        fontWeight: 'bold'
    }
});

export default StadisticsGraph;
