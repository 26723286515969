import { Card, Col, Divider, Row, Spin, Table, Typography } from "antd";
import { BookOutlined, DownCircleFilled, DownloadOutlined, HourglassOutlined, InfoCircleOutlined, LoadingOutlined, PlusOutlined, UpCircleFilled } from "@ant-design/icons";
import { StyleSheet } from "../../StyleSheet";
import ActionCard from "./components/ActionCard";
import { useCallback, useEffect, useState } from "react";
import { UserState } from "../../store/slices/user";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "../../components/Buttons";
import { subMenusAll } from "../../commons/subMenus";
import { AccountResponse, DispersionData, SchedulerResponse, ScheduledDispersionStatus, Status, TransactionResume, State } from "../../types/types";
import { getAccounts, getPeriodsList, getSchedulersTransactions, getTransactionsByAccountAndPeriod, printStatement } from "../../methods/axiosMethods";
import _ from "lodash";
import { ColumnsType } from "antd/es/table";
import { image } from "../../commons/ImageRoutes";
import { formatAmount } from "../../functions/Functions";
import GraphOfBalances from "./components/GraphOfBalances";
import { useNavigate } from "react-router-dom";
import GenericModal from "../../components/GenericModal";
import SelectInput, { SelectOption } from "../../components/SelectInput";
import moment from "moment";
import 'moment/locale/es';
import ModalNotification, { AlertTypeNew } from "../../components/ModalNotification";
import { setAccount } from "../../store/slices/account";
import { logout } from "../../context/keycloak";
import CardMenuDashboard from "../../components/CardMenuDashboard";
import stylesCss from "./css/dashboard.module.css";
import DrawerScheduledInfo from "../transfers/scheduler/components/DrawerScheduledInfo";

moment.locale('es');
const { Text } = Typography;

const Dashboard = () => {
    // Reducer
    const session: UserState = useSelector((state: any) => state.user);
    const [userRol, setUserRol] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // Data
    const [userName, setUserName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [lastAccess, setLastAccess] = useState("");
    const [accountsList, setAccountsList] = useState<Array<AccountResponse>>([]);
    const [transactionsList, setTransactionsList] = useState<Array<TransactionResume>>([]);
    const [schedulersDispersions, setSchedulersDispersions] = useState<Array<SchedulerResponse>>([]);
    const [incomings, setIncomings] = useState<number>(30);
    const [outcomiongs, setOutcomings] = useState<number>(30);
    const [period, setPeriod] = useState("");
    const [secondPeriod, setSecondPeriod] = useState("");
    // Control
    const [scaleOnHover, setScaleOnHover] = useState(false);
    const [accountsLoading, setAccountsLoading] = useState<boolean>(true);
    const [loadingStatement, setLoadingStatement] = useState<boolean>(false);
    const [schedulerLoading, setSchedulerLoading] = useState<boolean>(true);
    const [transactionsLoading, setTransactionsLoading] = useState<boolean>(true);
    const [currentAccountIndex, setCurrentAccountIndex] = useState(0);
    const [gettingAccounts, setGettingAccounts] = useState<boolean>(false);
    const [showModalToChangeAccount, setShowModalToChangeAccount] = useState<boolean>(false);
    const [accountTmp, setAccountTmp] = useState<string>("");
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<DispersionData>();

    // Constants
    const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;
    const welcomeHeight = 50;

    const columnsScheduled: ColumnsType<SchedulerResponse> = [
        {
            title: "Acciones",
            dataIndex: "operation",
            key: "operation",
            width: "10%",
            render: (event, record: SchedulerResponse) => (
                <div>
                    <Buttons
                        type={'default'}
                        title={"Ver"}
                        color="#4d76f3"
                        action={() => showDrawer(record)} />
                </div>
            ),
        },
        {
            title: "Cuenta Origen",
            dataIndex: "dispersionData",
            key: "dispersionData",
            render: (data: DispersionData) => {
                const formattedDate = new Date(data.date).toLocaleString('es-ES', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                });

                return (
                    <>
                        <div style={{ padding: 0 }}>
                            <Text style={{ fontSize: 15, fontWeight: 600 }}>
                                {data.name}
                            </Text>
                        </div>
                        <div>
                            <Text style={{ fontSize: 13, color: '#8893aa' }}>
                                {`${formatAmount.format(data.balance + data.iva)} - ${formattedDate}`}
                            </Text>
                        </div>
                    </>
                );
            },
        },
    ];

    const columns: ColumnsType<TransactionResume> = [
        {
            title: "Tipo",
            dataIndex: "transactionType",
            key: "transactionType",
            align: 'center',
            className: 'custom-header',
            render: (text, record) => {
                const statusInfo: any = {
                    DONE: {
                        textColor: record.incoming > 0 ? "#2ed2a7" : "#ea915f",
                        tagText: record.incoming > 0 ? "Ingreso" : "Egreso",
                        icon: record.incoming > 0 ? <DownCircleFilled style={{ color: "#2ed2a7" }} /> : <UpCircleFilled style={{ color: "#ea915f" }} />,
                    },
                    REJECTED: {
                        textColor: "#ff5780",
                        tagText: "Rechazada",
                        icon: <InfoCircleOutlined style={{ color: "red" }} />,
                    },
                    CANCELED: {
                        textColor: "#fd0061",
                        tagText: "Cancelada",
                        icon: <InfoCircleOutlined style={{ color: "#fd0061" }} />,
                    },
                    CREATED: {
                        textColor: "#ff5780",
                        tagText: "Rechazada",
                        icon: <InfoCircleOutlined style={{ color: "red" }} />,
                    },
                    PROCESSING: {
                        textColor: "blue",
                        tagText: "Procesando",
                        icon: <HourglassOutlined style={{ color: "#00abfb" }} />,
                    },
                    PENDING: {
                        textColor: "blue",
                        tagText: "Procesando",
                        icon: <HourglassOutlined style={{ color: "#00abfb" }} />,
                    },
                };

                const { tagText, icon, textColor } = statusInfo[record.status] || {};

                return (
                    <>
                        {icon && icon} &nbsp;&nbsp;
                        {textColor && (
                            <Text style={{ fontSize: 14, color: textColor }}>
                                {tagText}
                            </Text>
                        )}
                    </>
                );
            },
        },
        {
            title: "Fecha",
            dataIndex: "date",
            key: "date",
            className: 'custom-header',
            align: 'center',
            render: (text, record) => {
                const dateString = record.date.toString();
                const dateParts = dateString.split(' ');
                const formattedDate = dateParts[0].replace(/-/g, '/');
                return formattedDate;
            }
        },
        {
            title: "Concepto",
            dataIndex: "paymentConcept",
            key: "paymentConcept",
            align: 'center',
            className: 'custom-header',
            render: (text, record) => {
                const textPayment = record.paymentConcept ? record.paymentConcept : 'N/A';
                return textPayment;
            }
        },
        {
            title: "Entidad Receptora",
            dataIndex: "receiverBank",
            key: "receiverBank",
            className: 'custom-header',
            align: 'center',
        },
        {
            title: "Cuenta Receptor",
            dataIndex: "receiverAccountNumber",
            key: "receiverAccountNumber",
            className: 'custom-header',
            align: 'center',
        },
        {
            title: "Monto",
            dataIndex: "incoming" || "outcoming",
            key: "incoming" || "outcoming",
            align: 'center',
            className: 'custom-header',
            render: (text, record) => (
                <span style={{
                    color: (record.incoming > 0 && (record.status === State.DONE || record.status === State.PROCESSING || record.status === State.PENDING))
                        ? "#2ed2a7" :
                        ((record.outcoming > 0 && (record.status === State.DONE || record.status === State.PROCESSING || record.status === State.PENDING)) ? "#ea915f" : "red")
                }}>
                    {record.incoming > 0 ?
                        (record.incoming !== 0 ?
                            formatAmount.format(record.incoming + record.iva) : '$0.00')
                        : (record.outcoming !== 0 ?
                            formatAmount.format(record.outcoming + record.iva) : '$0.00')}
                </span>
            ),
        },
    ];

    const getPrincipalData = useCallback(() => {
        if (session && session.isLogged && session.userInfo && session.userInfo.name) {
            setUserName(session.userInfo.name);
        }
        if (session.userInfo.typeUser !== undefined) {
            setUserRol(session.userInfo.typeUser);
        }

        const currentDate = new Date();

        const formattedDate = new Intl.DateTimeFormat('es-ES', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        }).format(currentDate);

        setLastAccess(formattedDate);
    }, [session]);

    // Obtener la lista de cuentas.
    const getAccountsList = useCallback(async () => {
        setAccountsLoading(true);
        if (session && session.isLogged && session.userInfo && session.userInfo.name) {
            setUserName(session.userInfo.name);
        }
        await getAccounts()
            .then((response) => {
                if (response.data && response.data.data) {
                    if (response.data.status === Status.OK && !_.isEmpty(response.data.data)) {
                        setAccountsList(response.data.data);
                        dispatch(setAccount({ accountsInfo: response.data.data }))
                    }
                }
                setAccountsLoading(false);
            })
            .catch(() => {
                setNotificationType('ERROR');
                setMessage("Hubo un error al obtener las cuentas, intente más tarde...");
                setShowNotification(true);
            });

        setAccountsLoading(false);
        setGettingAccounts(true);
    }, [dispatch, session]);

    const showDrawer = (record: SchedulerResponse) => {
        setOpenDrawer(true);
        setSelectedRecord(record.dispersionData);
    };

    // Obtener lista de transferencias programadas
    const getSchedulers = useCallback(async (account: string) => {
        setSchedulerLoading(true);

        await getSchedulersTransactions(account, ScheduledDispersionStatus.SCHEDULED)
            .then((response) => {
                if (response.data) {
                    const sortedData = response.data.sort(
                        (a, b) => new Date(a.dispersionData.date).getTime() - new Date(b.dispersionData.date).getTime()
                    );
                    setSchedulersDispersions(sortedData);
                }
            })
            .catch(() => {
                setNotificationType('ERROR');
                setMessage("Hubo un error al obtener la lista de transferencias programadas, intente más tarde...");
                setShowNotification(true);
            });
        setSchedulerLoading(false);
    }, []);

    // Obtener transacciones
    const getTransactionsList = useCallback(async (account: string, period: string) => {
        setTransactionsLoading(true);

        await getTransactionsByAccountAndPeriod(account, period)
            .then((response) => {
                if (response.data && response.data.data) {
                    if (response.data.status === Status.OK && !_.isEmpty(response.data.data)) {
                        setTransactionsList(response.data.data.transactions);
                    }
                }
            })
            .catch(() => {
                setNotificationType('ERROR');
                setMessage("Hubo un error al obtener la lista de transferencias, intente más tarde...");
                setShowNotification(true);
            });
        setTransactionsLoading(false);
    }, []);

    useEffect(() => {
        getAccountsList();
    }, [getAccountsList]);

    useEffect(() => {
        getPrincipalData();
    }, [getPrincipalData]);

    useEffect(() => {
        if (gettingAccounts && !_.isEmpty(accountsList) && currentAccountIndex >= 0) {
            setAccountNumber(accountsList[currentAccountIndex].accountNumber);
            setIncomings(accountsList[currentAccountIndex].balance.incomings);
            setOutcomings(accountsList[currentAccountIndex].balance.outcomings);
        }
    }, [accountsList, currentAccountIndex, gettingAccounts]);

    useEffect(() => {
        if (gettingAccounts && !_.isEmpty(accountsList) && currentAccountIndex >= 0) {
            getTransactionsList(
                accountsList[currentAccountIndex].accountNumber,
                accountsList[currentAccountIndex].balance.period
            );
        }
    }, [accountsList, currentAccountIndex, getTransactionsList, gettingAccounts]);

    useEffect(() => {
        if (gettingAccounts && !_.isEmpty(accountsList) && currentAccountIndex >= 0) {
            getSchedulers(accountsList[currentAccountIndex].accountNumber);
        }
    }, [accountsList, currentAccountIndex, getSchedulers, gettingAccounts]);

    useEffect(() => {
        if (gettingAccounts && !_.isEmpty(accountsList) && currentAccountIndex >= 0) {
            getPeriodsFromAccount(accountsList[currentAccountIndex].accountNumber)
        }
    }, [accountsList, currentAccountIndex, gettingAccounts]);

    const getPeriodsFromAccount = async (accountNumber: string) => {
        if (accountNumber !== undefined) {
            setAccountsLoading(true);
            await getPeriodsList(accountNumber)
                .then((response) => {
                    if (response.data && response.data.data && response.data.status === Status.OK) {
                        const [firstPeriod, periodTmp] = _.orderBy(response.data.data.periods, 'period', 'desc');
                        const currentPeriod = moment().format('MM-YYYY');
                        if (moment(firstPeriod.period, 'MM-YYYY').isSame(currentPeriod, 'month')) {
                            setSecondPeriod("");
                        } else if (periodTmp && !moment(firstPeriod.period, 'MM-YYYY').isSame(moment(periodTmp.period, 'MM-YYYY'), 'month')) {
                            const formattedSecondPeriod = moment(periodTmp.period, 'MM-YYYY')
                                .format('MMM-YYYY')
                                .replace(/^\w/, (c) => c.toUpperCase())
                                .replace('.', '');
                            setPeriod(periodTmp.period);
                            setSecondPeriod(formattedSecondPeriod);
                        } else {
                            const formattedPeriod = moment(firstPeriod.period, 'MM-YYYY')
                                .format('MMM-YYYY')
                                .replace(/^\w/, (c) => c.toUpperCase())
                                .replace('.', '');

                            setPeriod(firstPeriod.period);
                            setSecondPeriod(formattedPeriod);
                        }
                    }
                    setAccountsLoading(false);
                })
                .catch(() => {
                    setAccountsLoading(false);
                    setNotificationType('ERROR');
                    setMessage("Hubo un error al obtener la lista de periodos, intente más tarde...");
                    setShowNotification(true);
                })
        }
    };

    const downloadStatement = async () => {
        setLoadingStatement(true);
        await printStatement(accountNumber, period)
            .then((response) => {
                setLoadingStatement(false);
            })
            .catch(() => {
                setLoadingStatement(false);
                setNotificationType('ERROR');
                setMessage("Hubo un error al imprimir el estado de cuenta, intente más tarde..");
                setShowNotification(true);
            })
    };

    return (
        <>
            {accountsLoading ? (
                <div style={styles.divLoading}>
                    <Spin indicator={antIcon} />
                </div>
            ) : (
                <Row justify={"center"} gutter={[24, 24]}>
                    <Col xs={22} xl={17} style={{ marginBottom: 25 }}>
                        <div style={{ height: welcomeHeight, marginTop: 15, marginBottom: 10 }}>
                            <Text style={{ fontSize: 16, fontWeight: 600 }}>
                                Bienvenido &nbsp;
                                <Text style={styles.userName}>
                                    {userName}
                                </Text>
                            </Text>
                            <div>
                                <Text style={{ fontSize: 13, color: '#757575' }}>
                                    Último Acceso: {lastAccess} horas
                                </Text>
                            </div>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <Row gutter={[16, 16]} justify={"space-between"}>
                                {
                                    subMenusAll.map((element, index) => (
                                        <Col xs={8} xl={3} key={index}>
                                            <CardMenuDashboard title={element.title} icon={element.icon} action={element.navigateTo} color={element.color} />
                                        </Col>
                                    ))
                                }
                            </Row>
                        </div>
                        <Row gutter={[16, 16]} style={styles.rowContainer}>
                            <Col xs={24} xl={24} >
                                <Card
                                    title="Historial de transferencias"
                                    style={{
                                        boxShadow: "0px 0px 8px #00000029",
                                        height: transactionsList.length < 5 ? 475 : 'auto'
                                    }}
                                    bodyStyle={{ padding: 5 }}
                                    extra={
                                        <Text
                                            onClick={() => navigate("/transfers/history")}
                                            style={styles.textLink}>
                                            Ver todas
                                        </Text>
                                    }
                                >
                                    {(transactionsLoading) ? (
                                        <div style={styles.loadingContainer}>
                                            <Spin indicator={antIcon} />
                                        </div>
                                    ) : (
                                        <Row justify={"center"}>
                                            <Col xs={22}>
                                                <div style={styles.divRoot} >
                                                    <Row align={"middle"} justify={"end"} gutter={[12, 12]}>
                                                        <Col xs={24} xl={10}>
                                                            <div style={{ display: "flex", justifyContent: 'flex-start' }}>
                                                                <Text>
                                                                    Cuenta
                                                                </Text>
                                                            </div>
                                                            <div>
                                                                <Text style={{ fontWeight: 600 }}>
                                                                    {accountsList[currentAccountIndex]?.alias}
                                                                </Text>
                                                            </div>
                                                            <div>
                                                                <Text style={{ fontSize: 12 }}>
                                                                    STP | CLABE {accountNumber}
                                                                </Text>
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} xl={10} >
                                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                                                <Text style={{ fontSize: 12 }}>
                                                                    Saldo Total
                                                                </Text>
                                                                <div style={{ flexDirection: 'column', display: 'flex', alignItems: 'flex-end' }}>
                                                                    <Text style={{ fontSize: 30, fontWeight: 600, lineHeight: 1 }}>
                                                                        {accountsList[currentAccountIndex]?.balance.balance && accountsList[currentAccountIndex]?.balance.balance !== 0 ? formatAmount.format(accountsList[currentAccountIndex]?.balance.balance) : '$0.00'}
                                                                    </Text>
                                                                    <Text style={{ fontSize: 12 }}>
                                                                        MXN
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={8} xl={4}>
                                                            <div style={styles.divInformation}>
                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                    <Text style={styles.textChangeAccount}>
                                                                        {"Cambiar"}
                                                                    </Text>
                                                                    <Text style={styles.textChangeAccount}>
                                                                        {"Cuenta"}
                                                                    </Text>
                                                                </div>
                                                                <div style={{ cursor: 'pointer', transform: scaleOnHover ? 'scale(0.95)' : 'scale(1)' }}
                                                                    onMouseEnter={() => setScaleOnHover(true)}
                                                                    onMouseLeave={() => setScaleOnHover(false)}
                                                                    onClick={() => setShowModalToChangeAccount(true)}
                                                                >
                                                                    <img
                                                                        src={image.changeAcc}
                                                                        alt={"actualizar"}
                                                                        width={35}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                            <Col xs={24} xl={24} >
                                                <Table
                                                    pagination={false}
                                                    bordered={false}
                                                    dataSource={transactionsList.slice(0, 5)}
                                                    columns={columns}
                                                    locale={{ emptyText: "No hay datos disponibles" }}
                                                    style={{ textAlign: 'center', marginBottom: 10 }}
                                                    scroll={{ y: 400, x: 700 }} />
                                            </Col>
                                        </Row>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} lg={24} xl={10} >
                                <GraphOfBalances loading={transactionsLoading} incomings={incomings} outcomings={outcomiongs} />
                            </Col>
                            <Col xs={24} lg={12} xl={7} style={{ marginTop: 15 }}>
                                <ActionCard
                                    title="Agrega una Cuenta de Terceros"
                                    isBoldTitle={true}
                                    titleColor="#71a408"
                                    subText="CLABE / Tarjeta / Teléfono"
                                    button={
                                        <Buttons
                                            title="Agregar"
                                            type="primary"
                                            color="#00abb1"
                                            icon={<PlusOutlined style={{ marginLeft: 5 }} />}
                                            action={() => navigate("/admin/accountsThird/add-account")} />
                                    }
                                    image="../../images/tarjetas.svg"
                                    color="#d4eba4"
                                    height={185}
                                />
                            </Col>
                            {secondPeriod !== "" && secondPeriod !== undefined ? (
                                <Col xs={24} lg={12} xl={7} style={{ marginTop: 15 }}>
                                    <ActionCard
                                        title="Consulta tu"
                                        titleColor="#000000"
                                        text="Estado de Cuenta"
                                        isBoldText={true}
                                        textStyle={{ color: "#ffcc00" }}
                                        subText={`Periodo: ${secondPeriod}`}
                                        button={
                                            <Buttons
                                                title="Descargar"
                                                type="primary"
                                                color="#3d3dff"
                                                icon={<DownloadOutlined style={{ marginLeft: 5 }} />}
                                                action={() => downloadStatement()} />
                                        }
                                        image="../../images/edo-de-cuenta.svg"
                                        color="#fff5cc"
                                        height={185}
                                        isLoading={loadingStatement}
                                    />
                                </Col>
                            ) : <Col xs={24} lg={12} xl={7} style={{ marginTop: 15 }}>
                                <ActionCard
                                    title="Consulta tu"
                                    titleColor="#000000"
                                    text="Estado de Cuenta"
                                    isBoldText={true}
                                    textStyle={{ color: "#ffcc00" }}
                                    subText={"Por el momento no cuentas con estados de cuenta para consultar."}
                                    button={""}
                                    image="../../images/edo-de-cuenta.svg"
                                    color="#fff5cc"
                                    height={185}
                                    isLoading={loadingStatement}
                                />
                            </Col>
                            }
                        </Row>
                    </Col>
                    <Col xs={22} xl={6} className={stylesCss.viewColumn}>
                        <Row justify={"space-evenly"} align={"middle"} >
                            <Col xs={16} sm={24} md={18} lg={10} xl={13} xxl={17} style={{ marginTop: 10 }}>
                                <Text>
                                    Consultar movimientos
                                </Text>
                            </Col>
                            <Col xs={7} sm={24} md={6} lg={10} xl={9} xxl={7} style={{ marginTop: 10 }}>
                                <Buttons
                                    type={'primary'}
                                    title={'Consultar'}
                                    icon={<BookOutlined />}
                                    action={() => navigate(`/admin/accounts/details/${accountNumber}`)}
                                />
                            </Col>
                        </Row>
                        <div style={{ height: 10, marginTop: 7 }}>
                            <Divider style={styles.lineDivider} />
                        </div>

                        <Row gutter={[16, 16]} style={{ ...styles.rowContainer }}>
                            <Col xs={24} xl={24} >
                                <Card
                                    title="Transferencias programadas"
                                    style={{
                                        boxShadow: "0px 0px 8px #00000029",
                                        marginTop: 5, marginBottom: 5,
                                        height: schedulersDispersions.length < 5 ? 470 : 'auto',
                                    }}
                                    bodyStyle={{ padding: 5 }}
                                    extra={
                                        <Text
                                            onClick={() => navigate("/transfers/scheduled")}
                                            style={styles.textLink}>
                                            Ver todas
                                        </Text>
                                    }
                                >
                                    {(schedulerLoading) ? (
                                        <div style={styles.loadingContainer}>
                                            <Spin indicator={antIcon} />
                                        </div>
                                    ) : (
                                        <Col xs={24} xl={24} >
                                            <Table
                                                pagination={false}
                                                showHeader={false}
                                                scroll={{ x: 200 }}
                                                bordered={false}
                                                dataSource={schedulersDispersions.slice(0, 4)}
                                                columns={columnsScheduled}
                                                locale={{ emptyText: "No hay datos disponibles" }}
                                                style={{ textAlign: 'center', marginBottom: 10 }}
                                            />
                                        </Col>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                        <div style={{ marginTop: 10, marginBottom: 10 }}>
                            <Text style={{ fontSize: 17, fontWeight: 600, }}>
                                Accesos directos
                            </Text>
                        </div>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={userRol === "admin" ? 12 : 24} style={{ marginBottom: userRol === "admin" ? 0 : 15 }}
                            >
                                <CardMenuDashboard
                                    title={"Subir Plantilla de Transferencias"}
                                    icon={image.transferTemplateIcon}
                                    action={"/transfers/immediate"}
                                    state="openModalImport"
                                    color="#DCF8C9"
                                    directAccess
                                />
                            </Col>
                            {
                                userRol === "admin" ? (
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12} style={{ marginBottom: 15 }}>
                                        <CardMenuDashboard
                                            title={"Crear Usuario"}
                                            icon={image.createUserIcon}
                                            action={"/settings/add-user"}
                                            color="#BED6FF"
                                            directAccess
                                        />
                                    </Col>
                                ) : null
                            }
                        </Row>
                    </Col>
                </Row>
            )}
            <GenericModal
                showActionButtons
                show={showModalToChangeAccount}
                title="Cambiar de cuenta"
                onClose={() => setShowModalToChangeAccount(false)}
                onConfirm={() => {
                    setCurrentAccountIndex(
                        accountsList.findIndex((element) => element.accountNumber === accountTmp)
                    );
                    setShowModalToChangeAccount(false);
                }}
            >
                <Row style={{ padding: 30 }}>
                    <Col span={24}>
                        <SelectInput
                            label="Seleccione una cuenta"
                            value={accountTmp}
                            options={
                                accountsList.map((element) => ({
                                    value: element.accountNumber,
                                    label: `${element.accountNumber} | ${element.alias}`
                                } as SelectOption))
                            }
                            onChange={(val) => setAccountTmp(val)}
                        />
                    </Col>
                </Row>
            </GenericModal>
            {
                selectedRecord ?
                    <DrawerScheduledInfo
                        selectedRecord={selectedRecord}
                        drawerVisible={openDrawer}
                        onCloseDrawer={() => setOpenDrawer(false)}
                    />
                    : null
            }
            <ModalNotification
                title={"Dashboard"}
                message={message}
                alertType={notificationType}
                show={showNotification}
                onClose={() =>
                    message === "Hubo un error al obtener las cuentas, intente más tarde..." ?
                        logout()
                        :
                        setShowNotification(false)
                }
            />
        </>
    );
};

const styles = StyleSheet.create({
    divLoading: {
        display: "flex",
        justifyContent: "center",
        width: '100%',
        alignItems: 'center'
    },
    divData: {
        width: '90%',
        display: 'flex',
        paddingLeft: 20
    },
    userName: {
        fontSize: 16,
        fontWeight: 500,
        textTransform: "uppercase",
        color: '#757575'
    },
    rowSubmenu: {
        marginTop: 5,
        display: 'flex',
        justifyContent: 'space-evenly'
    },
    rowContainer: {
        justifyContent: 'center',
        marginBottom: '10px',
    },
    rowPrincipal: {
        width: '100%',
        margin: 0
    },
    divInformation: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-end',
    },
    textChangeAccount: {
        fontSize: 12,
        lineHeight: 1,
        marginRight: 10
    },
    cardRoot: {
        marginTop: '15px',
        width: '100%',
        boxShadow: "0px 0px 8px #00000029",
        borderRadius: 12,
        padding: 10,
        display: 'flex',
    },
    logoContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'end'
    },
    lineDivider: {
        backgroundColor: 'black',
        margin: 0,
    },
    loadingContainer: {
        display: "flex",
        width: '100%',
        height: "100%",
        justifyContent: "center",
        alignItems: 'center',
    },
    textLink: {
        color: "#1677ff",
        cursor: 'pointer'
    },
    divRoot: {
        marginTop: '15px',
    },
});

export default Dashboard;
