import { Card, Col, Progress, Row, Space, Spin, Tooltip, Typography } from "antd";
import { formatAmount } from "../../../functions/Functions";
import { LoadingOutlined } from "@ant-design/icons";
import { StyleSheet } from "../../../StyleSheet";
const { Text } = Typography;

interface GraphOfBalancesProps {
    loading: boolean;
    incomings: number;
    outcomings: number;
};

const GraphOfBalances = (props: GraphOfBalancesProps) => {
    const { loading, incomings, outcomings } = props;
    const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

    return (
        <Card style={{ ...styles.cardRoot, height: 185 }} bodyStyle={{ padding: 10, width: '100%' }}>
            {(loading) ? (
                <div style={styles.loadingContainer}>
                    <Spin indicator={antIcon} />
                </div>
            ) : (
                <Row style={{ height: "100%" }}>
                    <Col span={12} style={{ justifyContent: 'center', display: 'flex' }}>
                        <Space wrap>
                            <Tooltip title={`Ingresos: ${incomings}, Egresos: ${outcomings}`}>
                                <Progress
                                    percent={(incomings * 100) / (incomings + outcomings)}
                                    type="circle"
                                    showInfo={false}
                                    strokeLinecap="butt"
                                    strokeWidth={18}
                                    trailColor={(incomings === 0 && outcomings === 0) ? "#f0f0f0" : "#00c2e8"}
                                    strokeColor={["#9fe543", "#00c2e8", "#e8e8e8"]}
                                    size={150}
                                />
                            </Tooltip>
                        </Space>
                    </Col>
                    <Col
                        span={12}
                        style={styles.colContainer}
                    >
                        <Row style={{ width: "100%", marginBottom: 10 }} gutter={[5, 5]}>
                            <Col>
                                <div style={styles.divColor} />
                            </Col>
                            <Col>
                                <Text>
                                    Ingresos:
                                </Text>
                            </Col>
                            <Col>
                                <Text style={{ color: '#85b34d', fontWeight: 600 }}>
                                    {formatAmount.format(incomings)}
                                </Text>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%", marginTop: 10 }} gutter={[5, 5]}>
                            <Col>
                                <div style={{ ...styles.divColor, backgroundColor: '#00c2e8' }} />
                            </Col>
                            <Col>
                                <Text>Egresos: </Text>
                            </Col>
                            <Col>
                                <Text style={{ color: '#40bfc2', fontWeight: 600 }}>
                                    {formatAmount.format(outcomings)}
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </Card>
    );
};

const styles = StyleSheet.create({
    cardRoot: {
        marginTop: '15px',
        width: '100%',
        boxShadow: "0px 0px 8px #00000029",
        borderRadius: 12,
        padding: 10,
        display: 'flex',
    },
    colContainer: {
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    loadingContainer: {
        display: "flex",
        width: '100%',
        height: "100%",
        justifyContent: "center",
        alignItems: 'center',
    },
    divColor: {
        backgroundColor: '#9fe543',
        width: 15,
        height: 15
    }
});

export default GraphOfBalances;
