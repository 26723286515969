//---------------------------------------------------------------------------------------------------------------------- Imports
import { Space } from "antd";
import { StyleSheet } from "../StyleSheet";
import { ChangeEvent, useEffect, useState } from "react";

//---------------------------------------------------------------------------------------------------------------------- Interfaces
interface InputProps {
    label: string;
    name?: string;
    value: string;
    min?: number;
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    disable?: boolean;
    withError?: boolean;
    errorLabel?: string;
    onChange: (value: string) => void;
    inputRef: React.RefObject<HTMLInputElement>;
};

//---------------------------------------------------------------------------------------------------------------------- Component
const InputAmount = (props: InputProps) => {
    const {
        label, name, value, required, minLength, maxLength, disable,
        withError, errorLabel, onChange, inputRef
    } = props;
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [valueHook, setValueHook] = useState<string>(value);

    useEffect(() => {
        const handleClickOutside = (event: any): void => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                const trimmedValue = valueHook.trim();
                if (trimmedValue !== '') {
                    // Extraer solo los dígitos y el punto decimal
                    const numericValue = trimmedValue.replace(/[^0-9.]/g, '');
                    // Formatear el valor antes de asignarlo
                    const parsedValue = parseFloat(numericValue);
                    // Verificar si el valor es un número válido
                    if (!isNaN(parsedValue)) {
                        // Formatear el número con dos decimales y el símbolo de pesos
                        const formattedValue = parsedValue.toLocaleString('es-MX', {
                            style: 'currency',
                            currency: 'MXN',
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        });

                        setValueHook(formattedValue);

                        // Llamar a la función onChange con el valor numérico sin formato
                        onChange(parsedValue.toString());
                    }
                }
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [valueHook, onChange, inputRef]);

    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
        let err = false;
        let errMsg = "";

        if (required && event.target.value.length < 1) {
            err = true;
            errMsg = "Este campo es obligatorio.";
        } else if (minLength !== undefined && event.target.value.length < minLength) {
            err = true;
            errMsg = `Este campo requiere por lo menos ${minLength} caracteres.`;
        }

        setError(err);
        setErrorMsg(errMsg);

        // Expresión regular para permitir solo números, un punto decimal, comas y el símbolo de dólar
        const regex = /^[0-9,$.]*$/;

        // Verificar si la tecla presionada es la tecla de suprimir (Delete)
        const isDeleteKey = (event.nativeEvent as InputEvent).inputType === "deleteContentBackward";

        // Verificar si el nuevo valor cumple con la expresión regular o es la tecla de suprimir
        if (regex.test(event.target.value) || isDeleteKey) {
            onChange(event.target.value);
            setValueHook(event.target.value);
        }
    };


    return (
        <div style={styles.mainContainer}>
            <div style={{ ...styles.divStyle, ...(withError || error) ? { border: "1px solid #f00" } : (disable) && { border: "1px solid #afafaf" } }}>
                <label htmlFor={name} style={
                    {
                        ...styles.labelStyle,
                        cursor: 'default',
                        userSelect: 'none',
                        pointerEvents: 'none'
                    }}>
                    {`${(required) ? "*" : ""} ${label}`}
                </label>
                <Space.Compact style={
                    {
                        cursor: 'default',
                        userSelect: 'none'
                    }}>
                    <input
                        type="text"
                        value={valueHook}
                        minLength={minLength}
                        maxLength={maxLength}
                        onChange={handleAmountChange}
                        style={styles.inputStyle}
                        ref={inputRef}
                        disabled={disable}
                    />
                </Space.Compact>
            </div>
            {(withError || error) && (
                <label htmlFor={name} style={styles.errorLabel}>
                    {(error) ? errorMsg : errorLabel}
                </label>
            )}
        </div>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    divStyle: {
        display: "flex",
        flexDirection: "column",
        border: "1px solid #000000",
        borderRadius: "10px",
        padding: 5
    },
    labelStyle: {
        fontSize: 12,
        color: "#a1a1a1"
    },
    inputStyle: {
        border: "none",
        width: "100%",
        backgroundColor: "transparent",
        outline: "none",
    },
    errorLabel: {
        color: "#f00",
        fontSize: 12,
    },
});


//---------------------------------------------------------------------------------------------------------------------- Exports
export default InputAmount;
