//---------------------------------------------------------------------------------------------------------------------- Imports
import { useCallback, useEffect, useState } from "react";
import { StyleSheet } from "../StyleSheet";

//---------------------------------------------------------------------------------------------------------------------- Interfaces
export interface SelectOption {
    label: string;
    value: string;
};

interface CustomSelectProps {
    label: string;
    value: string | number;
    options: SelectOption[];
    placeholder?: string;
    withError?: boolean;
    errorLabel?: string;
    onChange?: (value: string) => void;
    disable?: boolean;
};



//---------------------------------------------------------------------------------------------------------------------- Component
const SelectInput = (props: CustomSelectProps) => {
    const { label, options, placeholder, withError, errorLabel, value, onChange, disable } = props;
    const [valueHook, setValueHook] = useState<string | number>();

    const onChangeValue = useCallback((val: string) => {
        setValueHook(val);
        if (onChange) {
            onChange(val);
        }
    }, [onChange]);

    useEffect(() => {
        if (value) {
            setValueHook(value);
        }
    }, [value])

    return (
        <div style={styles.mainContainer}>
            <div style={{ ...styles.inputContainer, ...(withError) ? { border: "1px solid #f00" } : (disable) && { border: "1px solid #afafaf" } }}>
                <div>
                    <label style={styles.labelStyle}>{label}</label>
                </div>
                <div style={styles.selectContainer}>
                    <select
                        placeholder={placeholder}
                        style={styles.selectStyle}
                        onChange={(e) => onChangeValue(e.target.value)}
                        value={valueHook}
                        //value={value}
                        disabled={disable}
                    >
                        {options.map((element, index) => (
                            <option key={index} value={element.value}>
                                {element.label}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {(withError && errorLabel) && (
                <div>
                    <label style={styles.errorText}>{errorLabel}</label>
                </div>
            )}
        </div>
    );
};

//---------------------------------------------------------------------------------------------------------------------- Styles
const styles = StyleSheet.create({
    mainContainer: {
        width: "100%",
        display: 'flex',
        justifyContent: 'center',
        flexDirection: "column",
    },
    inputContainer: {
        display: "flex",
        flexDirection: "column",
        border: "1px solid #000000",
        borderRadius: "10px",
        padding: 5,
    },
    labelStyle: {
        fontSize: 12,
        color: "#a1a1a1"
    },
    selectContainer: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
    },
    selectStyle: {
        width: "100%",
        border: "none",
        backgroundColor: "transparent",
        outline: "none",
    },
    errorText: {
        color: "#f00",
        fontSize: 12,
    }
});

//---------------------------------------------------------------------------------------------------------------------- Exports
export default SelectInput;
