//---------------------------------------------------------------------------------------------------------------------- Imports
import { Row, Col, Typography, Card, Skeleton, Spin } from "antd";
import { StyleSheet } from "../../../StyleSheet";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getAccountById, getBanks, modifyAccount } from "../../../methods/axiosMethods";
import { AccountResponse, AccountType, Status, UpdateAccountRequest } from "../../../types/types";
import { setInstitution } from "../../../store/slices/institutions";
import { clearAccount } from "../../../store/slices/account";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import { subMenusOfAccounts } from "../../../commons/subMenus";
import Input from "../../../components/Input";
import CardDashboard from "../../../components/CardDashboard";
import { image } from "../../../commons/ImageRoutes";
import ModalNotification, { AlertTypeNew } from "../../../components/ModalNotification";
import { LoadingOutlined } from "@ant-design/icons";
const { Text } = Typography;

//---------------------------------------------------------------------------------------------------------------------- View
const MyAccountsEdit = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const accData = useSelector((state: any) => state.account.accountsInfo);
    const [accountNumber, setAccountNumber] = useState("");
    const [ifpe, setIfpe] = useState("");
    const [alias, setAlias] = useState("");
    const [name, setName] = useState("");
    const [enabledAccount, setEnabledAccount] = useState<boolean>(false);
    const [accToEdit, setAccountToEdit] = useState<AccountResponse>();
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [message, setMessage] = useState("");
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [shouldNavigate, setShouldNavigate] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
    const [accountType, setAccountType] = useState<AccountType>();
    const banksList = useSelector((state: any) => state.institutions.institutionsInfo);
    const navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

    useEffect(() => {
        if (banksList && _.isEmpty(banksList)) {
            getBanks()
                .then((response) => {
                    if (response.data.status === Status.OK && response.data.data && !_.isEmpty(response.data.data)) {
                        dispatch(setInstitution({ institutionsInfo: response.data.data }))
                    }
                })
                .catch(() => {
                    setNotificationType('ERROR');
                    setMessage("Hubo un error al obtener la lista Instituciones, intente más tarde...");
                    setShowNotification(true);
                });
        }
    }, [banksList, dispatch]);

    const getAccounts = useCallback(async (accountNumber: string) => {
        setIsLoadingData(true);
        await getAccountById(accountNumber)
            .then((response) => {
                if (response.data.status === Status.OK && !_.isEmpty(response.data.data)) {
                    setAccountToEdit(response.data.data)
                } else {
                    setNotificationType("ERROR");
                    setMessage("No fue posible obtener los datos de la cuenta de tercero en este momento, intente más tarde.");
                    setShouldNavigate(true);
                    setShowNotification(true);
                    setIsLoadingData(false);
                }
            })
            .catch(() => {
                setNotificationType("ERROR");
                setMessage("No fue posible obtener los datos de la cuenta de tercero en este momento, intente más tarde.");
                setShouldNavigate(true);
                setShowNotification(true);
                setIsLoadingData(false);
            });
    }, []);

    useEffect(() => {
        if (params && params.accountNumber) {
            getAccounts(params.accountNumber);
        }
        else if (accData && !_.isEmpty(accData)) {
            setIsLoadingData(true);
            const filteredResult: Array<AccountResponse> = accData.filter(
                (data: AccountResponse) => data.accountNumber === params.accountNumber
            );
            setAccountToEdit(filteredResult[0]);
            setIsLoadingData(false);
        }
    }, [accData, params, getAccounts]);


    useEffect(() => {
        if (accToEdit) {
            setName(accToEdit.name);
            setAccountType(accToEdit.accountType);
            setAlias(accToEdit.alias);
            setAccountNumber(accToEdit.accountNumber);
            setEnabledAccount(accToEdit.enabled)
            let bankData = _.find(banksList, { bankCode: accToEdit.bank });
            setIfpe(bankData && bankData.bankName ? bankData.bankName : "");
            setIsLoadingData(false);
        }
    }, [accToEdit, banksList]);

    const handleSaveButton = (enable: boolean) => {
        if (!_.isEmpty(alias)) {
            const bodyData: UpdateAccountRequest = {
                alias: alias,
                enable: enable
            }
            setIsLoading(true);
            modifyAccount(accountNumber, bodyData)
                .then((response) => {
                    if (response.data.status && response.data.status === Status.UPDATED) {
                        setNotificationType('SUCCESS');
                        setShouldNavigate(true);
                        setMessage(response.data.message);
                        dispatch(clearAccount());
                    } else {
                        setNotificationType("ERROR");
                        setMessage(response.data.message);
                        setShouldNavigate(false);
                    }
                    setShowNotification(true);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    setNotificationType('ERROR');
                    setShouldNavigate(false);
                    setShowNotification(true);
                    setMessage("Hubo un error al editar el registro.");
                });
        } else {
            setNotificationType('INFO');
            setMessage("Hay campos obligatorios incompletos.");
            setShouldNavigate(false);
            setShowNotification(true);
        }
    };

    const navigateNotification = () => {
        navigate("/admin/accounts")
    };

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfAccounts} title="Editar Cuenta">
            {
                isLoading ? (
                    <div style={styles.contentView}>
                        <Spin
                            indicator={antIcon}
                            tip="Cargando..."
                            style={styles.loading}
                        />
                    </div>
                ) : (
                    <>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                                <CardDashboard
                                    title={"Guardar"}
                                    icon={image.saveIcon}
                                    height={135}
                                    isFunction={() => handleSaveButton(true)}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4} >
                                <CardDashboard
                                    title={"Cancelar"}
                                    icon={image.cancelIcon}
                                    action={"/admin/accounts"}
                                    height={135} />
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                                {
                                    enabledAccount ? (
                                        <CardDashboard
                                            title={"Inhabilitar"}
                                            icon={image.disableIcon}
                                            height={135}
                                            isFunction={() => handleSaveButton(false)}
                                        />
                                    ) : (
                                        <CardDashboard
                                            title={"Habilitar"}
                                            icon={image.enableIcon}
                                            height={135}
                                            isFunction={() => handleSaveButton(true)}
                                        />
                                    )
                                }
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                            <Col style={styles.divContainer} md={24} lg={22} xl={20} xxl={16}>
                                {
                                    isLoadingData ? (
                                        <Skeleton />
                                    ) : (
                                        <>
                                            <div>
                                                <Text style={styles.textTitle}>
                                                    Editar Datos de la Cuenta
                                                </Text>
                                            </div><div>
                                                <Text style={styles.textRequire}>
                                                    Todo los campos marcados con (*) son requeridos
                                                </Text>
                                            </div><Row>
                                                <Col xs={24} xl={12} style={{ marginTop: 15 }}>
                                                    <div style={{
                                                        ...styles.inputContainer,
                                                    }}>
                                                        <Input
                                                            label="Alias"
                                                            required
                                                            value={alias}
                                                            type="text"
                                                            maxLength={128}
                                                            onChange={(val) => setAlias(val)} />
                                                    </div>
                                                </Col>
                                            </Row><Col span={24}>
                                                <Card style={styles.cardStyle}>
                                                    <Row>
                                                        <Col xs={24} sm={12} md={12}>
                                                            <div style={styles.infoContainer}>
                                                                <Text style={styles.inputTextTitle}>
                                                                    Nombre
                                                                </Text>
                                                            </div>
                                                            <div>
                                                                <Text type="secondary" style={styles.inputTextDescr}>
                                                                    <b>{name}</b>
                                                                </Text>
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} sm={12} md={12}>
                                                            <div style={styles.infoContainer}>
                                                                <Text style={styles.inputTextTitle}>
                                                                    Número de Cuenta
                                                                </Text>
                                                            </div>
                                                            <div>
                                                                <Text type="secondary" style={styles.inputTextDescr}>
                                                                    <b>{accountNumber}</b>
                                                                </Text>
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} sm={12} md={12} style={{ marginTop: 10 }}>
                                                            <div style={styles.infoContainer}>
                                                                <Text style={styles.inputTextTitle}>
                                                                    Tipo
                                                                </Text>
                                                            </div>
                                                            <div>
                                                                <Text type="secondary" style={styles.inputTextDescr}>
                                                                    <b>{accountType}</b>
                                                                </Text>
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} sm={12} md={12} style={{ marginTop: 10 }}>
                                                            <div style={styles.infoContainer}>
                                                                <Text style={styles.inputTextTitle}>
                                                                    IFPE
                                                                </Text>
                                                            </div>
                                                            <div>
                                                                <Text type="secondary" style={styles.inputTextDescr}>
                                                                    <b>{ifpe}</b>
                                                                </Text>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </>
                                    )
                                }
                            </Col>
                        </Row>
                    </>
                )
            }
            <ModalNotification
                title={"Mis Cuentas"}
                message={message}
                alertType={notificationType}
                show={showNotification}
                onClose={() => shouldNavigate ? navigateNotification() : setShowNotification(false)}
            />
        </SubDefaultLayout>
    );
};

//---------------------------------------------------------------------------------------------------------------------- Styles
const styles = StyleSheet.create({
    divContainer: {
        backgroundColor: "white",
        marginTop: "1%",
        display: "flex",
        flexDirection: "column",
        borderRadius: 15,
        padding: 30,
        boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.10)"
    },
    cardStyle: {
        border: "none",
        boxShadow: "0px 4px 6px #00000029",
        backgroundColor: "#eaeaff",
    },
    inputTextTitle: {
        fontSize: 15,
        paddingLeft: 10,
        color: "#000089"
    },
    inputTextDescr: {
        fontSize: 18,
        paddingLeft: 10,
        color: "#000089"
    },
    infoContainer: {
        display: 'flex',
        marginTop: "5px",
    },
    inputContainer: {
        marginBottom: "20px",
        justifyContent: "flex-end",
    },
    textTitle: {
        fontSize: 18,
        color: '#434343',
        fontWeight: 600
    },
    textRequire: {
        fontSize: 13,
        color: '#676767'
    },
    contentView: {
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loading: {
        maxHeight: "none",
    },
});

//---------------------------------------------------------------------------------------------------------------------- Exports
export default MyAccountsEdit;
