import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from 'react-redux';
import { store } from './store/index';
import KeycloakProvider from "./context/KeycloakContext";



const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);



root.render(
  <Provider store={store}>
    <KeycloakProvider>
      <App />
    </KeycloakProvider>
  </Provider>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
