//---------------------------------------------------------------------------------------------------------------------- Imports
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { StyleSheet } from "../StyleSheet";
import { useCallback, useState } from "react";

//---------------------------------------------------------------------------------------------------------------------- Interfaces
interface InputProps {
    label: string;
    type: "number" | "text" | "password" | "email" | "date" | "time"
    name?: string;
    value?: string | number;
    prefix?: string;
    min?: number;
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    disable?: boolean;
    withError?: boolean;
    errorLabel?: string;
    onChange?: (value: string) => void;
    step?: number;
    isTextNumeric?: boolean;
};

//---------------------------------------------------------------------------------------------------------------------- Component
const Input = (props: InputProps) => {
    const {
        label, type, name, value, required, minLength, maxLength, disable,
        withError, errorLabel, onChange, prefix, min, step, isTextNumeric,
    } = props;
    const [show, setShow] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [valueHook, setValueHook] = useState<string | number | undefined>(value);

    const validator = useCallback((val: string) => {
        let err = false;
        let errMsg = "";

        if (required && val.length < 1) {
            err = true;
            errMsg = "Este campo es obligatorio.";
        }
        else if (minLength !== undefined && val.length < minLength) {
            err = true;
            errMsg = `Este campo requiere por lo menos ${minLength} caracteres.`;
        }

        setError(err);
        setErrorMsg(errMsg);
        let value = "";
        if (isTextNumeric) {
            value = val.replace(/[^0-9]/g, '');
        } else {
            value = val;
        }
        setValueHook(value);
        if (onChange) {
            onChange(value);
        }
    }, [required, minLength, onChange, isTextNumeric]);

    return (
        <div style={styles.mainContainer}>
            <div style={{ ...styles.divStyle, ...(withError || error) ? { border: "1px solid #f00" } : (disable) && { border: "1px solid #afafaf" } }}>
                <label htmlFor={name} style={
                    {
                        ...styles.labelStyle,
                        cursor: 'default',
                        userSelect: 'none',
                        pointerEvents: 'none'
                    }}>
                    {`${(required) ? "*" : ""} ${label}`}
                </label>
                <div style={{
                    ...styles.inputContainer,
                    cursor: 'default',
                    userSelect: 'none'
                }}>
                    {
                        prefix ?
                            prefix
                            : null
                    }
                    <input
                        name={name}
                        value={valueHook}
                        type={(show) ? "text" : type}
                        data-date-format="DD-MM-YYYY"
                        minLength={minLength}
                        maxLength={maxLength}
                        onChange={(e) => validator(e.target.value)}
                        disabled={disable}
                        style={styles.inputStyle}
                        min={min ? min : 0}
                        step={step ? step : undefined}
                    />
                    {(type === "password") && (
                        <div style={styles.eyeContainer} onClick={() => setShow(!show)}>
                            {(show) ? (
                                <EyeInvisibleOutlined />
                            ) : (
                                <EyeOutlined />
                            )}
                        </div>
                    )}
                </div>
            </div>
            {(withError || error) && (
                <label htmlFor={name} style={styles.errorLabel}>
                    {(error) ? errorMsg : errorLabel}
                </label>
            )}
        </div>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    divStyle: {
        display: "flex",
        flexDirection: "column",
        border: "1px solid #000000",
        borderRadius: "10px",
        padding: 5,
    },
    labelStyle: {
        fontSize: 12,
        color: "#a1a1a1"
    },
    inputContainer: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
    },
    inputStyle: {
        border: "none",
        width: "100%",
        backgroundColor: "transparent",
        outline: "none",
    },
    eyeContainer: {
        display: "flex",
        padding: "0px 10px 0px 10px",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
    },
    errorLabel: {
        color: "#f00",
        fontSize: 12,
    },
});

//---------------------------------------------------------------------------------------------------------------------- Exports
export default Input;
