//---------------------------------------------------------------------------------------------------------------------- Imports
import { Card, Col, Row } from "antd";
import { StyleSheet } from "../../../StyleSheet";
import { ReactNode } from "react";

//---------------------------------------------------------------------------------------------------------------------- Interfaces
interface CardButtonProps {
    color: string,
    icon: ReactNode;
    text: string;
    action: Function;
};

//---------------------------------------------------------------------------------------------------------------------- Component
const CardButton = (props: CardButtonProps) => {
    const { color, icon, text, action } = props;

    return (
        <Card
            style={styles.cardContainer}
            onClick={() => action()}
        >
            <Row style={styles.mainContainer}>
                <Col span={12}>
                    <div style={{ ...styles.iconContainer, ...{ backgroundColor: color } }}>
                        {icon}
                    </div>
                </Col>
                <Col span={12}>
                    {text}
                </Col>
            </Row>
        </Card>
    );
};

//---------------------------------------------------------------------------------------------------------------------- Styles
const styles = StyleSheet.create({
    cardContainer: {
        boxShadow: "0px 0px 8px #00000029",
        borderRadius: 12,
        height: "100%",
        border: "none",
        cursor: "pointer",
        alignItems:'center',
        display:'flex'
    },
    mainContainer: {
        display: "flex",
        width: "100%",
        alignItems: "center",
    },
    iconContainer: {
        display: "flex",
        width: 70,
        height: 70,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        marginRight: 20,
    },
});

//---------------------------------------------------------------------------------------------------------------------- Exports
export default CardButton;
