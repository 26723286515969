import { Card, Row, Col, Typography, Divider } from 'antd';
import { StyleSheet } from "../StyleSheet";
import { AccountDest, TransferObj } from '../types/types';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'moment/locale/es';
import moment from 'moment';

const { Text } = Typography;

interface CardProps {
    title: string;
    backgroundColor: string;
    fontColor: string;
    accDestDataForm: TransferObj
    accDestData: AccountDest;
    showFooterDate?: boolean;
}

const CardDataTransferConfirm = (props: CardProps) => {
    const { title, accDestDataForm, accDestData, showFooterDate,
        backgroundColor, fontColor } = props;
    const [amountTotal, setAmountTotal] = useState("");
    const [dateTime, setDateTime] = useState("");
    const [dateDay, setDateDay] = useState("");
    const [dateHour, setDateHour] = useState("");
    const [accTypeDest, setAccTypeDest] = useState("");
    const [accBankDest, setAccBankDest] = useState("");

    useEffect(() => {
        let amountTotal = Number(accDestDataForm.amountDest) + Number(accDestDataForm.ivaDest);
        let date = moment(accDestDataForm.dateTimeDest).format("LL").toString();
        let dateDay = moment(accDestDataForm.dateTimeDest).format("dddd").toString();
        let dateHour = dayjs(accDestDataForm.dateTimeDest?.toString()).format("hh:mm a");
        setAmountTotal(amountTotal.toFixed(2));
        setDateDay(dateDay);
        setDateTime(date);
        setDateHour(dateHour);

        switch (accDestData.accType) {
            case "CLABE":
                setAccTypeDest("CLABE");
                break;
            case 'DEBIT':
                setAccTypeDest("Tarjeta de Debito");
                break;
            case 'CEL':
                setAccTypeDest("Teléfono celular");
                break;
        }

        if (accDestData.bankDataDest) {
            setAccBankDest(accDestData.bankDataDest.bankName)
        } else {
            setAccBankDest("STP")
        }

    }, [accDestData, accDestDataForm])

    return (
        <>
            <Card style={{ ...styles.cardRoot, backgroundColor: backgroundColor }} bodyStyle={{ padding: 10 }}>
                <div style={styles.divRow}>
                    <Text style={{ ...styles.textTitle, color: fontColor }}>
                        {title}
                    </Text>
                    <Text style={{ ...styles.textAccName, color: fontColor }}>
                        {
                            accDestData.accountName.length > 22 ?
                                accDestData.accountName.substring(0, 22 - 3) + '...'
                                : accDestData.accountName
                        }
                    </Text>
                    <Text style={{ ...styles.textAccNumber, color: fontColor }}>
                        {accBankDest} | {accTypeDest}
                    </Text>
                    <Text style={{ ...styles.textAccNumber, color: fontColor }}>
                        {accDestData.accountNumber}
                    </Text>
                    <Divider style={styles.divider} />
                    <Text style={styles.textDescTitle}>
                        Descripción de concepto
                    </Text>
                    <Text style={{ ...styles.textDescData, color: fontColor }}>
                        {accDestDataForm.conceptDest}
                    </Text>
                    <Text style={styles.textDescTitle}>
                        Referencia Numérica
                    </Text>
                    <Text style={{ ...styles.textDescData, color: fontColor }}>
                        {accDestDataForm.numRefDest}
                    </Text>
                    <Row>
                        <Col span={10}>
                            <div style={styles.divRow}>
                                <Text style={styles.textDescTitle}>
                                    Importe sin IVA
                                </Text>
                                <Text style={{ ...styles.textDescData, color: fontColor }}>
                                    ${Number(accDestDataForm.amountDest).toFixed(2)}
                                </Text>
                            </div>
                        </Col>
                        <Col span={12} style={styles.colView}>
                            <div style={styles.divRow}>
                                <Text style={styles.textDescTitle}>
                                    Total
                                </Text>
                                <Text style={{ ...styles.textDescData, color: fontColor }}>
                                    ${amountTotal}
                                </Text>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Card>
            {
                showFooterDate ?
                    <Row style={{ marginTop: 15 }} gutter={[12, 12]} align={"middle"}>
                        <Col xs={24} md={14} lg={16}>
                            <Card style={{ ...styles.cardDate, backgroundColor: backgroundColor }} bodyStyle={styles.cardBody}>
                                <div style={styles.divRow}>
                                    <Text style={styles.textDateTitle}>
                                        Fecha
                                    </Text>
                                    <Text style={{ ...styles.textDateData, color: fontColor }}>
                                        {dateTime}
                                    </Text>
                                    <Text style={{ ...styles.textDateDay, color: fontColor }}>
                                        {dateDay}
                                    </Text>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} md={10} lg={8}>
                            <Card style={{ ...styles.cardDate, backgroundColor: backgroundColor }} bodyStyle={styles.cardBody}>
                                <div style={styles.divRow}>
                                    <Text style={styles.textDateTitle}>
                                        Hora
                                    </Text>
                                    <Text style={{ ...styles.textDescData, color: fontColor }}>
                                        {dateHour}
                                    </Text>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    : null
            }

        </>
    );
};

const styles = StyleSheet.create({
    cardRoot: {
        width: '100%',
        boxShadow: "0px 0px 5px #00000029",
        borderRadius: 12,
        padding: 10,
    },
    cardDate: {
        width: '100%',
        boxShadow: "0px 0px 5px #00000029",
        borderRadius: 12,
        padding: 10,
        minHeight: 110,
        display: 'flex',
        alignItems: 'center'
    },
    cardBody: { 
        padding: "10px 0px 10px 15px" 
    },
    textTitle: {
        fontSize: 15
    },
    textAccNumber: {
        fontSize: 15
    },
    textAccName: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    textDescTitle: {
        fontSize: 13,
        color: "#a1a1a1"
    },
    textDescData: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    textDateDay: {
        fontSize: 12,
        textTransform: 'capitalize'
    },
    textDateTitle: {
        fontSize: 15,
        color: "#434343"
    },
    textDateData: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    divRow: {
        display: 'flex',
        flexDirection: 'column'
    },
    colView: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    divider: {
        margin: "5px 0px 10px",
        borderBlockStart: "2px solid #ffb13d",
        width: "60%",
        minWidth: "60%"
    }
});

export default CardDataTransferConfirm;
