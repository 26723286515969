import { Row, Col } from "antd";
import { StyleSheet } from "../../../StyleSheet";
import { AccountDest, AccountOrigin, TransferObj } from "../../../types/types";
import CardDataTransfer from "../../../components/CardDataTransfer";
import { image } from "../../../commons/ImageRoutes";
import CardDataTransferConfirm from "../../../components/CardDataTransferConfirm";

interface Props {
    accDestDataForm: TransferObj
    accDestData: AccountDest;
    accOriginData: AccountOrigin;
    isSqueduled: boolean
}

const TransferConfirmation = (props: Props) => {
    const { accDestData, accOriginData, isSqueduled, accDestDataForm } = props;

    return (
        <>
            <h2 style={styles.h2}>
                {
                    isSqueduled ? "Confirme los Datos de la Transferencia Programada"
                        : "Confirme los Datos de Transferencia"
                }
            </h2>
            <Row gutter={[12, 12]}>
                <Col xs={24} sm={13}>
                    <Row gutter={[12, 12]} align={"middle"}>
                        <Col xs={24} sm={22}>
                            <CardDataTransfer
                                accountName={accOriginData.accountName}
                                accountNumber={accOriginData.accountNumber}
                                backgroundColor={"#eaeaff"}
                                fontColor={"#000089"}
                                cardType="origin"
                            />
                        </Col>
                        <Col xs={24} sm={2} style={styles.colView}>
                            <img
                                src={image.transferImmIconBlue}
                                alt={"transferIcon"}
                                style={styles.logoIcon}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={11}>
                    <CardDataTransferConfirm
                        title="Cuenta Destino"
                        backgroundColor={"#fffeea"}
                        fontColor={"#434343"}
                        accDestDataForm={accDestDataForm}
                        accDestData={accDestData}
                        showFooterDate={isSqueduled}
                    />
                </Col>
            </Row>
        </>
    );
};

const styles = StyleSheet.create({
    colView: {
        display: 'flex',
        justifyContent: "center",
        height: 50,
        alignItems: 'center'
    },
    logoIcon: {
        width: 25,
        height: 25
    },
    h2: {
        color: "#434343",
        marginBottom: 30,
        paddingLeft: 20
    }
});

export default TransferConfirmation;