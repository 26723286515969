import { Card, Col, Row, Typography } from 'antd';
import { StyleSheet } from "../StyleSheet";
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
const { Text } = Typography;

interface CardDashboardProps {
    title: string;
    color?: string;
    icon?: string;
    action?: string;
    state?: string;
    isFunction?: Function;
    height?: number;
}

const CardDashboard = (props: CardDashboardProps) => {
    const { title, icon, action, height, isFunction, state } = props;
    const [scaleOnHover, setScaleOnHover] = useState(false);
    const navigate = useNavigate();

    return (
        <div
            onClick={() =>
                isFunction ? isFunction()
                    // si existe action y state, lo manda con state
                    : action && state ? navigate(action, {
                        state: state
                    })
                        : action ? navigate(action)
                            : null
            }
            style={{ cursor: 'pointer' }}
            onMouseEnter={() => setScaleOnHover(true)}
            onMouseLeave={() => setScaleOnHover(false)}
        >
            <Card
                style={{ ...styles.cardRoot, height: (height ? height : 160), transform: scaleOnHover ? 'scale(0.95)' : 'scale(1)' }}
                bodyStyle={{ padding: '5px 20px 5px 20px' }}>
                <Row style={styles.rowRoot}>
                    <Col style={{ marginBottom: 10 }}>
                        <div>
                            <img
                                src={icon}
                                alt={title}
                                width={45}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div style={{ lineHeight: 1.3 }}>
                            <Text style={{ fontWeight: 600, lineHeight: 1.3 }} >
                                {title}
                            </Text>
                        </div>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

const styles = StyleSheet.create({
    cardRoot: {
        marginTop: 10,
        width: '100%',
        boxShadow: "0px 0px 8px #00000029",
        borderRadius: 12,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        transition: 'transform 0.3s',
    },
    rowRoot: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center'
    }
});

export default CardDashboard;
