import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountResponse } from '../../types/types';

export interface AccountState {
    accountsInfo: Array<AccountResponse>;
}

const initialState: AccountState = {
    accountsInfo: [],
};

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setAccount: (state, action: PayloadAction<AccountState>) => {
            state.accountsInfo = action.payload.accountsInfo;
        },
        clearAccount: (state) => {
            state.accountsInfo = [];
        }
    }
});

export const { setAccount, clearAccount } = accountSlice.actions;
export const getAccountsData = (state: any) => (state.account);
export default accountSlice.reducer;
