import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Period } from '../../types/types';

export interface PeriodState {
    periodsInfo: Array<Period>;
}

const initialState: PeriodState = {
    periodsInfo: []
};

export const periodSlice = createSlice({
    name: 'period',
    initialState: initialState,
    reducers: {
        setPeriod: (state: PeriodState, action: PayloadAction<PeriodState>) => {
            state.periodsInfo = action.payload.periodsInfo;
        },
        clearPeriod: (state) => {
            state.periodsInfo = [];
        }
    }
});

export const { setPeriod, clearPeriod } = periodSlice.actions;
export const getPeriodData = (state: any) => (state.period);
export default periodSlice.reducer;
