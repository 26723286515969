import { Col, Row, Space, Spin, Table, TableProps, Tag, Typography, Upload, UploadFile, UploadProps, message } from "antd";
import { StyleSheet } from "../../../StyleSheet";
import { useCallback, useEffect, useState } from "react";
import { AccountThirdData, AccountThirdDataRequest, AccountThirdDisableRequest, AccountType, CatalogBankResponse, CreateMultipleAccountThirdRequest, DisableMultipleAccountThirdRequest, Institution, Status, TableParams, AccountThirdObj, DeleteAccountThirdRequest } from "../../../types/types";
import { CopyOutlined, DeleteOutlined, EditOutlined, LoadingOutlined, MinusOutlined, PlusOutlined, UploadOutlined, } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { Link, useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { addAccountThird, deleteAccountThird, disableAccountThirdList, getAccountsThird, getBanks } from "../../../methods/axiosMethods";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { clearAccountThird, setAccountThird } from "../../../store/slices/accountsThird";
import { setInstitution } from "../../../store/slices/institutions";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import { subMenusOfAccounts } from "../../../commons/subMenus";
import Buttons from "../../../components/Buttons";
import CardDashboard from "../../../components/CardDashboard";
import { image } from "../../../commons/ImageRoutes";
import GenericModal from "../../../components/GenericModal";
import * as XLSX from 'xlsx';
import ModalNotification, { AlertTypeNew } from "../../../components/ModalNotification";

const { Text } = Typography;

const AccountsThird = () => {
    const session = useSelector((state: any) => state.user);
    const [userRol, setUserRol] = useState("");
    const accList = useSelector((state: any) => state.accountThird.accountsThirdInfo);
    const [accountsThirdList, setAccountsThirdList] = useState<Array<AccountThirdData>>([]);
    const [accountsThirdListTmp, setAccountsThirdListTmp] = useState<Array<AccountThirdData>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const hasSelected = selectedRowKeys.length > 0;
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [openModalImport, setOpenModalImport] = useState<boolean>(false);
    const [disableConfirmButton, setDisableConfirmButton] = useState<boolean>(true);
    const banksList = useSelector((state: any) => state.institutions.institutionsInfo);
    const [banks, setBanks] = useState<CatalogBankResponse[]>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fileList, setFileList] = useState<UploadFile[]>();
    const [file, setFile] = useState<any>();

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            // Se define que inicia en la pag 1 y el tamaño de la pagina será de 10
            current: 1,
            pageSize: 10,
            showSizeChanger: false,
        },
    });

    useEffect(() => {
        if (file) {
            setDisableConfirmButton(false);
        }
    }, [file]);

    useEffect(() => {
        if (session && session.isLogged) {
            if (session.userInfo.typeUser !== undefined) {
                setUserRol(session.userInfo.typeUser);
            }
        }
    }, [session]);

    const getAccountsThirdList = useCallback(() => {
        setLoading(true);
        getAccountsThird()
            .then((response) => {
                if (response.data && response.data.data &&
                    response.data.status === Status.OK &&
                    !_.isEmpty(response.data.data)) {
                    setAccountsThirdList(response.data.data);
                    setAccountsThirdListTmp(accList);
                    dispatch(setAccountThird({
                        accountsThirdInfo: response.data.data
                    }));
                } else if (response.data && response.data.data &&
                    response.data.status === Status.OK) {
                    setAccountsThirdList(response.data.data);
                    setAccountsThirdListTmp(accList);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setNotificationType('ERROR');
                setNotificationMessage("No fue posible obtener la lista de cuentas de terceros, intente más tarde.");
                setShowNotification(true);
                setLoading(false);
            })
    }, [accList, dispatch]);

    useEffect(() => {
        if (_.isEmpty(accList)) {
            getAccountsThirdList();
        } else {
            setAccountsThirdList(accList);
            setAccountsThirdListTmp(accList);
        }
    }, [getAccountsThirdList, accList]);

    const handleTableChange: TableProps<AccountThirdData>["onChange"] = (
        pagination,
    ) => {
        setTableParams({
            pagination,
        });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setAccountsThirdListTmp([]);
        }
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const headerSearcher = (text: string) => {
        const filteredResults = accountsThirdList.filter(
            (data: AccountThirdData) =>
                data.bankAccountNumber.toLowerCase().includes(text.toLowerCase()) ||
                data.name.toLowerCase().includes(text.toLowerCase()) ||
                data.rfc.toLowerCase().includes(text.toLowerCase())
        );
        setAccountsThirdListTmp(filteredResults);
        setSelectedRowKeys([])
    };

    const handleCopy = (item: string) => {
        navigator.clipboard.writeText(item);
    };

    const columns: ColumnsType<AccountThirdData> = [
        {
            title: "Estatus",
            dataIndex: "enabled",
            key: "enabled",
            className: 'custom-header',
            render: (enabled: boolean) => (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            margin: 5,
                            backgroundColor: (enabled) ? "#0fe248" : "#ff3d6b",
                        }}
                    />
                    <label
                        style={{
                            color: (enabled) ? "#0fe248" : "#ff3d6b",
                        }}
                    >
                        {enabled ? "Activa" : "Inactiva"}
                    </label>
                </div>
            ),
        },
        {
            title: "Acciones",
            dataIndex: "operation",
            key: "operation",
            width: "30%",
            className: 'custom-header',
            render: (value, record: AccountThirdData) => (
                <Row gutter={[16, 12]} justify={"center"}>
                    <Col>
                        <Buttons
                            type="primary"
                            title="Transferir"
                            color="#3d3dff"
                            action={() => navigate("/transfers/immediate", { state: record })}
                            imageIcon={image.transferImmIconSelected}
                            isDisabled={!record.enabled}
                        />
                    </Col>
                    <Col>
                        <Buttons
                            type="default"
                            title="Editar"
                            color="#ffb13d"
                            action={() => navigate(`/admin/accountsThird/${record.id}`)}
                            icon={<EditOutlined />}
                        />
                    </Col>
                </Row>
            ),
        },
        {
            title: "Nombre",
            dataIndex: "name",
            key: "name",
            className: 'custom-header',
            width: "10%",
        },
        {
            title: "RFC",
            dataIndex: "rfc",
            key: "rfc",
            className: 'custom-header',
            render: (rfc: string) => (
                <Text>
                    {rfc ? rfc : "-"}
                </Text>
            )
        },
        {
            title: "Cuenta",
            dataIndex: "bankAccountNumber",
            key: "bankAccountNumber",
            className: 'custom-header',
            render: (bankAccountNumber: string) => (
                <div>
                    <span>{bankAccountNumber}</span>
                    <span style={{ marginLeft: "5px" }}>
                        <CopyOutlined onClick={() => handleCopy(bankAccountNumber)} />
                    </span>
                </div>
            ),
        },
        {
            title: "Tipo de Cuenta",
            dataIndex: "accountType",
            key: "accountType",
            className: 'custom-header',
            render: (accountType: string) => (
                <Tag
                    color={
                        accountType === "CLABE"
                            ? "green"
                            : accountType === "CEL"
                                ? "purple"
                                : accountType === "DEBIT"
                                    ? "blue"
                                    : undefined
                    }
                >
                    {
                        accountType === "CLABE"
                            ? "CLABE"
                            : accountType === "CEL"
                                ? "Teléfono"
                                : accountType === "DEBIT"
                                    ? "Tarjeta"
                                    : ""
                    }
                </Tag>
            ),
        },
    ];

    useEffect(() => {
        if (banksList && _.isEmpty(banksList)) {
            setLoading(true);
            getBanks()
                .then((response) => {
                    if (response.data.status === Status.OK && response.data.data && !_.isEmpty(response.data.data)) {
                        dispatch(setInstitution({ institutionsInfo: response.data.data }))
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setNotificationType('ERROR');
                    setNotificationMessage("No fue posible obtener las instituciones bancarias en este momento, intente más tarde.");
                    setShowNotification(true);
                    setLoading(false);
                });
        } else {
            setBanks(banksList);
        }
    }, [banksList, dispatch]);

    const enableOrDisableAccounts = (isEnable: boolean) => {
        const data: DisableMultipleAccountThirdRequest = { accountThirdDataRequestList: [] };
        selectedRowKeys.forEach((element) => {
            accList.forEach((account: AccountThirdData) => {
                if (element.valueOf()) {
                    if (account.id === element) {
                        let accountData: AccountThirdDisableRequest = {
                            accountThirdId: account.id,
                            enable: isEnable
                        };
                        data.accountThirdDataRequestList.push(accountData)
                    }
                }
            })
        });
        setLoading(true);
        disableAccountThirdList(data)
            .then((response) => {
                if (response.data && response.data.status) {
                    setShowNotification(true);
                    setNotificationMessage(response.data.message);
                    if (response.data && response.data.status &&
                        response.data.data &&
                        response.data.status === Status.UPDATED) {
                        dispatch(clearAccountThird());
                        setNotificationType("SUCCESS")
                        setSelectedRowKeys([])
                    } else {
                        setNotificationType("ERROR")
                    }
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setShowNotification(true);
                setNotificationType("ERROR")
                setNotificationMessage("Hubo un error inhabilitando las cuentas de terceros, intente más tarde.")
            })
    }

    const handleDeleteAccountThird = () => {
        setConfirmDelete(true);
    }

    const deleteAccountThi = () => {
        const data: DeleteAccountThirdRequest = { thirdAccountsIds: [] };
        selectedRowKeys.forEach((element) => {
            if (element.valueOf()) {
                data.thirdAccountsIds.push(element.toString());
            }
        })

        setConfirmDelete(false);
        setSelectedRowKeys([]);
        setLoading(true);
        deleteAccountThird(data)
            .then((response) => {
                if (response.data && response.data.status) {
                    setShowNotification(true);
                    setNotificationMessage(response.data.message);
                    if (response.data && response.data.status &&
                        response.data.status === Status.OK) {
                        dispatch(clearAccountThird());
                        setNotificationType("SUCCESS");
                    } else {
                        setNotificationType("ERROR");
                    }
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setShowNotification(true);
                setNotificationType("ERROR");
                setNotificationMessage("Hubo un error borrando las cuentas de terceros, intente más tarde.");
            })
    }

    const handleClose = () => {
        setOpenModalImport(false)
    };

    const validateData = (objectToImport: AccountThirdObj[]) => {
        let messageErrors = "";
        let requestAccountThird: AccountThirdDataRequest[] = [];
        // revisa si hay campos vacios en campos requeridos
        objectToImport.forEach((elem: AccountThirdObj, index: number) => {
            let hasError = false;
            // campos obligatorios en general
            if (elem.name == null || elem.alias == null ||
                elem.bankAccountNumber == null ||
                elem.bankName == null || elem.typeAccount == null) {
                hasError = true;
            }
            // fin campos obligatorios en general
            if (hasError) {
                const itemMessage = `El registro ${index + 2} tiene campos obligatorios inválidos.\n`;
                messageErrors += itemMessage;
            } else {
                let dataBank = _.find(banks, { bankName: elem.bankName });

                let typeAccount = elem.typeAccount === "Tarjeta de Debito" ? AccountType.DEBIT
                    : elem.typeAccount === "Teléfono celular" ? AccountType.CEL
                        : AccountType.CLABE

                let accountThirdObj: AccountThirdDataRequest = {
                    name: elem.name,
                    typeAccount: typeAccount,
                    divisa: 'MXN',
                    alias: elem.alias,
                    bankAccountNumber: elem.bankAccountNumber,
                    bankName: dataBank?.bankCode as Institution,
                    enabled: true,
                    rfc: elem.rfc === null ? elem.rfc : "",
                    street: elem.street === null ? elem.street : "",
                    streetNumber: elem.streetNumber === null ? elem.streetNumber : "",
                    zip: elem.zip === null ? elem.zip : "",
                    state: elem.state === null ? elem.state : "",
                    municipality: elem.municipality === null ? elem.municipality : "",
                    country: 'Mexico',
                };
                requestAccountThird.push(accountThirdObj)
            }
        });
        if (!_.isEmpty(messageErrors)) {
            setNotificationType('ERROR');
            setNotificationMessage(messageErrors);
            setShowNotification(true);
        } else {
            let accountArray: CreateMultipleAccountThirdRequest = {
                accountsThirds: requestAccountThird
            }
            setLoading(true);
            addAccountThird(accountArray)
                .then((response) => {
                    if (response.data.status === Status.CREATED) {
                        setOpenModalImport(false);
                        dispatch(clearAccountThird());
                        setNotificationType('SUCCESS');
                        setNotificationMessage(response.data.message);
                    } else {
                        setNotificationType('ERROR');
                        setNotificationMessage(response.data.message);
                    }
                    setLoading(false);
                    setShowNotification(true);
                })
                .catch(() => {
                    setLoading(false);
                    setNotificationType('ERROR');
                    setNotificationMessage("No fue posible crear la cuenta de tercero en este momento, intente más tarde.");
                    setShowNotification(true);
                })
        }
    };

    useEffect(() => {
        setFileList([])
    }, [openModalImport]);

    const handleConfirm = () => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e: any) => {
            const bufferArray = e?.target.result;
            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const heading = [
                [
                    'name',
                    'alias',
                    'bankAccountNumber',
                    'bankName',
                    'typeAccount',
                    'rfc',
                    'street',
                    'streetNumber',
                    'zip',
                    'state',
                    'municipality',
                ]
            ];
            XLSX.utils.sheet_add_aoa(ws, heading);
            const data: any = XLSX.utils.sheet_to_json(ws);
            validateData(data)
        };
    };

    const onChangeFileUpload: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfAccounts} title="Cuentas de Terceros">
            <Row gutter={[15, 25]}>
                <Col span={24}>
                    <Row gutter={[10, 10]}>
                        <Col xs={24} md={6} xl={4} >
                            <CardDashboard
                                title={"Crear Cuenta de Tercero"}
                                icon={image.createThirdAccIcon}
                                action={"/admin/accountsThird/add-account"}
                                height={135} />
                        </Col>
                        <Col xs={24} md={6} xl={4} >
                            <CardDashboard
                                title={"Importar"}
                                icon={image.importIcon}
                                isFunction={() => setOpenModalImport(true)}
                                height={135} />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <div style={styles.primaryContainer}>
                        <Row gutter={[16, 16]} style={styles.rowContainer}>
                            <Col xs={24} md={8} xxl={5} >
                                <Search
                                    onSearch={(value: string) => headerSearcher(value)}
                                    onChange={(value) =>
                                        _.isEmpty(value.target.value)
                                            ? setAccountsThirdListTmp(accountsThirdList)
                                            : null
                                    }
                                    placeholder="Buscar por nombre, cuenta o RFC"
                                />
                            </Col>
                            <Col xs={0} sm={0} md={5} xxl={12} />
                            <Col xs={24} md={11} lg={11} xxl={7}
                                style={{ display: 'flex', justifyContent: 'space-evenly' }}
                            >
                                <Buttons
                                    type="primary"
                                    title="Habilitar"
                                    action={() => enableOrDisableAccounts(true)}
                                    icon={<PlusOutlined />}
                                    color="#40CD9D"
                                    isDisabled={!hasSelected}
                                    iconLeft
                                />
                                <Buttons
                                    type="primary"
                                    title="Inhabilitar"
                                    color="#FF3D6B"
                                    action={() => enableOrDisableAccounts(false)}
                                    icon={<MinusOutlined />}
                                    isDisabled={!hasSelected}
                                    iconLeft
                                />
                                {
                                    userRol === "admin" ? (
                                        <Buttons
                                            type="primary"
                                            title="Borrar"
                                            color="#765CE9"
                                            isDisabled={!hasSelected}
                                            action={handleDeleteAccountThird}
                                            icon={<DeleteOutlined />}
                                            iconLeft
                                        />
                                    ) : null
                                }
                            </Col>
                        </Row>
                        <Col xs={24}>
                            <Table
                                dataSource={accountsThirdListTmp}
                                columns={columns}
                                onChange={handleTableChange}
                                locale={{ emptyText: "No hay datos disponibles" }}
                                rowSelection={rowSelection}
                                rowKey={(record) => record.id}
                                scroll={{ y: 400, x: 700 }}
                                size="large"
                                pagination={tableParams.pagination}
                                loading={{
                                    spinning: loading,
                                    indicator: <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />,
                                }}
                            />
                        </Col>
                    </div>
                </Col>
            </Row>
            <GenericModal
                title={"Estimado Usuario"}
                show={confirmDelete}
                onClose={() => setConfirmDelete(false)}
                onConfirm={deleteAccountThi}
                showActionButtons
            >
                <Row style={{ padding: 20 }}>
                    <Text>
                        ¿Estas seguro que deseas eliminar las cuentas de terceros seleccionados?
                    </Text>
                </Row>
            </GenericModal>
            <GenericModal
                title={"Importar Cuentas de Terceros"}
                show={openModalImport}
                onConfirm={() => handleConfirm()}
                onClose={() => handleClose()}
                showActionButtons
                disableConfirmButton={disableConfirmButton}
            >
                <Row style={{ padding: 20 }}>
                    <Spin spinning={loading}>
                        <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
                            <Col style={styles.inputUploadRoot}>
                                <div style={styles.inputContainerRow}>
                                    <Text style={styles.inputTextTitleModal}>
                                        Descargar Plantilla
                                    </Text>
                                    <Link
                                        to="/files/Plantilla_Cuentas_Terceros.xlsx"
                                        target="_blank"
                                        download
                                        style={{ marginLeft: 10 }}
                                    >
                                        Plantilla
                                    </Link>
                                </div>
                            </Col>
                            <Col style={styles.inputUploadRoot}>
                                <div style={styles.inputContainerRow}>
                                    <Text style={styles.inputTextTitleModal}>
                                        Subir Plantilla
                                    </Text>
                                    <Space style={styles.buttonUploadModal}>
                                        <Upload maxCount={1}
                                            fileList={fileList}
                                            onChange={onChangeFileUpload}
                                            beforeUpload={async file => {
                                                const isKeyFile = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                                                if (!isKeyFile) {
                                                    message.error(`${file.name} no es de tipo .xlsx`);
                                                }
                                                else {
                                                    setFile(file)
                                                }
                                                return isKeyFile || Upload.LIST_IGNORE;
                                            }}>
                                            <div style={{ marginLeft: 7 }}>
                                                <Buttons
                                                    type={"primary"}
                                                    title={"Elegir archivo"}
                                                    icon={<UploadOutlined />}
                                                    color="#3d84ff"
                                                />
                                            </div>
                                        </Upload>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Spin>
                </Row>
            </GenericModal>
            <ModalNotification
                title={"Cuentas de Terceros"}
                message={notificationMessage}
                alertType={notificationType}
                show={showNotification}
                onClose={() => setShowNotification(false)}
            />
        </SubDefaultLayout>
    );
};

const styles = StyleSheet.create({
    primaryContainer: {
        padding: "20px",
        borderRadius: 10,
        backgroundColor: 'white',
        boxShadow: "rgba(177, 179, 185, 0.51) 5px 8px 24px 5px",
    },
    title: {
        paddingLeft: "8px",
        fontSize: 18,
    },
    import: {
        color: "#000",
        borderColor: "#000"
    },
    rowContainer: {
        justifyContent: 'flex-end',
        marginBottom: '20px',
    },
    inputTextTitleModal: {
        fontSize: 15,
        width: "30%"
    },
    buttonUploadModal: {
        width: "70%",
    },
    inputContainerRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'baseline',
    },
    inputUploadRoot: {
        width: "100%",
    }
});

export default AccountsThird;
