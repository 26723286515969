import { Col, Modal, Row } from 'antd';
import { StyleSheet } from "../../../StyleSheet";
import { useEffect, useState } from 'react';
import { PeriodInterface, labelMonth } from '../../../functions/Functions';
import SelectInput from '../../../components/SelectInput';
import { format } from 'date-fns';

interface ModalSelectProps {
    openModal: boolean;
    handleClose: Function;
    periods: PeriodInterface;
    printStatement: (period: string) => void;
    isLoading: boolean;
};

const ModalEdoCuenta = (props: ModalSelectProps) => {
    const { openModal, handleClose, periods, printStatement, isLoading } = props;
    const [indexOfCurrentYear, setIndexOfCurrentYear] = useState<number>(0);
    const [indexOfCurrentMonth, setIndexOfCurrentMonth] = useState<number>(0);
    const currentDate = format(new Date(), 'MM-yyyy');
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
    const [monthSelected, setMonthSelected] = useState<string>("");
    const [periodSelected, setPeriodSelected] = useState<string>("");

    const handleChangeYear = (value: string) => {
        let index = periods.periods.findIndex(elem => elem.year === value);
        let indexRequest = 0;
        // si el arreglo de meses del index es mayor a 1, es para que se establezca el del index  del monthSelected
        // sino el index que le corresponde será el 0 (es para cuando solo tiene enero)
        if (periods && periods.periods && index >= 0 &&
            periods.periods[index].months && periods.periods[index].months.length > 1) {
            indexRequest = Number(monthSelected);
        }
        setIndexOfCurrentYear(index);
        setIndexOfCurrentMonth(indexRequest);
    };

    const handleChangeMonth = (value: string) => {
        setMonthSelected(value)
        let index = periods.periods[indexOfCurrentYear].months.findIndex(elem => elem === value)
        setIndexOfCurrentMonth(index);
    };

    useEffect(() => {
        const period = `${periods.periods[indexOfCurrentYear]?.months[indexOfCurrentMonth]}-${periods.periods[indexOfCurrentYear]?.year}`;
        if (period === currentDate) {
            setButtonDisabled(true);
        } else {
            setPeriodSelected(period);
            setButtonDisabled(false);
        }
    }, [currentDate, indexOfCurrentYear, indexOfCurrentMonth, periods, monthSelected]);

    return (
        <>
            <Modal
                title={"Descargar estado de cuenta"}
                open={openModal}
                centered
                onOk={() => printStatement(periodSelected)}
                onCancel={() => handleClose()}
                okText="Confirmar"
                cancelText="Cerrar"
                cancelButtonProps={{ disabled: isLoading }}
                confirmLoading={isLoading}
                // Deshabilitar el botón Confirmar si la fecha actual es igual al periodo seleccionado
                okButtonProps={{ disabled: buttonDisabled }}
            >
                <Row gutter={[16, 12]}>
                    {(periods.periods[indexOfCurrentYear]) && (
                        <>
                            <Col xs={12} sm={12} md={12}>
                                <div style={styles.inputContainer}>
                                    <SelectInput
                                        label={"Año"}
                                        value={periods.periods[indexOfCurrentYear].year}
                                        options={periods.periods.map((elem) => ({
                                            label: elem.year,
                                            value: elem.year
                                        }))}
                                        onChange={(value) => handleChangeYear(value)}
                                        disable={isLoading}
                                    />
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                                <div style={styles.inputContainer}>
                                    <SelectInput
                                        label={"Mes"}
                                        value={monthSelected}
                                        options={periods.periods[indexOfCurrentYear].months.map(elem => ({
                                            value: elem,
                                            label: labelMonth(elem).label
                                        }))}
                                        onChange={(value) => handleChangeMonth(value)}
                                        disable={isLoading}
                                    />
                                </div>
                            </Col>
                        </>
                    )}
                </Row>
            </Modal>
        </>
    );
};

const styles = StyleSheet.create({
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
});

export default ModalEdoCuenta;
